import { Dashboard } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = ({ showSideBar, setShowSideBar }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);
  const [isMenuOpen2, setIsMenuOpen2] = useState(false);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsMenuOpen1(false);
    setIsMenuOpen2(false);
  };
  const handleMenuToggle1 = () => {
    setIsMenuOpen(false);
    setIsMenuOpen1(!isMenuOpen1)
  }
  const handleSetActiveMenu = (menu) => {
    setActiveMenu(menu);
  };
  const DashboardBtn = () => {
    setShowSideBar(false);
    setIsMenuOpen2(true);
    setIsMenuOpen(false);
    setIsMenuOpen1(false);
    setActive1(false);
    setActive2(false);
    setActive3(false);
  };
  return (
    <aside className={`sidebar sidebar_admin ${showSideBar && "hidden"}`}>
      <div className="sidebar-start">
        <div className="head">
          {showSideBar ? (
            <img className="logo-side" src="/img/logo-side.png" />
          ) : (
            <img className="logo-default" src="/img/logo.png" alt="" />
          )}
        </div>
        <div className="sidebar-body">
          <div className="menu  menu_admin">
            <ul className="admin_header_dash">
              <li
                className={
                  location.pathname ===
                    "/Customer-dashboard/customer-registration" ||
                    location.pathname ===
                    "/Customer-dashboard/registration-agreement" ||
                    activeMenu === "customerRegistration" ||
                    isMenuOpen
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    handleMenuToggle();
                    handleSetActiveMenu("customerRegistration");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">Customer Registration</span>
                  <i className="arrow ph-bold ph-caret-down">
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(true);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/customer-registration"
                      className={`${active1 && "active"}`}
                    >
                      Registration Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(true);
                      setActive1(false);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/registration-agreement"
                      className={`${active2 && "active"}`}
                    >
                      Registration Agreement
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={
                  location.pathname ===
                    "/Customer-dashboard/my-quotation" ||
                    activeMenu === "customerMyQuotation" ||
                    isMenuOpen1
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    handleMenuToggle1();
                    handleSetActiveMenu("my-quotation");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">My Quotation</span>
                  <i className="arrow ph-bold ph-caret-down">
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen1 ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(false);
                      setActive3(true);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/my-quotation"
                      className={`${active3 && "active"}`}
                    >
                      My Quotation List
                    </Link>
                  </li>
                  {/* <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(true);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/customer-registration"
                      className={`${active1 && "active"}`}
                    >
                      Registration Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(true);
                      setActive1(false);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/registration-agreement"
                      className={`${active2 && "active"}`}
                    >
                      Registration Agreement
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li
                className={
                  location.pathname ===
                    "/Customer-dashboard/payments" ||
                    activeMenu === "customerPayments" 
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    // handleMenuToggle1();
                    handleSetActiveMenu("payments");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">Payments</span>
                  <i className="arrow ph-bold ph-caret-down">
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  {/* <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(true);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/customer-registration"
                      className={`${active1 && "active"}`}
                    >
                      Registration Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(true);
                      setActive1(false);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/registration-agreement"
                      className={`${active2 && "active"}`}
                    >
                      Registration Agreement
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li
                className={
                  location.pathname ===
                    "/Customer-dashboard/order-history" ||
                    activeMenu === "customerOrderHistory" 
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    // handleMenuToggle1();
                    handleSetActiveMenu("order-history");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">Order History</span>
                  <i className="arrow ph-bold ph-caret-down">
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  {/* <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(true);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/customer-registration"
                      className={`${active1 && "active"}`}
                    >
                      Registration Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(true);
                      setActive1(false);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/registration-agreement"
                      className={`${active2 && "active"}`}
                    >
                      Registration Agreement
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li
                className={
                  location.pathname ===
                    "/Customer-dashboard/emails" ||
                    activeMenu === "customerEmails" 
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    // handleMenuToggle1();
                    handleSetActiveMenu("emails");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">Emails</span>
                  <i className="arrow ph-bold ph-caret-down">
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  {/* <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(false);
                      setActive1(true);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/customer-registration"
                      className={`${active1 && "active"}`}
                    >
                      Registration Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                      setActive2(true);
                      setActive1(false);
                      setActive3(false);
                      setIsMenuOpen2(false);
                    }}
                  >
                    <Link
                      to="/Customer-dashboard/registration-agreement"
                      className={`${active2 && "active"}`}
                    >
                      Registration Agreement
                    </Link>
                  </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
