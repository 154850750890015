import React, { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { getEmployee } from '../../../Redux/action/Admin/EmployeeMasterAction';
import Beatloader from '../../../Common/Loaders/Beatloader';

const adminMainUrl = process.env.REACT_APP_API_SERVER;

const EmployeeDetails = ({ accessedModules, accessedPermission }) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);

  const token = Cookies.get('token');
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    }
  }, [])

  useEffect(() => {
    if (id) {
      try {
        let emp_id = atob(id);
        if (emp_id) {
          dispatch(getEmployee(emp_id));
        } else {
          toast.error("Please enter valid url.")
          navigate(-1);
        }
      } catch (error) {
        toast.error("Please enter valid url.")
        navigate(-1);
      }

    } else {
      toast.error("Please enter valid url.")
      navigate(-1);
    }
  }, [id]);
  let { employeeData, loadingEmployeeApi } = useSelector(state => state?.EmployeeMasterReducer);

  useEffect(() => {
    if (loadingEmployeeApi) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loadingEmployeeApi]);

  function formatDateToDDMMMYYYY(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const options = {
        day: '2-digit',   // 2-digit day
        month: 'short',   // short month name (e.g., Jan, Feb, Mar)
        year: 'numeric'   // 4-digit year
      };
      return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    } else {
      return "-";
    }

  };


  const scrolltoTop = () => {
    window.scroll(0, 0);
  }

  return (
    <>
      <Toaster />
      {
        showLoader ?
          <Beatloader />
          :
          <div className="container">
            <div className="col-md-12">
              <div className="row stat-cards">
                <div className="col-md-6 col-xl-6">
                  <div className="main-nav-det">
                    <a onClick={() => { navigate(-1) }} className="query_info"><img src="/img/re_darect.png" alt="Query" /> Employee Details</a>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6">
                  {accessedPermission.includes("edit_employee") && <div className="button-Edit-admin">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate('/admin-dashboard/employee-registration-update');
                        Cookies.set("emply_id", employeeData?.userInfo?.id)
                      }}>Edit</a>
                  </div>}
                </div>
              </div>
              <div className="admin_detail_main_list">
                <div className="col-lg-12">
                  <div className="admin_profile_photo_main mb-3">
                    <div className="admin_profile_photo">
                      <span className="admin_profile_photo_img"><img src={`${adminMainUrl}/${employeeData?.userInfo?.profile_image}`} alt="profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "/img/Luna_Dasia.png";
                        }}
                      /></span>
                    </div>
                    <div className="admin_profile_text">
                      <h3>{employeeData?.userInfo?.first_name} {employeeData?.userInfo?.middle_name} {employeeData?.userInfo?.last_name}</h3>
                      <h4>{employeeData?.userInfo?.designation ? employeeData?.userInfo?.designation?.name : ""} - {employeeData?.userInfo?.departments ? employeeData?.userInfo?.departments?.name : ""}</h4>
                    </div>
                  </div>

                </div>
                <div className="col-lg-12">

                  <div className="users-table-admin table-responsive mb-4">
                    <table className="posts-table">
                      <thead>
                        <tr className="personal-table-admin-th">
                          <th colSpan="4" className="text-left">Personal Details</th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr className="personal-table-admin-td">
                          <td>
                            First Name
                          </td>
                          <td>
                            {employeeData?.userInfo?.first_name}
                          </td>
                          <td>
                            Country
                          </td>
                          <td>
                            {employeeData?.userInfo?.EmplyPermanentAddress ? (employeeData?.userInfo?.EmplyPermanentAddress?.country ? employeeData?.userInfo?.EmplyPermanentAddress?.country?.name : "-") : "-"}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Middle Name
                          </td>
                          <td>
                            {employeeData?.userInfo?.middle_name ?? "-"}
                          </td>
                          <td>
                            State
                          </td>
                          <td>
                            {employeeData?.userInfo?.EmplyPermanentAddress ? (employeeData?.userInfo?.EmplyPermanentAddress?.state ? employeeData?.userInfo?.EmplyPermanentAddress?.state?.name : "-") : "-"}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Last Name
                          </td>
                          <td>
                            {employeeData?.userInfo?.last_name ?? "-"}
                          </td>
                          <td>
                            City
                          </td>
                          <td>
                            {employeeData?.userInfo?.EmplyPermanentAddress ? (employeeData?.userInfo?.EmplyPermanentAddress?.cityData ? employeeData?.userInfo?.EmplyPermanentAddress?.cityData?.name : "-") : "-"}

                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Employee ID
                          </td>
                          <td>
                            {employeeData?.userInfo?.unique_id}
                          </td>
                          <td>
                            DOB
                          </td>
                          <td>
                            {formatDateToDDMMMYYYY(employeeData?.userInfo?.DOB)}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            ID Card
                          </td>
                          <td>
                            {employeeData?.userInfo?.proofType ? employeeData?.userInfo?.proofType?.name : "-"}
                          </td>
                          <td>
                            ID Number
                          </td>
                          <td>
                            {employeeData?.userInfo?.proof_id_number}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            E-mail ID
                          </td>
                          <td>
                            {employeeData?.userInfo?.contact_email}
                          </td>
                          <td>
                            Contact Number
                          </td>
                          <td>
                            {employeeData?.userInfo?.contact_number}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Permanent Address
                          </td>
                          <td>
                            {employeeData?.userInfo?.EmplyPermanentAddress ?
                              `${employeeData?.userInfo?.EmplyPermanentAddress?.street_number} ${employeeData?.userInfo?.EmplyPermanentAddress?.street} ${employeeData?.userInfo?.EmplyPermanentAddress?.country ? employeeData?.userInfo?.EmplyPermanentAddress?.country?.name : ""} ${employeeData?.userInfo?.EmplyPermanentAddress?.state ? employeeData?.userInfo?.EmplyPermanentAddress?.state?.name : ""} ${employeeData?.userInfo?.EmplyPermanentAddress?.cityData ? employeeData?.userInfo?.EmplyPermanentAddress?.cityData?.name : ""} ${employeeData?.userInfo?.EmplyPermanentAddress?.postal_code}`
                              : "-"}
                          </td>
                          <td>
                            Residential Address
                          </td>
                          <td>
                            {employeeData?.userInfo?.EmplyResidentialAddress ?
                              `${employeeData?.userInfo?.EmplyResidentialAddress?.street_number} ${employeeData?.userInfo?.EmplyResidentialAddress?.street} ${employeeData?.userInfo?.EmplyResidentialAddress?.country ? employeeData?.userInfo?.EmplyResidentialAddress?.country?.name : ""} ${employeeData?.userInfo?.EmplyResidentialAddress?.state ? employeeData?.userInfo?.EmplyResidentialAddress?.state?.name : ""} ${employeeData?.userInfo?.EmplyResidentialAddress?.cityData ? employeeData?.userInfo?.EmplyResidentialAddress?.cityData?.name : ""} ${employeeData?.userInfo?.EmplyResidentialAddress?.postal_code}`
                              : "-"}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Gender
                          </td>
                          <td>
                            {employeeData?.userInfo?.gender ? employeeData?.userInfo?.gender?.name : "-"}
                          </td>
                          <td>
                            Marital Status
                          </td>
                          <td colSpan="3">
                            {employeeData?.userInfo?.marital_status ?? "-"}
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div className="users-table-admin table-responsive mb-4">
                    <table className="posts-table">
                      <thead>
                        <tr className="personal-table-admin-th">
                          <th colSpan="4" className="text-left">Official Details </th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr className="personal-table-admin-td">

                          <td>
                            Department
                          </td>
                          <td>
                            {employeeData?.userInfo?.departments ? employeeData?.userInfo?.departments?.name : "-"}
                          </td>
                          <td>
                            Official Contact
                          </td>
                          <td>
                            {employeeData?.userInfo?.offical_contact_number}
                          </td>


                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Designation
                          </td>
                          <td>
                            {employeeData?.userInfo?.designation ? employeeData?.userInfo?.designation?.name : "-"}
                          </td>

                          <td>
                            Official E mail ID
                          </td>
                          <td colSpan="3">
                            {employeeData?.userInfo?.offical_contact_email}
                          </td>
                        </tr>
                        <tr className="personal-table-admin-td">
                          <td>
                            Role
                          </td>
                          <td>
                            {employeeData?.userInfo?.role ? employeeData?.userInfo?.role?.name : "-"}
                          </td>
                          <td>Reporting Manager</td>
                          <td>{employeeData?.userInfo?.user_reporting_list
                            ?.filter(val => val?.reporting_user_info) // Filter out null or undefined reporting_user_info
                            ?.map((val, ind) => (
                              <Link to={`/admin-dashboard/employee-details/${btoa(val?.reporting_user_info?.id)}`} key={ind + 1} style={{ color: "#000" }} onClick={scrolltoTop}>
                                {`${val?.reporting_user_info?.first_name} ${val?.reporting_user_info?.middle_name ?? ""} ${val?.reporting_user_info?.last_name ?? ""}`}
                              </Link>
                            ))
                            ?.reduce((prev, curr) => [prev, ', ', curr],[])
                          }</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="users-table-admin table-responsive mb-4">
                    <table className="posts-table">
                      <thead>
                        <tr className="personal-table-admin-th">
                          <th colSpan="4" className="text-left">Account Credential </th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr className="personal-table-admin-td">

                          <td>
                            User ID
                          </td>
                          <td>
                            {employeeData?.userInfo?.username}
                          </td>
                          <td></td>
                          <td></td>
                          {/* <td>
                            Password
                          </td>
                          <td>
                            {employeeData?.password}
                          </td> */}


                        </tr>


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
      }


    </>
  )
}

export default EmployeeDetails