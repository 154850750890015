import React, { useRef, useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { adminMainUrl } from '../../Config';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
export const ChangePassword = ({ id }) => {
    const [showPassword, setShowPassword] = useState(true);
    const [showPassword1, setShowPassword1] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate()
    const closeButtonRef = useRef(null);
    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
        },
        validate: values => {
            const errors = {};
            if (!values.old_password) {
                errors.old_password = 'Old Password is required';
            }
            if (!values.new_password) {
                errors.new_password = 'New Password is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                const token = Cookies.get("token");
                const updatePassword = {
                    old_password: values.old_password,
                    new_password: values.new_password,
                };

                const response = await fetch(`${adminMainUrl}/api/change-password`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-token": token, // Ensure the header key is in quotes
                    },
                    body: JSON.stringify(updatePassword),
                });
                if (response.ok) {
                    closeButtonRef.current.click();
                    toast.success('Password changed successfully');
                    navigate("/admin-login")
                    // You can add further actions here, like redirecting the user
                } else {
                    const errorData = await response.json(); // Parse the error response
                    toast.error(errorData.message || 'Failed to change password');
                }
            }
            catch (error) {
                toast.error('An error occurred');
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    return (
        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header px-0">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Change Password
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <form className="login-form mx-auto w-100" onSubmit={formik.handleSubmit}>

                        <div data-mdb-input-init="" className="form-outline mb-2">
                            <div className="pt-3">
                                <label htmlFor="old_password" className='text-black'>Old Password</label>
                                <div className='relative password-input'>
                                    <input
                                        type={showPassword ? "password" : "text"}
                                        id="old_password"
                                        className="form-control"
                                        placeholder="Enter old Password"
                                        name="old_password"
                                        value={formik.values.old_password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                    {
                                        showPassword ?
                                            <VisibilityOffIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                            :
                                            <VisibilityIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                    }
                                </div>
                                {formik.touched.old_password && formik.errors.old_password ? (
                                    <div className="error">{formik.errors.old_password}</div>
                                ) : null}

                            </div>
                            <div className="pt-3">
                                <label htmlFor="new_password" className='text-black'>New Password</label>
                                <div className='relative password-input'>
                                    <input
                                        type={showPassword1 ? "password" : "text"}
                                        id="new_password"
                                        className="form-control"
                                        placeholder="Enter New Password"
                                        name="new_password"
                                        value={formik.values.new_password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                    {
                                        showPassword1 ?
                                            <VisibilityOffIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword1(!showPassword1) }} />
                                            :
                                            <VisibilityIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword1(!showPassword1) }} />
                                    }
                                </div>
                                {formik.touched.new_password && formik.errors.new_password ? (
                                    <div className="error">{formik.errors.new_password}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className=" pt-1 mb-2 pb-1">
                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Set Password"}
                            </button>
                        </div>
                    </form>
                    {/* <div className="modal-footer"> */}
                    <button
                        ref={closeButtonRef}
                        style={{ display: 'none' }}
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>

                    {/* </div> */}
                </div>
            </div>
        </div>

    )
}
