import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getEmpList } from "../../../Redux/action/Admin/EmployeeAction";
import Beatloader from "../../../Common/Loaders/Beatloader";
import Skeleton from "react-loading-skeleton";
import SkeletonTableLoader from "../../../Common/Loaders/SkeletonTableLoader";
import ReactPaginate from "react-paginate";
import { Formik, useFormik } from "formik";

const adminMainUrl = process.env.REACT_APP_API_SERVER;

const AllEmployeeList = ({ accessedModules, accessedPermission }) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page_no = queryParams.get("page");

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(null); // Pages are 1-indexed
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const ITEMS_PER_PAGE = 10;

  const token = Cookies.get('token');
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    }
  }, [])

  useEffect(() => {
    const page = page_no ? parseInt(page_no) : 1;
    setCurrentPage(page);
  }, [page_no]);

  useEffect(() => {
    if (currentPage !== null) {
      // Ensure currentPage is set before dispatching the action
      dispatch(getEmpList(currentPage, ITEMS_PER_PAGE, queryParams));
    }
  }, [currentPage, dispatch]);

  const {
    loadingEmpListApi,
    empListDataError,
    empListData,
    empListTotalPage,
    empListCurrentPage,
    empListTotalData,
  } = useSelector((state) => state?.EmployeeReducer);

  useEffect(() => {
    if (loadingEmpListApi) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }

    if (empListData) {
      setList(empListData);
    }

    if (empListCurrentPage) {
      setCurrentPage(empListCurrentPage);
    }
    if (empListTotalData) {
      setTotalItems(empListTotalData);
    }
    if (empListTotalPage) {
      setTotalPages(empListTotalPage);
    }
  }, [
    loadingEmpListApi,
    empListDataError,
    empListData,
    empListTotalPage,
    empListCurrentPage,
    empListTotalData,
  ]);

  const handlePageClick = (selectedObject) => {
    const selectedPage = selectedObject.selected + 1; // react-paginate uses 0-indexed pages
    // navigate(`?page=${selectedPage}`);
    // setCurrentPage(selectedPage);
    const params = new URLSearchParams(location.search);
    params.set("page", selectedPage);
    navigate(`?${params.toString()}`);
    setCurrentPage(selectedPage);
  };

  //search and filter data

  const parseQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      search_by: params.get("search_by") || "",
      emply_id: params.get("emply_id") || "",
      emply_name: params.get("emply_name") || "",
      department: params.get("department") || "",
      designation: params.get("designation") || "",
      contact_number: params.get("contact_number") || "",
      contact_email: params.get("contact_email") || "",
    };
  };

  let formik = useFormik({
    initialValues: parseQueryParams(),
    onSubmit: (values) => {
      const params = new URLSearchParams(location.search);
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          params.set(key, values[key]);
        } else {
          params.delete(key);
        }
      });

      if (!params.has("page")) {
        params.set("page", "1");
      } else {
        params.set("page", "1");
      }
      const queryString = params.toString();
      navigate(`${location.pathname}?${queryString}`);
      // const currentPage = params.get('page') || 1;
      const currentPage = 1;
      const ITEMS_PER_PAGE = 10;
      dispatch(getEmpList(currentPage, ITEMS_PER_PAGE, queryString));
    },
  });

  const resetFilters = () => {
    try {
      formik.setValues({
        search_by: "",
        emply_id: "",
        emply_name: "",
        department: "",
        designation: "",
        contact_number: "",
        contact_email: "",
      });
      navigate(`/admin-dashboard/all-employee-list?page=1`)
    } catch (error) {
      console.log(error.message);
      toast.error("Some error occured!");
    }
  };

  function formatName(firstName, middleName, lastName, maxLength = 18) {
    const fullName = `${firstName || ''} ${middleName || ''} ${lastName || ''}`.trim();

    if (fullName.length > maxLength) {
      return fullName.slice(0, maxLength - 3) + '...';
    }

    return fullName;
  }

  const tableRef = useRef(null);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    const slider = tableRef.current;
    setIsGrabbing(true);
    slider.classList.add('grabbing'); // Apply grabbing CSS class
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    const slider = tableRef.current;
    setIsGrabbing(false);
    slider.classList.remove('grabbing'); // Remove grabbing CSS class
  };

  const handleMouseMove = (e) => {
    if (!isGrabbing) return;
    e.preventDefault();
    const slider = tableRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; // Speed of scroll
    slider.scrollLeft = scrollLeft - walk;
  };

  return (
    <>
      <Toaster />
      <div className="container">
        <div className="col-md-12">
          <div className="row stat-cards">
            <div className="col-md-6 col-xl-6">
              <div className="main-nav-det">
                <h2>List of Employees </h2>
              </div>
            </div>
            <div className="col-md-6">
              {accessedPermission?.includes("add_employee") && <div className="button-det-list">
                <button
                  // to="/admin-dashboard/employee-registration"
                  onClick={() => {
                    Cookies.remove("emply_id");
                    navigate("/admin-dashboard/employee-registration")
                  }}
                >
                  <span>
                    <img src="/img/place.png" alt="" />
                  </span>{" "}
                  Add New Employee
                </button>
              </div>}
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-9">
              <div className="search_main_filter">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row pt-1 pb-1 main_row">
                    <div className="col-md-4">
                      <div className="input_box_filet filter_icon">
                        <input
                          type="text"
                          id="search_by"
                          name="search_by"
                          className="form-control"
                          placeholder="Search"
                          value={formik.values.search_by}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span>
                          <img src="/img/search.png" alt="" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <div className="dropdown dropdown-filter-admin">
                        <a
                          className="dropdown-toggle input_box_id_button"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                            <img className="filter-img" src="/img/bi_filter.png" alt="" />
                          </span>
                          Filter
                        </a>

                        <div
                          className="dropdown-menu main_drop_filter_admin"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <table className="content-table">
                            <tbody>
                              <tr>
                                <td>
                                  <label htmlFor="#">Emp ID.</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Emp ID."
                                    name="emply_id"
                                    value={formik.values.emply_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label htmlFor="#">Emp Name</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    name="emply_name"
                                    value={formik.values.emply_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="#">Department</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Department"
                                    name="department"
                                    value={formik.values.department}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="#">Designation</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Designation"
                                    name="designation"
                                    value={formik.values.designation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="#">Phone No.</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone No."
                                    name="contact_number"
                                    value={formik.values.contact_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="#">Email ID.</label>
                                </td>
                                <td className="input_box_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Email ID."
                                    name="contact_email"
                                    value={formik.values.contact_email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button
                            type="submit"
                            className="d-flex align-items-center"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center buttons-filter">
                      <button
                        type="submit"
                        className="search-btn text-white w-fit"
                        style={{
                          background: "#04aed2",
                          marginTop: "7px",
                          marginBottom: "15px",
                        }}
                      >
                        Search
                      </button>
                      <button
                        onClick={resetFilters}
                        className="search-btn text-white w-fit"
                        style={{
                          background: "#04aed2",
                          marginTop: "7px",
                          marginBottom: "15px",
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <hr />
            </div>
          </div>
          <div className="row pb-2 pt-2">
            <div className="col-lg-12">
              <div className="users-table-admin table-responsive border-redi">

                <div className="table-wrapper" ref={tableRef}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  onMouseMove={handleMouseMove}>
                  <table className="posts-table">
                    <thead>
                      <tr className="users-table-admin-th">
                        <th>S. No.</th>
                        <th>Emp ID.</th>
                        <th>Emp Name</th>
                        <th>Role</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Phone No.</th>
                        <th>Email ID.</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    {showLoader ? (
                      <tbody>
                        <SkeletonTableLoader colSpan={8} count={2} length={5} />
                      </tbody>
                    ) : (
                      <tbody>
                        {
                          list.length > 0 ?
                            list.map((emp, index) => {
                              const rowColor = index % 2 === 0 ? "#ffffff" : "#f0f0f0";
                              return (
                                <tr className="users-table-admin-td" key={index + 1} style={{ backgroundColor: rowColor }}>
                                  <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                                  <td>{emp.unique_id}</td>
                                  <td className="employee-profile-sec d-flex justify-content-start align-items-center">
                                    <span className="profile_solis">
                                      <img
                                        src={`${adminMainUrl}/${emp.profile_image}`}
                                        alt="profile"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = "/img/no-profile.png";
                                        }}
                                      />
                                    </span>
                                    {formatName(emp?.first_name, emp?.middle_name, emp?.last_name)}
                                  </td>
                                  <td>
                                    {emp?.role ? emp?.role?.name : "-"}
                                  </td>
                                  <td>
                                    {emp?.departments ? emp?.departments?.name : "-"}
                                  </td>
                                  <td>
                                    {emp?.designation ? emp?.designation?.name : "-"}
                                  </td>
                                  <td>
                                    <Link to={`tel:${emp?.contact_number}`}>
                                      {emp?.contact_number}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={`mailto:${emp?.contact_email}`}>
                                      {emp?.contact_email}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin-dashboard/employee-details/${btoa(
                                        emp.id
                                      )}`}
                                    >
                                      {" "}
                                      <div
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="View employee details"
                                      >
                                        <img
                                          src="/img/Vector.png"
                                          alt="view"
                                          className="pointer w-fit border-radius-none"
                                        />
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }) :
                            <tr className="users-table-admin-td">
                              <td colSpan={8}>
                                <i>No data found.</i>
                              </td>
                            </tr>
                        }
                      </tbody>
                    )}
                  </table>
                </div>
                {list.length > 0 && <div className="pagination-controls my-5 d-flex justify-content-center align-items-center react-custom-paginate">
                  <ReactPaginate
                    previousLabel={<img src="/img/right.png" alt="Previous" />}
                    nextLabel={<img src="/img/left.png" alt="Next" />}
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={currentPage - 1} // To sync ReactPaginate's internal state with currentPage
                  />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployeeList;
