import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
import { uploadImageSchema, validationCreateServiceSchema, uploadVideoSchema } from '../../../ValidationScema';
import { mainUrl } from '../../../Config';
import Api from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const QuoteRequestForm = () => {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date());
  const [productType, setProductTypes] = useState([]);
  const [cargoType, setCargoType] = useState([]);
  const [shipmentMode, setShipmentMode] = useState([]);
  const [shipmentType, setShipmentType] = useState([]);
  const [shipmentVia, setShipmentVia] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [selectedImg, setSelectedImg] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [quoteId, setQuoteId] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const isLastRowFilled = () => {
    const lastRow = formik.values.packageInfo[formik.values.packageInfo.length - 1];
    return Object.values(lastRow).every(value => value !== "");
  };
  const handleRemoveRow = (index) => {
    const updatedPackageInfo = formik.values.packageInfo.filter((_, i) => i !== index);
    formik.setFieldValue("packageInfo", updatedPackageInfo);
  };

  const handleAddRow = () => {
    if (isLastRowFilled()) {
      formik.setFieldValue("packageInfo", [
        ...formik.values.packageInfo,
        {
          pkg_type_id: "",
          mw_unit: "",
          total_gross_weight: "",
          tgw_unit: "",
          total_box: "",
          dimension_l: "",
          dimension_w: "",
          dimension_h: ""
        }
      ]);
    } else {
      Swal.fire('Error', 'Please fill in all fields before adding a new row.', 'error');
    }
  };

  // const handleRemoveRow = (index) => {
  //   const newPackageInfo = [...formik.values.packageInfo];
  //   newPackageInfo.splice(index, 1);
  //   formik.setFieldValue('packageInfo', newPackageInfo);
  // };
  // const [quotationId,setQuotationId]=useState('')
  const GetProductType = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/productTypeList`, "GET");
      const options = data?.data?.map(item => ({
        productId: item.id,
        value: item.name,
        label: item.name,
      }));
      setProductTypes(options);

    } catch (error) {
      setProductTypes([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetCargoType = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/cargoTypeList`, "GET");
      const options = data?.data?.map(item => ({
        cargoId: item.id,
        value: item.name,
        label: item.name,
      }));
      setCargoType(options);

    } catch (error) {
      setCargoType([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentMode = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/shipmentModeList`, "GET");
      const options = data?.data?.map(item => ({
        shipmentModeId: item.id,
        value: item.name,
        label: item.name,
      }));
      setShipmentMode(options);

    } catch (error) {
      setShipmentMode([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentType = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/shipmentTypeList`, "GET");
      const options = data?.data?.map(item => ({
        shipmentTypeId: item.id,
        value: item.name,
        label: item.name,
      }));
      setShipmentType(options);

    } catch (error) {
      setShipmentType([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentVia = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/shipmentViaList`, "GET");
      const options = data?.data?.map(item => ({
        shipmentViaId: item.id,
        value: item.name,
        label: item.name,
      }));
      setShipmentVia(options);

    } catch (error) {
      setShipmentVia([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetPackageType = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/packageTypeList`, "GET");
      const options = data?.data?.map(item => ({
        packageTypeId: item.id,
        value: item.name,
        label: item.name,
      }));
      setPackageType(options);

    } catch (error) {
      setPackageType([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetStatus = async () => {
    try {
      const data = await Api(
        `${mainUrl}/api/v.1/statusList`,
        "GET"
      );
      if (data.status === 200) {
        // const filteredData = data?.data?.filter(status =>
        //   status.status_name !== "Requested" &&
        //   status.status_name !== "process" &&
        //   status.status_name !=="In process" &&
        //   status.status_name !=="Sent" &&
        //   status.status_name !== "Sent for Price"
        // );
        setStatusList(data)
      } else {
        console.error("API call was not successful:", data.error);
      }
    } catch (error) {

      console.error("Error fetching data:", error);
    }
  };
  const unit = [
    { value: 'kg', label: 'kg' },

  ]
  // const unit = [
  //    packageTypeId: 1,
  //   value: kg,
  //   label: kg,]
  const createQuotationStatus = async (quotationId) => {
    try {
      const token = Cookies.get("token");
      const requesId = {
        quote_status_id: statusList?.data?.[1]?.id,
        quote_ids: [quotationId]
      };
      const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requesId),
      });
    } catch (error) {
      console.log(error)
    }
  }
  const cancelImage = (index) => {
    const newImages = uploadImageFormik.values.images.filter((_, i) => i !== index);
    uploadImageFormik.setFieldValue("images", newImages);
    setSelectedImg(newImages);
  }

  const cancelVideo = (index) => {
    const newVideos = uploadVideoFormik.values.upload_video.filter((_, i) => i !== index);
    uploadVideoFormik.setFieldValue("upload_video", newVideos);
    setSelectedVideo(newVideos);
  }

  const uploadImageFormik = useFormik({
    initialValues: {
      images: [],
    },
    validationSchema: uploadImageSchema, // Ensure this is defined
    onSubmit: async (values) => {
      const token = Cookies.get("token");
      const formData = new FormData();
      values.images.forEach((image, index) => {
        formData.append(`images`, image);
      });
      // formData.append('upload_video', values.upload_video);
      formData.append('quote_id', quoteId);  // Replace with actual quote_id

      try {
        const response = await fetch(`${mainUrl}/api/v.1/upload_image`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const updatedInformation = 2
        await createQuotationStatus(updatedInformation);
        toast.success(responseData?.message);
      } catch (error) {
        toast.error(error);
        console.error("Error uploading files:", error);
      }
    },
  });

  const uploadVideoFormik = useFormik({
    initialValues: {
      upload_video: [],
    },
    validationSchema: uploadVideoSchema, // Ensure this is defined
    onSubmit: async (values) => {
      const token = Cookies.get("token");
      const formData = new FormData();
      values.upload_video.forEach((video, index) => {
        formData.append(`upload_video`, video);
      });
      formData.append('quote_id', quoteId);  // Replace with actual quote_id
      try {
        const response = await fetch(`${mainUrl}/api/v.1/upload_video`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const updatedInformation = 2
        await createQuotationStatus(updatedInformation);
        toast.success(responseData?.message);
      } catch (error) {
        toast.error(error);
        console.error("Error uploading files:", error);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      mobile: "",
      email: "",
      origin: "",
      destination: "",
      product_name: "",
      product_type_id: "",
      shipment_type_id: "",
      shipment_mode_id: "",
      shipment_via_id: "",
      cargo_type_id: "",
      // required_service:"",
      expected_transit_time: "",
      detailed_requirement: "",
      agreement: 0,
      packageInfo: [
        {
          pkg_type_id: "",
          mw_unit: "",
          total_gross_weight: "",
          tgw_unit: "",
          total_box: "",
          dimension_l: "",
          dimension_w: "",
          dimension_h: ""
        }
      ],
    },
    validationSchema: validationCreateServiceSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const token = Cookies.get("token");

        const response = await fetch(`${mainUrl}/api/v.1/createQuotation`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          const responseData = await response.json();
          setQuoteId(responseData?.data?.id);
          const updatedInformation = responseData?.data?.id
          await createQuotationStatus(updatedInformation);
          navigate('/dashboard/query-list');
          uploadImageFormik.handleSubmit();
          uploadVideoFormik.handleSubmit();
          resetForm()
          toast.success(responseData?.message);
        } else {
          toast.error("Wrong Credentials");
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });
  useEffect(() => {
    GetProductType();
    GetCargoType();
    GetShipmentMode();
    GetShipmentType();
    GetShipmentVia();
    GetPackageType();
    GetStatus()
  }, []);
 
  const RequiredServiceOptions = [
    { value: 'Freight Forwarding', label: 'Freight Forwarding' },
    { value: 'Custom Clearance', label: 'Custom Clearance' },
    { value: 'Both', label: 'Both' },
  ];
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det">
              <h2>Fill the Quote Request Form</h2>
            </div>
            <form onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();

            }}>
              <div className="main_detail_form">
                <div className="main-nav-det">
                  <h4>
                    General information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Full Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        placeholder="Enter Full Name "
                        value={formik?.values?.full_name}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        pattern="[a-zA-Z\s]*"
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik?.touched.full_name && formik?.errors.full_name ? (
                        <div className="error">{formik?.errors.full_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Phone Number <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        name="mobile"
                        value={formik?.values?.mobile}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        pattern="\d*"
                        onKeyPress={(event) => {
                          if (!/\d/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik?.touched?.mobile && formik?.errors?.mobile ? (
                        <div className="error">{formik?.errors?.mobile}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Email Id <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        value={formik?.values.email}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched.email && formik?.errors?.email ? (
                        <div className="error">{formik?.errors?.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Origin <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Origin "
                        name="origin"
                        value={formik?.values?.origin}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.origin && formik?.errors?.origin ? (
                        <div className="error">{formik?.errors?.origin}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Destination <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Destination"
                        name="destination"
                        value={formik?.values?.destination}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik.touched.destination && formik.errors.destination ? (
                        <div className="error">{formik.errors.destination}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    Pickup Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Product Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="product_name"
                        className="form-control"
                        placeholder="Enter Product Name"
                        value={formik?.values?.product_name}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik.touched.product_name && formik.errors.product_name ? (
                        <div className="error">{formik.errors.product_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Product Type
                      </label>
                      <div className='mt-2'>

                        <Select
                          options={productType}
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("product_type_id", selectedOptions.productId)
                          }}

                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.product_type_id && formik.errors.product_type_id ? (
                        <div className="error">{formik.errors.product_type_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Type <span>*</span>
                      </label>
                      <div className="mt-2">
                        <Select
                          options={shipmentType}
                          onChange={(selectedOption) =>
                            formik.setFieldValue("shipment_type_id", selectedOption.shipmentTypeId)
                          }
                          onBlur={formik.handleBlur}
                        />

                      </div>
                      {formik.touched.shipment_type_id && formik.errors.shipment_type_id ? (
                        <div className="error">{formik.errors.shipment_type_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Mode <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select
                          options={shipmentMode}
                          onChange={(selectedOption) =>
                            formik.setFieldValue("shipment_mode_id", selectedOption.shipmentModeId)
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.shipment_mode_id && formik.errors.shipment_mode_id ? (
                        <div className="error">{formik.errors.shipment_mode_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Via <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select
                          options={shipmentVia}
                          onChange={(selectedOption) =>
                            formik.setFieldValue("shipment_via_id", selectedOption.shipmentViaId)
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.shipment_via_id && formik.errors.shipment_via_id ? (
                        <div className="error">{formik.errors.shipment_via_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Cargo Type <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select
                          options={cargoType}
                          onChange={(selectedOption) =>
                            formik.setFieldValue("cargo_type_id", selectedOption.cargoId)
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.cargo_type_id && formik.errors.cargo_type_id ? (
                        <div className="error">{formik.errors.cargo_type_id}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Required Service <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select
                          options={RequiredServiceOptions}
                          // value={RequiredServiceOptions.find(option => option.value === formik.values.required_service)}
                          // onChange={(selectedOption) =>
                          //   formik.setFieldValue("cargo_type_id", selectedOption.cargoId)
                          // }
                          onChange={(selectedOption) =>
                            formik.setFieldValue('required_service', selectedOption.value)
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>

                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Expected Transit Date <span>*</span>
                      </label>
                      <div className="custom-datepicker" style={{ position: "relative" }}>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => {
                            if (date < new Date().setHours(0, 0, 0, 0)) {
                              Swal.fire('Error', 'Expected Date can not in past date', 'error');
                              setStartDate(null);
                              formik.setFieldValue("expected_transit_time", "");
                            } else {
                              setStartDate(date);
                              formik.setFieldValue("expected_transit_time", date);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <img src="/img/Calendar.png" alt="" style={{ position: "absolute", top: "20px", right: "12px" }} />
                      </div>
                      {formik.touched.expected_transit_time && formik.errors.expected_transit_time ? (
                        <div className="error">{formik.errors.expected_transit_time}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Detailed Requirement <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="detailed_requirement"
                        className="form-control"
                        placeholder="Enter Detailed Requirement"
                        value={formik?.values?.detailed_requirement}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik.touched.detailed_requirement && formik.errors.detailed_requirement ? (
                        <div className="error">{formik.errors.detailed_requirement}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    Package Dimensions/Weight
                  </h4>
                </div>

                <div>

                  {formik.values.packageInfo.map((packageItem, index) => (
                    <div key={index}>
                      <div  className="row">
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Type of PKG <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select
                                options={packageType}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(`packageInfo.${index}.pkg_type_id`, selectedOption.packageTypeId)
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.packageInfo?.[index]?.pkg_type_id &&
                              formik.errors.packageInfo?.[index]?.pkg_type_id ? (
                              <div className="error">
                                {formik.errors.packageInfo[index].pkg_type_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing">
                            <div className="input_box_section">
                              <label>
                                Dimensions (L x W x H per box) <span>*</span>
                              </label>

                              <ul className='mb-0 ps-0 mt-2'>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="L"
                                      value={packageItem.dimension_l}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      name={`packageInfo.${index}.dimension_l`}
                                      pattern="\d*"
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="X"
                                      readOnly=""
                                      disabled
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="W"
                                      value={packageItem.dimension_w}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      name={`packageInfo.${index}.dimension_w`}
                                      pattern="\d*"
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="X"
                                      readOnly=""
                                      disabled
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="H"
                                      value={packageItem.dimension_h}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      name={`packageInfo.${index}.dimension_h`}
                                      pattern="\d*"
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </li>

                              </ul>
                              {formik.touched.packageInfo?.[index]?.dimension_l &&
                                formik.errors.packageInfo?.[index]?.dimension_l ? (
                                <div className="error">
                                  {formik.errors.packageInfo[index].dimension_l}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing-kg">
                            <div className="input_box_section">
                              <label>
                                Max Weight (per box) <span>*</span>
                              </label>

                              <ul className='mb-0 mt-2'>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max weight"
                                      value={packageItem.max_weight}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      name={`packageInfo.${index}.max_weight`}
                                      pattern="\d*"
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </li>

                                <li className='w-100'>
                                  <div className="input_box_section w-swction_main mb-0 ">
                                    <Select
                                      options={unit}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(`packageInfo.${index}.mw_unit`, selectedOption.value)
                                      }
                                      onBlur={formik.handleBlur}
                                    />

                                  </div>
                                </li>

                              </ul>
                              {formik.touched.packageInfo?.[index]?.max_weight &&
                                formik.errors.packageInfo?.[index]?.max_weight ? (
                                <div className="error">
                                  {formik.errors.packageInfo[index].max_weight}
                                </div>
                              ) : null}

                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing-kg">
                            <div className="input_box_section ">
                              <label>
                                Total Gross Weight <span>*</span>
                              </label>

                              <ul className='mt-2 mb-0'>
                                <li className="padd_left_inherit">
                                  <div className="input_box_section mb-0">
                                    <input
                                      pattern="\d*"
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      type="text"
                                      className="form-control"
                                      placeholder="Total Gross Weight"
                                      value={packageItem.total_gross_weight}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      name={`packageInfo.${index}.total_gross_weight`}
                                    />
                                  </div>
                                </li>

                                <li className='w-100'>
                                  <div className="input_box_section w-swction_main mb-0">
                                    <Select
                                      options={unit}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(`packageInfo.${index}.tgw_unit`, selectedOption.value)
                                      }
                                      onBlur={formik.handleBlur}
                                    />

                                  </div>
                                </li>

                              </ul>
                              {formik.touched.packageInfo?.[index]?.total_gross_weight &&
                                formik.errors.packageInfo?.[index]?.total_gross_weight ? (
                                <div className="error">
                                  {formik.errors.packageInfo[index].total_gross_weight}
                                </div>
                              ) : null}

                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Total no. of boxes <span>*</span>
                            </label>
                            <input
                              pattern="\d*"
                              onKeyPress={(event) => {
                                if (!/\d/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Total number of boxes"
                              value={packageItem.total_box}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name={`packageInfo.${index}.total_box`}
                            />
                            {formik.touched.packageInfo?.[index]?.total_box &&
                              formik.errors.packageInfo?.[index]?.total_box ? (
                              <div className="error">
                                {formik.errors.packageInfo[index].total_box}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div>
                        </div>

                      </div>
                      <div>
                        {formik.values.packageInfo.length > 1 && (
                          <button
                            className="remoove btn btn-danger w-fit my-3"
                            onClick={() => handleRemoveRow(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="col-md-6">
                    <div className="main-nav-det ">
                      <div className='d-md-flex justify-content-between align-items-center'>

                        <button
                          type="button"
                          className='bg-none '
                          onClick={handleAddRow}
                        >
                          <span className='me-2'>
                            <img src="/img/pla.png" alt="img" />
                          </span>
                          Add another package
                        </button>

                      </div>

                      <div className="main_package_formar">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          readOnly=""
                          placeholder="Download Package List Format"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main_section_detail">
                    <div className="main-nav-det">
                      <h2>Fill the Quote Request Form</h2>
                    </div>

                    <div className="related_to_main">
                      <h5>
                        Related documents or Videos
                      </h5>
                    </div>
                    <div className="related_to_main">
                      <h5>Upload relevant documents and specifications to help the forwarder</h5>
                    </div>
                    <ul className="main_list_numvar">
                      <li>
                        <p>
                          Uploading cargo-related documents, such as the proforma invoice and packing list, will help forwarders return the most accurate quotes.
                        </p>
                      </li>
                      <li>
                        <p>
                          Accepted document formats: .jpg, .jpeg, .png, .pdf, .docx, .doc, .xlsx, .xls. Accepted video formats: .avi, .mp4, .wav.
                        </p>
                      </li>
                      <li>
                        <p>
                          The maximum file size accepted is 10MB per file, with a limit of 3 files. For videos, the maximum size accepted is 100MB, with a limit of 1 video.
                        </p>
                      </li>
                    </ul>
                    <div className="main_select_photo">
                      <ul>
                        <li>
                          <div className="file-input image-input-box">
                            <input
                              type="file"
                              name="images"
                              accept="image/*"
                              multiple
                              id="images"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                if (files.length) {
                                  const newImages = [...uploadImageFormik.values.images, ...files];
                                  uploadImageFormik.setFieldValue("images", newImages);
                                  setSelectedImg(newImages);
                                } else {
                                  console.log("No files selected");
                                }
                              }}
                              onBlur={uploadImageFormik.handleBlur}
                            />
                            <button type="button" className="folder_main_bro label_1">
                              <span>
                                <img src="/img/upload.png" alt="file" />
                              </span>{" "}
                              Upload File
                            </button>
                            {uploadImageFormik.touched.images && uploadImageFormik.errors.images ? (
                              <div>{uploadImageFormik.errors.images}</div>
                            ) : null}
                          </div>
                          {uploadImageFormik.touched.images && uploadImageFormik.errors.images ? (
                            <div className="error">{uploadImageFormik.errors.images}</div>
                          ) : null}
                          {selectedImg && selectedImg.map((img, index) => (
                            <div key={index} className="mt-3 border border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative">
                              <CancelIcon onClick={() => cancelImage(index)} style={{ position: "absolute", top: "6px", right: "6px", cursor: "pointer" }} />
                              <img
                                style={{ width: "100px", height: "100px" }}
                                className="w-[100px] h-[100px] object-contain"
                                src={URL.createObjectURL(img)}
                                alt="Selected"
                              />
                            </div>
                          ))}
                        </li>
                        <li>
                          <div className="file-input video-input-box">
                            <input
                              type="file"
                              accept="video/*"
                              name="upload_video"
                              id="upload_video"
                              // multiple
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                if (files.length) {
                                  const newVideos = [...uploadVideoFormik.values.upload_video, ...files];
                                  uploadVideoFormik.setFieldValue("upload_video", newVideos);
                                  setSelectedVideo(newVideos);
                                } else {
                                  console.log("No files selected");
                                }
                              }}
                              onBlur={uploadVideoFormik.handleBlur}
                            />
                            <button type="button" className="folder_main_bro label_1">
                              <span>
                                <img src="/img/video.png" alt="video" />
                              </span>{" "}
                              Upload Videos
                            </button>
                            {uploadVideoFormik.touched.upload_video && uploadVideoFormik.errors.upload_video ? (
                              <div>{uploadVideoFormik.errors.upload_video}</div>
                            ) : null}
                          </div>
                          {uploadVideoFormik.touched.upload_video && uploadVideoFormik.errors.upload_video ? (
                            <div className="error">{uploadVideoFormik.errors.upload_video}</div>
                          ) : null}
                          {selectedVideo && selectedVideo.map((video, index) => (
                            <div key={index} className="border mt-3 border-[#cac8c8] rounded-md w-[110px] h-[110px] flex justify-center items-center relative">
                              <CancelIcon onClick={() => cancelVideo(index)} style={{ position: "absolute", top: "6px", right: "6px", cursor: "pointer" }} />
                              <video
                                style={{ width: "100px", height: "100px" }}
                                className="w-[100px] h-[100px] object-contain"
                                src={URL.createObjectURL(video)}
                                controls
                                alt="Selected"
                              />
                            </div>
                          ))}
                        </li>
                      </ul>
                    </div>
                    <div className="main-box-det-check mt-3">
                      <label className="users-table__checkbox" id="new_text">
                        <input
                          type="checkbox"
                          className="check"
                          name="agreement"
                          checked={formik.values.agreement === 1}
                          onChange={(event) => {
                            formik.setFieldValue("agreement", event.target.checked ? 1 : 0);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        I have read, understood and agreed to abide by the <b className='ms-2'>Buying Request Posting Rules</b>
                      </label>
                      {formik.touched.agreement && formik.errors.agreement ? (
                        <div className="error">{formik.errors.agreement}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mb-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn search-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuoteRequestForm