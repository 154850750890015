
import { adminMainUrl } from "../../Config";

//side bar and navbar//
export const fetchSidebarPermissionRequest = () => ({
    type: "FIND_SIDEBAR_PERMISSION_REQUEST",
});

export const fetchSidebarPermissionSuccess = (data) => ({
    type: "FIND_SIDEBAR_PERMISSION_SUCCESS",
    payload: data,
});

export const fetchSidebarPermissionFailure = (error) => ({
    type: "FIND_SIDEBAR_PERMISSION_FAILURE",
    payload: error,
});


export const getSidebarPermission = (token) => {
    return async function (dispatch) {
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/get-user-permission`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchSidebarPermissionSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in SidebarPermission function(redux):", err);
            })
    }
};

//sidebar and navbar//

