import React from 'react'
import Select from 'react-select';
const Goods = () => {
    const GoodTypes=[
        { value: 'ABCD', label: 'ABCD' },
        { value: 'ABCD', label: 'ABCD' },
        { value: 'ABCD', label: 'ABCD' },
    ]
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Goods Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>No. Of Pkgs.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Unit</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Gross Weight</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Unit</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Chargable Wt.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Goods Type</label>
                            <Select
                                    className='mt-2'
                                    options={GoodTypes}
                                />

                          
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Goods Description</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Marks &amp; Nos</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Goods