import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { GenderApi } from '../../../Redux/action/MasterAction';
import { adminMainUrl } from '../../../Config';
import GenderModal from '../../Modal/GenderModal';
import EditGender from '../../Modal/EditGender';
import toast, { Toaster } from 'react-hot-toast';
import { BeatLoader } from 'react-spinners';
import Pagination from '../../Pagination';
import Skeleton from 'react-loading-skeleton'
const Gender = ({ accessedModules, accessedPermission }) => {
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;
    const [reload, setReload] = useState(false);
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [GenderName, setGenderName] = useState(null)
    const [GenderId, setGenderId] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page
    // const { name } = useParams();
    const dispatch = useDispatch();
    const {
        loadingGenderTypeApi,
        findGenderTypeData,
        findGenderTypeDataError,

    } = useSelector(state => state?.MasterReducer);
    useEffect(() => {
        dispatch(GenderApi(adminMainUrl));
    }, [adminMainUrl, reload]);
    const handleSuccess = () => {
        // Toggle reload to trigger useEffect
        setReload(prev => !prev);
    };


    // Initialize Formik
    const statusChange = async (id) => {
        setSpinnerLoader(true)
        try {
            const response = await fetch(`${adminMainUrl}/api/gender/status-change`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': Cookies.get('token'), // Assuming token is stored in cookies
                },
                body: JSON.stringify({ id: id.toString() }),
            });

            const data = await response.json();

            if (response.ok) {
                setSpinnerLoader(false)
                handleSuccess(); // Call the success handler
                toast.success(data?.msg);
            } else {
                setSpinnerLoader(false)
                toast.error(data?.msg);
            }
        } catch (error) {
            setSpinnerLoader(false)
            console.error('Network error:', error);
        }
    };

    const handleChange = (id) => {
        if (accessedPermission.includes("state_change_master_data")) {
            statusChange(id);
        }
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = findGenderTypeData?.data

    const totalPages = Math.ceil(findGenderTypeData?.data?.length / itemsPerPage);

    // Function to change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Function for "Next" and "Previous" buttons
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const arr = new Array(5).fill(null);
    return (
        <>
            <Toaster />
            <div className="container">
                <div className="col-md-12">
                    <div className="row stat-cards align-items-center">
                        <div className="col-md-6 col-xl-6">
                            <div className="main-nav-det">
                                <h2>Gender Type</h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-6 justify-content-end">
                            {accessedPermission.includes("add_master_data") && <div className="button-det-list">
                                <button className='btn search-btn w-fit ' data-bs-toggle="modal"
                                    data-bs-target="#GenderType">
                                    <span>
                                        <img src="/img/place.png" alt="" />
                                    </span>{" "}
                                    Add Gender Type
                                </button>
                            </div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <hr />
                        </div>
                    </div>
                    <div className="row pb-2 pt-2">
                        <div className="col-lg-12">
                            <div className="users-table-admin table-responsive border-redi">
                                <table className="posts-table">
                                    <thead>
                                        <tr className="users-table-admin-th">
                                            <th>S. No.</th>
                                            <th>Gender Type</th>
                                            <th>Status</th>
                                            {accessedPermission.includes("edit_master_data") && <th>Actions</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingGenderTypeApi ?
                                                arr.map((value, index) => {
                                                    return (

                                                        <tr key={index}>
                                                            <td
                                                                colSpan="4"
                                                                style={{
                                                                    height: "70px",
                                                                    padding: "0px 27px",
                                                                    width: "100%",
                                                                    textAlign: "center"
                                                                }}
                                                            >
                                                                <Skeleton count={2} />
                                                            </td>

                                                        </tr>
                                                    )
                                                }) :
                                                spinnerLoader ?
                                                    <>
                                                        <tr>
                                                            <td
                                                                colSpan="5"
                                                                style={{
                                                                    height: "70px",
                                                                    padding: "0px 27px",
                                                                    width: "100%",
                                                                    textAlign: "center"
                                                                }}
                                                            >
                                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>

                                                                    <BeatLoader />
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </> :
                                                    currentData?.map((value, index) => {
                                                        return (
                                                            <tr key={index} className="users-table-admin-td">
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    {value?.name}
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch d-flex justify-content-center">
                                                                        <div
                                                                            type="button"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="bottom"
                                                                            title={value.status === true ? "active" : "inactive"}
                                                                        >
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`flexSwitchCheckChecked-${index}`}
                                                                                checked={value.status === true}
                                                                                onChange={() => handleChange(value.id)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {accessedPermission.includes("edit_master_data") && <td>
                                                                    <div
                                                                        onClick={() => {
                                                                            setGenderId(value?.id);
                                                                            setGenderName(value?.name)
                                                                        }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#editGenderType"> <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="edit">
                                                                            <img src="/img/edit-line.png" alt="view" className='pointer w-fit border-radius-none' />
                                                                        </div>
                                                                    </div>
                                                                </td>}
                                                            </tr>
                                                        )
                                                    })
                                        }
                                    </tbody>
                                </table>

                                <Pagination data={currentItems} dataPerPage={dataPerPage} setDataOffset={setDataOffset} dataOffset={dataOffset} setPageCount={setPageCount} pageCount={pageCount} setCurrentData={setCurrentData} currentData={currentData} />
                                {/* <div className="pagination-controls my-5 d-flex justify-content-center align-items-center">
                                    <button onClick={handlePrevious} disabled={currentPage === 1}>
                                        <img src="/img/right.png" className="mx-2" alt="left" />
                                    </button>
                                    <span className="search-btn py-2 px-3 mx-3">{currentPage}</span>
                                    <button onClick={handleNext} disabled={currentPage === totalPages}>
                                        <img src="/img/left.png" className="mx-2" alt="right" />
                                    </button>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GenderModal id="GenderType" onSuccess={handleSuccess} />
            <EditGender id='editGenderType' onSuccess={handleSuccess} GenderTypeId={GenderId} GenderTypeName={GenderName} />
        </>
    )
}

export default Gender;