import React, { useRef, useState,useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
const InProcess = ({ headers, loading, arr, List, quotationId ,fetchQuotations,count,setCount}) => {
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    // const [count, setCount] = useState(1);
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scroll speed here
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };
    const totalPages = List?.pagination?.totalPages || 1;
    const navigate = useNavigate();
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function capitalizeText(text) {
        return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    }
    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCount(pageNumber);
        }
    };
    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (count <= 3) {
                pageNumbers.push(1, 2, 3, 4, '...', totalPages);
            } else if (count > 3 && count < totalPages - 2) {
                pageNumbers.push(1, '...', count - 1, count, count + 1, '...', totalPages);
            } else {
                pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            }
        }
        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();
    useEffect(() => {
        fetchQuotations()
    }, [count]);
    return (
        <div className="users-table border-redi">

            <div className="table-wrapper" ref={scrollContainerRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
                <table className="posts-table">
                    <thead>
                        <tr className="users-table-admin-th">
                            {headers.map((header) => (
                                <th key={header}>{capitalizeText(header)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                arr.map((value, index) => {
                                    return (

                                        <tr key={index}>
                                            <td
                                                colSpan="12"
                                                style={{
                                                    height: "70px",
                                                    padding: "0px 27px",
                                                    width: "100%",
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Skeleton count={2} />
                                            </td>

                                        </tr>
                                    )
                                }) :
                                List?.data?.length ?
                                    List?.data?.map((value, index) => {
                                        const rowColor = index % 2 === 0 ? "#ffffff" : "#f0f0f0";
                                        return (
                                            <tr key={index} style={{ backgroundColor: rowColor }}>
                                                <td className="color_1 text-center">{value?.request_no}</td>
                                                <td className="color_1">
                                                    {formatDate(value?.createdAt)}
                                                </td>
                                                <td className="color_1">{value?.full_name}</td>
                                                <td className="color_1">{value?.email}</td>
                                                <td className="color_1">{value?.phone}</td>
                                                <td className="color_1">{value?.origin}</td>
                                                <td className="color_1">{value?.destination}</td>
                                                <td className="color_1">{value?.shipment_type?.name}</td>
                                                <td className="color_1">{value?.shipment_via?.name}</td>
                                                <td className="color_1">{value?.required_service?.name}</td>
                                                <td className="text-ellipsis color_1" title={value?.picked_user?.first_name
                                                    ? `${value.picked_user.first_name} ${value.picked_user.middle_name || ''} ${value.picked_user.last_name || ''}`.trim()
                                                    : "empty"}>
                                                    {
                                                        value?.picked_user?.first_name
                                                            ? `${value.picked_user.first_name} ${value.picked_user.middle_name || ''} ${value.picked_user.last_name || ''}`.trim()
                                                            : "empty"
                                                    }
                                                </td>
                                                {/* <td className="color_1">
                        {value?.picked_user?.first_name} {value?.picked_user?.middle_name} {value?.picked_user?.last_name}
                      </td> */}
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/admin-dashboard/query-info-give-rates', { state: { quotationId: value.id } }) }}>
                                                        <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                                            <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    (

                                        <tr>
                                            <td colSpan="12" style={{ height: "300px", width: "100%", textAlign: "center" }}>
                                                No data available
                                            </td>
                                        </tr>
                                    )
                        }
                    </tbody>
                </table>
            </div>
            {/* Pagination Controls */}
            <div className="pagination-controls my-5 d-flex justify-content-center align-items-center">
                <button
                    className=''
                    onClick={() => handlePageChange(count - 1)}
                    disabled={count === 1}
                >
                    <img src="/img/right.png" className='mx-2' alt="right" />
                </button>

                {pageNumbers.map((number, index) =>
                    number === '...' ? (
                        <span key={index} className="mx-2">...</span>
                    ) : (
                        <span
                            key={number}
                            className={`search-btn-1 py-2 px-3 mx-2 ${number === count ? 'active' : ''}`}
                            onClick={() => handlePageChange(number)}
                            style={{ cursor: 'pointer' }}
                        >
                            {number}
                        </span>
                    )
                )}

                <button
                    onClick={() => handlePageChange(count + 1)}
                    disabled={count === totalPages}
                >
                    <img src="/img/left.png" className='mx-2' alt="right" />
                </button>
            </div>

        </div>
    )
}

export default InProcess