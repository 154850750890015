import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from "js-cookie";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import Select from 'react-select'
import Swal from 'sweetalert2';

import { RateRequestSchema } from '../../../ValidationScema';
import { mainUrl } from '../../../Config';
import { v4 as uuidv4 } from 'uuid';
import QuotationAirExportModal from '../../Modal/QuotationAirExportModal';
const QuotationAirExportForm = () => {
  
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det">
              <h2>
                <Link to="/pricing-employee-dashboard/query-info-picked" className="query_info">
                  <img src="/img/re_darect.png" alt="Query" />
                </Link>
                Quotation Air Export Form
              </h2>
            </div>
            <div className="main_detail_form Air_Export_Form_bg">
              <form className="Air_Export_bg_main">
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/gen.png" alt="gen" />
                    </span>
                    Shipment Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Number <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Date of Creation <span>*</span></label>
                      <div className="custom-datepicker" style={{ position: "relative" }}>
                        <DatePicker/>
                        <img src="/img/Calendar.png" alt="" style={{ position: "absolute", top: "20px", right: "12px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Buying Reference Number</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Rate Validity <span>*</span> </label>
                      <div className="custom-datepicker" style={{ position: "relative" }}>
                        <DatePicker/>
                        <img src="/img/Calendar.png" alt="" style={{ position: "absolute", top: "20px", right: "12px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Validity Against</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Rise in</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                </div>

                {/* Company Information */}
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Company Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Company Name <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Phone Number <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Email ID <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>GSTIN <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Country <span>*</span> </label>
                      <Select
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>State <span>*</span> </label>
                      <Select
                        className="mt-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    General Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                      Customer Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Address <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Billing Account <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Attention To</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Airline <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Place of Receipt</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Commodity</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Air Port of Loading <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Cargo Weight (Kg) <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Air Port of Discharge <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Incoterms</label>
                      <input
                        type="text"
                        className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Tentative Transit Time <span>*</span>
                      </label>
                      <div className='custom-datepicker'>
                        <DatePicker
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Final Place of Delivery <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Notes</label>
                      <textarea
                        rows={3}
                        className="form-control"
                        placeholder="Examples..."
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="add-Company-button" style={{ cursor: "pointer" }}>
                      <span>
                        <img src="/img/twotone-add.png" alt="Add Company" />
                      </span>
                      Add Airline
                    </div>
                  </div>

                </div>

                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="add-Company-button">
                      <a href="">
                        <span>
                          <img src="/img/twotone-add.png" alt="Add Company" />
                        </span>
                        Airline Name
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Tariff Details
                  </h4>
                </div>

                        <div className={`flex_detail_main`}>
                          <div className="table_detail_tariff table-responsive">
                            <table className={`posts-table geeks `}>
                              <thead>
                                <tr className="users-table-tariff n_detail_comp">
                                  <th>Airline N.</th>
                                  <th>Charge Head</th>
                                  <th>Buyer Amount</th>
                                  <th>Seller Amount</th>
                                  <th>Narration</th>
                                  <th>Valid Up To</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>

                                </tr>
                              </thead>
                              <tbody>

                                 <tr className="main_table_row_new n_detail_comp">
                                    <td>ABC</td>
                                    <td>Manager</td>
                                    <td>25,000</td>
                                    <td>35,000</td>
                                    <td>Narration</td>
                                    <td>Valid Up To</td>
                                    <td>Currency</td>
                                    <td>Exchange Rate</td>
                                    <td>Unit</td>
                                    <td>FC Amount</td>
                                    <td>Rate</td>

                                  </tr>

                              </tbody>
                            </table>
                          </div>
                          <div className="box_button_main" data-bs-toggle="modal" data-bs-target="#myModal" >
                            <div style={{ cursor: "pointer" }}>
                              <img src="/img/twotone-add.png" alt="" />
                            </div>
                          </div>
                            <div className="box_button_main">
                              <div style={{ cursor: "pointer" }}>
                                <CloseIcon style={{ width: "15px" }} className='text-danger' />
                              </div>
                            </div>
                        </div>


                <div className="main-nav-det-button text-right main-Export-button">
                  {/* <Link to="/dashboard/query-list"> */}
                  <button type='submit' className="btn btn-primary" style={{ background: 'rgb(4, 174, 210)' }} onClick={() => { navigate('/pricing-employee-dashboard/send-pricing-list') }}>

                    Submit
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
<QuotationAirExportModal id="myModal"/>
      

    </>
  )
}

export default QuotationAirExportForm