import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import DatePicker from "react-datepicker";
import { Button, Paper } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import { useDispatch, useSelector } from 'react-redux';
// import toast, { Toaster } from 'react-hot-toast';
import { getCity, getCountry, getEmployee, getGender, getIdProof, getResidentialCity, getResidentialCountry, getResidentialState, getState } from '../../../Redux/action/Admin/EmployeeMasterAction';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
const adminMainUrl = process.env.REACT_APP_API_SERVER;

const PersonalDetailsUpdate = ({ activeStep, handleNext, handleBack }) => {
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const [startDate, setStartDate] = useState(eighteenYearsAgo);
  const [cheque, setCheque] = useState(null);
  const [adminProfile, setAdminProfile] = useState(null);
  const dispatch = useDispatch();
  let [genderOptions, setGenderOptions] = useState([]);
  let [idProofOptions, setIdProofOptions] = useState([]);
  let [sameAddress, setSameAddress] = useState(false);
  let [countryOptions, setCountryOptions] = useState([]);
  let [stateOptions, setStateOptions] = useState([]);
  let [cityOptions, setCityOptions] = useState([]);
  let [residentialCountryOptions, setResidentialCountryOptions] = useState([]);
  let [residentialStateOptions, setResidentialStateOptions] = useState([]);
  let [residentialCityOptions, setResidentialCityOptions] = useState([]);
  let [disable, setDisable] = useState(false);
  let [showLoader, setShowLoader] = useState(true);
  let [profileImagePath, setProfileImagePath] = useState("/img/profile.png");

  useEffect(() => {

    //get masters data
    dispatch(getGender());
    dispatch(getIdProof());
    dispatch(getCountry());
    dispatch(getResidentialCountry());

  }, []);


  const handleGetState = (id) => {
    try {
      if (id) {
        dispatch(getState(id));
      }
    } catch (error) {
      console.log("Error in handleGetState function:", error.message);
    }
  }
  const handleGetResidentialState = (id) => {
    try {
      if (id) {
        dispatch(getResidentialState(id));
      }
    } catch (error) {
      console.log("Error in handleGetResidentialState function:", error.message);
    }
  }
  const handleGetCity = (id) => {
    try {
      if (id) {
        dispatch(getCity(id));
      }
    } catch (error) {
      console.log("Error in handleGetCity function:", error.message);
    }
  }
  const handleGetResidentialCity = (id) => {
    try {
      if (id) {
        dispatch(getResidentialCity(id));
      }
    } catch (error) {
      console.log("Error in handleGetResidentialCity function:", error.message);
    }
  }


  //master -> Gender
  let genderData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.genderData : []);

  useEffect(() => {
    if (genderData) {
      let genderArr = [];
      genderData.map(val => { if (val.status) { genderArr.push({ value: val.id, label: val.name }) } });
      setGenderOptions(genderArr);
    }
  }, [genderData]);

  //master-> id proof
  let idproofData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.idproofData : []);
  useEffect(() => {
    if (idproofData) {
      let idproofArr = [];
      idproofData.map(val => { if (val.status) { idproofArr.push({ value: val.id, label: val.name }) } });
      setIdProofOptions(idproofArr);
    }
  }, [idproofData]);


  //master-> country
  let countryData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.countryData : []);
  useEffect(() => {
    if (countryData) {
      let countryArr = [];
      countryData.map(val => { if (val.status) { countryArr.push({ value: val.id, label: val.name }) } });
      setCountryOptions(countryArr);
    }
  }, [countryData]);

  //master-> state
  let stateData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.stateData : []);
  useEffect(() => {
    if (stateData) {
      let stateArr = [];
      stateData.map(val => { stateArr.push({ value: val.id, label: val.name }) });
      setStateOptions(stateArr);
    }
  }, [stateData]);

  //master-> city
  let cityData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.cityData : []);
  useEffect(() => {
    if (cityData) {
      let cityArr = [];
      cityData.map(val => { cityArr.push({ value: val.id, label: val.name }) });
      setCityOptions(cityArr);
    }
  }, [cityData]);

  //master-> residential country
  let residentialCountryData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.residentialCountryData : []);
  useEffect(() => {
    if (residentialCountryData) {
      let residentialCountryArr = [];
      residentialCountryData.map(val => { if (val.status) { residentialCountryArr.push({ value: val.id, label: val.name }) } });
      setResidentialCountryOptions(residentialCountryArr);
    }
  }, [residentialCountryData]);

  //master-> residential state
  let residentialStateData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.residentialStateData : []);
  useEffect(() => {
    if (residentialStateData) {
      let residentialStateArr = [];
      residentialStateData.map(val => { residentialStateArr.push({ value: val.id, label: val.name }) });
      setResidentialStateOptions(residentialStateArr);
    }
  }, [residentialStateData]);

  //master->residential city
  let residentialCityData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.residentialCityData : []);
  useEffect(() => {
    if (residentialCityData) {
      let residentialCityArr = [];
      residentialCityData.map(val => { residentialCityArr.push({ value: val.id, label: val.name }) });
      setResidentialCityOptions(residentialCityArr);
    }
  }, [residentialCityData]);

  //get employee details
  let { employeeData, loadingEmployeeApi } = useSelector(state => state?.EmployeeMasterReducer);

  const maritalStatusOptions = [
    { value: 'Married', label: 'Married' },
    { value: 'Unmarried', label: 'Unmarried' },
  ]

  const handleCheque = (event) => {
    const file = event.target.files[0];
    // formik.setFieldValue("warranty_doc", file);
    setCheque(file);
  };

  const handleRemoveCheque = () => {
    // formik.setFieldValue("warranty_doc", null);
    setCheque(null);
  };

  let initialValues = {
    profile_image: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    DOB: startDate,
    gender_id: "",
    marital_status: "",
    contact_number: "",
    contact_email: "",
    proof_type_id: "",
    proof_id_number: "",
    same_as_permanent_address: false,
    street_number: "",
    street: "",
    country_id: "",
    state_id: "",
    city_id: "",
    postal_code: "",
    r_street_number: "",
    r_street: "",
    r_country_id: "",
    r_state_id: "",
    r_city_id: "",
    r_postal_code: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      profile_image: Cookies.get('token') ? Yup.string().required("Please select profile image.") : Yup.string(),
      first_name: Yup.string().required("Please enter first name."),
      last_name: Yup.string().required("Please enter last name."),
      DOB: Yup.date().required("Please select DOB."),
      gender_id: Yup.string().required('Please select gender.'),
      marital_status: Yup.string().required('Please select marital status.'),
      contact_number: Yup.string().required("Please enter contact number."),
      contact_email: Yup.string().required("Please enter email-id.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid email"),
      proof_type_id: Yup.string().required('Please select ID proof.'),
      proof_id_number: Yup.string().required("Please enter ID number."),
      street_number: Yup.string().required("Please enter street number."),
      street: Yup.string().required("Please enter street."),
      country_id: Yup.string().required('Please select country.'),
      state_id: Yup.string().required('Please select state.'),
      city_id: Yup.string().required('Please select city.'),
      postal_code: Yup.string().required("Please enter postal code."),
      r_street_number: !sameAddress ? Yup.string().required("Please enter street number.") : Yup.string(),
      r_street: !sameAddress ? Yup.string().required("Please enter street.") : Yup.string(),
      r_country_id: !sameAddress ? Yup.string().required('Please select country.') : Yup.string(),
      r_state_id: !sameAddress ? Yup.string().required('Please select state.') : Yup.string(),
      r_city_id: !sameAddress ? Yup.string().required('Please select city.') : Yup.string(),
      r_postal_code: !sameAddress ? Yup.string().required("Please enter postal code.") : Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisable(true);
      try {
        const formData = new FormData();
        formData.append("emply_id", Cookies.get("emply_id"));
        formData.append("first_name", values.first_name);
        formData.append("middle_name", values.middle_name);
        formData.append("last_name", values.last_name);
        formData.append("DOB", values.DOB);
        formData.append("gender_id", values.gender_id);
        formData.append("marital_status", values.marital_status);
        formData.append("contact_number", values.contact_number);
        formData.append("contact_email", values.contact_email);
        formData.append("proof_type_id", values.proof_type_id);
        formData.append("proof_id_number", values.proof_id_number);
        formData.append("street_number", values.street_number);
        formData.append("street", values.street);
        formData.append("country_id", values.country_id);
        formData.append("state_id", values.state_id);
        formData.append("city_id", values.city_id);
        formData.append("postal_code", values.postal_code);
        formData.append("same_as_permanent_address", values.same_as_permanent_address == false ? 0 : 1);
        formData.append("r_street_number", values.r_street_number);
        formData.append("r_street", values.r_street);
        formData.append("r_country_id", values.r_country_id);
        formData.append("r_state_id", values.r_state_id);
        formData.append("r_city_id", values.r_city_id);
        formData.append("r_postal_code", values.r_postal_code);
        formData.append("profile_image", values.profile_image);
        let config = { headers: { "auth-token": Cookies.get('token'), 'content-type': 'multipart/form-data', } }

        axios.post(`${adminMainUrl}/api/employee/update`, formData, config)
          .then(res => {
            if (res.status === 200) {
              resetForm();
              toast.success(res.data.msg);
              setDisable(false);
              handleNext();
            } else {
              toast.error(res.data.msg);
              setDisable(false);
            }
          }).catch(err => {
            console.log(err);
            toast.error(err.response.data.msg);
            setDisable(false);
          });

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.msg);
        setDisable(false);
      }
    }
  });


  let emply_id = Cookies.get("emply_id");
  useEffect(() => {
    if (emply_id) {
      dispatch(getEmployee(emply_id));
      if (loadingEmployeeApi) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
      formik.resetForm();
      formik.setFieldValue({ first_name: "" });
      formik.setValues({
        profile_image: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        DOB: initialValues.DOB,
        gender_id: "",
        marital_status: "",
        contact_number: "",
        contact_email: "",
        proof_type_id: "",
        proof_id_number: "",
        same_as_permanent_address: false,
        street_number: "",
        street: "",
        country_id: "",
        state_id: "",
        city_id: "",
        postal_code: "",
        r_street_number: "",
        r_street: "",
        r_country_id: "",
        r_state_id: "",
        r_city_id: "",
        r_postal_code: "",
      });
    }
  }, [emply_id, loadingEmployeeApi]);

  useEffect(() => {

    if (employeeData && employeeData?.userInfo) {
      if (employeeData?.userInfo.EmplyPermanentAddress) {
        if (employeeData?.userInfo?.EmplyPermanentAddress?.country_id) {
          handleGetState(Number(employeeData?.userInfo?.EmplyPermanentAddress?.country_id))
        }
        if (employeeData?.userInfo?.EmplyPermanentAddress?.state_id) {
          handleGetCity(Number(employeeData?.userInfo?.EmplyPermanentAddress?.state_id));
        }
      }
      if (employeeData?.userInfo.EmplyResidentialAddress) {
        if (employeeData?.userInfo?.EmplyResidentialAddress?.country_id) {
          handleGetResidentialState(Number(employeeData?.userInfo?.EmplyResidentialAddress?.country_id));
        }
        if (employeeData?.userInfo?.EmplyResidentialAddress?.state_id) {
          handleGetResidentialCity(Number(employeeData?.userInfo?.EmplyResidentialAddress?.state_id));
        }
      }
      if (employeeData?.userInfo?.same_as_permanent_address) {
        setSameAddress(true);
      } else {
        setSameAddress(false);
      };

      //change profile image path:
      setProfileImagePath(`${adminMainUrl}/${employeeData?.userInfo?.profile_image}`)

      if (employeeData?.userInfo?.DOB) {
        const date = new Date(employeeData?.userInfo?.DOB);
        setStartDate(date);
      }
      formik.setValues({
        profile_image: employeeData?.userInfo?.profile_image ?? "",
        first_name: employeeData?.userInfo.first_name ?? "",
        middle_name: employeeData?.userInfo.middle_name ?? "",
        last_name: employeeData?.userInfo.last_name ?? "",
        DOB: employeeData?.userInfo.DOB ? employeeData?.userInfo.DOB?.split('T')[0] : initialValues.DOB,
        gender_id: employeeData?.userInfo.gender_id ?? "",
        marital_status: employeeData?.userInfo.marital_status ?? "",
        contact_number: employeeData?.userInfo.contact_number ?? "",
        contact_email: employeeData?.userInfo.contact_email ?? "",
        proof_type_id: employeeData?.userInfo.proof_type_id ?? "",
        proof_id_number: employeeData?.userInfo.proof_id_number ?? "",
        same_as_permanent_address: employeeData?.userInfo.same_as_permanent_address ?? false,
        street_number: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo.EmplyPermanentAddress?.street_number ?? "" : "",
        street: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo?.EmplyPermanentAddress?.street ?? "" : "",
        country_id: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo?.EmplyPermanentAddress?.country_id ?? "" : "",
        state_id: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo?.EmplyPermanentAddress?.state_id ?? "" : "",
        city_id: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo?.EmplyPermanentAddress?.city ?? "" : "",
        postal_code: employeeData?.userInfo?.EmplyPermanentAddress ? employeeData?.userInfo?.EmplyPermanentAddress?.postal_code ?? "" : "",
        r_street_number: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo.EmplyResidentialAddress?.street_number ?? "" : "",
        r_street: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo?.EmplyResidentialAddress?.street ?? "" : "",
        r_country_id: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo?.EmplyResidentialAddress?.country_id ?? "" : "",
        r_state_id: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo?.EmplyResidentialAddress?.state_id ?? "" : "",
        r_city_id: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo?.EmplyResidentialAddress?.city ?? "" : "",
        r_postal_code: employeeData?.userInfo?.EmplyResidentialAddress ? employeeData?.userInfo?.EmplyResidentialAddress?.postal_code ?? "" : "",
      });
    }

  }, [employeeData]);

  const handleAdminProfile = (event) => {
    const file = event.target.files[0];
    let allowedExtensions = ['png', 'jpg', 'jpeg'];

    if (file && !allowedExtensions.includes(file?.name?.split('.').pop().toLowerCase())) {
      toast.error("Please enter a valid image.")
    } else if (file && (file.size / (1024 * 1024)) > 5) {
      toast.error("Image size should be less than 5 MB.")
    } else if (file) {
      setAdminProfile(file);
      formik.setFieldValue("profile_image", event.target.files[0]);
    } else {
      toast.error("Please upload profile image")
    }
  };



  return (
    <>
      {/* <Toaster /> */}
      {
        showLoader ?
          <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>
            <BeatLoader />
          </div> :
          <div className="main_detail_form section1" id="section1">
            <form onSubmit={formik.handleSubmit}>
              <FocusError formik={formik} />
              <div className="main-nav-det">
                <div className="admin_profile_photo_main just_center mb-3">
                  <div className="admin_profile_photo">
                    <span className="admin_profile_photo_img">
                      {
                        adminProfile ?
                          <img
                            src={URL.createObjectURL(adminProfile)}
                            alt="Selected"
                          />
                          : <img src={profileImagePath}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/img/no-profile-img.png";
                            }}
                            alt="profile" />
                      }</span>

                    <span className="admin_pro_add_img"><img src="/img/img_put.png" alt="" />
                      <input
                        type="file"
                        name="profile_image"
                        className="form-control form-control-admin"
                        placeholder="Upload Here"
                        onChange={(e) => {
                          handleAdminProfile(e);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    <div className="help-block with-errors">
                      {formik.touched.profile_image && formik.errors.profile_image ? (
                        <div className='text-danger small'>{formik.errors.profile_image}</div>
                      ) : null}
                    </div>

                  </div>
                </div>
                <div className='admin-emp-id'>
                  <p>EMP ID : <span>{employeeData?.userInfo?.unique_id}</span> </p>
                </div>
                <div className="admin_form_detail">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          name='first_name'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          maxLength={30}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.first_name && formik.errors.first_name ? (
                            <div className='text-danger small'>{formik.errors.first_name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Middle Name </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter middle name"
                          name='middle_name'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.middle_name}
                          maxLength={30}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.middle_name && formik.errors.middle_name ? (
                            <div className='text-danger small'>{formik.errors.middle_name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Last Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          name='last_name'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                          maxLength={30}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.last_name && formik.errors.last_name ? (
                            <div className='text-danger small'>{formik.errors.last_name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>DOB <span className="text-danger">*</span></label>
                        <div className="det_detail_main">
                          <div className="custom-datepicker">
                            <DatePicker
                              selected={startDate}
                              name='DOB'
                              onChange={(date) => {
                                setStartDate(date);
                                // Convert date to YYYY-MM-DD format
                                const formattedDate = date.toISOString().split('T')[0];
                                formik.setFieldValue('DOB', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.DOB}
                              maxDate={eighteenYearsAgo}
                            />
                            <span style={{ top: "13px" }}><img src="/img/date.png" alt="" /></span>
                          </div>
                        </div>
                        <div className="help-block with-errors">
                          {formik.touched.DOB && formik.errors.DOB ? (
                            <div className='text-danger small'>{formik.errors.DOB}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section ">
                        <label>Gender <span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={genderOptions}
                          name="gender_id"
                          value={genderOptions.find(option => option.value === formik.values.gender_id)}
                          onChange={option => formik.setFieldValue('gender_id', option.value)}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.gender_id && formik.errors.gender_id ? (
                            <div className='text-danger small'>{formik.errors.gender_id}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section  ">
                        <label>Marital Status<span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={maritalStatusOptions}
                          name="maritaltStatus"
                          value={maritalStatusOptions.find(option => option.value === formik.values.marital_status)}
                          onChange={option => formik.setFieldValue('marital_status', option.value)}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.marital_status && formik.errors.marital_status ? (
                            <div className='text-danger small'>{formik.errors.marital_status}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Contact Number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter contact number"
                          name="contact_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.contact_number && formik.errors.contact_number ? (
                            <div className='text-danger small'>{formik.errors.contact_number}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>E-mail ID <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter e-mail id"
                          name="contact_email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_email}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.contact_email && formik.errors.contact_email ? (
                            <div className='text-danger small'>{formik.errors.contact_email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section">
                        <label>Id Proof<span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={idProofOptions}
                          name="proof_type_id"
                          value={idProofOptions.find(option => option.value === formik.values.proof_type_id)}
                          onChange={option => formik.setFieldValue('proof_type_id', option.value)}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.proof_type_id && formik.errors.proof_type_id ? (
                            <div className='text-danger small'>{formik.errors.proof_type_id}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>ID Number<span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter ID number"
                          name="proof_id_number"
                          maxLength={30}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.proof_id_number}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.proof_id_number && formik.errors.proof_id_number ? (
                            <div className='text-danger small'>{formik.errors.proof_id_number}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-4">
                <div className="input_box_section file-input-main-admin">
                  <label>Copy of cancelled cheque<span className="text-danger">*</span></label>
                  <input type="file" onChange={(event) => handleCheque(event)} name="cancelledCheque" className="form-control form-control-admin"
                    placeholder="Upload Here" />
                  <button className="folder_main_update-admin"> Upload Here <span><img src="/img/upload.png"
                    alt="file" /></span></button>
                </div>
                {cheque && (
                  <div className="border border-[#cac8c8] rounded-md d-flex justify-center items-center relative multiple-img-box">
                    <img

                      className="object-contain h-[100px] w-[100px]"
                      src={URL.createObjectURL(cheque)}
                      alt="Selected"
                    />
                    <CancelIcon
                      className="top-[6px] right-[6px] absolute-img"
                      onClick={handleRemoveCheque}
                    />
                  </div>
                )}
              </div> */}
                    <div className="main-nav-det">
                      <h5>Permanent Address</h5>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Street Number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter street number "
                          name="street_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.street_number}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.street_number && formik.errors.street_number ? (
                            <div className='text-danger small'>{formik.errors.street_number}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Street <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter street"
                          name="street"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.street}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.street && formik.errors.street ? (
                            <div className='text-danger small'>{formik.errors.street}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section ">
                        <label>Country<span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={countryOptions}
                          name="country_id"
                          value={countryOptions.find(option => option.value === formik.values.country_id)}
                          onChange={option => { formik.setFieldValue('country_id', option.value); handleGetState(option.value); formik.setFieldValue('state_id', ''); }}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.country_id && formik.errors.country_id ? (
                            <div className='text-danger small'>{formik.errors.country_id}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section  ">
                        <label>State<span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={stateOptions}
                          name="state_id"
                          value={stateOptions.find(option => option.value === formik.values.state_id)}
                          onChange={option => { formik.setFieldValue('state_id', option.value); handleGetCity(option.value); formik.setFieldValue('city_id', ''); }}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.state_id && formik.errors.state_id ? (
                            <div className='text-danger small'>{formik.errors.state_id}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="select_box_section ">
                        <label>City<span className="text-danger">*</span></label>
                        <Select
                          className='mt-2'
                          options={cityOptions}
                          name="city_id"
                          value={cityOptions.find(option => option.value === formik.values.city_id)}
                          onChange={option => formik.setFieldValue('city_id', option.value)}
                          onBlur={formik.handleBlur}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.city_id && formik.errors.city_id ? (
                            <div className='text-danger small'>{formik.errors.city_id}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="input_box_section ">
                        <label>Postal Code <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter postal code"
                          name="postal_code"
                          maxLength={6}
                          onInput={(event) => {
                            let value = event.target.value;
                            value = value.replace(/[^0-9]/g, '');
                            event.target.value = value;
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.postal_code}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.postal_code && formik.errors.postal_code ? (
                            <div className='text-danger small'>{formik.errors.postal_code}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="main-nav-det">
                      <h5>Residential Address</h5>

                      <div className="main-box-det-check mt-3">
                        <label className="users-table__checkbox mb-2" id="new_text">
                          <input
                            type="checkbox"
                            className="check"
                            name="same_as_permanent_address"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              formik.setFieldValue("same_as_permanent_address", isChecked)
                              if (sameAddress == false) {
                                setSameAddress(true);
                              } else {
                                setSameAddress(false);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            checked={formik.values.same_as_permanent_address}
                          />
                          Same as Permanent Address
                          <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    {!sameAddress && <>
                      <div className="col-md-6 col-lg-4">
                        <div className="input_box_section ">
                          <label>Street Number <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter street number "
                            name="r_street_number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.r_street_number}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_street_number && formik.errors.r_street_number ? (
                              <div className='text-danger small'>{formik.errors.r_street_number}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="input_box_section ">
                          <label>Street <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter street"
                            name="r_street"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.r_street}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_street && formik.errors.r_street ? (
                              <div className='text-danger small'>{formik.errors.r_street}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="select_box_section ">
                          <label>Country<span className="text-danger">*</span></label>
                          <Select
                            className='mt-2'
                            options={residentialCountryOptions}
                            name="r_country_id"
                            value={residentialCountryOptions.find(option => option.value === formik.values.r_country_id)}
                            onChange={option => { formik.setFieldValue('r_country_id', option.value); handleGetResidentialState(option.value) }}
                            onBlur={formik.handleBlur}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_country_id && formik.errors.r_country_id ? (
                              <div className='text-danger small'>{formik.errors.r_country_id}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="select_box_section  ">
                          <label>State<span className="text-danger">*</span></label>
                          <Select
                            className='mt-2'
                            options={residentialStateOptions}
                            name="r_state_id"
                            value={residentialStateOptions.find(option => option.value === formik.values.r_state_id)}
                            onChange={option => { formik.setFieldValue('r_state_id', option.value); handleGetResidentialCity(option.value) }}
                            onBlur={formik.handleBlur}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_state_id && formik.errors.r_state_id ? (
                              <div className='text-danger small'>{formik.errors.r_state_id}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="select_box_section ">
                          <label>City<span className="text-danger">*</span></label>
                          <Select
                            className='mt-2'
                            options={residentialCityOptions}
                            name="r_city_id"
                            value={residentialCityOptions.find(option => option.value === formik.values.r_city_id)}
                            onChange={option => { formik.setFieldValue('r_city_id', option.value) }}
                            onBlur={formik.handleBlur}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_city_id && formik.errors.r_city_id ? (
                              <div className='text-danger small'>{formik.errors.r_city_id}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="input_box_section ">
                          <label>Postal Code <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter postal code"
                            name="r_postal_code"
                            maxLength={6}
                            onInput={(event) => {
                              let value = event.target.value;
                              value = value.replace(/[^0-9]/g, '');
                              event.target.value = value;
                            }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.r_postal_code}
                          />
                          <div className="help-block with-errors">
                            {formik.touched.r_postal_code && formik.errors.r_postal_code ? (
                              <div className='text-danger small'>{formik.errors.r_postal_code}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>}
                  </div>
                </div>
              </div>

              <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>

                <div className='button-container' style={{ marginTop: '20px' }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    className='btn-stepper'
                    style={{ visibility: "hidden" }}
                  >
                    Previous
                  </Button>
                  <Button
                    className='btn-stepper'
                    variant="contained"
                    type='submit'
                    disabled={disable}
                    // onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {!disable ? "Next" : <i className="fas fa-spinner fa-spin" style={{ color: "#fff" }}></i>}
                  </Button>
                </div>
              </Paper>
            </form>
          </div>
      }

    </>

  )
}

export default PersonalDetailsUpdate