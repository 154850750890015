import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from "js-cookie";
import { format } from 'date-fns';
import QuotationAirExportModal from '../../Modal/QuotationAirExportModal'
import DatePicker from "react-datepicker";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from "react-spinners";
import Swal from 'sweetalert2';
import { FocusError } from 'focus-formik-error';
import { AdminRateRequestSchema } from '../../../ValidationScema';
import { adminMainUrl } from '../../../Config';
import Api from '../../../Api';
import { getQuoteCompGenInfo } from '../../../Redux/action/Sales/QuoteAirExportCompGenInfoAction';
import Beatloader from '../../../Common/Loaders/Beatloader';
import { getCurrency } from '../../../Redux/action/Admin/EmployeeMasterAction';

const QuotationAirExportForm = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [cargoType, setCargoType] = useState([]);
  const [modalData, setModalData] = useState([]);

  const [weight, setWeight] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [updateTraficDetails, setUpdateTraficDetails] = useState([]);
  const [repriceTariffDetails, setRepriceTariffDetails] = useState([]);
  const [tableId, setTableId] = useState();
  const [state, setState] = useState([]);
  const [Id, setId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState([])
  const [rate, setRate] = useState([]);

  const token = Cookies.get("token");
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  const [airlines, setAirlines] = useState({}); // Initialize with existing airline data
  const [addNewTable, setAddNewTable] = useState([]); // Keep track of added tables
  // const [country, setCountry] = useState([]);

  useEffect(() => {
    //get masters data
    dispatch(getCurrency());

  }, []);

  let [currencyOptions, setCurrencyOptions] = useState([]);
  let currencyData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.currencyData : []);

  // console.log("cddd",currencyData);
  useEffect(() => {
    if (currencyData) {
      let currencyArr = [];
      currencyData.map(val => { currencyArr.push({ value: val.id, label: `${val.currency} (${val.currency_symbol})` }) });
      setCurrencyOptions(currencyArr);
    }
  }, [currencyData]);

  const handleModalOpen = (airlineCode) => {
    // Find the relevant data for the selected airline
    const airlineDetails = airlines[airlineCode] || [];
    setModalData(airlineDetails); // Set the data for the modal
    setTableId(airlineCode); // Or whatever identifier you are using
    // Open the modal
  };
  useEffect(() => {
    const groupedData = updateTraficDetails.reduce((acc, detail) => {
      if (!acc[detail.airline_code]) {
        acc[detail.airline_code] = []; // Initialize the group if it doesn't exist
      }
      acc[detail.airline_code].push(detail); // Add the detail to the corresponding group
      return acc;
    }, {});

    setAirlines(groupedData); // Update airlines state with grouped data
    setAddTable(Object.keys(groupedData)); // Track added tables based on existing grouped data
  }, [updateTraficDetails]);
  useEffect(() => {
    if (location.state?.quotationId) {
      setId(location.state.quotationId);
    }
  }, [location.state]);

  //show correct time
  function addTimeAndFormat(timeString, hoursToAdd, minutesToAdd) {
    if (timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);

      // Create a new Date object
      let date = new Date();
      date.setHours(hours, minutes, seconds || 0); // Set the time from the input string

      // Add the hours and minutes to the time
      date.setHours(date.getHours() + hoursToAdd);
      date.setMinutes(date.getMinutes() + minutesToAdd);

      // Format the result in 12-hour time with AM/PM
      const formattedTime = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return formattedTime;
    } else {
      return "";
    }
    // Split the timeString into hours, minutes, and seconds

  }
console.log(token,"token//")
  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      dispatch(getQuoteCompGenInfo(token, location.state.quotationId));
      console.log(location.state.quotationId,"location.state.quotationId")
    }
  }, [token]);

  const { loadingQuoteCompGenInfoApi, quoteCompGenInfoDataError, quoteCompGenInfoData } = useSelector(state => state?.QuoteAirExportCompGenInfoReducer);

  //for formik
  function convertToHourMinutes(timeString) {
    if (timeString) {
      // Split the time string into hours, minutes, and seconds
      const [hours, minutes] = timeString.split(':');

      // Return only the hours and minutes
      return `${hours}:${minutes}`;
    } else {
      return null;
    }

  }

  const convertToTodayDate = (timeString) => {
    if (!timeString) return null;

    const [hours, minutes, seconds] = timeString.split(':');

    // Get today's date
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // Months are 0-indexed in JS Date
    const day = today.getDate();

    // Create a new Date object for today with the time from timeString
    const fullDate = new Date(year, month, day, parseInt(hours), parseInt(minutes), parseInt(seconds));

    return fullDate;
  };


  function convertToValidDate(timeString) {
    if (!timeString) return null;

    // Split the time string (HH:MM:SS) into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Create a new Date object (use today's date)
    const date = new Date();

    // Set the time part (hours, minutes, seconds) while keeping the current date
    date.setHours(hours, minutes, seconds || 0, 0); // Default seconds to 0 if not provided

    // Return the date object
    return date;
  }

  const formik = useFormik({
    initialValues: {
      quotation_id: `${Id}`,
      ship_quotation_no: '',
      ship_date_of_creation: "",
      ship_buy_ref_no: '',
      ship_rate_validity: "",
      // ship_validity_against: '',
      ship_currency_id: '',
      ship_quotation_rise_in: '',


      com_company_name: "",
      com_address: "",
      com_phone_no: "",
      com_email: "",
      com_gstin: "",
      com_country_id: "",
      com_state_id: "",

      gen_customer_name: "",
      gen_address: "",
      gen_billing_account: "",
      gen_attention_to: "",
      gen_airline: "",
      gen_place_of_receipt: "",
      gen_commodity: "",
      gen_airport_of_landing: "",
      gen_cargo_weight: "",
      gen_airport_of_discharge: "",
      gen_incoterms: "",
      gen_tentative_transit_time: null,
      gen_find_place_of_delivery: "",
      gen_notes: "",

      tarrifArr: updateTraficDetails,

    },

    validationSchema: AdminRateRequestSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);

      //setting preset values.
      values.com_company_name = quoteCompGenInfoData?.com_company_name;
      values.com_address = quoteCompGenInfoData?.com_address;
      values.com_phone_no = quoteCompGenInfoData?.com_phone_no;
      values.com_email = quoteCompGenInfoData?.com_email;
      values.com_gstin = quoteCompGenInfoData?.com_gstin;
      values.com_country_id = quoteCompGenInfoData?.com_country_id;
      values.com_state_id = quoteCompGenInfoData?.com_state_id;

      // values.gen_customer_name = quoteCompGenInfoData?.gen_customer_name;
      // values.gen_address = quoteCompGenInfoData?.gen_address;
      // values.gen_billing_account = quoteCompGenInfoData?.gen_billing_account;
      // values.gen_attention_to = quoteCompGenInfoData?.gen_attention_to;
      // values.gen_airline = quoteCompGenInfoData?.gen_airline;
      // values.gen_place_of_receipt = quoteCompGenInfoData?.gen_place_of_receipt;
      // values.gen_commodity = quoteCompGenInfoData?.gen_commodity;
      // values.gen_airport_of_landing = quoteCompGenInfoData?.gen_airport_of_landing;
      // values.gen_cargo_weight = quoteCompGenInfoData?.gen_cargo_weight;
      // values.gen_airport_of_discharge = quoteCompGenInfoData?.gen_airport_of_discharge;
      // values.gen_incoterms = quoteCompGenInfoData?.gen_incoterms;
      // values.gen_tentative_transit_time = convertToHourMinutes(quoteCompGenInfoData?.gen_tentative_transit_time);
      // values.gen_find_place_of_delivery = quoteCompGenInfoData?.gen_find_place_of_delivery;
      // values.gen_notes = quoteCompGenInfoData?.gen_notes;

      // const timeOnly = values.gen_tentative_transit_time
      //   ? new Date(values.gen_tentative_transit_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      //   : null;

      // const timeOnly = values.gen_tentative_transit_time
      //   ? convertToValidDate(values.gen_tentative_transit_time).toLocaleTimeString([], {
      //     hour: '2-digit',
      //     minute: '2-digit',
      //     second: '2-digit',
      //     hour12: false // 24-hour format
      //   })
      //   : null;

      // const timeOnly = values.gen_tentative_transit_time
      //   ? new Date(values.gen_tentative_transit_time).toLocaleTimeString([], {
      //     hour: '2-digit',
      //     minute: '2-digit',
      //     second: '2-digit',
      //     hour12: false  // 24-hour format
      //   })
      //   : null;

      //today
      const timeOnly = values.gen_tentative_transit_time
        ? new Date(values.gen_tentative_transit_time).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false  // 24-hour format
        })
        : null;

      const updatedValues = {
        ...values,
        gen_tentative_transit_time: timeOnly, // Only send the time
        tarrifArr: updateTraficDetails, // Your existing logic for tarrifArr
      };
      // console.log(updatedValues);
      try {

        const response = await fetch(`${adminMainUrl}/api/pricing/store-export-info`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'auth-token': token,
          },
          body: JSON.stringify(updatedValues),
        });
        const responseData = await response.json();
        if (response.ok) {
          setIsSubmitting(false);
          // navigate('/admin-dashboard/view-updated-query-list');
          navigate('/admin-dashboard/send-pricing-list');
          resetForm();
          setUpdateTraficDetails([])
          toast.success(responseData?.msg);
        } else {
          setIsSubmitting(false);
          toast.error(responseData?.msg);
        }
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      }
      finally {
        setIsSubmitting(false);
      }
    },
  });


  useEffect(() => {
    if (loadingQuoteCompGenInfoApi) {
      setShowPageLoader(true)
    } else {
      setShowPageLoader(false)
    }

    if (quoteCompGenInfoData) {
      // console.log(quoteCompGenInfoData);

      // formik.setValues({
      //   com_company_name: quoteCompGenInfoData?.com_company_name,
      //   com_address: quoteCompGenInfoData?.com_address,
      //   com_phone_no: quoteCompGenInfoData?.com_phone_no,
      //   com_email: quoteCompGenInfoData?.com_email,
      //   com_gstin: quoteCompGenInfoData?.com_gstin,
      //   com_country_id: quoteCompGenInfoData?.com_country_id,
      //   com_state_id: quoteCompGenInfoData?.com_state_id,

      //   gen_customer_name: quoteCompGenInfoData?.gen_customer_name,
      //   gen_address: quoteCompGenInfoData?.gen_address,
      //   gen_billing_account: quoteCompGenInfoData?.gen_billing_account,
      //   gen_attention_to: quoteCompGenInfoData?.gen_attention_to,
      //   gen_airline: quoteCompGenInfoData?.gen_airline,
      //   gen_place_of_receipt: quoteCompGenInfoData?.gen_place_of_receipt,
      //   gen_commodity: quoteCompGenInfoData?.gen_commodity,
      //   gen_airport_of_landing: quoteCompGenInfoData?.gen_airport_of_landing,
      //   gen_cargo_weight: quoteCompGenInfoData?.gen_cargo_weight,
      //   gen_airport_of_discharge: quoteCompGenInfoData?.gen_airport_of_discharge,
      //   gen_incoterms: quoteCompGenInfoData?.gen_incoterms,
      //   gen_tentative_transit_time: null,
      //   gen_find_place_of_delivery: quoteCompGenInfoData?.gen_find_place_of_delivery,
      //   gen_notes: quoteCompGenInfoData?.gen_notes,
      // });
    }


  }, [loadingQuoteCompGenInfoApi, quoteCompGenInfoDataError, quoteCompGenInfoData])


  let addTableId;
  let tableDetailsId;
  const [addTable, setAddTable] = useState([1]);
  const handleAddAirline = () => {
    setAddTable([...addTable, { id: addTable.length, updateTraficDetails: [] }]);

  }
  const removeTable = (idToRemove) => {
    const updatedAddTable = addTable.filter((value, i) => i !== idToRemove);
    setAddTable(updatedAddTable);

  };

  useEffect(() => {
    if (Id) {
      formik.setValues({
        quotation_id: `${Id}`,
        ship_quotation_no: '',
        ship_date_of_creation: "",
        ship_buy_ref_no: '',
        ship_rate_validity: "",
        // ship_validity_against: '',
        ship_currency_id: "",
        ship_quotation_rise_in: '',

        // com_company_name: quoteCompGenInfoData?.com_company_name ?? "",
        // com_address: quoteCompGenInfoData?.com_address ?? "",
        // com_phone_no: quoteCompGenInfoData?.com_phone_no ?? "",
        // com_email: quoteCompGenInfoData?.com_email ?? "",
        // com_gstin: quoteCompGenInfoData?.com_gstin ?? "",
        // com_country_id: quoteCompGenInfoData?.com_country_id ?? "",
        // com_state_id: quoteCompGenInfoData?.com_state_id ?? "",

        gen_customer_name: quoteCompGenInfoData?.gen_customer_name ?? "",
        gen_address: quoteCompGenInfoData?.gen_address ?? "",
        gen_billing_account: quoteCompGenInfoData?.gen_billing_account ?? "",
        gen_attention_to: quoteCompGenInfoData?.gen_attention_to ?? "",
        gen_airline: quoteCompGenInfoData?.gen_airline ?? "",
        gen_place_of_receipt: quoteCompGenInfoData?.gen_place_of_receipt ?? "",
        gen_commodity: quoteCompGenInfoData?.gen_commodity ?? "",
        gen_airport_of_landing: quoteCompGenInfoData?.gen_airport_of_landing ?? "",
        gen_cargo_weight: quoteCompGenInfoData?.gen_cargo_weight ?? "",
        gen_airport_of_discharge: quoteCompGenInfoData?.gen_airport_of_discharge ?? "",
        gen_incoterms: quoteCompGenInfoData?.gen_incoterms ?? "",
        gen_tentative_transit_time: null,
        gen_find_place_of_delivery: quoteCompGenInfoData?.gen_find_place_of_delivery ?? "",
        gen_notes: quoteCompGenInfoData?.gen_notes ?? "",

        tarrifArr: updateTraficDetails,
      });
    }
  }, [Id]);
  const GetWeightUnitType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/weight-unit/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          weightId: item.id,
          value: item.name,
          label: item.name,
        }));
      setWeight(options);
    } catch (error) {
      setWeight([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetCargoType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          cargoId: item.id,
          value: item.name,
          label: item.name,
        }));
      setCargoType(options);
    } catch (error) {
      setCargoType([]);

      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    GetCargoType();
    GetWeightUnitType()
  }, []);
  const GetUserInfo = async (information) => {
    // setSpinnerLoader(true)
    try {
      const token = Cookies.get("token");
      const requesId = {
        // quotation_id: `${Id}`,
        quotation_id: `${Id}`
      };
      const response = await fetch(`${adminMainUrl}/api/pricing/list-export-info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(requesId),
      });
      if (!response.ok) {
        // setSpinnerLoader(false);
        // setIsVisible(false)
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRate(data?.data);
      // setIsVisible(false)
      // setSpinnerLoader(false);
    } catch (error) {
      // setIsVisible(false)
      // setSpinnerLoader(false);
      console.log(error);
    }
  };

  const handleNewAirline = () => {
    const newAirlineCode = `Airline ${Object.keys(airlines).length + 1}`; // Generate a new airline code
    setAirlines(prevState => ({
      ...prevState,
      [newAirlineCode]: [] // Add the new airline with an empty array for details
    }));
    setAddNewTable(prevAddTable => [...prevAddTable, newAirlineCode]); // Track added tables
  };

  const deleteTable = (index) => {
    // Ensure the index is valid
    // if (index < 0 || index >= addNewTable.length) return;

    const airlineCode = addNewTable[index]; // Get the airline code based on the index

    setAirlines(prevState => {
      const newState = { ...prevState };
      delete newState[airlineCode]; // Remove the airline from the state
      return newState; // Return the updated state
    });

    setAddNewTable(prevAddTable => {
      const newAddTable = prevAddTable.filter((_, i) => i !== index); // Remove from tracking
      return newAddTable;
    });
  };
  const GetCountryType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/country/list`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          CountryTypeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setCountry(options);
    } catch (error) {
      setCountry([]);

      console.error("Error fetching data:", error);
    }
  };

  const GetStateType = async (countryId) => {
    try {
      const data = await Api(`${adminMainUrl}/api/state/list?country_id=${countryId}`, "GET"); // Use dynamic country ID
      const options = data?.data?.map((item) => ({
        StateTypeId: item.id,
        value: item.name,
        label: item.name,
      }));
      setState(options); // Update the state options
    } catch (error) {
      setState([]);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (Id) {
      GetUserInfo();
    }

  }, [Id]);

  useEffect(() => {
    GetCountryType()
    GetUserInfo();
    GetStateType();
  }, []);

  // const convertToDate = (timeString) => {
  //   if (!timeString) return null;

  //   let date;
  //   // console.log(timeString);
  //   if (typeof timeString === 'string') {
  //     // Handle case when timeString is in HH:MM:SS format
  //     const [hours, minutes] = timeString.split(':');
  //     date = new Date();
  //     date.setHours(parseInt(hours), parseInt(minutes), 0); // Ensure seconds are set to 0
  //   } else if (timeString instanceof Date) {
  //     // If timeString is a Date object, return it directly
  //     date = timeString;
  //   }

  //   return date;
  // };



  const convertToDate = (timeString) => {
    if (!timeString) return null;

    let date;

    if (typeof timeString === 'string') {
      // Handle case when timeString is in HH:MM:SS format
      const [hours, minutes] = timeString.split(':');
      date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes), 0); // Ensure seconds are set to 0
    } else if (timeString instanceof Date) {
      // If timeString is a Date object, return it directly
      date = timeString;
    }

    return date;
  };

  useEffect(() => {

    if (rate) {
      const companyInfo = rate[0];
      formik.setValues({
        // quotation_id: `${Id}`,
        quotation_id: `${Id}`,
        ship_quotation_no: companyInfo?.ship_quotation_no || '',
        ship_date_of_creation: companyInfo?.ship_date_of_creation || '',
        ship_buy_ref_no: companyInfo?.ship_buy_ref_no || '',
        ship_rate_validity: companyInfo?.ship_rate_validity || '',
        // ship_validity_against: companyInfo?.ship_validity_against || '',
        ship_currency_id: companyInfo?.ship_currency_id || '',
        ship_quotation_rise_in: companyInfo?.ship_quotation_rise_in || '',

        // com_company_name: companyInfo?.com_company_name || '',
        // com_address: companyInfo?.com_address || '',
        // com_phone_no: companyInfo?.com_phone_no || '',
        // com_email: companyInfo?.com_email || '',
        // com_gstin: companyInfo?.com_gstin || '',
        // com_country_id: companyInfo?.com_country_id || '',
        // com_state_id: companyInfo?.com_state_id || '',

        gen_customer_name: companyInfo?.gen_customer_name || '',
        gen_address: companyInfo?.gen_address || '',
        gen_billing_account: companyInfo?.gen_billing_account || '',
        gen_attention_to: companyInfo?.gen_attention_to || '',
        gen_airline: companyInfo?.gen_airline || '',
        gen_place_of_receipt: companyInfo?.gen_place_of_receipt || '',
        gen_commodity: companyInfo?.gen_commodity || '',
        gen_airport_of_landing: companyInfo?.gen_airport_of_landing || '',
        gen_cargo_weight: companyInfo?.gen_cargo_weight ? Number(companyInfo.gen_cargo_weight) : '',
        gen_airport_of_discharge: companyInfo?.gen_airport_of_discharge || '',
        gen_incoterms: companyInfo?.gen_incoterms || '',
        gen_tentative_transit_time: companyInfo?.gen_tentative_transit_time ? convertToTodayDate(companyInfo?.gen_tentative_transit_time) : '',
        gen_find_place_of_delivery: companyInfo?.ship_quotation_no || '',
        gen_notes: companyInfo?.gen_notes || '',

        tarrifArr: updateTraficDetails,



        // id: companyInfo?.id || '',



      });
      // formik.setFieldValue('tarrifArr', updateTraficDetails);

    }
  }, [rate, Id]);
  // Function to generate a unique quotation number
  const generateQuotationNumber = () => {
    const timestamp = Date.now(); // Get the current timestamp
    return `${timestamp}${Id}`; // Combine them into a unique string
  };

  // Set the unique quotation number when the component mounts if the field is empty
  useEffect(() => {
    if (!formik.values.ship_quotation_no) {
      const uniqueQuotationNo = generateQuotationNumber();
      formik.setFieldValue('ship_quotation_no', uniqueQuotationNo);
    }
  }, [formik]);
  useEffect(() => {
    // If a country is already selected on form load, fetch the states
    if (formik.values.com_country_id) {
      GetStateType(formik.values.com_country_id);
    }
  }, [formik.values.com_country_id]);
  const groupedData = updateTraficDetails.reduce((acc, detail) => {
    if (!acc[detail.airline_code]) {
      acc[detail.airline_code] = []; // Initialize the group if it doesn't exist
    }
    acc[detail.airline_code].push(detail); // Add the detail to the corresponding group
    return acc;
  }, {});
  return (
    <>
      {showPageLoader ?
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px", width: "100%" }}
        >
          <Beatloader />
        </div>
        : <div className="container">
          <div className="col-md-12">
            <div className="main_section_detail">
              <div className="main-nav-det">
                <h2>
                  Quotation Air Export Form
                </h2>
              </div>
              <div className="main_detail_form Air_Export_Form_bg">
                {/* {
                isSubmitting ?
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "500px", width: "100%" }}
                  >
                    <BeatLoader />
                  </div> : */}

                <form className="Air_Export_bg_main" onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();

                }}>
                  <FocusError formik={formik} />
                  {/* <input type="hidden" name="uhqpa_id" value={status_id} /> */}
                  {/* Shipment Information */}
                  <div className="main-nav-det">
                    <h4>
                      <span>
                        <img src="/img/gen.png" alt="gen" />
                      </span>
                      Shipment Information
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Quotation Number <span>*</span></label>
                        <input
                          disabled
                          type="text"
                          placeholder="Enter quotation number"
                          maxLength={16}
                          className="form-control"
                          value={formik.values.ship_quotation_no}
                          onChange={(e) => {
                            const num = e.target.value.replace(/[^0-9]/g, ''); // If you want to allow only numbers
                            formik.setFieldValue('ship_quotation_no', num);
                          }}
                          onBlur={formik.handleBlur}
                          name="ship_quotation_no"
                        />
                        {formik.touched.ship_quotation_no && formik.errors.ship_quotation_no ? (
                          <div className="error">{formik.errors.ship_quotation_no}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section por_main_detail">
                        <label>Date of Creation <span>*</span></label>
                        <div className="custom-datepicker">
                          <DatePicker
                            minDate={new Date()}
                            placeholderText='Enter date of creation'
                            style={{ width: "100%" }}
                            selected={startDate}
                            onChange={(date) => {
                              const formattedDate = format(date, 'yyyy-MM-dd');
                              setStartDate(date);
                              formik.setFieldValue("ship_date_of_creation", formattedDate);
                            }}
                            // onChange={(date) => {
                            //   setStartDate(date);
                            //   formik.setFieldValue("ship_date_of_creation", date);
                            // }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.ship_date_of_creation && formik.errors.ship_date_of_creation ? (
                          <div className="error">{formik.errors.ship_date_of_creation}</div>
                        ) : null}
                        <span>
                          <img src="/img/simple-calende.png" alt="Calendar" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Buying Reference Number <span>*</span></label>
                        <input
                          maxLength={8}
                          placeholder='Enter buying reference number'
                          type="text"
                          className="form-control"
                          value={formik.values.ship_buy_ref_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="ship_buy_ref_no"
                        />
                        {formik.touched.ship_buy_ref_no && formik.errors.ship_buy_ref_no ? (
                          <div className="error">{formik.errors.ship_buy_ref_no}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section por_main_detail">
                        <label>Rate Validity <span>*</span> </label>
                        <div className="custom-datepicker">
                          <DatePicker
                            minDate={new Date()}
                            placeholderText='Enter rate validity'
                            style={{ width: "100%" }}
                            selected={endDate}
                            onChange={(date) => {
                              const formattedDate = format(date, 'yyyy-MM-dd');
                              setEndDate(date);
                              formik.setFieldValue("ship_rate_validity", formattedDate);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.ship_rate_validity && formik.errors.ship_rate_validity ? (
                          <div className="error">{formik.errors.ship_rate_validity}</div>
                        ) : null}
                        <span>
                          <img src="/img/simple-calende.png" alt="Calendar" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Quotation Currency <span>*</span></label>
                        <Select
                          className='mt-2'
                          options={currencyOptions}
                          name="ship_currency_id"
                          value={currencyOptions.find(option => option.value === formik.values.ship_currency_id)}
                          onChange={option => formik.setFieldValue('ship_currency_id', option.value)}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.ship_currency_id && formik.errors.ship_currency_id ? (
                          <div className="error">{formik.errors.ship_currency_id}</div>
                        ) : null}
                        {/* <input
                          type="text"
                          placeholder='Enter validity against'
                          className="form-control"
                          value={formik.values.ship_validity_against}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="ship_validity_against"
                        /> */}
                        {/* <select
                          id="ship_validity_against"
                          className="form-control"
                          name="ship_validity_against"
                          value={formik.values.ship_validity_against}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="">-Select a currency-</option>
                          {currencyOptions.map((val, i) => {
                            return <option key={val?.id} value={val.id}>{val.currency}({val.currency_symbol})</option>
                          })}
                        </select>
                        {formik.touched.ship_validity_against && formik.errors.ship_validity_against ? (
                          <div className="error">{formik.errors.ship_validity_against}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Quotation Rise in <span>*</span></label>
                        <input
                          type="text"
                          placeholder='Enter quotation rise in'
                          className="form-control"
                          value={formik.values.ship_quotation_rise_in}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="ship_quotation_rise_in"
                        />
                        {formik.touched.ship_quotation_rise_in && formik.errors.ship_quotation_rise_in ? (
                          <div className="error">{formik.errors.ship_quotation_rise_in}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* Company Information */}
                  <div className="main-nav-det">
                    <h4>
                      <span>
                        <img src="/img/Product.png" alt="gen" />
                      </span>
                      Company Information
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Company Name</label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.com_company_name : ""}</p>
                        {/* <input
                          type="text"
                          placeholder='Enter company name'
                          className="form-control"
                          value={formik.values.com_company_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="com_company_name"
                        />
                        {formik.touched.com_company_name && formik.errors.com_company_name ? (
                          <div className="error">{formik.errors.com_company_name}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Address  </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.com_company_name : ""}</p>

                        {/* <input
                          type="text"
                          placeholder='Enter address'
                          className="form-control"
                          value={formik.values.com_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="com_address"
                        />
                        {formik.touched.com_address && formik.errors.com_address ? (
                          <div className="error">{formik.errors.com_address}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Phone Number  </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.com_phone_no : ""}</p>

                        {/* <input
                          placeholder='Enter phone number'
                          maxLength={10}
                          type="text"
                          className="form-control"
                          value={formik.values.com_phone_no}
                          onChange={(e) => {
                            const num = e.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue('com_phone_no', num);
                          }}
                          onBlur={formik.handleBlur}
                          name="com_phone_no"
                        />
                        {formik.touched.com_phone_no && formik.errors.com_phone_no ? (
                          <div className="error">{formik.errors.com_phone_no}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Email ID  </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.com_email : ""}</p>

                        {/* <input
                          placeholder='Enter email ID'
                          type="text"
                          className="form-control"
                          value={formik.values.com_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="com_email"
                        />
                        {formik.touched.com_email && formik.errors.com_email ? (
                          <div className="error">{formik.errors.com_email}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>GSTIN  </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.com_gstin : ""}</p>

                        {/* <input
                          type="text"
                          maxLength={15}
                          placeholder='Enter GSTIN'
                          className="form-control"
                          value={formik.values.com_gstin}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="com_gstin"
                        />
                        {formik.touched.com_gstin && formik.errors.com_gstin ? (
                          <div className="error">{formik.errors.com_gstin}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>Country  </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.country_data ? quoteCompGenInfoData?.country_data?.name : "" : ""}</p>

                        {/* <Select
                          placeholder='Enter country'
                          className="mt-2"
                          value={country.find(option => option.CountryTypeId === formik.values.com_country_id)}
                          options={country}
                          onChange={async (selectedOption) => {
                            formik.setFieldValue('com_country_id', selectedOption.CountryTypeId);
                            await GetStateType(selectedOption.CountryTypeId); // Fetch states based on selected country
                          }}
                          onBlur={formik.handleBlur}
                        /> */}

                        {/* {formik.touched.com_country_id && formik.errors.com_country_id ? (
                          <div className="error">{formik.errors.com_country_id}</div>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input_box_section ">
                        <label>State </label>
                        <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.state_data ? quoteCompGenInfoData?.state_data?.name : "" : ""}</p>

                        {/* <Select
                          placeholder='Enter state'
                          className="mt-2"
                          value={state.find(option => option.StateTypeId === formik.values.com_state_id)}
                          options={state}
                          onChange={(selectedOption) => formik.setFieldValue('com_state_id', selectedOption.StateTypeId)}
                          onBlur={formik.handleBlur}
                        /> */}

                        {/* {formik.touched.com_state_id && formik.errors.com_state_id ? (
                          <div className="error">{formik.errors.com_state_id}</div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="main-nav-det">
                    <h4>
                      <span>
                        <img src="/img/Product.png" alt="gen" />
                      </span>
                      General Information
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>
                          Customer Name
                        </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_customer_name : ""}</p> */}

                        <input
                          placeholder='Enter customer name'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_customer_name}
                          name="gen_customer_name"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_customer_name && formik?.errors?.gen_customer_name ? (
                          <div className="error">{formik?.errors?.gen_customer_name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Address </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_address : ""}</p> */}

                        <input
                          placeholder='Enter address'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_address}
                          name="gen_address"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_address && formik?.errors?.gen_address ? (
                          <div className="error">{formik?.errors?.gen_address}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Billing Account </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_billing_account : ""}</p> */}

                        <input
                          type="text"
                          placeholder='Enter billing account'
                          className="form-control"
                          value={formik?.values?.gen_billing_account}
                          name="gen_billing_account"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_billing_account && formik?.errors?.gen_billing_account ? (
                          <div className="error">{formik?.errors?.gen_billing_account}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Attention To </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_attention_to : ""}</p> */}

                        <input
                          type="text"
                          placeholder='Enter attention to'
                          className="form-control"
                          value={formik?.values?.gen_attention_to}
                          name="gen_attention_to"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_attention_to && formik?.errors?.gen_attention_to ? (
                          <div className="error">{formik?.errors?.gen_attention_to}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Airline</label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airline : ""}</p> */}

                        <input
                          placeholder='Enter airline'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_airline}
                          name="gen_airline"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_airline && formik?.errors?.gen_airline ? (
                          <div className="error">{formik?.errors?.gen_airline}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Place of Receipt</label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_place_of_receipt : ""}</p> */}

                        <input
                          placeholder='Enter place of receipt'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_place_of_receipt}
                          name="gen_place_of_receipt"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_place_of_receipt && formik?.errors?.gen_place_of_receipt ? (
                          <div className="error">{formik?.errors?.gen_place_of_receipt}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Commodity </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_commodity : ""}</p> */}

                        <input
                          placeholder='Enter commodity'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_commodity}
                          name="gen_commodity"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_commodity && formik?.errors?.gen_commodity ? (
                          <div className="error">{formik?.errors?.gen_commodity}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>
                          Air Port of Loading
                        </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airport_of_landing : ""}</p> */}

                        <input
                          placeholder='Enter air port of loading '
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_airport_of_landing}
                          name="gen_airport_of_landing"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_airport_of_landing && formik?.errors?.gen_airport_of_landing ? (
                          <div className="error">{formik?.errors?.gen_airport_of_landing}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Cargo Weight</label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.weight_unit_data ? quoteCompGenInfoData?.weight_unit_data?.name : "" : ""}</p> */}

                        <Select
                          className='mt-2'
                          options={weight}
                          onChange={(selectedOption) =>
                            formik.setFieldValue(
                              "gen_cargo_weight",
                              selectedOption.weightId
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {/* <input
                        placeholder='Enter cargo weight'
                        type="text"
                        className="form-control"
                        value={formik?.values?.gen_cargo_weight}
                        name="gen_cargo_weight"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      /> */}
                        {formik?.touched?.gen_cargo_weight && formik?.errors?.gen_cargo_weight ? (
                          <div className="error">{formik?.errors?.gen_cargo_weight}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>
                          Air Port of Discharge
                        </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_airport_of_discharge : ""}</p> */}

                        <input
                          placeholder='Enter air port of discharge'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_airport_of_discharge}
                          name="gen_airport_of_discharge"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_airport_of_discharge && formik?.errors?.gen_airport_of_discharge ? (
                          <div className="error">{formik?.errors?.gen_airport_of_discharge}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Incoterms </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_incoterms : ""}</p> */}

                        <input
                          placeholder='Enter incoterms'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_incoterms}
                          name="gen_incoterms"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_incoterms && formik?.errors?.gen_incoterms ? (
                          <div className="error">{formik?.errors?.gen_incoterms}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>
                          Tentative Transit Time
                        </label>
                        <div className='custom-datepicker'>
                          {/* <p className='my-1'>{quoteCompGenInfoData ? addTimeAndFormat(quoteCompGenInfoData?.gen_tentative_transit_time, 0, 0) : ""}</p> */}

                          <DatePicker
                            placeholderText="Enter transit time"
                            selected={formik.values.gen_tentative_transit_time ? convertToDate(formik.values.gen_tentative_transit_time) : null}
                            onChange={(date) => formik.setFieldValue('gen_tentative_transit_time', date || null)}
                            onBlur={formik.handleBlur}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className="form-control"
                            name="gen_tentative_transit_time"
                          />
                        </div>
                        {/* <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.tentative_transit_time}
                        name="general_info.tentative_transit_time"
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('general_info.tentative_transit_time', num);
                        }}
                        onBlur={formik?.handleBlur}
                      /> */}
                        {formik?.touched?.gen_tentative_transit_time && formik?.errors?.gen_tentative_transit_time ? (
                          <div className="error">{formik?.errors?.gen_tentative_transit_time}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>
                          Final Place of Delivery
                        </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_find_place_of_delivery : ""}</p> */}

                        <input
                          placeholder='Enter final place of delivery'
                          type="text"
                          className="form-control"
                          value={formik?.values?.gen_find_place_of_delivery}
                          name="gen_find_place_of_delivery"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_find_place_of_delivery && formik?.errors?.gen_find_place_of_delivery ? (
                          <div className="error">{formik?.errors?.gen_find_place_of_delivery}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input_box_section">
                        <label>Notes </label>
                        {/* <p className='my-1'>{quoteCompGenInfoData ? quoteCompGenInfoData?.gen_notes : ""}</p> */}
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder='Enter notes'
                          value={formik?.values?.gen_notes}
                          name="gen_notes"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                        />
                        {formik?.touched?.gen_notes && formik?.errors?.gen_notes ? (
                          <div className="error">{formik?.errors?.gen_notes}</div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                  
                  </div> */}

                  </div>

                  {/* <div className="row">
                  <div className="col-md-12">
                    <div className="add-Company-button">
                      <a href="">
                        <span>
                          <img src="/img/twotone-add.png" alt="Add Company" />
                        </span>
                        Airline Name
                      </a>
                    </div>
                  </div>
                </div> */}
                  <div className="main-nav-det d-md-flex justify-content-between align-items-center">
                    <h4>
                      <span>
                        <img src="/img/Product.png" alt="gen" />
                      </span>
                      Tariff Details
                    </h4>
                    <div className="add-Company-button" style={{ cursor: "pointer" }}
                      // onClick={handleAddAirline}
                      onClick={handleNewAirline}
                    >
                      <span>
                        <img src="/img/twotone-add.png" alt="Add Company" />
                      </span>
                      Add Airline
                    </div>
                  </div>
                  <div className="table_detail_tariff table-responsive">
                    {Object.keys(airlines).map((airlineCode, index) => (
                      <div className={`flex_detail_main ${index}-tarrif-details`} key={index}>
                        <div className="table_detail_tariff table-responsive">
                          <table className={`posts-table geeks ${index}`}>
                            <thead>
                              <tr className="users-table-tariff n_detail_comp">
                                <th>Airline Code</th>
                                <th>Charge Head</th>
                                <th>Buying Price</th>
                                <th>Selling Price</th>
                                <th>Narration</th>
                                <th>Valid Upto</th>
                                <th>Currency</th>
                                <th>Exchange Rate</th>
                                <th>Unit ID</th>
                                <th>FC Amount</th>
                                <th>Rate</th>
                                <th>Cargo Type ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {airlines[airlineCode]?.length > 0 ? (
                                airlines[airlineCode].map((detail, indexData) => (
                                  <tr key={indexData} className="main_table_row_new n_detail_comp">
                                    <td>{detail.airline_code}</td>
                                    <td>{detail.charge_head}</td>
                                    <td>{detail.buying_price}</td>
                                    <td>{detail.selling_price}</td>
                                    <td>{detail.narration}</td>
                                    <td>{detail.valid_upto ? format(new Date(detail.valid_upto), 'dd/MM/yyyy') : 'N/A'}</td>
                                    <td>{detail.currency}</td>
                                    <td>{detail.exchange_rate}</td>
                                    <td>{detail.unit_id}</td>
                                    <td>{detail.fc_amount}</td>
                                    <td>{detail.rate}</td>
                                    <td>{detail.cargo_type_id}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="12" className='text-center'>No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="box_button_main ms-3" onClick={() => handleModalOpen(airlineCode)} data-bs-toggle="modal" data-bs-target="#myModal">
                          <div className='d-flex justify-content-center align-items-center' style={{ cursor: "pointer" }}>
                            <img src="/img/twotone-add.png" alt="Add" />
                          </div>
                        </div>
                        {index !== 0 && (
                          <div className="box_button_main ms-3">
                            <div className='d-flex justify-content-center align-items-center' style={{ cursor: "pointer" }} onClick={() => deleteTable(index)}>
                              <CloseIcon style={{ width: "15px" }} className='text-danger' />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {/* {
                  addTable?.map((value, index) => {
                    addTableId = index + 1;
                    const Id = value.id;

                    return (
                      <>

                        <div className="table_detail_tariff table-responsive">
                          {Object.keys(groupedData).map((airlineCode, index) => (
                            <div className={`flex_detail_main ${index}-tarrif-details`} key={index}>
                         
                              <div className="table_detail_tariff table-responsive">
                                <table className={`posts-table geeks ${index}`}> 
                                  <thead>
                                    <tr className="users-table-tariff n_detail_comp">
                                      <th>Airline Code</th>
                                      <th>Charge Head</th>
                                      <th>Buying Price</th>
                                      <th>Selling Price</th>
                                      <th>Narration</th>
                                      <th>Valid Upto</th>
                                      <th>Currency</th>
                                      <th>Exchange Rate</th>
                                      <th>Unit ID</th>
                                      <th>FC Amount</th>
                                      <th>Rate</th>
                                      <th>Cargo Type ID</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groupedData[airlineCode].map((detail, indexData) => (
                                      <tr key={indexData} className="main_table_row_new n_detail_comp">
                                        <td>{detail.airline_code}</td>
                                        <td>{detail.charge_head}</td>
                                        <td>{detail.buying_price}</td>
                                        <td>{detail.selling_price}</td>
                                        <td>{detail.narration}</td>
                                        <td>{detail.valid_upto
                                          ? format(new Date(detail.valid_upto), 'dd/MM/yyyy')
                                          : 'N/A'}</td>
                                        <td>{detail.currency}</td>
                                        <td>{detail.exchange_rate}</td>
                                        <td>{detail.unit_id}</td>
                                        <td>{detail.fc_amount}</td>
                                        <td>{detail.rate}</td>
                                        <td>{detail.cargo_type_id}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                           
                              <div className="box_button_main ms-3" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => { setTableId(index + 1); }}>
                                <div className='d-flex justify-content-center align-items-center ' style={{ cursor: "pointer" }}>
                                  <img src="/img/twotone-add.png" alt="" />
                                </div>
                              </div>
                              {index !== 0 && index === addTable.length - 1 && (
                                <div className="box_button_main ms-3">
                                  <div className='d-flex justify-content-center align-items-center ' style={{ cursor: "pointer" }} onClick={() => removeTable(index)}>
                                    <CloseIcon style={{ width: "15px" }} className='text-danger' />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}

                        </div>


                      </>
                    )
                  })
                } */}
                  {/* {
                  addTable?.map((value, index) => {
                    addTableId = index + 1;
                    const Id = value.id;

                    return (
                      <>
                        <div className={`flex_detail_main ${index}-tarrif-details`}>
                          <div className="table_detail_tariff table-responsive">
                            <table className={`posts-table geeks `}>
                              <thead>
                                <tr className="users-table-tariff n_detail_comp">
                                  <th>Airline N.</th>
                                  <th>Charge Head</th>
                                  <th>Buyer Amount</th>
                                  <th>Seller Amount</th>
                                  <th>Narration</th>
                                  <th>Valid Up To</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>
                                  <th>Cargo Type</th>
                                </tr>
                              </thead>
                              <tbody>

                                {updateTraficDetails.map((detail, indexData) => {
                                  // tableDetailsId = detail.tableId
                                  return (addTableId == detail.tableId &&
                                    <tr key={indexData} className="main_table_row_new n_detail_comp">
                                      <td>{detail.airline_code}</td>
                                      <td>{detail.charge_head}</td>
                                      <td>{detail.buying_price}</td>
                                      <td>{detail.selling_price}</td>
                                      <td>{detail.narration}</td>
                                      <td>{detail.valid_upto}</td>
                                      <td>{detail.currency}</td>
                                      <td>{detail.exchange_rate}</td>
                                      <td>{detail.unit_id}</td>
                                      <td>{detail.fc_amount}</td>
                                      <td>{detail.rate}</td>
                                      <td>{detail.cargo_type_id}</td>
                                    </tr>
                                  )
                                })}

                              </tbody>
                            </table>
                          </div>
                          <div className="box_button_main" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => { setTableId(index + 1); }}>
                            <div style={{ cursor: "pointer" }}>
                              <img src="/img/twotone-add.png" alt="" />
                            </div>
                          </div>
                          {index !== 0 && index === addTable.length - 1 && (
                            <div className="box_button_main">
                              <div style={{ cursor: "pointer" }} onClick={() => removeTable(index)}>
                                <CloseIcon style={{ width: "15px" }} className='text-danger' />
                              </div>
                            </div>
                          )}
                        </div>


                      </>
                    )
                  })
                } */}
                  <div className="main-nav-det-button text-right main-Export-button">
                    {/* <Link to="/admin-dashboard/query-list"> */}
                    <button className="btn search-btn" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Submit</div> : "Submit"}
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
                {/* } */}
              </div>
            </div>
          </div>
        </div>}

      <QuotationAirExportModal modalData={modalData} setRepriceTariffDetails={setRepriceTariffDetails} repriceTariffDetails={repriceTariffDetails} addTableId={addTableId} rate={rate} tableId={tableId} updateTraficDetails={updateTraficDetails} setUpdateTraficDetails={setUpdateTraficDetails} modal="myModal" />

    </>
  )
}

export default QuotationAirExportForm;