import React, { useEffect, useState } from 'react'
import ViewRateModal from '../../Modal/ViewRateModal'
import { Link, useParams } from 'react-router-dom'
import { mainUrl } from '../../../Config';
import Api from '../../../Api';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { RateRequestSchema } from '../../../ValidationScema';
import { format } from 'date-fns';
const ViewRate = () => {
  const { status_id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [rateValidity, setRateValidity] = useState(null);
  const [rate, setRate] = useState(null);
  const [edit, setEdit] = useState(false);
  const formik = useFormik({
    initialValues: {
      uhqpa_id: status_id,
      shipment_info: {
        id: '',
        quotation_number: '',
        date_of_creation: '',
        buy_ref_number: '',
        rate_validity: '',
        validity_against: '',
        quotation_rise_in: '',
      },
      company_info: {
        id: '',
        company_name: '',
        address: '',
        phone_number: '',
        email: '',
        gstin: '',
        country: '',
        state: '',
      },
      general_info: {
        id: '',
        company_name: '',
        address: '',
        billing_account: '',
        attention_to: '',
        airline: '',
        place_of_receipt: '',
        commodity: '',
        airport_of_loading: '',
        cargo_weight: '',
        airport_of_discharge: '',
        incoterms: '',
        tentative_transit_time: '',
        final_delivery_place: '',
        notes: '',
      },
      tarrif_details: [
        {
          airline_name: '',
          charge_head: '',
          buying_price: '',
          selling_price: '',
          narration: '',
          valid_upto: null,
          currency: '',
          exchange_rate: '',
          unit: '',
          fc_amount: '',
          rate: '',
        },
      ],

    },
    validationSchema: RateRequestSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const token = Cookies.get("token");

        const response = await fetch(`${mainUrl}/api/v.1/updateQuotationRate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          const responseData = await response.json();
          GetUserInfo();
          setEdit(false)
          // const updatedInformation = responseData?.uhqpa_id
          // await createQuotationStatus(updatedInformation);
          // navigate('/dashboard/view-updated-query-list');
          resetForm()
          toast.success(responseData?.message);
        } else {
          toast.error("Wrong Credentials");
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });

  useEffect(() => {
    if (rate?.data?.tariffDetails) {
      const tariffDetails = rate.data.tariffDetails.map((value) => ({
        id: value?.id,
        airline_name: value.airline_name || '',
        charge_head: value.charge_head || '',
        buying_price: value.buying_price || '',
        selling_price: value.selling_price || '',
        narration: value.narration || '',
        valid_upto: value.valid_upto || '',
        currency: value.currency || '',
        exchange_rate: value.exchange_rate || '',
        unit: value.unit || '',
        fc_amount: value.fc_amount || '',
        rate: value.rate || '',
      }));

      formik.setFieldValue('tarrif_details', tariffDetails);
    }

    if (rate?.data?.shipmentInfo) {
      const shipmentInfo = rate.data.shipmentInfo[0];
      formik.setFieldValue('shipment_info', {
        id: shipmentInfo?.id || '',
        quotation_number: shipmentInfo?.quotation_number || '',
        date_of_creation: startDate || '',
        buy_ref_number: shipmentInfo?.buy_ref_number || '',
        rate_validity: rateValidity,
        validity_against: shipmentInfo?.validity_against || '',
        quotation_rise_in: shipmentInfo?.quotation_rise_in || '',
      });
      if (shipmentInfo?.date_of_creation) {
        const formattedDate = format(new Date(shipmentInfo.date_of_creation), 'yyyy-MM-dd');
        setStartDate(new Date(formattedDate));
        formik.setFieldValue('shipment_info.date_of_creation', formattedDate);
      }
      if (shipmentInfo?.rate_validity) {
        const formattedDate = format(new Date(shipmentInfo.rate_validity), 'yyyy-MM-dd');
        setRateValidity(new Date(formattedDate));
        formik.setFieldValue('shipment_info.rate_validity', formattedDate);
      }
    }

    if (rate?.data?.companyInfo) {
      const companyInfo = rate.data.companyInfo[0];
      formik.setFieldValue('company_info', {
        id: companyInfo?.id || '',
        company_name: companyInfo?.company_name || '',
        address: companyInfo?.address || '',
        phone_number: companyInfo?.phone_number || '',
        email: companyInfo?.email || '',
        gstin: companyInfo?.gstin || '',
        country: companyInfo?.country || '',
        state: companyInfo?.state || '',
      });
    }

    if (rate?.data?.generalInfo) {
      const generalInfo = rate.data.generalInfo[0];
      formik.setFieldValue('general_info', {
        id: generalInfo?.id || '',
        company_name: generalInfo?.company_name || '',
        address: generalInfo?.address || '',
        billing_account: generalInfo?.billing_account || '',
        attention_to: generalInfo?.attention_to || '',
        airline: generalInfo?.airline || '',
        place_of_receipt: generalInfo?.place_of_receipt || '',
        commodity: generalInfo?.commodity || '',
        airport_of_loading: generalInfo?.airport_of_loading || '',
        cargo_weight: generalInfo?.cargo_weight || '',
        airport_of_discharge: generalInfo?.airport_of_discharge || '',
        incoterms: generalInfo?.incoterms || '',
        tentative_transit_time: generalInfo?.tentative_transit_time || '',
        final_delivery_place: generalInfo?.final_delivery_place || '',
        notes: generalInfo?.notes || '',
      });
    }
  }, [rate]);
  const GetUserInfo = async () => {
    try {
      const data = await Api(
        `${mainUrl}/api/v.1/viewRate/${status_id}`,
        "GET"
      );
      if (data.status === 200) {
        setRate(data)
      } else {
        console.error("API call was not successful:", data.error);
      }
    } catch (error) {

      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    GetUserInfo();
  }, []);
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det d-sm-flex justify-content-between align-items-center">
              <h2>
                <Link to='/pricing-employee-dashboard/send-pricing-list' className="query_info">
                  <img src="/img/re_darect.png" alt="Query" />
                </Link>
                Quotation Air Export Form
              </h2>
              {/* <div style={{ cursor: "pointer" }} className='search-btn text-white py-1' onClick={() => { setEdit(!edit) }}>
                Edit
              </div> */}
            </div>
            <div className="main_detail_form Air_Export_Form_bg">
              <form onSubmit={formik.handleSubmit} className="Air_Export_bg_main">
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/gen.png" alt="gen" />
                    </span>
                    Shipment Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Number </label>
                      <input
                        disabled={edit === false ? true : false}

                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.quotation_number}
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('shipment_info.quotation_number', num);
                        }}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Date of Creation </label>
                      <div className="custom-datepicker">
                        <DatePicker
                          disabled={edit === false}
                          selected={startDate}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setStartDate(date);
                            formik.setFieldValue('shipment_info.date_of_creation', formattedDate);
                          }}
                          onBlur={formik.handleBlur}
                          name="shipment_info.date_of_creation"
                          className="form-control"
                        />
                      </div>
                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Buying Reference Number</label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.buy_ref_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.buy_ref_number"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                      <label>Rate Validity <span>*</span> </label>

                      <div className="custom-datepicker">
                        <DatePicker
                          disabled={edit === false}
                          selected={rateValidity}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setRateValidity(date);
                            formik.setFieldValue('shipment_info.rate_validity', formattedDate);
                          }}
                          onBlur={formik.handleBlur}
                          name="shipment_info.rate_validity"
                          className="form-control"
                        />
                      </div>

                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Validity Against</label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.validity_against}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.validity_against"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section ">
                      <label>Quotation Rise in <span>*</span></label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.quotation_rise_in}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_rise_in"

                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Company Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Company Name <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.company_name"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.address"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Phone Number<span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.phone_number"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Email ID <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.email"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>GSTIN <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.gstin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.gstin"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section">
                      <label>Country <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.country"

                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input_box_section">
                      <label>State <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.state"

                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    General Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Company Name <span>*</span> </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.general_info.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.company_name"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.general_info.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.address"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Billing Account</label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.general_info.billing_account}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.billing_account"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Attention To</label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.general_info.attention_to}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.attention_to"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Airline </label>
                      <input
                        disabled={edit === false ? true : false}
                        type="text"
                        className="form-control"
                        value={formik.values.general_info.airline}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airline"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Place of Receipt </label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.place_of_receipt}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.place_of_receipt"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Commodity </label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.commodity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.commodity"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Air Port of Loading <span>*</span> </label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.airport_of_loading}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airport_of_loading"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Cargo Weight (Kg) </label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.cargo_weight}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.cargo_weight"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Air Port of Discharge <span>*</span> </label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.airport_of_discharge}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.airport_of_discharge"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Incoterms</label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.incoterms}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.incoterms"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Tentative Transit Time <span>*</span></label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.tentative_transit_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.tentative_transit_time"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Final Place of Delivery <span>*</span></label>
                      <input
                        type="text"
                        disabled={edit === false ? true : false}
                        className="form-control"
                        value={formik.values.general_info.final_delivery_place}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.final_delivery_place"

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Notes</label>
                      <textarea
                        disabled={edit === false ? true : false}
                        rows={3}
                        className="form-control"
                        placeholder="Examples..."
                        defaultValue={""}
                        value={formik.values.general_info.notes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="general_info.notes"

                      />
                    </div>
                  </div>
                </div>
                {/* <button className='search-btn text-white py-1' type="submit">Submit</button> */}
              </form>
              <div className="main-nav-det">
                <h4>
                  <span>
                    <img src="/img/Product.png" alt="gen" />
                  </span>
                  Tariff Details
                </h4>
              </div>
              <div className="flex_detail_main ">
                <div className="table_detail_tariff table-responsive">
                  <table className="posts-table geeks table-responsive">
                    <thead>
                      <tr className="users-table-tarif-main">
                        <th>
                          <div className="main-box-det-check">
                            <label className="users-table__checkbox" id="new_text">
                              <input type="checkbox" className="check" />
                              Airline Name
                            </label>
                          </div>
                        </th>
                        <th>Charge Head</th>
                        <th>Narration</th>
                        <th>Currency</th>
                        <th>Unit</th>

                        <th>Rate</th>
                        <th>FC Amount</th>
                        <th>Exchange Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        rate?.data?.tariffDetails?.map((value, index) => {
                          {
                            return (
                              <tr className="main_table_row_new" key={index}>
                                <td>{value?.airline_name}</td>
                                <td>{value?.charge_head}</td>
                                <td>{value?.narration}</td>
                                <td>{value?.currency}</td>
                                <td>{value?.unit}</td>

                                <td>{value?.rate}</td>
                                <td>{value?.fc_amount}</td>
                                <td>{value?.exchange_rate}</td>
                              </tr>
                            )
                          }
                        })
                      }


                    </tbody>
                  </table>
                </div>
                <div className="box_button_main">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#myModal">
                    <img src="/img/edit-line.png" alt="edit" />
                  </a>
                </div>
              </div>
              <div className="main-nav-det-button text-right main-Export-button">
                <Link to={`/pricing-employee-dashboard/send-pricing-list`}>Download Quotation</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ViewRateModal modal={"myModal"} tariffDetails={rate?.data?.tariffDetails} GetUserInfo={GetUserInfo} />
    </>
  )
}

export default ViewRate