import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { adminMainUrl, mainUrl } from "../../../Config";
import Api from "../../../Api";
import Cookies from "js-cookie";
import { Height } from "@mui/icons-material";
import { toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import { RemarkModal } from "../../Modal/RemarkModal";
const QueryInfo = () => {
  const [quotationId, setQuotationId] = useState(null);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [viewData, setViewData] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.quotationId) {
      setQuotationId(location.state.quotationId);
    }
  }, [location.state]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  const ViewQuotation = async (information) => {
    setSpinnerLoader(true)
    try {
        const token = Cookies.get("token");
        const requesId = {
            quotation_id: `${quotationId}`,
        };
        const response = await fetch(`${adminMainUrl}/api/quotation/view`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "auth-token": token,
            },
            body: JSON.stringify(requesId),
        });
        if (!response.ok) {
            setSpinnerLoader(false);
            toast.error(data?.msg);
            setIsVisible(false);
            navigate(-1)
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // toast.success(data?.msg);
        setViewData(data?.data);
        setIsVisible(false)
        setSpinnerLoader(false);
    } catch (error) {
        setIsVisible(false)
        setSpinnerLoader(false);
        console.log(error);
    }
};
  useEffect(() => {
    if (quotationId) {
      ViewQuotation();
    }
  }, [quotationId]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const parsedFiles = JSON.parse(viewData?.files || "[]");
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>
                    <Link
                      to="/admin-dashboard/picked-leads"
                      className="query_info"
                    >
                      <img src="/img/re_darect.png" alt="Query" />
                    </Link>
                    Query Information
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="new_infometion_table">
            {spinnerLoader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px", width: "100%" }}
              >
                <BeatLoader />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <table className="posts-table">
                    <tbody>
                      <tr>
                        <td className="table_info_color">
                          <p>Request Number</p>
                        </td>
                        <td>
                          <p>{viewData?.request_no}</p>
                        </td>
                        {/* <td>
                                            <div className="info-det-button" onClick={() => { navigate('/admin-dashboard/view-rates') }}>View Rates
                                            </div>
                                        </td> */}
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Request Date</p>
                        </td>
                        <td colSpan={2}>
                          <p>{formatDate(viewData?.createdAt)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Name</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.full_name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Email</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.email}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Phone Number</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.phone}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Origin</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.origin}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Destination</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.destination}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Description</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.detailed_requirement}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Expected transit date</p>
                        </td>
                        <td colSpan={2}>
                          <p>{formatDate(viewData?.expected_transit_date)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Type of Shipment Type</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.shipment_type?.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Shipment Via</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.shipment_via?.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Required Service</p>
                        </td>
                        <td colSpan={2}>
                          <p>{viewData?.required_servie?.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Package dimension/weight</p>
                        </td>
                        <td>
                          <tr>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Length
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Width
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Height
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Type
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Max Weight
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Unit
                            </td>
                            <td style={{ border: "1px solid #c7c7c7" }}>
                              Total Box
                            </td>
                          </tr>
                          {viewData?.package_info?.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.length}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.width}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.height}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.package_type?.name}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.max_weight}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.gross_weight_unit?.name}
                                </td>
                                <td style={{ border: "1px solid #c7c7c7" }}>
                                  {value?.total_boxes}
                                </td>
                              </tr>
                            );
                          })}

                          {/* <p>12cmX200cmX299cm 120 KG 2 Boxes</p> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Shipment Document</p>
                        </td>
                        <td colSpan={2}>
                          <div className="col-6 input_box_section file-input-main">
                            {parsedFiles?.map((value, index) => {
                              return (
                                <button
                                  className="folder_main_update"
                                  key={index}
                                >
                                  <a
                                    href={`${adminMainUrl}/${value}`}
                                    target="blank"
                                  >
                                    {value}
                                  </a>
                                  <span>
                                    <img
                                      src="/img/download-icon.png"
                                      alt="file"
                                    />
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Shipment Video</p>
                        </td>
                        <td colSpan={2}>
                          <div className="col-6 input_box_section file-input-main">
                            <button className="folder_main_update">
                              <a
                                href={`${adminMainUrl}/${viewData?.videos}`}
                                target="blank"
                              >
                                {viewData?.videos}
                              </a>
                              <span>
                                <img src="/img/download-icon.png" alt="file" />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr style={{ borderTop: "1px solid #d4d4d4" }}>
                        <td className="table_info_color">
                          <p>Picked by</p>
                        </td>
                        <td colSpan={2}>
                          <p>Aman</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="table_info_color">
                          <p>Status</p>
                        </td>
                        <td colSpan={2}>
                          <a href="#" className="view_in_color">
                            {viewData?.status}
                          </a>
                        </td>
                      </tr>
                      {
                                                viewData?.quote_remarks?.length > 0 &&
                                                <tr style={{ borderTop: "1px solid #d4d4d4" }}>
                                                    <td className="table_info_color">
                                                        <p data-bs-toggle="modal" data-bs-target="#remark" className="cursor-pointer" >Remark</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                      
                                                        <p>{viewData?.quote_remarks?.length}</p>
                                                    </td>
                                                </tr>
                                            }
                      <tr>
                        <td className="table_info_color">
                          <div
                            className="send_query_list_button w-fit p-0 send-query" style={{ background: "none", color: "#000" }}
                            id="remarks"
                            onClick={handleToggle}
                          >
                            Add Remark
                          </div>
                        </td>
                        <td colSpan={2}>
                          <div
                            className="mt-1"
                            id="remarks"
                            style={{ display: isVisible ? "block" : "none" }}
                          >
                            {/* <label for="remarks-desc">Remark</label> */}
                            <textarea
                              id="remarks-desc"
                              name="remarks-desc"
                              class="form-control"
                              placeholder="Write Something Here...."
                            ></textarea>
                            {/* <button className="send_query_list_button send-query desc-btn" id="sendRemarks" style={{ display: isVisible ? 'inline' : 'none' }}>
                                       Send
                                    </button> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/quote-request-form");
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/picked-leads");
                      }}
                    >
                      {/* <button>Send for Pricing</button> */}
                      Send for Pricing
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/picked-leads");
                      }}
                    >
                      Sent to Client
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/query-follow-up-summary");
                      }}
                    >
                      Follow-Up
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/view-rates");
                      }}
                    >
                      View Rates
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/converted-leads");
                      }}
                    >
                      Lead Won
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="send_query_list_button send-query"
                      onClick={() => {
                        navigate("/admin-dashboard/non-converted-leads");
                      }}
                    >
                      Lead Lost
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <RemarkModal viewData={viewData} id={"remark"} />
    </>
  );
};

export default QueryInfo;
