import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
// import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { adminMainUrl } from "../../../Config";
import { adminLoginSchema } from "../../../ValidationScema";
import { toast, ToastContainer } from "react-toastify";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "react-toastify/dist/ReactToastify.css";
import Api from "../../../Api";
const Login = () => {
    const location = useLocation();
    // const notify = () => toast("Wow so easy!");
    const [mail, setMail] = useState(null);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(true);
    const [roleId, setRoleId] = useState([]);
    // const location = useLocation();
    const validationSchema = Yup.object({
        new_password: Yup.string()
            .min(4, "Password must be at least 4 characters")
            .required("New Password is required"),
    });
    useEffect(() => {
        if (location.state?.email) {
            setMail(location.state.email);
        }
    }, [location.state]);
    const formik = useFormik({
        initialValues: {
            new_password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await fetch(
                    `${adminMainUrl}/api/forgot-pasword-update`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": localStorage.getItem("auth-token"), // Add auth-token if required
                        },
                        body: JSON.stringify({
                            email: mail, // Pre-filled email
                            new_password: values.new_password, // New password entered by the user
                        }),
                    }
                );
                const data = await response.json();
                if (response.ok) {
                    toast.success(data?.msg);
                    navigate("/admin-login"); // Redirect to login page
                } else {
                    toast.error(data?.msg);
                    const errorData = await response.json();
                    console.error("Error updating password:", errorData.message);
                }
            } catch (error) {
                console.error("Network error:", error);
            }
        },
    });

    return (
        <section className="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 second-body">
                        <div className="card-body align-items-center mx-md-4 card-padding">
                            <div className="logo-section pt-4">
                                <Link to="/admin-login-forget-otp" className="query_info">
                                    <img src="/img/back-icon.png" alt="Query" />
                                </Link>
                            </div>
                            <div className="logo-section text-center pt-4">
                                <img src="/img/committed-logo.png" alt="logo" />
                            </div>
                            <form
                                className="login-form mx-auto"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="forget-head">
                                    <h4>Create Your Password</h4>
                                </div>
                                <div data-mdb-input-init="" className="form-outline mb-2">
                                    <label htmlFor="new_password">New Password</label>
                                    <div className='relative password-input'>
                                        <input
                                            type={showPassword ? "password" : "text"}
                                            id="new_password"
                                            className="form-control"
                                            placeholder="Enter New Password"
                                            name="new_password"
                                            value={formik.values.new_password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                        {
                                            showPassword ?
                                                <VisibilityOffIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                                :
                                                <VisibilityIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                        }
                                    </div>
                                    {formik.touched.new_password && formik.errors.new_password ? (
                                        <div className="error">{formik.errors.new_password}</div>
                                    ) : null}
                                </div>
                                <div className="text-center pt-1 mb-2 pb-1">
                                    <button className="btn-login mb-3" type="submit">
                                        Set Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-7 p-0">
                        <div className="img-form">
                            <img
                                src="/img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
