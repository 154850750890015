import React from 'react'
import { Link } from 'react-router-dom'

const ClientLogin = () => {
    return (
        <section className="login">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-7  order-lg-1  order-md-1  order-sm-2 p-0 ">
              <div className="img-form">
                <img
                  src="./img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-5  order-sm-2 order-lg-2 second-body">
              <div className="card-body align-items-center  mx-md-4 card-padding">
                <div className="logo-section text-center pt-4 mt-5">
                  <img src="./img/committed-logo.png" alt="logo" />
                </div>
                <form className="login-form mx-auto">
                  {/* <div data-mdb-input-init class="form-outline pt-4">
                                <label for="select">Select Your Role</label>
                                <select class="form-select form-select-md mb-3">
                                    <option selected>Please Select Role</option>
                                    <option value="1">Super Admin</option>
                                    <option value="2">Admin</option>
                                    <option value="3">Head Of Department</option>
                                    <option value="3">Employee</option>
                                    <option value="3">Client</option>
                                </select>
                            </div> */}
                  <div data-mdb-input-init="" className="form-outline">
                    <label htmlFor="select">Email</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      required=""
                    />
                    <label className="form-label" htmlFor="email" />
                  </div>
                  <div
                    data-mdb-input-init=""
                    className="form-outline mb-1"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="select">Password</label>
                    <input
                      type="password"
                      id="pass"
                      className="form-control"
                      placeholder="Password"
                      required=""
                    />
                    <label className="form-label" htmlFor="pass" />
                    <i className="fa fa-eye-slash" id="togglePassword" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="rememberMe"
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>
                  </div>
                </form>
                <Link to="/Customer-dashboard/customer-registration">
                  <div className="text-center pt-1 mb-2 pb-1">
                    <button className=" btn-login mb-3" type="submit">
                      Login
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      


    )
}

export default ClientLogin