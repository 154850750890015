import React, { useState,useEffect } from 'react'
import {  Route, Routes ,Navigate } from 'react-router-dom';
// import KycApprovalRequest from './KycApprovalRequest';
// import CustomerRegistration from './CustomerRegistrationForm/CustomerRegistration';
import RegistrationAgreement from './KycRegistrationAgreement/RegistrationAgreement';
import Navbar from './Navbar/Navbar';
import SideBar from './Sidebar/Sidebar';
import KycApprovalRequest from './KycApprovalRequest';
// import CustomerRegistration from './CustomerRegistrationForm/CustomerRegistration';
import KycRegistration from './KycRegistrationForm/KycRegistration';

const ClientDashboard = () => {
  const [showSideBar,setShowSideBar]=useState(false)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
  <div className="page-flex">
  {/* ! Sidebar */}
  <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
  <div className="main-wrapper">
    {/* ! Main nav */}
  <Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar}/>
    {/* ! Main */}
    <main className="main users chart-page" id="skip-target">
        <Routes>
    <Route index element={<Navigate to="Kyc-approval-request" />} />
    <Route path='kyc-approval-request' element={<KycApprovalRequest/>} />
    <Route path='customer-registration-form' element={<KycRegistration/>} />
    <Route path='customer-registration-agreement' element={<RegistrationAgreement/>} />
    {/* <Route path='quote-request-form' element={<QuoteRequestForm/>} /> */}
    {/* <Route path='query-list' element={<QueryList/>} /> */}
    {/* <Route path='all-rate-request' element={<AllRateRequest/>} /> */}
    {/* <Route path='sales-dashboard' element={<SalesDashboard/>} /> */}
    {/* <Route path='query-info-give-rates' element={<QueryInfoGiveRate/>} /> */}
    {/* <Route path='view-rates' element={<ViewRate/>} /> */}
    {/* <Route path='query-information' element={<QueryInfo/>} /> */}
    {/* <Route path='quotation-air-export' element={<QuotationAirExportForm/>} /> */}
    </Routes>

    </main>
  </div>
</div>
    </div>
  )
}

export default ClientDashboard