import React from 'react'

const ProvisionalSheetTab = () => {
    return (
        <div
            className="tab-pane fade"
            id="provisional-sheet"
            role="tabpanel"
            aria-labelledby="provisional-sheet-tab"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Provisional Cost Details</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Type</label>
                            <select name="#" id="#">
                                <option value="#">Select</option>
                                <option value="#">Expense</option>
                                <option value="#">Income</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Docket Number</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="NDL/AE/5104/24"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Charges</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Account Name</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Date</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="22/04/2024"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>Narration</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>Amount</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section payment_option">
                            <div className="payment_option_button">
                                <input
                                    type="radio"
                                    id="cash"
                                    name="cash"
                                    defaultChecked=""
                                />
                                <label htmlFor="cash">Cash</label>
                            </div>
                            <div className="payment_option_button">
                                <input type="radio" id="bank" name="cash" />
                                <label htmlFor="bank">Bank</label>
                            </div>
                        </div>
                    </div>
                    <div className="button-shipment-registration d-flex mt-2">
                        <div className="btn">
                            Add
                        </div>
                    </div>
                </div>
            </form>
            <div className="border_divider mt-3" />
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Cost and Expense Sheet</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table_detail_tariff table-responsive shipment_registration_table">
                        <table className="posts-table geeks">
                            <thead>
                                <tr className="n_detail_comp">
                                    <th className="users-table-th">Docket No.</th>
                                    <th className="users-table-th">Account Name</th>
                                    <th className="users-table-th">Charges</th>
                                    <th className="users-table-th">Narration</th>
                                    <th className="users-table-th">Amount</th>
                                    <th className="users-table-th">Type</th>
                                    <th className="users-table-th">Row Type</th>
                                    <th className="users-table-th">Date</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="n_detail_comp">ABCDEFG</td>
                                    <td className="n_detail_comp">ABCDEFG</td>
                                    <td className="n_detail_comp" />
                                    <td className="n_detail_comp" />
                                    <td className="n_detail_comp">12</td>
                                    <td className="n_detail_comp" />
                                    <td className="n_detail_comp" />
                                    <td className="n_detail_comp">22/04/2024</td>
                                    <td className="main_table_last">
                                        <div className="main_icon_click">
                                            <a href="#main_id_sec">
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    src="/img/delete-outline.png"
                                                    alt="delete"
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="button-shipment-registration d-flex mt-2">
                <div className="btn">
                    Save
                </div>
                <div className="btn">
                    Clear
                </div>
            </div>
        </div>
    )
}

export default ProvisionalSheetTab