import React, { useRef, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { adminMainUrl } from '../../Config';
import toast, { Toaster } from 'react-hot-toast';
const EditRequiredService = ({ id, onSuccess, RequiredServiceId, RequiredServiceName }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (RequiredServiceId && RequiredServiceName) {
            formik.setValues({
                id: `${RequiredServiceId}`,
                name: RequiredServiceName,
            });
        }
    }, [RequiredServiceId, RequiredServiceName]);
    const closeButtonRef = useRef(null);
    const validationSchema = Yup.object({
        name: Yup.string().required('RequiredService Type is required'),
    });

    // Initialize Formik
    const formik = useFormik({
        initialValues: {
            id: `${RequiredServiceId}` || '',
            name: RequiredServiceName || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                const response = await fetch(`${adminMainUrl}/api/required-service/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'auth-token': Cookies.get('token'), // Assuming token is stored in cookies
                    },
                    body: JSON.stringify(values),
                });

                const data = await response.json();

                if (response.ok) {
                    closeButtonRef.current.click();
                    if (onSuccess) onSuccess();
                    toast.success(data?.msg);
                } else {
                    toast.error(data?.msg);
                }
            } catch (error) {
                console.error('Network error:', error);
            }
            finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });
    return (
        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content p-0">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Required Service
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Required Service <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="error">{formik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Update"}
                                </button>
                            </div>

                        </form>
                    </div>
                    {/* <div className="modal-footer"> */}
                    <button
                        ref={closeButtonRef}
                        style={{ display: 'none' }}
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button type="button" className="btn btn-primary">
                    Save changes
                </button>
            </div> */}
                </div>
            </div>
        </div>
    )
}

export default EditRequiredService