import React,{useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const ActivityDetailsTab = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade"
            id="activity-details"
            role="tabpanel"
            aria-labelledby="activity-details-tab"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Activity Details</h4>
                    </div>
                </div>
            </div>
            <div className="scroll_listing_detail">
                <div className="users-table ">
                    <table className="posts-table">
                        <thead>
                            <tr className="users-table-info">
                                <th>Select</th>
                                <th>User</th>
                                <th>Activity</th>
                                <th>Activity Date</th>
                                <th>Activity Time</th>
                                <th>Remarks</th>
                                <th>Complete Date</th>
                                <th>Complete Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">#1234</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="" />
                                <td className="">29/04/2024</td>
                                <td className="">09:12 pm</td>
                                <td className="">Complete</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_list_next_page">
                        <ul>
                            <li>
                                {" "}
                                <a href="#">
                                    <img src="/img/right.png" alt="right" />
                                </a>{" "}
                            </li>
                            <li className="main_list_next_list active">
                                <a href="#">1</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">2</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">3</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">4</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">5</a>
                            </li>
                            <li className="main_list_next_list img_icon_sec">
                                <a href="#">
                                    <img src="/img/Menu-icon.png" alt="manu" />
                                </a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">10</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">11</a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="/img/left.png" alt="left" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Update Pending Activity</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Docket Number</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="NDL/AE/5104/24"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Activity</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>Activity Complete Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Activity Complete Time</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Remarks</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className="button-shipment-registration d-flex mt-2">
                <div className="btn">
                    Save
                </div>
                <div className="btn">
                    Clear
                </div>
            </div>
        </div>
    )
}

export default ActivityDetailsTab