import React from 'react'

const TrackingGoodSeaImport = () => {
    return (
        <main className="main users chart-page" id="skip-target">
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>Tracking Goods</h2>
                                </div>
                            </div>

                        </div>
                        {/* =============Search bar============= */}
                        <div className="query_bg_info_kyc">

                            <form className='main_section_detail' action="#">
                                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                                <div className="row ">
                                    <label>AWB Number</label>
                                    <div className="col-sm-8 col-md-6">
                                        <div className="input_box_section">

                                            <input type="text" className="form-control" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 ">  <button type='button' className='btn search-btn w-fit mt-2 ms-0'>
                                        Search
                                    </button></div>

                                </div>
                                <div className="row border-top-2px tracking-goods mt-3 ">
                                    <div className="col-12 ">
                                        <h3 className="text-capitalize mt-4">
                                            Delivery Tracking
                                        </h3>
                                    </div>
                                    <div className="delivery-tracking-container my-4 d-flex justify-content-lg-between ">
                                        <div className="delivery-box">
                                            <p className="mb-0">Pickup Location</p>
                                            <span>Delhi</span>
                                        </div>
                                        <div className="delivery-box">
                                            <p className="mb-0">Destination</p>
                                            <span>Mumbai</span>
                                        </div>
                                        <div className="delivery-box">
                                            <p className="mb-0">Arrival Time</p>
                                            <span>15 Days</span>
                                        </div>
                                        <div className="delivery-box">
                                            <p className="mb-0">Agent Name</p>
                                            <span>John</span>
                                        </div>
                                        <div className="delivery-box">
                                            <p className="mb-0">Phone Number</p>
                                            <span>1234567890</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <img src="/img/tracking.png" alt="" className="" />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.852075256286!2d79.84691481018649!3d6.908285393062352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2595d28e379b5%3A0x6ffce24517cc68ba!2s03%2C%2015%20Sellamuttu%20Ave%2C%20Colombo%2000300%2C%20Sri%20Lanka!5e0!3m2!1sen!2sin!4v1720767206195!5m2!1sen!2sin"
                                            width="100%"
                                            height="100%"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        />

                                    </div>
                                </div>

                            </form>

                        </div>
                        {/* =============Search bar============= */}

                    </div>
                </div>
            </div>
        </main>
    )
}

export default TrackingGoodSeaImport