import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
// import toast, { Toaster } from 'react-hot-toast';
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModuleList } from "../../../Redux/action/Admin/RolePermissionAction";
import { FocusError } from "focus-formik-error";
import axios from "axios";
import Beatloader from "../../../Common/Loaders/Beatloader";
import { toast } from "react-toastify";
const adminMainUrl = process.env.REACT_APP_API_SERVER;

const RoleAddForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  let [showLoader, setShowLoader] = useState(false);
  let [disable, setDisable] = useState(false);
  let [list, setList] = useState([]);

  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;
  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      dispatch(getModuleList());
    }
  }, []);

  let { loadingModuleList, moduleListDataError, moduleListData } = useSelector(state => state?.RolePermissionReducer);

  useEffect(() => {
    if (loadingModuleList) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    };

    if (moduleListData) {
      setList(moduleListData);
    }

  }, [loadingModuleList, moduleListDataError, moduleListData]);


  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: '',
      permissionList: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Role Name is required'),
      permissionList: Yup.array().min(1, 'At least one permission must be selected')
    }),
    onSubmit: (values, { resetForm }) => {
      setDisable(true);
      try {
        const token = Cookies.get('token');
        let config = {
          method: "GET",
          headers: {
            'auth-token': token,
            'Content-Type': 'application/json',
          }
        };

        axios.post(`${adminMainUrl}/api/role/create`, values, config)
          .then(response => {
            if (response.status == 200) {
              setDisable(false);
              navigate('/admin-dashboard/roles/list');
              resetForm();
              toast.success(response?.data?.msg)
            } else {
              setDisable(false);
              toast.error(response?.data?.msg)
            }
          }).catch(error => {
            setDisable(false);
            console.log(error.message);
            toast.error(error?.response?.data?.msg)
          })
      } catch (error) {
        setDisable(false);
        console.log(error.message);
        toast.error(error?.response?.data?.msg)
      }
    },
  });

  // Handle head checkbox toggle
  // const handleHeadCheckboxChange = (module) => {
  //   const isHeadChecked = formik.values.permissionList.some(
  //     (permModule) => permModule.module_short_name === module.short_name
  //   );

  //   let updatedPermissions;

  //   if (isHeadChecked) {
  //     // Uncheck all permissions for the module
  //     updatedPermissions = formik.values.permissionList.filter(
  //       (permModule) => permModule.module_short_name !== module.short_name
  //     );
  //   } else {
  //     // Check all permissions for the module
  //     const newModulePermissions = {
  //       module_short_name: module.short_name,
  //       permission_list: module.permission.map((p) => ({
  //         permission_short_name: p.short_name,
  //       })),
  //     };
  //     updatedPermissions = [...formik.values.permissionList, newModulePermissions];
  //   }

  //   formik.setFieldValue('permissionList', updatedPermissions);
  // };

  // const handlePermissionCheckboxChange = (module, permission) => {
  //   const moduleIndex = formik.values.permissionList.findIndex(
  //     (permModule) => permModule.module_short_name === module.short_name
  //   );

  //   let updatedPermissions = [...formik.values.permissionList];
  //   let existingModule;

  //   if (moduleIndex > -1) {
  //     existingModule = { ...updatedPermissions[moduleIndex] };  // Copy the existing module
  //     const permissionIndex = existingModule.permission_list.findIndex(
  //       (perm) => perm.permission_short_name === permission.short_name
  //     );

  //     if (permissionIndex > -1) {
  //       // Uncheck the permission
  //       existingModule.permission_list.splice(permissionIndex, 1);

  //       // Remove the entire module if no permissions are left
  //       if (existingModule.permission_list.length === 0) {
  //         updatedPermissions.splice(moduleIndex, 1);
  //       } else {
  //         updatedPermissions[moduleIndex] = existingModule; // Update the existing module in the list
  //       }
  //     } else {
  //       // Check the permission
  //       existingModule.permission_list.push({
  //         permission_short_name: permission.short_name,
  //       });
  //       updatedPermissions[moduleIndex] = existingModule; // Update the existing module in the list
  //     }
  //   } else {
  //     // Add new module with the selected permission
  //     updatedPermissions.push({
  //       module_short_name: module.short_name,
  //       permission_list: [{ permission_short_name: permission.short_name }],
  //     });
  //   }

  //   // Ensure head checkbox is updated based on whether all permissions are checked
  //   const allPermissionsSelected =
  //     updatedPermissions.find(
  //       (permModule) => permModule.module_short_name === module.short_name
  //     )?.permission_list.length === module.permission.length;

  //   if (!allPermissionsSelected && moduleIndex > -1) {
  //     // Uncheck the head if not all permissions are selected
  //     updatedPermissions = updatedPermissions.filter(
  //       (permModule) => permModule.module_short_name !== module.short_name
  //     );
  //     if (existingModule && existingModule.permission_list.length > 0) {
  //       updatedPermissions.push(existingModule);
  //     }
  //   }

  //   formik.setFieldValue('permissionList', updatedPermissions);
  // };


  const handleHeadCheckboxChange = (module) => {
    // Check if the module is currently in the permissionList
    const existingModule = formik.values.permissionList.find(
      (permModule) => permModule.module_short_name === module.short_name
    );

    if (existingModule) {
      // If the module exists, it means the head checkbox is currently checked
      // We need to toggle the state of all permissions for this module
      const updatedPermissions = formik.values.permissionList.filter(
        (permModule) => permModule.module_short_name !== module.short_name
      );
      formik.setFieldValue('permissionList', updatedPermissions);
    } else {
      // If the module does not exist, we need to add all permissions for this module
      const newModulePermissions = {
        module_short_name: module.short_name,
        permission_list: module.permission.map((p) => ({
          permission_short_name: p.short_name,
        })),
      };
      const updatedPermissions = [...formik.values.permissionList, newModulePermissions];
      formik.setFieldValue('permissionList', updatedPermissions);
    }
  };



  const handlePermissionCheckboxChange = (module, permission) => {
    const moduleIndex = formik.values.permissionList.findIndex(
      (permModule) => permModule.module_short_name === module.short_name
    );

    let updatedPermissions = [...formik.values.permissionList];
    let existingModule;

    if (moduleIndex > -1) {
      existingModule = { ...updatedPermissions[moduleIndex] };  // Copy the existing module
      const permissionIndex = existingModule.permission_list.findIndex(
        (perm) => perm.permission_short_name === permission.short_name
      );

      if (permissionIndex > -1) {
        // Uncheck the permission
        existingModule.permission_list.splice(permissionIndex, 1);

        // Remove the entire module if no permissions are left
        if (existingModule.permission_list.length === 0) {
          updatedPermissions.splice(moduleIndex, 1);
        } else {
          updatedPermissions[moduleIndex] = existingModule; // Update the existing module in the list
        }
      } else {
        // Check the permission
        existingModule.permission_list.push({
          permission_short_name: permission.short_name,
        });
        updatedPermissions[moduleIndex] = existingModule; // Update the existing module in the list
      }
    } else {
      // Add new module with the selected permission
      updatedPermissions.push({
        module_short_name: module.short_name,
        permission_list: [{ permission_short_name: permission.short_name }],
      });
    }

    formik.setFieldValue('permissionList', updatedPermissions);
  };







  return (
    <>
      {/* <Toaster /> */}
      {showLoader ? <Beatloader /> : <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det">
              <h2>Add Role Form</h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <FocusError formik={formik} />
              <div className="main_detail_form">
                <div className="main-nav-det">
                  <h4>Role information</h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Role Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Role here"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger small">{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="main-nav-det">
                  <h4>Assign Permissions</h4>
                </div>
                <div className="row">
                  {list.length > 0 ? (
                    list.map((module, index) => {
                      const isHeadChecked = formik.values.permissionList.some(
                        (permModule) =>
                          permModule.module_short_name === module.short_name &&
                          permModule.permission_list.length === module.permission.length
                      );
                      return <div key={index} className="col-md-4 mb-3">
                        <div
                          className="table-responsive role_css acl-role"
                          style={{ height: '200px', overflowY: 'scroll', margin: '10px 0px' }}
                        >
                          <table className="table table-striped permission_table">
                            <tbody>
                              <tr>
                                <th>{module.name}</th>
                                <th style={{ width: 40 }}>
                                  <input
                                    type="checkbox"
                                    className="modulePerm"
                                    // name={`permissionList.${module.short_name}`}
                                    checked={isHeadChecked}
                                    // checked={formik.values.permissionList.some(
                                    //   (permModule) => permModule.module_short_name === module.short_name
                                    // )}
                                    onChange={() => handleHeadCheckboxChange(module)}
                                  />
                                </th>
                              </tr>
                              {module.permission.map((permission, i) => (
                                <tr key={i}>
                                  <td>{permission.name}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      // name={`permissionList.${module.short_name}`}
                                      checked={formik.values.permissionList.some(
                                        (permModule) =>
                                          permModule.module_short_name === module.short_name &&
                                          permModule.permission_list.some(
                                            (perm) => perm.permission_short_name === permission.short_name
                                          )
                                      )}
                                      onChange={() => handlePermissionCheckboxChange(module, permission)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    })
                  ) : (
                    <div className="text-center">
                      <i>No permission assigned for this role.</i>
                    </div>
                  )}
                </div>

                {formik.errors.permissionList && (
                  <div className="text-danger small">At least one permission must be selected</div>
                )}
                <div className="row mt-4 mb-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn search-btn" disabled={disable}>
                      {disable ? <i className="fas fa-spinner fa-spin"></i> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}
    </>
  );
};

export default RoleAddForm;
