import Cookies from 'js-cookie';
const adminMainUrl = process.env.REACT_APP_API_SERVER;

//emp list
export const fetchEmpListRequest = () => ({
    type: "FIND_EMP_LIST_REQUEST",
});

export const fetchEmpListSuccess = (data) => ({
    type: "FIND_EMP_LIST_SUCCESS",
    payload: data,
});

export const fetchEmpListFailure = (error) => ({
    type: "FIND_EMP_LIST_FAILURE",
    payload: error,
});

export const getEmpList = (current_page, items_per_page, queryParams) => {
    return async function (dispatch) {
        const token = Cookies.get('token');

        // Remove the 'page' parameter from queryParams if it exists
        let params = new URLSearchParams(queryParams);
        params.delete('page');  // Remove the page from the query params

        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };

        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/employee/list?page=${current_page}&${params.toString()}`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchEmpListSuccess(resData));
                });
            }).catch((err) => {
                console.log("ERROR in getEmpList function(redux):", err);
            });
    };
};


//emp list

// module detail
export const fetchRoleModuleDetailRequest = () => ({
    type: "FIND_ROLE_MODULE_DETAIL_REQUEST",
});

export const fetchRoleModuleDetailSuccess = (data) => ({
    type: "FIND_ROLE_MODULE_DETAIL_SUCCESS",
    payload: data,
});

export const fetchRoleModuleDetailFailure = (error) => ({
    type: "FIND_ROLE_MODULE_DETAIL_FAILURE",
    payload: error,
});

export const getRoleModuleDetail = (id, emply_id) => {

    return async function (dispatch) {
        dispatch(fetchRoleModuleDetailRequest());
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/employee/get-role-wise-permission?id=${id}&emply_id=${emply_id}`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchRoleModuleDetailSuccess(resData));
                });
            }).catch((err) => {
                console.log("ERROR in getRoleModuleDetail function(redux):", err);
                dispatch(fetchRoleModuleDetailFailure(err.message));
            });
    };
};

// module detail