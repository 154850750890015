import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { mainUrl } from '../../../Config';
import Swal from 'sweetalert2';

const QueryList = () => {
  const navigate = useNavigate();
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const headers = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'REQUIRED SERVICE',
    'PICKED BY',
    'actions',
  ];
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>Picked Rate Request List</h2>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="button-det-list">
                  <Link to="/admin-dashboard/quote-request-form">
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Manual Form
                  </Link>
                </div>
              </div> */}
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DATE &amp; TIME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="apply-btn cursor-pointer">

                                Apply
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="From"
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="To"
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <div className='search-btn text-white w-fit' style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}>Search</div>
                    <div
                      className='search-btn text-white w-fit'
                      style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            {/* <div className="row">
              <div className="col-md-12">
              <div class="main_listing_button">
                <ul>
                  <li><a href="#" class="color_1">Rebid</a></li>
                  <li><a href="#" class="color_2">In process</a></li>
                </ul>
              </div>
                <div className="scroll_listing_detail">
                  <div className="users-table ">
                    <table className="posts-table">
                      <thead>
                        <tr className="users-table-info">
                          <th>REQUEST NO.</th>
                          <th>DATE &amp; TIME</th>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          <th>PHONE NUMBER</th>
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>SHIPMENT TYPE</th>
                          <th>SHIPMENT VIA</th>
                          <th>PICKED BY</th>
                          <th>   <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div></th>
                        </tr>
                      </thead>
                      <tbody>
                                <tr>
                                  <td className="color_1 text-center">1234</td>
                                  <td className="color_1">
                                  12 May 2024
                                  </td>
                                  <td className="color_1">Rahul</td>
                                  <td className="color_1">rahul@gmail.com</td>
                                  <td className="color_1">9876543210</td>
                                  <td className="color_1">Delhi</td>
                                  <td className="color_1">London</td>
                                  <td className="color_1">Import</td>
                                  <td className="color_1">Air</td>
                                  <td className="color_1">
                                    Aman
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                         <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="color_1 text-center">1234</td>
                                  <td className="color_1">
                                  12 May 2024
                                  </td>
                                  <td className="color_1">Rahul</td>
                                  <td className="color_1">rahul@gmail.com</td>
                                  <td className="color_1">9876543210</td>
                                  <td className="color_1">Delhi</td>
                                  <td className="color_1">London</td>
                                  <td className="color_1">Import</td>
                                  <td className="color_1">Air</td>
                                  <td className="color_1">
                                    Aman
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2"  onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                         <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="color_1 text-center">1234</td>
                                  <td className="color_1">
                                  12 May 2024
                                  </td>
                                  <td className="color_1">Rahul</td>
                                  <td className="color_1">rahul@gmail.com</td>
                                  <td className="color_1">9876543210</td>
                                  <td className="color_1">Delhi</td>
                                  <td className="color_1">London</td>
                                  <td className="color_1">Import</td>
                                  <td className="color_1">Air</td>
                                  <td className="color_1">
                                    Aman
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2"  onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                         <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="color_1 text-center">1234</td>
                                  <td className="color_1">
                                  12 May 2024
                                  </td>
                                  <td className="color_1">Rahul</td>
                                  <td className="color_1">rahul@gmail.com</td>
                                  <td className="color_1">9876543210</td>
                                  <td className="color_1">Delhi</td>
                                  <td className="color_1">London</td>
                                  <td className="color_1">Import</td>
                                  <td className="color_1">Air</td>
                                  <td className="color_1">
                                    Aman
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2"  onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                         <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="color_1 text-center">1234</td>
                                  <td className="color_1">
                                  12 May 2024
                                  </td>
                                  <td className="color_1">Rahul</td>
                                  <td className="color_1">rahul@gmail.com</td>
                                  <td className="color_1">9876543210</td>
                                  <td className="color_1">Delhi</td>
                                  <td className="color_1">London</td>
                                  <td className="color_1">Import</td>
                                  <td className="color_1">Air</td>
                                  <td className="color_1">
                                    Aman
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2"  onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                         <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                              </div>
                                    </div>
                                  </td>
                                </tr>


                      </tbody>
                    </table>

                      <div className="pagination-controls my-5 d-flex justify-content-center align-items-center" >
                        <button >
                          <img src="/img/right.png" className='mx-2' alt="right" />
                        </button>
                        <span className='search-btn py-2 px-3 mx-3'>1</span>
                        <button>
                          <img src="/img/left.png" className='mx-2' alt="right" />
                        </button>

                      </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className="container mt-4">
              <ul className="nav nav-pills mb-3 border-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold active position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">Rebid</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">In Process</button>
                </li>
              </ul>
              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>


                        </tbody>
                      </table>

                      {/* Pagination Controls */}
                      <div className="pagination-controls my-5 d-flex justify-content-center align-items-center" >
                        <button >
                          <img src="/img/right.png" className='mx-2' alt="right" />
                        </button>
                        <span className='search-btn py-2 px-3 mx-3'>1</span>
                        <button>
                          <img src="/img/left.png" className='mx-2' alt="right" />
                        </button>

                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-employee-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QueryList