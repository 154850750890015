import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { mainUrl } from '../../../Config';
const QuotationConverted = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [full_name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentVia, setShipmentVia] = useState('');
  const [pickedBY, setPickedBy] = useState('');
  const [queryList, setQueryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const fromDate = startDate ? formatDate(startDate) : null;
  const toDate = endDate ? formatDate(endDate) : null;
  const navigate = useNavigate();
  const headers = [
    'QUOTATION NO.',
    'CONVERTED ON',
    'CLIENT NAME',
    'CLIENT EMAIL',
    'CLIENT PH. NO.',
    'ORIGIN',
    'DESTINATION',
    'CONVERTED BY',
    'QUOTATION SUMMARY',
    'Client Onboarding',
  ];
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const fetchQuotations = async (page, size) => {
    try {
      const token = Cookies.get("token");
      const requestBody = {
        page: currentPage,
        pageSize: 10,
        filterBy: {
          searchText,
          status_id,
          full_name,
          email,
          mobileNumber,
          origin,
          destination,
          shipmentType,
          shipmentVia,
          pickedBY,
          fromDate,
          toDate
        }
      };

      const response = await fetch(`${mainUrl}/api/v.1/getQuotationList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setQueryList(data)
      setPageSize(data?.data?.totalCount)
    } catch (error) {
      alert(error)
    } finally {
      // setLoading(false);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    fetchQuotations(currentPage, pageSize);
    createConvertedQuotation()
    createQuotationStatus();
  }, [currentPage, pageSize]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleStartDateChange = (date) => {
    if (date && date.getTime() === endDate?.getTime()) {
      alert("Start date and end date cannot be the same");
      return;
    }
    setStartDate(date);

    // Reset endDate if it is before the new startDate
    if (endDate && date && endDate.getTime() < date.getTime()) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && date.getTime() === startDate?.getTime()) {
      alert("Start date and end date cannot be the same");
      return;
    }
    if (date && startDate && date.getTime() < startDate.getTime()) {
      alert("End date cannot be before the start date");
      return;
    }
    setEndDate(date);
  };
  const [quotationId, setQuotationId] = useState()

  const viewInfo = (viewInfo) => {
    setQuotationId(viewInfo);
    createQuotationStatus();
    navigate(`/dashboard/query-information/${viewInfo}`)
  }
  const createConvertedQuotation = async (information) => {
    try {
      const token = Cookies.get("token");
      const requesId = {
        quote_ids: quotationId
      };
      const response = await fetch(`${mainUrl}/api/v.1/createConvertedQuotation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requesId),
      });
    } catch (error) {
      console.log(error)
    }
  }
  const createQuotationStatus = async (information) => {
    try {
      const token = Cookies.get("token");
      const requesId = {
        quote_status_id: 9,
        quote_ids: [quotationId]
      };
      const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requesId),
      });
    } catch (error) {
      console.log(error)
    }
  }
  const resetFields = () => {
    setSearchText('');
    setStatus_id('');
    setName('');
    setEmail('');
    setMobileNumber('');
    setOrigin('');
    setDestination('');
    setShipmentType('');
    setShipmentVia('');
    setPickedBy('');
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <main className="main users chart-page" id="skip-target">
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-12">
                <div className="main-nav-det">
                  <h2>
                    <Link to="/dashboard/query-list" className="query_info">
                      <img src="/img/re_darect.png" alt="Query" />
                    </Link>{" "}
                    Final Converted Quotations
                  </h2>
                </div>
              </div>
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row ">
                  <div className="col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              to="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>{" "}
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">QUOTATION NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DATE &amp; TIME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <a to="#">Apply</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <div className='search-btn text-white w-fit' style={{ background: "#1B7BB1", marginTop: "7px", marginBottom: "15px" }} onClick={() => { fetchQuotations() }}>Search</div>
                    <div
                      className='search-btn text-white w-fit'
                      style={{ background: "#1B7BB1", marginTop: "7px", marginBottom: "15px" }}
                      onClick={() => { resetFields() }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            <div className="row">
              <div className="col-md-12">
                <div className="scroll_listing_detail">
                  <div className="users-table ">
                    <table className="posts-table">
                      <thead>
                        <tr className="users-table-info">
                          {headers.map((header) => (
                            <th key={header}>{capitalizeText(header)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>#1234</td>
                          <td>
                            12 May 2024 <br />
                            09:12 pm
                          </td>
                          <td>Dummy Name</td>
                          <td>ramesh@gmail.com</td>
                          <td>+91 1232423443</td>
                          <td>Delhi</td>
                          <td>London</td>
                          <td>Dummy Name</td>
                          <td>
                            <Link to="/dashboard/query-list" className="d-flex justify-content-center align-items-center py-2">
                              <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                          <td>
                            <Link to="/email" className="d-flex justify-content-center align-items-center py-2">
                                <img src="/img/gmail.png" alt="view" className='pointer w-fit border-radius-none ' />
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="main_list_next_page">
                      <ul>
                        <li>
                          {" "}
                          <a to="#">
                            <img src="/img/right.png" alt="right" />
                          </a>{" "}
                        </li>
                        <li className="main_list_next_list active">
                          <a to="#">1</a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">2</a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">3</a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">4</a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">5</a>
                        </li>
                        <li className="main_list_next_list img_icon_sec">
                          <a to="#">
                            <img src="/img/Menu-icon.png" alt="manu" />
                          </a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">10</a>
                        </li>
                        <li className="main_list_next_list">
                          <a to="#">11</a>
                        </li>
                        <li>
                          <a to="#">
                            <img src="/img/left.png" alt="left" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  )
}

export default QuotationConverted