const initialState = {
    //role List 
    loadingRoleList: false,
    roleListDataError: "",
    roleListData: [],

    //role List 
    loadingRoleView: false,
    roleViewDataError: "",
    roleViewData: [],

    //module List 
    loadingModuleList: false,
    moduleListDataError: "",
    moduleListData: [],

    //module details 
    loadingModuleDetail: false,
    moduleDetailDataError: "",
    moduleDetailData: {},
    moduleDetailPermissionData: [],
}

const RolePermissionReducer = (state = initialState, action) => {
    switch (action.type) {

        //role List//
        case 'FIND_ROLE_LIST_REQUEST':
            return {
                ...state,
                loadingRoleList: true,
                roleListData: "",
                roleListDataError: '',
            };
        case 'FIND_ROLE_LIST_SUCCESS':
            return {
                ...state,
                loadingRoleList: false,
                roleListData: action.payload,
            };
        case 'FIND_ROLE_LIST_FAILURE':
            return {
                ...state,
                loadingRoleList: false,
                roleListDataError: action.payload,
            };
        //role List//

        //role View//
        case 'FIND_ROLE_VIEW_REQUEST':
            return {
                ...state,
                loadingRoleView: true,
                roleViewData: "",
                roleViewDataError: '',
            };
        case 'FIND_ROLE_VIEW_SUCCESS':
            return {
                ...state,
                loadingRoleView: false,
                roleViewData: action.payload,
            };
        case 'FIND_ROLE_VIEW_FAILURE':
            return {
                ...state,
                loadingRoleView: false,
                roleViewDataError: action.payload,
            };
        //role View//


        //module List//
        case 'FIND_MODULE_LIST_REQUEST':
            return {
                ...state,
                loadingModuleList: true,
                moduleListData: "",
                moduleListDataError: '',
            };
        case 'FIND_MODULE_LIST_SUCCESS':
            return {
                ...state,
                loadingModuleList: false,
                moduleListData: action.payload,
            };
        case 'FIND_MODULE_LIST_FAILURE':
            return {
                ...state,
                loadingModuleList: false,
                moduleListDataError: action.payload,
            };
        //module List//


        //module Detail//
        case 'FIND_MODULE_DETAIL_REQUEST':
            return {
                ...state,
                loadingModuleDetail: true,
                moduleDetailData: {},
                moduleDetailPermissionData: [],
                moduleDetailDataError: '',
            };
        case 'FIND_MODULE_DETAIL_SUCCESS':
            return {
                ...state,
                loadingModuleDetail: false,
                moduleDetailData: action.payload.data,
                moduleDetailPermissionData: action.payload.modules,
            };
        case 'FIND_MODULE_DETAIL_FAILURE':
            return {
                ...state,
                loadingModuleDetail: false,
                moduleDetailDataError: action.payload,
            };
        //module Detail//


        default:
            return state;
    }
}

export default RolePermissionReducer;