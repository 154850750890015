import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { CargoTypeApi } from "../../../Redux/action/MasterAction";
import { adminMainUrl } from "../../../Config";
import EditCargoType from "../../Modal/EditCargoType";
import CargoTypeModal from "../../Modal/CargoTypeModal";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from 'react-spinners';
import Pagination from "../../Pagination";
import Skeleton from 'react-loading-skeleton'
const CargoType = ({ accessedModules, accessedPermission }) => {
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const dataPerPage = 10;
  const [spinnerLoader, setSpinnerLoader] = useState(false)
  const [reload, setReload] = useState(false);
  const [CargoName, setCargoName] = useState(null);
  const [CargoId, setCargoId] = useState(null);
  const dispatch = useDispatch();
  const { loadingCargoTypeApi, findCargoTypeData, findCargoTypeDataError } =
    useSelector((state) => state?.MasterReducer);
  useEffect(() => {
    dispatch(CargoTypeApi(adminMainUrl));
  }, [adminMainUrl, reload]);
  const handleSuccess = () => {
    // Toggle reload to trigger useEffect
    setReload((prev) => !prev);
  };

  // Initialize Formik
  const statusChange = async (id) => {
    setSpinnerLoader(true)
    try {
      const response = await fetch(
        `${adminMainUrl}/api/cargo-type/status-change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": Cookies.get("token"),
          },
          body: JSON.stringify({ id: id.toString() }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSpinnerLoader(false)
        handleSuccess(); // Call the success handler
        toast.success(data?.msg);
      } else {
        setSpinnerLoader(false)
        toast.error(data?.msg);
        console.error("Error updating Cargo Type status:", data.message);
      }
    } catch (error) {
      setSpinnerLoader(false)
      console.error("Network error:", error);
    }
  };

  const handleChange = (id) => {
    if (accessedPermission.includes("state_change_master_data")) {
      statusChange(id);
    }
  };

  const currentItems = findCargoTypeData?.data
  const arr = new Array(5).fill(null);
  return (
    <>
      <Toaster />
      <div className="container">
        <div className="col-md-12">
          <div className="row stat-cards align-items-center">
            <div className="col-md-6 col-xl-6">
              <div className="main-nav-det">
                <h2>Cargo Type</h2>
              </div>
            </div>

            <div className="col-md-6 justify-content-end">
              {accessedPermission.includes("add_master_data") && <div className="button-det-list">
                <button
                  className="btn search-btn w-fit "
                  data-bs-toggle="modal"
                  data-bs-target="#CargoType"
                >
                  <span>
                    <img src="/img/place.png" alt="" />
                  </span>{" "}
                  Add Cargo Type
                </button>
              </div>}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <hr />
            </div>
          </div>
          <div className="row pb-2 pt-2">
            <div className="col-lg-12">
              <div className="users-table-admin table-responsive border-redi">
                <table className="posts-table">
                  <thead>
                    <tr className="users-table-admin-th">
                      <th>S. No.</th>
                      <th>Cargo Type</th>
                      <th>Status</th>
                      {accessedPermission.includes("edit_master_data") && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      loadingCargoTypeApi ?
                        arr.map((value, index) => {
                          return (

                            <tr key={index}>
                              <td
                                colSpan="4"
                                style={{
                                  height: "70px",
                                  padding: "0px 27px",
                                  width: "100%",
                                  textAlign: "center"
                                }}
                              >
                                <Skeleton count={2} />
                              </td>

                            </tr>
                          )
                        })
                        :
                        spinnerLoader ?
                          <>
                            <tr>
                              <td
                                colSpan="5"
                                style={{
                                  height: "70px",
                                  padding: "0px 27px",
                                  width: "100%",
                                  textAlign: "center"
                                }}
                              >
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>

                                  <BeatLoader />
                                </div>

                              </td>
                            </tr>
                          </> :

                          currentData?.map((value, index) => {
                            return (
                              <tr key={index} className="users-table-admin-td">
                                <td>{index + 1}</td>
                                <td>{value?.name}</td>
                                <td>
                                  <div className="form-check form-switch d-flex justify-content-center">
                                    <div
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title={value.status === true ? "active" : "inactive"}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`flexSwitchCheckChecked-${index}`}
                                        checked={value.status === true}
                                        onChange={() => handleChange(value.id)}
                                      />
                                    </div>
                                  </div>
                                </td>
                                {accessedPermission.includes("edit_master_data") && <td>
                                  <div
                                    onClick={() => {
                                      setCargoId(value?.id);
                                      setCargoName(value?.name);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#editCargoType"
                                  >
                                    <div
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="edit"
                                    >
                                      <img
                                        src="/img/edit-line.png"
                                        alt="view"
                                        className="pointer w-fit border-radius-none"
                                      />
                                    </div>
                                  </div>
                                </td>}
                              </tr>
                            );
                          })
                    }

                  </tbody>
                </table>
                <Pagination data={currentItems} dataPerPage={dataPerPage} setDataOffset={setDataOffset} dataOffset={dataOffset} setPageCount={setPageCount} pageCount={pageCount} setCurrentData={setCurrentData} currentData={currentData} />
                {/* <div className="pagination-controls my-5 d-flex justify-content-center align-items-center">
                  <button onClick={handlePrevious} disabled={currentPage === 1}>
                    <img src="/img/right.png" className="mx-2" alt="left" />
                  </button>
                  <span className="search-btn py-2 px-3 mx-3">
                    {currentPage}
                  </span>
                  <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    <img src="/img/left.png" className="mx-2" alt="right" />
                  </button>
                </div> */}

                {/* <div className="pagination-controls my-5 d-flex justify-content-center align-items-center" >
                                    <button >
                                        <img src="/img/right.png" className='mx-2' alt="right" />
                                    </button>
                                    <span className='search-btn py-2 px-3 mx-3'>1</span>
                                    <button>
                                        <img src="/img/left.png" className='mx-2' alt="right" />
                                    </button>

                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CargoTypeModal id="CargoType" onSuccess={handleSuccess} />
      <EditCargoType
        id="editCargoType"
        onSuccess={handleSuccess}
        CargoTypeId={CargoId}
        CargoTypeName={CargoName}
      />
    </>
  );
};

export default CargoType;
