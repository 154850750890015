import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PricingEmployeeDashboard = () => {

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Converted Leads',
            data: [10, 12, 8, 15, 20, 18, 25, 30, 35, 40, 50, 60],
            borderColor: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 2,
            tension: 0.1,
          },
          {
            label: 'Non-Converted Leads',
            data: [20, 15, 10, 18, 25, 20, 22, 28, 32, 35, 40, 45],
            borderColor: '#1B7BB1',
            backgroundColor: 'rgba(27, 123, 177, 0.2)',
            borderWidth: 2,
            tension: 0.1,
          },
        ],
      };
      
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: 'black',
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: 'black',
            },
          },
          x: {
            ticks: {
              color: 'black',
            },
          },
        },
      };
      
      const dataRevenue = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Total Revenue',
            data: [0, 50, 100, 150, 200, 250, 200, 150, 100, 150, 200, 0],
            fill: true,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderColor: 'rgba(0, 0, 0, 1)',
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
          },
        ],
      };
      
      const optionsRevenue = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: 'black',
            },
          },
          title: {
            display: true,
            text: 'Total Revenue',
            color: 'black',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: 'black',
            },
          },
          x: {
            ticks: {
              color: 'black',
            },
          },
        },
      };
      
      const dataConvert = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
          {
            label: 'Volume',
            data: [6, 12, 8, 10, 14, 8],
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1,
          },
          {
            label: 'Sales',
            data: [4, 9, 6, 11, 12, 7],
            backgroundColor: 'rgba(27, 123, 177, 0.6)',
            borderColor: '#1B7BB1',
            borderWidth: 1,
          },
        ],
      };
      
      const optionsConvert = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              color: 'black',
            },
          },
          title: {
            display: true,
            text: 'Converted Quotation',
            color: 'black',
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }
                return label;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 14,
            ticks: {
              color: 'black',
            },
          },
          x: {
            ticks: {
              color: 'black',
            },
          },
        },
      };
  
    return (
        <>
            <div className="container">
                {/* <div className="row mb-20">
                    <div className="col-md-6">
                        <div className="main-nav-det"><h2>Shipments</h2></div>
                    </div>
                    <div className="col-md-6">
                        <div className="dropdown dropdown-filter-new main_secdetail_all">
                            <a
                                className="dropdown-toggle month_this_button"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                All Shipments
                            </a>
                            <div
                                className="dropdown-menu main_drop_new_week"
                                aria-labelledby="dropdownMenuLink"
                            >
                                <ul>
                                    <li>Shipments</li>
                                    <li>Shipments</li>
                                    <li>Shipments</li>
                                    <li>Shipments</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row stat-cards main-cards ">
                    <div className="col-md-6 col-xl-3">
                        <Link to="/pricing-employee-dashboard/query-list">
                        <article className="stat-cards-item">
                            <div className="stat-cards-info">
                            <p className="stat-cards-info__progress daai_icon_img justify-content-center">
                                    <span className="stat-cards-info__profit success">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trending-up"
                                            aria-hidden="true"
                                        >
                                            <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                                            <polyline points="17 6 23 6 23 12" />
                                        </svg>
                                    </span>
                                </p>
                                <p className="stat-cards-info__title text-center">1100</p>
                                <p className="stat-cards-info__num text-center">Total Query List
                                    <a href="#">
                                        <img src="/img/manu_det_icon.png" alt="" />
                                    </a>
                                </p>
                            </div>
                        </article>
                        </Link>
                    </div>
                    <div className="col-md-6 col-xl-3">
                    
                    <article className="stat-cards-item">
                            <div className="stat-cards-info">
                            <p className="stat-cards-info__progress daai_icon_img justify-content-center">
                                    <span className="stat-cards-info__profit success">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trending-up"
                                            aria-hidden="true"
                                        >
                                            <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                                            <polyline points="17 6 23 6 23 12" />
                                        </svg>
                                    </span>
                                </p>
                                <p className="stat-cards-info__title text-center">344</p>
                                <p className="stat-cards-info__num text-center">Total Sales Person
                                    <a href="#">
                                        <img src="/img/manu_det_icon.png" alt="" />
                                    </a>
                                </p>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-xl-3">
                    <article className="stat-cards-item">
                            <div className="stat-cards-info">
                            <p className="stat-cards-info__progress daai_icon_img justify-content-center">
                                    <span className="stat-cards-info__profit success">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trending-up"
                                            aria-hidden="true"
                                        >
                                            <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                                            <polyline points="17 6 23 6 23 12" />
                                        </svg>
                                    </span>
                                </p>
                                <p className="stat-cards-info__title text-center">124</p>
                                <p className="stat-cards-info__num text-center">Total Shipment
                                    <a href="#">
                                        <img src="/img/manu_det_icon.png" alt="" />
                                    </a>
                                </p>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-xl-3">
                    <article className="stat-cards-item">
                            <div className="stat-cards-info">
                            <p className="stat-cards-info__progress daai_icon_img justify-content-center">
                                    <span className="stat-cards-info__profit success">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trending-up"
                                            aria-hidden="true"
                                        >
                                            <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                                            <polyline points="17 6 23 6 23 12" />
                                        </svg>
                                    </span>
                                </p>
                                <p className="stat-cards-info__title text-center">121</p>
                                <p className="stat-cards-info__num text-center">Total Revenue
                                    <a href="#">
                                        <img src="/img/manu_det_icon.png" alt="" />
                                    </a>
                                </p>
                            </div>
                        </article>
                    </div>
                </div> */}
                <div className="row stat-cards mb-4">
              <div className="col-md-6 col-xl-4">
              <Link to="/pricing-employee-dashboard/rate-request-list">
                <article className="stat-cards-item-admin">
                  <div className="stat-cards-info">
                    <p className="stat-cards-Total">Rate Request List</p>
                    <p className="stat-cards-info__progress daai_icon_img">
                      <span className="stat-cards-admin">
                        45
                      </span>
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M21.4683 4.83296C21.4683 8.3499 24.3235 11.2009 27.8456 11.2009C28.2334 11.199 28.6202 11.1618 29.0013 11.0897V23.7144C29.0013 29.0257 25.8683 32.168 20.5493 32.168H8.96428C3.63129 32.168 0.498337 29.0257 0.498337 23.7144V12.1464C0.498337 6.83512 3.63129 3.66504 8.96428 3.66504H21.5796C21.5048 4.04984 21.4675 4.44098 21.4683 4.83296ZM18.1543 20.9197L22.6797 15.0801V15.0523C23.0671 14.5317 22.9682 13.7977 22.4569 13.3978C22.2094 13.2067 21.8949 13.124 21.5852 13.1685C21.2756 13.2131 20.9972 13.3811 20.8138 13.6341L16.9986 18.5422L12.6542 15.1218C12.4062 14.9286 12.0906 14.8433 11.7789 14.8851C11.4671 14.927 11.1853 15.0925 10.9972 15.3443L6.31867 21.3786C6.15422 21.5836 6.06569 21.8389 6.06804 22.1016C6.04088 22.6324 6.37607 23.1145 6.88359 23.2748C7.39111 23.435 7.94295 23.2328 8.22629 22.7829L12.139 17.7219L16.4834 21.1283C16.7305 21.3275 17.0481 21.4178 17.3632 21.3785C17.6783 21.3392 17.9639 21.1736 18.1543 20.9197Z"
                          fill="#000000" />
                        <ellipse opacity="0.4" cx="28.2095" cy="4.45679" rx="3.95874" ry="3.95874" fill="#646464" />
                      </svg>
                    </p>
                  </div>
                </article>
                </Link>
              </div>
              <div className="col-md-6 col-xl-4">
              <Link to="/pricing-employee-dashboard/picked-rate-request-list">
                <article className="stat-cards-item-admin">
                  <div className="stat-cards-info">
                    <p className="stat-cards-Total">Picked Rate Request List</p>
                    <p className="stat-cards-info__progress daai_icon_img">
                      <span className="stat-cards-admin">
                        125
                      </span>
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M21.4683 4.83296C21.4683 8.3499 24.3235 11.2009 27.8456 11.2009C28.2334 11.199 28.6202 11.1618 29.0013 11.0897V23.7144C29.0013 29.0257 25.8683 32.168 20.5493 32.168H8.96428C3.63129 32.168 0.498337 29.0257 0.498337 23.7144V12.1464C0.498337 6.83512 3.63129 3.66504 8.96428 3.66504H21.5796C21.5048 4.04984 21.4675 4.44098 21.4683 4.83296ZM18.1543 20.9197L22.6797 15.0801V15.0523C23.0671 14.5317 22.9682 13.7977 22.4569 13.3978C22.2094 13.2067 21.8949 13.124 21.5852 13.1685C21.2756 13.2131 20.9972 13.3811 20.8138 13.6341L16.9986 18.5422L12.6542 15.1218C12.4062 14.9286 12.0906 14.8433 11.7789 14.8851C11.4671 14.927 11.1853 15.0925 10.9972 15.3443L6.31867 21.3786C6.15422 21.5836 6.06569 21.8389 6.06804 22.1016C6.04088 22.6324 6.37607 23.1145 6.88359 23.2748C7.39111 23.435 7.94295 23.2328 8.22629 22.7829L12.139 17.7219L16.4834 21.1283C16.7305 21.3275 17.0481 21.4178 17.3632 21.3785C17.6783 21.3392 17.9639 21.1736 18.1543 20.9197Z"
                          fill="#000000" />
                        <ellipse opacity="0.4" cx="28.2095" cy="4.45679" rx="3.95874" ry="3.95874" fill="#646464" />
                      </svg>
                    </p>
                  </div>
                </article>
                </Link>
              </div>
              <div className="col-md-6 col-xl-4">
              <Link to="/pricing-employee-dashboard/send-pricing-list">
                <article className="stat-cards-item-admin">
                  <div className="stat-cards-info">
                    <p className="stat-cards-Total">Send Pricing List</p>
                    <p className="stat-cards-info__progress daai_icon_img">
                      <span className="stat-cards-admin">
                        345
                      </span>
                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M21.4683 4.83296C21.4683 8.3499 24.3235 11.2009 27.8456 11.2009C28.2334 11.199 28.6202 11.1618 29.0013 11.0897V23.7144C29.0013 29.0257 25.8683 32.168 20.5493 32.168H8.96428C3.63129 32.168 0.498337 29.0257 0.498337 23.7144V12.1464C0.498337 6.83512 3.63129 3.66504 8.96428 3.66504H21.5796C21.5048 4.04984 21.4675 4.44098 21.4683 4.83296ZM18.1543 20.9197L22.6797 15.0801V15.0523C23.0671 14.5317 22.9682 13.7977 22.4569 13.3978C22.2094 13.2067 21.8949 13.124 21.5852 13.1685C21.2756 13.2131 20.9972 13.3811 20.8138 13.6341L16.9986 18.5422L12.6542 15.1218C12.4062 14.9286 12.0906 14.8433 11.7789 14.8851C11.4671 14.927 11.1853 15.0925 10.9972 15.3443L6.31867 21.3786C6.15422 21.5836 6.06569 21.8389 6.06804 22.1016C6.04088 22.6324 6.37607 23.1145 6.88359 23.2748C7.39111 23.435 7.94295 23.2328 8.22629 22.7829L12.139 17.7219L16.4834 21.1283C16.7305 21.3275 17.0481 21.4178 17.3632 21.3785C17.6783 21.3392 17.9639 21.1736 18.1543 20.9197Z"
                          fill="#000000" />
                        <ellipse opacity="0.4" cx="28.2095" cy="4.45679" rx="3.95874" ry="3.95874" fill="#646464" />
                      </svg>
                    </p>
                  </div>
                </article>
                </Link>
              </div>

            </div>
            <div className="row">
              <div className="col-lg-8">
              <div className="chart-container height_leads_rev  admin_detail_new">
                  <div className="main_detail_conta">
                    <h3>Leads Analytics</h3>
                    <select name="#" id="#">
                      <option value="#">2024</option>
                    </select>
                  </div>
      <Line data={data} options={options} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="total_lead_won">
                  <div className="todeal_grow_admin border-grow">
                    <h4>Total Lead Won <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.25 3.25L11.875 2.125L12.25 4.75" stroke="#000000" strokeWidth="1.14286"
                    strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 1V13H13" stroke="#000000" strokeWidth="1.14286" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path d="M3.25 10.375L5.875 6.625L8.5 7.75L11.5 2.875" stroke="#000000" strokeWidth="1.14286"
                    strokeLinecap="round" strokeLinejoin="round" />
                </svg></h4>
                    <span>56</span>
                  </div>
                  <div className="todeal_grow_admin border-grow">
                    <h4>Total Lead Lost <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.25 3.25L11.875 2.125L12.25 4.75" stroke="#646464"
                    strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 1V13H13" stroke="#646464" strokeWidth="1.14286" strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path d="M3.25 10.375L5.875 6.625L8.5 7.75L11.5 2.875" stroke="#646464"
                    strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round" />
                </svg></h4>
                    <span className="yellow_color_main">32</span>
                  </div>

                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-7">
                <div className="admin_detail_new">
                  <div className="main_detail_conta">
                    <h3>Total Revenue</h3>
                    <select name="#" id="#">
                      <option value="#">2024</option>
                    </select>
                  </div>
                  <div className="chart-container height_total_rev">
                  <Line data={dataRevenue} options={optionsRevenue} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="admin_detail_new" style={{height:"360px"}}>
                  
                    <Line data={dataConvert} options={optionsConvert} />
                </div>
              </div>
            </div>



                {/* <div className="row stat-cards">
                    <div className="col-md-7 col-xl-7">
                        <article className="stat-cards-item-bg">
                            <div className="main-nav-det-tebl">
                                <h4 className="stat-price">$ 11000</h4>
                                <div className="dropdown dropdown-filter-new">
                                    <a
                                        className="dropdown-toggle month_this_button"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        6 months
                                    </a>
                                    <div
                                        className="dropdown-menu main_drop_new_week"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <ul>
                                            <li>5 month</li>
                                            <li>4 month</li>
                                            <li>3 month</li>
                                            <li>2 month</li>
                                            <li>1 month</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <a href="#" className="main_detail_new">
                                Total Leads
                            </a>
                            <div className='mt-4'>
                               <img src="/img/graph.png" alt="" />
                            </div>
                        </article>
                    </div>
                    <div className="col-md-5 col-xl-5">
                        <article className="stat-cards-item-bg">
                            <div className="main-nav-det-tebl">
                                <h4>Lead Management</h4>
                                <div className="dropdown dropdown-filter-new">
                                    <a
                                        className="dropdown-toggle month_this_button"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        This month
                                    </a>
                                    <div
                                        className="dropdown-menu main_drop_new_week"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <ul>
                                            <li>5 month</li>
                                            <li>4 month</li>
                                            <li>3 month</li>
                                            <li>2 month</li>
                                            <li>1 month</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <section className="main_section_box d-flex justify-content-between align-items-center">
                                <div className="">
                                    <img src="/img/circle-graph.png" alt="" />
                                </div>
                                <ul className="pieID legend">
                                    <li>
                                        <em>New Customers</em>
                                        <span>718</span>
                                    </li>
                                    <li>
                                        <em>Repeated</em>
                                        <span>531</span>
                                    </li>
                                </ul>
                            </section>
                        </article>
                    </div>
                </div>
                <div className="row stat-cards-top">
                    <div className="col-md-6 col-xl-6">
                        <article style={{flexDirection:"column"}} className="stat-cards-item-bg d-flex justify-content-between ">
                            <div className="main-nav-det-tebl">
                                <h4>Sales Mapping by Country</h4>
                            </div>
                            <div className="users-table-detail">
                                <img src="/img/world.png" alt="" />
                            </div>
                            <div className="main_listing_map">
                                <ul>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_1" /> India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_2" /> India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_3" /> India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_4" /> India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_5" /> India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {" "}
                                            <span className="color_map_6" /> India
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-xl-6">
                        <article className="stat-cards-item-bg">
                            <div className="main-nav-det-tebl">
                                <h4>Target vs Reality</h4>
                            </div>
                            <div className='mt-4'>
                              <img src='/img/sales-graph.png'/>
                            </div>
                            <div className="main_col-table">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="main_listind_table">
                                            <span>
                                                <img src="/img/Bag.png" alt="" />
                                            </span>
                                            <div className="Sales_list_head">
                                                <h5>Reality Sales</h5>
                                                <p>Global</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="vailu_numbar_new">
                                            <h4>8.823</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="main_listind_table">
                                            <span className="color_new_make">
                                                <img src="/img/Ticket.png" alt="" />
                                            </span>
                                            <div className="Sales_list_head">
                                                <h5>Target Sales</h5>
                                                <p>Commercial</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="vailu_numbar_new">
                                            <h6>12.122</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div> */}
            </div>

        </>
    )
}

export default PricingEmployeeDashboard