import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Flight = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Flight Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>1st Carrier</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>2nd Carrier</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>ETD</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>ETA</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>IGM No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>IGM Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>Inward Date</label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Rotation No</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>ATD</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>ATA</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Flight