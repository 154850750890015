import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
// import KycApprovalRequest from '../../Kyc_Onboarding/Dashboard/KycApprovalRequest';
import SideBar from './SideBar';
import Navbar from './Navbar';
// import CustomerRegistration from '../../Kyc_Onboarding/Dashboard/KycRegistrationForm/KycRegistration';
// import RegistrationAgreement from '../../Kyc_Onboarding/Dashboard/KycRegistrationAgreement/RegistrationAgreement';
import QuoteRequestForm from './QuoteRequestForm/QuoteRequestForm';
import QueryList from './QueryList/QueryList';
import AllRateRequest from './AllRateRequest/AllRateRequest';
import SalesDashboard from './SalesHODdashboard/SalesDashboard';
import ViewRate from './ViewRate/ViewRate';
import QueryInfoGiveRate from './QueryInfoGiveRate/QueryInfoGiveRate';
import QueryInfo from './QueryInfo/QueryInfo';
import QuotationAirExportForm from './QuotationAirExportForm/QuotationAirExportForm';
import QuotationAirImportForm from './QuotationAirImportForm/QuotationAirImportForm';
import QuotationSeaExportForm from './QuotationSeaExportForm/QuotationSeaExportForm';
import QuotationSeaImportForm from './QuotationSeaImportForm/QuotationSeaImportForm';
import QuotationConverted from './QuotationConverted/QuotationConverted';
import QueryFollowUpForm from './QueryFollowUpForm/QueryFollowUpForm';
import QueryFollowUpSummary from './QueryFollowUpSummary/QueryFollowUpSummary';
import QueryList1 from './QueryList/QueryList1';
import AllRateRequest1 from './AllRateRequest/AllRateRequest1';
import EditQuoteRequestForm from '../../Admin/Dashboard/QuoteRequestForm/EditQuoteRequestForm';

const Dashboard = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [userdata, setUserData] = useState({})
  const userData = sessionStorage.getItem('userData');
  useEffect(() => {
    if (userData) {
      setUserData(JSON.parse(userData));
    }
  }, [userData]);
 
  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      <div className="page-flex">
        {/* ! Sidebar */}
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div className="main-wrapper">
          {/* ! Main nav */}
          <Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} userdata={userdata} />
          {/* ! Main */}
          <main className="main users chart-page" id="skip-target">
            <Routes>
              <Route index element={<Navigate to="sales-dashboard" />} />
              {/* <Route path='Kyc-approval-request' element={<KycApprovalRequest/>} /> */}
              {/* <Route path='customer-registration-form' element={<CustomerRegistration/>} /> */}
              {/* <Route path='customer-registration-agreement' element={<RegistrationAgreement/>} /> */}
              <Route path='quote-request-form' element={<QuoteRequestForm />} />
              <Route path='edit-quote-request-form' element={<EditQuoteRequestForm
               />} />
              <Route path='query-list' element={<QueryList />} />
              <Route path='all-rate-request' element={<AllRateRequest />} />
              <Route path='sales-dashboard' element={<SalesDashboard />} />
              
              <Route path='query-info-give-rates' element={<QueryInfoGiveRate />} />
              <Route path='view-rates/:status_id' element={<ViewRate />} />
              <Route path='query-information/:status_id' element={<QueryInfo />} />
              <Route path='quotation-air-export' element={<QuotationAirExportForm />} />
              <Route path='quotation-air-import' element={<QuotationAirImportForm />} />
              <Route path='quotation-sea-export' element={<QuotationSeaExportForm />} />
              <Route path='quotation-sea-import' element={<QuotationSeaImportForm />} />
              <Route path='quotation-converted' element={<QuotationConverted />} />
              <Route path='query-follow-up-form' element={<QueryFollowUpForm />} />
              <Route path='query-follow-up-summary' element={<QueryFollowUpSummary />} />
              <Route path='view-updated-query-list' element={<QueryList1 />} />
              <Route path='selected-rate-list' element={<AllRateRequest1 />} />
            </Routes>

          </main>
        </div>
      </div>
    </div>
  )
}

export default Dashboard