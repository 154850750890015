import React from 'react'

const Declaration = () => {
  return (
    <div id="section4" className="section4">
    <form action="#">
      <div className="main-nav-det">
        <h4>Declaration </h4>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>Credit Period Required by Party in Days</label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>
              Total Credit Limit Required by Party in numeric figure (In Lacs)
            </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>Digital Signature</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <input id="checkbox" type="checkbox" />
          I/We hereby declare that the particulars given herein above are true,
          correct and complete to the best of my/our knowledge and belief, the
          documents submitted in support of this Form, KYC are genuine and
          obtained legally from the respective issuing authority. In case of any
          change in any of the aforementioned particulars, I/we undertake to
          notify you in writing failing which the above particulars may be relied
          upon including all shipments/documents executed and tendered by the
          individual so authorized and mentioned above. I/we hereby authorize you
          to submit the above particulars to the customs and other regulatory
          authorities on my/our behalf as may be required in order to transport
          and customs clear my/our shipments.
        </div>
      </div>
    </form>
  </div>
  
  )
}

export default Declaration