import React from 'react'

const BankDetails = () => {
  return (
    <div id="section2" className="section2" >
    <form action="#">
      <div className="main-nav-det">
        <h4>Bank Account Details </h4>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>Bank Name </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>Bank Address </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>Beneficiary Name </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>Account No. </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section ">
            <label>IFSC Code </label>
            <input
              type="text"
              className="form-control color_place"
              placeholder="Enter here"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  
  )
}

export default BankDetails