import React from 'react'

const Party = () => {
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Party Details </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Importer </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Supplier</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Broker</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Sub Agent1 </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Sub Agent2</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="main-box-det-check">
                            <label className="users-table__checkbox" id="new_text">
                                <input type="checkbox" className="check" />
                                &nbsp;Seller Same As Supplier
                            </label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="main-box-det-check">
                            <label className="users-table__checkbox" id="new_text">
                                <input type="checkbox" className="check" />
                                &nbsp;Seller Same As Importer
                            </label>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Party