import React from 'react';
import styles from './Unauthorized.module.css'; // Import the CSS module
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    return (
        <div id={styles.unauthorized_access}>
            <div>403</div>
            <div className={styles.unauthorized_access_txt}>
                Forbidden<span className={styles.unauthorized_access_blink}>_</span>
            </div>
            <Link className={`${styles.unauthorized_access_link} my-3`} to={`/admin-dashboard/admin-dashboard`}>&larr; Go to Home</Link>
        </div>
    );
}

export default Unauthorized;
