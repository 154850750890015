import React from 'react'
import Select from 'react-select'
const QuotationAirExportModal = ({ id }) => {
  const cargoType = [
    { value: 'HAZ', label: 'HAZ' },
    { value: 'Temperature Control', label: 'Temperature Control' },
    { value: 'NON-HAZ', label: 'NON-HAZ' },
    { value: 'AVI', label: 'AVI' },
    { value: 'PER', label: 'PER' },
    { value: 'HUM', label: 'HUM' },
    { value: 'GEN', label: 'GEN' },
    { value: 'ODC', label: 'ODC' },
    { value: 'DGR', label: 'DGR' },
    { value: 'EXP', label: 'EXP' }
  ]
  const chargeHead = [
    { value: 'A/F', label: 'A/F' },
    { value: 'SSC', label: 'SSC' },
    { value: 'FSC', label: 'FSC' },
    { value: 'XRAY', label: 'XRAY' },
    { value: 'MISC', label: 'MISC' },
    { value: 'AMS', label: 'AMS' },
    { value: 'EXTRA', label: 'EXTRA' },

  ]
  return (
    <>
      <div className="modal fade" id={id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog detail_popup_width modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="btn-close-new-section">
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body detail_con_popup_main">
              <div className="main-nav-det">
                <h4>New Rate</h4>
              </div>
              <div className="main_detail_form " id="main_id_sec">
                <form action="#">
                  <div className="Air_Export_Form_bg">
                    <div className="Air_Export_bg_main">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Airline Code <span>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Airline Code"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Charge Head <span>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Company Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Buying Price </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Buying Price"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Selling Price <span>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Selling Price"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className="input_box_section input_popup_rate">
                      <label>Narration </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Narration"
                      />
                    </div>
                  </div> */}
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Valid Up To<span>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Valid Up To"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Currency </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Currency"
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className="input_box_section input_popup_rate">
                      <label>Exchange Rate </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Buying Price"
                      />
                    </div>
                  </div> */}
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Unit </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Selling Price"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>FC Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="FC Amount"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Rate </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Company Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className=" ">
                            <label>Cargo type </label>
                            <Select options={cargoType} className='mt-2' />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className=" ">
                            <label>Charge head </label>
                            <Select options={chargeHead} className='mt-2' />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="info-det-button-add ">
                            <a href="">+ Add</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Air_Export_Form_bg">
                    <div className="Air_Export_bg_main">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                              <thead>
                                <tr className="users-table-tariff n_detail_comp">
                                  <th>Airline N.</th>
                                  <th>Charge Head</th>
                                  <th>Buyer Amount</th>
                                  <th>Seller Amount</th>
                                  <th>Narration</th>
                                  <th>Valid Up To</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>A/F</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>SSC</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>FSC</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>XRAY</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>MISC</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>AMS</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="main_table_row_new n_detail_comp">
                                  <td>DHL</td>
                                  <td>EXTRA</td>
                                  <td>18-05 2024</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>lorem ipsum</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <a href="#main_id_sec">
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </a>
                                      <a href="#">
                                        <img
                                          src="/img/delete-outline.png"
                                          alt="delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main-nav-det-button text-right main-Export-button">
                      <a href="quotation_air_export_form.html" data-bs-dismiss="modal">Submit</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuotationAirExportModal