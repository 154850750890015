import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const QueryFollowUpForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <main className="main users chart-page" id="skip-target">
  <div className="container">
    <div className="col-md-12">
      <div className="main_section_detail">
        <div className="row">
          <div className="col-md-6">
            <div className="main-nav-det">
              <h2>Query Follow-Up Summary Form</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="new_infometion_table">
        <div className="main_detail_form ">
          <form action="#">
            <div className="Air_Export_bg_main">
              <div className="row">
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate">
                    <label>Quotation Number<span>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Quotation Number"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate por_main_detail">
                    <label>Date<span>*</span> </label>
                    <div className='custom-datepicker'>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                    <span>
                      <img src="/img/date_chat.png" alt="Calendar" />
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate">
                    <label>Validity Period<span>*</span> </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email ID"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate">
                    <label>Client Information<span>*</span> </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Shipping from?"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate">
                    <label>Additional Charges<span>*</span> </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Quotation Number"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_box_section input_popup_rate">
                    <label>Total Amount<span>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Quotation Number"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_box_section input_box_section_bg">
                    <label>Delivery Information<span>*</span></label>
                    <textarea
                      rows={3}
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_box_section input_box_section_bg">
                    <label>Quoted Items/Services<span>*</span></label>
                    <textarea
                      rows={3}
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_box_section input_box_section_bg">
                    <label>Summary of Sender mail conversation<span>*</span></label>
                    <textarea
                      rows={3}
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input_box_section input_box_section_bg">
                    <label>Summary of Sender Receiver conversation<span>*</span></label>
                    <textarea
                      rows={3}
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main_select_photo">
                    <ul>
                      <li>
                        <div className="file-input">
                          <input type="file" name="userfile" id="userfile" />
                          <button className="folder_main_bro label_1">
                            <span>
                              <img src="/img/upload.png" alt="file" />
                            </span>{" "}
                            Upload Email Add
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="main-nav-det-button text-right main-Export-button">
                    <Link to="/dashboard/query-follow-up-summary">Save Conversion</Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

  )
}

export default QueryFollowUpForm