const seekerInitialState = {
    //product-type
    loadingSidebarPermissionApi: true,
    sidebarPermissionDataError: "",
    sidebarPermissionData: [],

}

const NavbarReducer = (state = seekerInitialState, action) => {
    switch (action.type) {

        //product-type//
        case 'FIND_SIDEBAR_PERMISSION_REQUEST':
            return {
                ...state,
                loadingSidebarPermissionApi: true,
                sidebarPermissionData: "",
                sidebarPermissionDataError: '',
            };
        case 'FIND_SIDEBAR_PERMISSION_SUCCESS':
            return {
                ...state,
                loadingSidebarPermissionApi: false,
                sidebarPermissionData: action.payload,
            };
        case 'FIND_SIDEBAR_PERMISSION_FAILURE':
            return {
                ...state,
                loadingSidebarPermissionApi: false,
                sidebarPermissionDataError: action.payload,
            };
        //product-type//

        default:
            return state;
    }
}

export default NavbarReducer;