import React from 'react'
import Select from 'react-select';
const Dimentions = () => {
    const unit = [
        { value: 'Inch', label: 'Inch' },
        { value: 'Centimeter', label: 'Centimeter' },
    ]
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Dimension Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Length</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Height</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Width</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Pkgs&nbsp;</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Unit&nbsp;</label>
                            <Select
                                className='mt-2'
                                options={unit}
                            />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="main-nav-det">
                            <h4>Dimensions</h4>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="add-Company-button">
                            <a href="">
                                <span>
                                    <img src="/img/twotone-add.png" alt="Add Dimensions" />
                                </span>
                                Add Dimensions
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                                <thead>
                                    <tr className="n_detail_comp">
                                        <th className="users-table-th">Length</th>
                                        <th className="users-table-th">Height</th>
                                        <th className="users-table-th">Width</th>
                                        <th className="users-table-th">Pkgs</th>
                                        <th className="users-table-th">Unit</th>
                                        <th width={100} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>Inch</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>Inch</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Other Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Docket Owner</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Reference No/PO</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Status</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Remarks</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Ass. Value</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Total Duty</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Quotation No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Reference No</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Verified By</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Sales Person</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Place of Supply</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Dimentions