import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { Button, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../../Redux/action/Admin/EmployeeMasterAction';
import { BeatLoader } from 'react-spinners';
// import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const adminMainUrl = process.env.REACT_APP_API_SERVER;

export const AccountCredential = ({ handleBack, handleNext, activeStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [disable, setDisable] = useState(false);
  let [showLoader, setShowLoader] = useState(true);

  let initialValues = {
    username: "",
    password: "",
  }
  let { employeeData, loadingEmployeeApi } = useSelector(state => state?.EmployeeMasterReducer);
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      username: Yup.string().required('Please enter username.'),
      password: Yup.string().required('Please enter password.'),
    }),
    onSubmit: (values,{resetForm}) => {
      values.emply_id = Cookies.get("emply_id");
      setDisable(true);
      try {
        let config = { headers: { "auth-token": Cookies.get('token'), 'Content-Type': 'application/json', } }

        axios.post(`${adminMainUrl}/api/employee/account-credential-add`, values, config)
          .then(res => {
            if (res.status === 200) {
              // Cookies.set("emply_id", res?.data?.data?.emply_id)
              toast.success(res.data.msg);
              Cookies.remove("emply_id");
              resetForm();
              navigate("/admin-dashboard/all-employee-list")
              setDisable(false);
            } else {
              toast.error(res.data.msg);
              setDisable(false);
            }
          }).catch(err => {
            console.log(err);
            toast.error(err.response.data.msg);
            setDisable(false);
          });

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.msg);
        setDisable(false);
      }
    }

  });


  let emply_id = Cookies.get("emply_id");
  useEffect(() => {
    if (emply_id) {
      dispatch(getEmployee(emply_id));
      if (loadingEmployeeApi) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    } else {
      toast.error("Please fill personal details first.")
      handleBack();
    }
  }, [emply_id, loadingEmployeeApi]);

  useEffect(() => {
    if (employeeData) {
      formik.setValues({
        username: employeeData?.userInfo?.username ?? "",
        password: employeeData?.userInfo?.password ?? "",
      })
    }
  }, [employeeData]);

  return (
    <>
      {/* <Toaster /> */}

      {showLoader ?
        <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>
          <BeatLoader />
        </div>
        :
        <div id="section3" className="section3">
          <form onSubmit={formik.handleSubmit}>
            <FocusError formik={formik} />

            <div className="main-nav-det">
              <div className="admin_form_detail">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="input_box_section ">
                      <label>Username<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Username"
                        name="username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.username && formik.errors.username ? (
                          <div className='text-danger small'>{formik.errors.username}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="input_box_section ">
                      <label>Password <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.password && formik.errors.password ? (
                          <div className='text-danger small'>{formik.errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>

              <div className='button-container' style={{ marginTop: '20px' }}>
                <Button
                  type="button"
                  disabled={disable}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                  className='btn-stepper'
                >
                  Previous
                </Button>
                <Button
                  className='btn-stepper'
                  type="submit"
                  variant="contained"
                  // onClick={handleNext}
                  disabled={disable}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {!disable ? "Submit" : <i className="fas fa-spinner fa-spin" style={{ color: "#fff" }}></i>}

                </Button>
              </div>
            </Paper>
          </form>
        </div>
      }
    </>
  )
}
