import React,{useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function ShipmentStatus() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade"
            id="shipment-status"
            role="tabpanel"
            aria-labelledby="shipment-status-tab"
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="input_box_section">
                        <label>Docket Number</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="NDL/AE/5104/24"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Shipment Status</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table_detail_tariff table-responsive shipment_registration_table">
                        <table className="posts-table geeks">
                            <thead>
                                <tr className="n_detail_comp">
                                    <th className="users-table-th">Level</th>
                                    <th className="users-table-th">Day</th>
                                    <th className="users-table-th">Status Date</th>
                                    <th className="users-table-th">Status Time</th>
                                    <th className="users-table-th">Email</th>
                                    <th className="users-table-th">Remarks</th>
                                    <th className="users-table-th">User</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="n_detail_comp">ABCDEFG</td>
                                    <td className="n_detail_comp">ABCDEFG</td>
                                    <td className="n_detail_comp">22/04/2024</td>
                                    <td className="n_detail_comp">6:00 PM</td>
                                    <td className="n_detail_comp">abc@gmail.com</td>
                                    <td className="n_detail_comp">ABCDEFG</td>
                                    <td className="n_detail_comp">ABC</td>
                                    <td className="main_table_last">
                                        <div className="main_icon_click">
                                            <a href="#main_id_sec">
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    src="/img/delete-outline.png"
                                                    alt="delete"
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>Status Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="alfa_listing-kg">
                            <div className="input_box_section ">
                                <label>Receiving Time</label>
                          
                            <ul className='mt-2'>
                                <li className="padd_left_inherit">
                                    <div className="input_box_section ">
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter Here"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="input_box_section w-swction_main">
                                        <select name="#" id="#">
                                            <option value="#">AM</option>
                                            <option value="#">PM</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_Air_Export shipment_status justify-content-between">
                            <div className="main-box-det-check">
                                <label
                                    className="users-table__checkbox"
                                    id="email"
                                >
                                    <input type="checkbox" className="check" />
                                    Email
                                </label>
                            </div>
                            <div className="main-box-det-check">
                                <label className="users-table__checkbox" id="sms">
                                    <input type="checkbox" className="check" />
                                    SMS
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Remarks</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="button-shipment-registration d-flex mt-2">
                        <div className="btn">

                            Save
                        </div>
                        <div className="btn">

                            Send Mail
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ShipmentStatus