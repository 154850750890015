import Cookies from 'js-cookie';
const adminMainUrl = process.env.REACT_APP_API_SERVER;

//roles list
export const fetchRoleListRequest = () => ({
    type: "FIND_ROLE_LIST_REQUEST",
});

export const fetchRoleListSuccess = (data) => ({
    type: "FIND_ROLE_LIST_SUCCESS",
    payload: data,
});

export const fetchRoleListFailure = (error) => ({
    type: "FIND_ROLE_LIST_FAILURE",
    payload: error,
});

export const getRoleList = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');

        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };

        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/role/list`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchRoleListSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in getRoleList function(redux):", err);
            });
    };
};


//roles list


//roles list
export const fetchRoleViewRequest = () => ({
    type: "FIND_ROLE_VIEW_REQUEST",
});

export const fetchRoleViewSuccess = (data) => ({
    type: "FIND_ROLE_VIEW_SUCCESS",
    payload: data,
});

export const fetchRoleViewFailure = (error) => ({
    type: "FIND_ROLE_VIEW_FAILURE",
    payload: error,
});

export const getRoleView = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');

        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };

        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/role/get`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchRoleViewSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in getRoleView function(redux):", err);
            });
    };
};


//roles list





//modules list
export const fetchModuleListRequest = () => ({
    type: "FIND_MODULE_LIST_REQUEST",
});

export const fetchModuleListSuccess = (data) => ({
    type: "FIND_MODULE_LIST_SUCCESS",
    payload: data,
});

export const fetchModuleListFailure = (error) => ({
    type: "FIND_MODULE_LIST_FAILURE",
    payload: error,
});

export const getModuleList = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };

        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/module/list`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchModuleListSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in getModuleList function(redux):", err);
            });
    };
};


//modules list

// module detail
export const fetchModuleDetailRequest = () => ({
    type: "FIND_MODULE_DETAIL_REQUEST",
});

export const fetchModuleDetailSuccess = (data) => ({
    type: "FIND_MODULE_DETAIL_SUCCESS",
    payload: data,
});

export const fetchModuleDetailFailure = (error) => ({
    type: "FIND_MODULE_DETAIL_FAILURE",
    payload: error,
});

export const getModuleDetail = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        // Append the page and items per page to the URL
        const apiUrl = `${adminMainUrl}/api/role/view?id=${id}`;

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchModuleDetailSuccess(resData));
                });
            }).catch((err) => {
                console.log("ERROR in getModuleDetail function(redux):", err);
            });
    };
};

// module detail