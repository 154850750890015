import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import { traficDetailsSchema } from '../../ValidationScema';
import Api from '../../Api';
import { mainUrl } from '../../Config';
const QuotationAirExportModal = ({ addTableId, tableDetailsId, tableId, modal, traficDetails, setUpdateTraficDetails, updateTraficDetails }) => {
  const [currentRowData, setCurrentRowData] = useState(null);
  const [tableIdData, setTableIdData] = useState(1)
  const [cargoType, setCargoType] = useState([]);
  const unit = [
    { value: 'Kg', label: 'Kg' }
  ]
  const GetCargoType = async () => {
    try {
      const data = await Api(`${mainUrl}/api/v.1/cargoTypeList`, "GET");
      const options = data?.data?.map(item => ({
        cargoId: item.id,
        value: item.name,
        label: item.name,
      }));
      setCargoType(options);

    } catch (error) {
      setCargoType([]);

      console.error("Error fetching data:", error);
    }
  };
  const chargeHead = [
    { value: 'A/F', label: 'A/F' },
    { value: 'SSC', label: 'SSC' },
    { value: 'FSC', label: 'FSC' },
    { value: 'XRAY', label: 'XRAY' },
    { value: 'MISC', label: 'MISC' },
    { value: 'AMS', label: 'AMS' },
    { value: 'EXTRA', label: 'EXTRA' },

  ]
  const handleEditClick = (detail) => {
    setCurrentRowData(detail);
  };

  const formik = useFormik({
    initialValues: currentRowData || {
      airline_name: '',
      charge_head: '',
      buying_price: '',
      selling_price: '',
      narration: '',
      valid_upto: '',
      currency: '',
      exchange_rate: '',
      unit: '',
      fc_amount: '',
      rate: '',
      cargo_type_id: '',
    },
    validationSchema: traficDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      values.tableId = tableId;
      setTableIdData(values.tableId)
      if (currentRowData) {
        // Update the selected row data
        const updatedDetails = updateTraficDetails.map((detail) =>
          detail === currentRowData ? { ...values, valid_upto: values.valid_upto.split('T')[0] } : detail
        );
        setUpdateTraficDetails(updatedDetails);
        setCurrentRowData(null);
      } else {
        setUpdateTraficDetails([...updateTraficDetails, values]);
      }
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (currentRowData) {
      formik.setValues(currentRowData);
    }
  }, [currentRowData]);
  const handleDeleteClick = (index) => {
    const updatedDetails = updateTraficDetails.filter((_, i) => i !== index);
    setUpdateTraficDetails(updatedDetails);
  };
  useEffect(() => {
    GetCargoType();

  }, []);
  return (
    <>
      <div className="modal fade" id={modal} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog detail_popup_width modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="btn-close-new-section">
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body detail_con_popup_main">
              <div className="main-nav-det">
                <h4>New Rate</h4>
              </div>
              <div className="main_detail_form " id="main_id_sec">
                <form onSubmit={formik.handleSubmit}>
                  <div className="Air_Export_Form_bg">
                    <div className="Air_Export_bg_main">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Airline Code <span>*</span></label>
                            <input
                              type="text"
                              placeholder="airline name"
                              value={formik.values.airline_name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="airline_name"
                            />
                            {formik.touched.airline_name && formik.errors.airline_name ? (
                              <div className="error">{formik.errors.airline_name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Charge Head <span>*</span></label>
                            <input
                              type="text"
                              placeholder="charge head"
                              value={formik.values.charge_head}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="charge_head"
                            />
                            {formik.touched.charge_head && formik.errors.charge_head ? (
                              <div className="error">{formik.errors.charge_head}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Buying Price</label>
                            <input
                              type="text"
                              placeholder="buying price"
                              value={formik.values.buying_price}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('buying_price', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="buying_price"
                            />
                            {formik.touched.buying_price && formik.errors.buying_price ? (
                              <div className="error">{formik.errors.buying_price}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Selling Price <span>*</span></label>
                            <input
                              type="text"
                              placeholder="selling price"
                              value={formik.values.selling_price}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('selling_price', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="selling_price"
                            />
                            {formik.touched.selling_price && formik.errors.selling_price ? (
                              <div className="error">{formik.errors.selling_price}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Narration</label>
                            <input
                              type="text"
                              placeholder="narration"
                              value={formik.values.narration}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="narration"
                            />
                            {formik.touched.narration && formik.errors.narration ? (
                              <div className="error">{formik.errors.narration}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Valid Upto <span>*</span></label>
                            <DatePicker
                              style={{ width: '100%' }}
                              selected={formik.values.valid_upto}
                              onChange={(date) => {
                                const formattedDate = date.toISOString().split('T')[0];
                                formik.setFieldValue('valid_upto', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              name="valid_upto"
                            />
                            {formik.touched.valid_upto && formik.errors.valid_upto ? (
                              <div className="error">{formik.errors.valid_upto}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Currency</label>
                            <input
                              type="text"
                              placeholder="currency"
                              value={formik.values.currency}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="currency"
                            />
                            {formik.touched.currency && formik.errors.currency ? (
                              <div className="error">{formik.errors.currency}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Exchange Rate</label>
                            <input
                              type="text"
                              placeholder="exchange rate"
                              value={formik.values.exchange_rate}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('exchange_rate', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="exchange_rate"
                            />
                            {formik.touched.exchange_rate && formik.errors.exchange_rate ? (
                              <div className="error">{formik.errors.exchange_rate}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section ">
                            <label>Unit</label>
                            <Select
                              className="mt-2"
                              options={unit}
                              onChange={(selectedOption) =>
                                formik.setFieldValue('unit', selectedOption.value)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {/* <input
                              type="text"
                              placeholder="unit"
                              value={formik.values.unit}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="unit"
                            /> */}
                            {formik.touched.unit && formik.errors.unit ? (
                              <div className="error">{formik.errors.unit}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>FC Amount</label>
                            <input
                              type="text"
                              placeholder="FC amount"
                              value={formik.values.fc_amount}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('fc_amount', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="fc_amount"
                            />
                            {formik.touched.fc_amount && formik.errors.fc_amount ? (
                              <div className="error">{formik.errors.fc_amount}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Rate</label>
                            <input
                              type="text"
                              placeholder="rate"
                              value={formik.values.rate}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('rate', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="rate"
                            />
                            {formik.touched.rate && formik.errors.rate ? (
                              <div className="error">{formik.errors.rate}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="">
                            <label>Cargo Type <span>*</span></label>
                            <Select
                              className="mt-2"
                              options={cargoType}
                              onChange={(selectedOption) =>
                                formik.setFieldValue('cargo_type_id', selectedOption.cargoId)
                              }
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="info-det-button-add">
                            <button type="button" onClick={() => formik.setFieldValue('tarrif_details', [...formik.values.tarrif_details, {}])}>
                              + Add
                            </button>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <button type="submit" className='search-btn'>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="Air_Export_Form_bg">
                  <div className="Air_Export_bg_main">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                          <table className="posts-table geeks">
                            <thead>
                              <tr className="users-table-tariff n_detail_comp">
                                <th>Airline N.</th>
                                <th>Charge Head</th>
                                <th>Buyer Amount</th>
                                <th>Seller Amount</th>
                                <th>Narration</th>
                                <th>Valid Up To</th>
                                <th>Currency</th>
                                <th>Exchange Rate</th>
                                <th>Unit</th>
                                <th>FC Amount</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addTableId && updateTraficDetails.map((detail, index) => (

                                <tr key={index} className="main_table_row_new n_detail_comp">
                                  <td>{detail.airline_name}</td>
                                  <td>{detail.charge_head}</td>
                                  <td>{detail.buying_price}</td>
                                  <td>{detail.selling_price}</td>
                                  <td>{detail.narration}</td>
                                  <td>{detail.valid_upto}</td>
                                  <td>{detail.currency}</td>
                                  <td>{detail.exchange_rate}</td>
                                  <td>{detail.unit}</td>
                                  <td>{detail.fc_amount}</td>
                                  <td>{detail.rate}</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <div style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </div>
                                      <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(index)}>
                                        <img src="/img/delete-outline.png" alt="delete" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-nav-det-button text-right main-Export-button">
                    <a data-bs-dismiss="modal" >Submit</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuotationAirExportModal