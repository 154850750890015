import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = ({ showSideBar, setShowSideBar }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const DashboardBtn = () => {
    setShowSideBar(false);
    setIsMenuOpen(false);
  };
  const handleSetActiveMenu = (menu) => {
    setActiveMenu(menu);
  };
  return (
    <aside className={`sidebar sidebar_admin ${showSideBar && "hidden"}`}>
      <div className="sidebar-start">
        <div className="head">
          {showSideBar ? (
            <img className="logo-side" src="/img/logo-side.png" />
          ) : (
            <img className="logo-default" src="/img/logo.png" alt="" />
          )}
        </div>
        <div className="sidebar-body">
          <div className="menu menu_admin">
            <ul className="admin_header_dash">
              <li
                className={
                  location.pathname ===
                    "/pricing-employee-dashboard/dashboard" ||
                  activeMenu === "dashboard"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  DashboardBtn();
                }}
              >
                <Link
                  to="/pricing-employee-dashboard/dashboard"
                  onClick={() => {
                    handleSetActiveMenu("dashboard");
                  }}
                  className="admin_header_list dash_board"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25276 3.88415L8.53116 8.02411L8.66936 10.1049C8.67086 10.3189 8.70436 10.5315 8.76906 10.7358C8.93596 11.1324 9.33756 11.3844 9.77446 11.3668L16.4317 10.9313C16.72 10.9266 16.9984 11.0344 17.2056 11.2311C17.3783 11.395 17.4898 11.6094 17.525 11.84L17.5368 11.98C17.2613 15.7947 14.4596 18.9765 10.6528 19.7978C6.84591 20.6191 2.94222 18.8841 1.06107 15.5347C0.518754 14.5616 0.180014 13.4921 0.0647445 12.3888C0.0165945 12.0622 -0.00460556 11.7323 0.00134444 11.4023C-0.00460556 7.31254 2.90783 3.77677 6.98469 2.92438C7.47537 2.84797 7.95639 3.10773 8.15316 3.55534C8.20406 3.659 8.23766 3.77002 8.25276 3.88415Z"
                      fill="#1B7BB1"
                    />
                    <path
                      opacity="0.4"
                      d="M20.0002 7.81229L19.9932 7.84488L19.973 7.89227L19.9758 8.0224C19.9654 8.1947 19.8988 8.3605 19.7842 8.4945C19.6647 8.634 19.5015 8.729 19.3218 8.7659L19.2122 8.7809L11.5314 9.2786C11.2759 9.3038 11.0215 9.2214 10.8316 9.052C10.6732 8.9107 10.572 8.7201 10.5434 8.5147L10.0279 0.84506C10.0189 0.81913 10.0189 0.791019 10.0279 0.765079C10.0349 0.553669 10.128 0.353839 10.2863 0.210229C10.4445 0.0666194 10.6549 -0.00880074 10.8702 0.000819257C15.4301 0.116829 19.2625 3.39579 20.0002 7.81229Z"
                      fill="#1B7BB1"
                    />
                  </svg>
                  <span className="text">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.startsWith(
                    "/pricing-employee-dashboard/quote-request-form"
                  ) ||
                  location.pathname.startsWith(
                    "/pricing-employee-dashboard/query-list"
                  ) ||
                  activeMenu === "pricing"
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    handleMenuToggle();
                    handleSetActiveMenu("pricing");
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_580_3476)">
                      <path
                        d="M8.99997 14.1431H1.71426C1.48693 14.1431 1.26891 14.2334 1.10817 14.3942C0.947423 14.5549 0.857117 14.773 0.857117 15.0003V22.286C0.857117 22.5133 0.947423 22.7313 1.10817 22.8921C1.26891 23.0528 1.48693 23.1431 1.71426 23.1431H8.99997C9.2273 23.1431 9.44532 23.0528 9.60607 22.8921C9.76681 22.7313 9.85712 22.5133 9.85712 22.286V15.0003C9.85712 14.773 9.76681 14.5549 9.60607 14.3942C9.44532 14.2334 9.2273 14.1431 8.99997 14.1431ZM22.2857 14.1431H15C14.7726 14.1431 14.5546 14.2334 14.3939 14.3942C14.2331 14.5549 14.1428 14.773 14.1428 15.0003V22.286C14.1428 22.5133 14.2331 22.7313 14.3939 22.8921C14.5546 23.0528 14.7726 23.1431 15 23.1431H22.2857C22.513 23.1431 22.731 23.0528 22.8918 22.8921C23.0525 22.7313 23.1428 22.5133 23.1428 22.286V15.0003C23.1428 14.773 23.0525 14.5549 22.8918 14.3942C22.731 14.2334 22.513 14.1431 22.2857 14.1431ZM15.6514 0.857422H8.36569C8.13836 0.857422 7.92034 0.947728 7.7596 1.10847C7.59885 1.26922 7.50855 1.48724 7.50855 1.71456V9.00028C7.50855 9.22761 7.59885 9.44563 7.7596 9.60637C7.92034 9.76712 8.13836 9.85742 8.36569 9.85742H15.6514C15.8787 9.85742 16.0967 9.76712 16.2575 9.60637C16.4182 9.44563 16.5085 9.22761 16.5085 9.00028V1.71456C16.5085 1.48724 16.4182 1.26922 16.2575 1.10847C16.0967 0.947728 15.8787 0.857422 15.6514 0.857422Z"
                        stroke="white"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_580_3476">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text">Pricing</span>
                  <i
                    className={`arrow ph-bold ph-caret-down ${
                      isMenuOpen && "arrow-active"
                    }`}
                  >
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/pricing-employee-dashboard/rate-request-list"
                      className={
                        location.pathname ===
                        "/pricing-employee-dashboard/rate-request-list"
                          ? "active"
                          : ""
                      }
                    >
                      Rate Request List
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/pricing-employee-dashboard/picked-rate-request-list"
                      className={
                        location.pathname ===
                        "/pricing-employee-dashboard/picked-rate-request-list"
                          ? "active"
                          : ""
                      }
                    >
                      Picked Rate Request List
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/pricing-employee-dashboard/send-pricing-list"
                      className={
                        location.pathname ===
                        "/pricing-employee-dashboard/send-pricing-list"
                          ? "active"
                          : ""
                      }
                    >
                      Send Pricing List
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
