import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModuleDetail } from "../../../Redux/action/Admin/RolePermissionAction";
import { FocusError } from "focus-formik-error";
import axios from "axios";
import Beatloader from "../../../Common/Loaders/Beatloader";
const adminMainUrl = process.env.REACT_APP_API_SERVER;

const RoleView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const { b64id } = useParams();

  let [showLoader, setShowLoader] = useState(false);
  let [disable, setDisable] = useState(false);
  let [detail, setDetail] = useState([]);
  let [permissionList, setPermissionList] = useState([]);

  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      if (b64id) {
        let id = atob(b64id);
        dispatch(getModuleDetail(id));
      } else {
        toast.error("Please enter correct url.")
        navigate(-1);
      }
    }
  }, []);

  let { loadingModuleDetail, moduleDetailDataError, moduleDetailData, moduleDetailPermissionData } = useSelector(state => state?.RolePermissionReducer);

  useEffect(() => {
    if (loadingModuleDetail) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    };

    if (moduleDetailData) {
      setDetail(moduleDetailData);
    }

    if (moduleDetailPermissionData) {
      setPermissionList(moduleDetailPermissionData);
    }

  }, [loadingModuleDetail, moduleDetailDataError, moduleDetailData, moduleDetailPermissionData]);


  return (
    <>
      <Toaster />
      {showLoader ? <Beatloader /> : <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <h2><Link class="query_info" to={-1}><img src="/img/re_darect.png" alt="Query" /></Link>Role & Permissions Information</h2>
            <div className="new_infometion_table p-3">
              <div className="main_detail_form">
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Role Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Role here"
                        disabled={true}
                        value={detail?.name}
                      />
                    </div>
                  </div>
                </div>

                <div className="main-nav-det">
                  <h4>Assigned Permissions</h4>
                </div>
                <div className="row">
                  {permissionList.length > 0 ? (
                    permissionList.map((module, index) => {
                      return (module.permission.filter(permission => permission?.assign_permission).length > 0) && <div key={index} className="col-md-4 mb-3">
                        <div
                          className="table-responsive role_css acl-role"
                          style={{ height: '200px', overflowY: 'scroll', margin: '10px 0px' }}
                        >
                          <table className="table table-striped permission_table">
                            <tbody>
                              <tr>
                                <th>{module.name}</th>
                              </tr>
                              {module.permission.filter(permission => permission?.assign_permission).length > 0 ? (
                                module.permission.map((permission, i) => (
                                  permission?.assign_permission && (
                                    <tr key={i}>
                                      <td>{permission.name}</td>
                                    </tr>
                                  )
                                ))
                              ) : (
                                <tr>
                                  <td><i>No permissions assigned</i></td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    })
                  ) : (
                    <div className="text-center">
                      <i>No permission assigned for this role.</i>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  );
};

export default RoleView;
