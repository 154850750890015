import React from 'react'
import './Email.css';
import { Link } from 'react-router-dom';
const Email2 = () => {
    return (
        <>
            <div className='h-vh'>

                <div className="email-container">

                    <div className="email-content">
                        <p>Hello Xyz,</p>
                        {/* <p>Greetings of the day.</p> */}
                        <p>
                            We are pleased to inform you that your query has been successfully processed. Below are the platform access details for your reference.
                        </p>
                        <div className="login-details">
                            <p>
                                Platform Link : <Link to="/client-login">Logistics.com</Link>
                            </p>
                            <p>
                                Username : <span>Xyz@gmail.com</span>
                            </p>
                            <p>
                                Password : <span>********</span>
                            </p>
                        </div>

                        <p>Please log in using the provided credentials to explore the platform and fill all the detail with documents. Should you need any assistance or have any feedback, feel free to reach out to us.</p>
                        <p>Looking forward to your continued engagement.</p>
                    </div>
                    <div className="email-footer">

                        <Link to="/client-login">
                            <p>© 2024 Logistics.com</p>
                        </Link>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Email2