
import React from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

const SeaImportAgentDetails = () => {
    const navigate = useNavigate()
    return (
        <main className="main users chart-page" id="skip-target">
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>Agent’s Details</h2>
                                </div>
                            </div>

                        </div>
                        {/* =============Search bar============= */}
                        <div className="query_bg_info_kyc">

                            <form className='main_section_detail' action="#">
                                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Address</label>
                                            <input type="text" className="form-control" placeholder="Enter Address" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Phone Number</label>
                                            <input type="text" className="form-control" placeholder="Enter Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Email Id</label>
                                            <input type="text" className="form-control" placeholder="Enter Email Id" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>WCA Number</label>
                                            <input type="text" className="form-control" placeholder="Enter WCA Number" />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button onClick={() => { navigate("/admin-dashboard/tracking-goods") }} type='button' className='btn search-btn w-fit '>
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                        {/* =============Search bar============= */}

                    </div>
                </div>
            </div>
        </main>
    )
}

export default SeaImportAgentDetails