import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { getPickedRateReqList } from '../../../Redux/action/Sales/PickedRateRequestAction';
import { toast } from 'react-toastify';
import Beatloader from '../../../Common/Loaders/Beatloader';
import { useFormik } from 'formik';
import ReactPaginate from 'react-paginate';

const PickedRateRequestListInProcess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(null); // Pages are 1-indexed
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function formatDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  }

  const headers = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    // 'REQUIRED SERVICE',
    'PICKED BY',
    'actions',
  ];

  const tableRef = useRef(null);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);


  //for mouse events
  const handleMouseDown = (e) => {
    const slider = tableRef.current;
    setIsGrabbing(true);
    slider.classList.add('grabbing'); // Apply grabbing CSS class
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    const slider = tableRef.current;
    setIsGrabbing(false);
    slider.classList.remove('grabbing'); // Remove grabbing CSS class
  };

  const handleMouseMove = (e) => {
    if (!isGrabbing) return;
    e.preventDefault();
    const slider = tableRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; // Speed of scroll
    slider.scrollLeft = scrollLeft - walk;
  };


  const token = Cookies.get('token');
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  const queryParams = new URLSearchParams(location.search);
  const queryString = new URLSearchParams(location.search).toString();
  useEffect(() => {
    // console.log(queryString);
    setQuery(queryString);
    // dispatch(getPickedRateReqList(token, "INPROCESS", queryParams.get("page") ?? 1, queryString))
  }, [queryParams])

  useEffect(() => {
    dispatch(getPickedRateReqList(token, "INPROCESS", queryParams.get("page") ?? 1, queryString))
  }, [query])


  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      // dispatch(getPickedRateReqList(token, currentModule, currentPage));
    }
  }, []);

  let { loadingPickedRateReqListApi, pickedRateReqListDataError, pickedRateReqListData, pickedRateReqListCurrentPage, pickedRateReqListTotalPage, pickedRateReqListTotalData } = useSelector(state => state?.PickedRateRequestReducer);


  useEffect(() => {

    if (pickedRateReqListData) {
      setList(pickedRateReqListData);
    }

    if (pickedRateReqListCurrentPage) {
      setCurrentPage(pickedRateReqListCurrentPage);
    }

    if (pickedRateReqListTotalData) {
      setTotalItems(pickedRateReqListTotalData);
    }

    if (pickedRateReqListTotalPage) {
      setTotalPages(pickedRateReqListTotalPage);
    }
  }, [loadingPickedRateReqListApi, pickedRateReqListDataError, pickedRateReqListData, pickedRateReqListCurrentPage, pickedRateReqListTotalPage, pickedRateReqListTotalData])




  const handlePageClick = (selectedObject) => {
    const selectedPage = selectedObject.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", selectedPage);
    navigate(`?${params.toString()}`);
  };
  //search and filter data
  const parseQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      search_by: params.get("search_by") || "",
      request_no: params.get("request_no") || "",
      name: params.get("name") || "",
      email: params.get("email") || "",
      phone: params.get("phone") || "",
      origin: params.get("origin") || "",
      destination: params.get("destination") || "",
      shipment_type_id: params.get("shipment_type_id") || "",
      shipment_via_id: params.get("shipment_via_id") || "",
      required_servie_id: params.get("required_servie_id") || "",
      start_date: params.get("start_date") || "",
      end_date: params.get("end_date") || "",
      quotation_date: params.get("quotation_date") || "",
      filter_by: params.get("filter_by") || ""
    };
  };

  let formik = useFormik({
    initialValues: parseQueryParams(),
    onSubmit: (values) => {
      const params = new URLSearchParams(location.search);
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          params.set(key, values[key]);
        } else {
          params.delete(key);
        }
      });

      if (!params.has("page")) {
        params.set("page", "1");
      } else {
        params.set("page", "1");
      }
      const queryString = params.toString();
      navigate(`${location.pathname}?${queryString}`);
      const currentPage = 1;
      const ITEMS_PER_PAGE = 10;
      dispatch(getPickedRateReqList(token, "INPROCESS", currentPage, queryString));
    },
  });

  const resetFilters = () => {
    try {
      formik.setValues({
        search_by: "",
        request_no: "",
        name: "",
        email: "",
        phone: "",
        origin: "",
        destination: "",
        shipment_type_id: "",
        shipment_via_id: "",
        required_servie_id: "",
        start_date: "",
        end_date: "",
        quotation_date: "",
        filter_by: "",
      });
      navigate(`/admin-dashboard/picked-rate-request-list-inprocess?page=1`)
    } catch (error) {
      console.log(error.message);
      toast.error("Some error occured!");
    }
  };


  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>Picked Rate Request List</h2>
                </div>
              </div>
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form onSubmit={formik.handleSubmit}>
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              name='search_by'
                              value={formik.values.search_by}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                        name="request_no"
                                        value={formik.values.request_no}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">DATE &amp; TIME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      // value={formik.values.date}
                                      // onChange={formik.handleChange}
                                      // onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                        name="origin"
                                        value={formik.values.origin}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                        name="destination"
                                        value={formik.values.destination}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                        name="shipment_type_id"
                                        value={formik.values.shipment_type_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                        name="shipment_via_id"
                                        value={formik.values.shipment_via_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                        name="required_servie_id"
                                        value={formik.values.required_servie_id}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                        value={formik.values.picked_user}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                              <button className="apply-btn cursor-pointer" type='submit'>
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="From"
                              name='start_date'
                              onChange={(date) => {
                                // setStartDate(date);
                                const formattedDate = date.toISOString().split('T')[0];
                                formik.setFieldValue('start_date', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.start_date}
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="To"
                              name='end_date'
                              onChange={(date) => {
                                // setStartDate(date);
                                const formattedDate = date.toISOString().split('T')[0];
                                formik.setFieldValue('end_date', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.end_date}
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <button className='search-btn text-white w-fit' style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }} type='submit'>Search</button>
                    <button
                      onClick={resetFilters}
                      className='search-btn text-white w-fit'
                      style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            <div className="container mt-4">
              <ul className="nav nav-pills mb-3 border-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button onClick={() => { navigate(`/admin-dashboard/picked-rate-request-list`) }} className="nav-link fw-semibold position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">Repricing</button>
                </li>
                <li className="nav-item" role="presentation">
                  {/* <button onClick={() => { showData("INPROCESS") }} className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">In Process</button> */}
                  <button onClick={() => { navigate('/admin-dashboard/picked-rate-request-list-inprocess') }} className="nav-link fw-semibold active position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="false">In Process</button>
                </li>
              </ul>

              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table border-redi">
                      <div className="table-wrapper" ref={tableRef}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onMouseMove={handleMouseMove}>
                        <table className="posts-table">
                          <thead>
                            <tr className="users-table-admin-th">
                              {headers.map((header) => (
                                <th key={header}>{capitalizeText(header)}</th>
                              ))}
                            </tr>
                          </thead>
                          {loadingPickedRateReqListApi ?
                            <Beatloader /> :
                            <tbody>
                              {list && list?.map((value, index) => {
                                return <tr key={index} className='users-table-admin-td'>
                                  <td className=" text-center">{value?.request_no}</td>
                                  <td className="">
                                    {formatDate(value?.createdAt)}
                                  </td>
                                  <td className="">{value?.full_name}</td>
                                  <td className="">{value?.email}</td>
                                  <td className="">{value?.phone}</td>
                                  <td className="">{value?.origin}</td>
                                  <td className="">{value?.destination}</td>
                                  <td className="">{value?.shipment_type?.name}</td>
                                  <td className="">{value?.shipment_via?.name}</td>
                                  <td className="">
                                    {
                                      value?.picked_user?.first_name
                                        ? `${value.picked_user.first_name} ${value.picked_user.middle_name || ''} ${value.picked_user.last_name || ''}`.trim()
                                        : ""
                                    }
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center py-2" onClick={() => {
                                      navigate('/admin-dashboard/query-info-give-rates', { state: { quotationId: value.id } })
                                    }}>
                                      <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                        <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              })}

                            </tbody>}
                        </table>
                      </div>
                      {list.length > 0 && <div className="pagination-controls my-5 d-flex justify-content-center align-items-center react-custom-paginate">
                        <ReactPaginate
                          previousLabel={<img src="/img/right.png" alt="Previous" />}
                          nextLabel={<img src="/img/left.png" alt="Next" />}
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={totalPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={currentPage - 1} // To sync ReactPaginate's internal state with currentPage
                        />
                      </div>}
                    </div>
                  </div>
                </div>
                {/* <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table border-redi">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-admin-th">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/pricing-dashboard/query-info-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="color_1 text-center">1234</td>
                            <td className="color_1">
                              12 May 2024
                            </td>
                            <td className="color_1">Rahul</td>
                            <td className="color_1">rahul@gmail.com</td>
                            <td className="color_1">9876543210</td>
                            <td className="color_1">Delhi</td>
                            <td className="color_1">London</td>
                            <td className="color_1">Import</td>
                            <td className="color_1">Air</td>
                            <td className="color_1">Both</td>
                            <td className="color_1">
                              Aman
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center py-2" onClick={() => { navigate('/admin-dashboard/query-info-pricing-picked') }}>
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>



            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PickedRateRequestListInProcess