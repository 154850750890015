import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { adminMainUrl } from "../../../Config";
import { adminLoginSchema } from "../../../ValidationScema";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  // const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Enter Your Email Address"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${adminMainUrl}/api/forgot-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.email }),
        });

        if (response.ok) {
          const data = await response.json();
          toast.success(data?.otp);
          navigate("/admin-login-forget-otp", {
            state: { email: values.email, otp: data?.otp },
          }); // Redirect to OTP page
        } else {
          const errorData = await response.json();
          console.error("Error sending OTP:", errorData.message);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    },
  });
  return (
    <section className="login">
      {/* <Toaster /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 second-body">
            <div className="card-body align-items-center mx-md-4 card-padding">
              <div className="logo-section pt-4">
                <Link to="/admin-login" className="query_info">
                  <img src="/img/back-icon.png" alt="Query" />
                </Link>
              </div>
              <div className="logo-section text-center pt-4">
                <img src="/img/committed-logo.png" alt="logo" />
              </div>
              <form
                className="login-form mx-auto"
                onSubmit={formik.handleSubmit}
              >
                <div className="forget-head">
                  <h4>Forgot Password</h4>
                </div>
                <div data-mdb-input-init="" className="form-outline mb-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="text-center pt-1 mb-2 pb-1">
                  <button className="btn-login mb-3" type="submit">
                    Send OTP
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-7 p-0">
            <div className="img-form">
              <img
                src="/img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
