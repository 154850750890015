import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ data ,currentData, setCurrentData,pageCount, setPageCount,dataOffset, setDataOffset,dataPerPage}) => {


    useEffect(() => {
        if (data && data.length > 0) {
            const endOffset = dataOffset + dataPerPage;
            setCurrentData(data.slice(dataOffset, endOffset));
            setPageCount(Math.ceil(data.length / dataPerPage));
        }
    }, [dataOffset, data]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * dataPerPage;
        setDataOffset(newOffset);
    };

    return (
        <nav
            aria-label="Page navigation example"
            className="nav-class d-flex justify-content-center py-5 example-pagination"
        >
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeClassName="active"
            />
        </nav>
    );
};

export default Pagination;