import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const DocketInformation = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="col-md-12">
                <div className="main_section_detail">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="main-nav-det">
                                <h2>
                                    {/* <Link to="/admin-dashboard/picked-leads" className="query_info">
                                        <img src="/img/re_darect.png" alt="Query" />
                                    </Link> */}
                                    Docket Information
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 bg-blur radius-sm">
                    <div className="bg-white pb-4 px-4 radius-sm">
                        <h4 className="docket-text my-3">Docket Details</h4>
                     
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="posts-table border-right-main">

                                        <tbody>
                                            <tr>
                                                <td className="table_info_color"><p>Docket Number</p></td>
                                                <td><p>#1234GH12</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Quotation Number</p></td>
                                                <td><p>#1234</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Converted Date</p></td>
                                                <td><p>12-May-2024</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Client Name</p></td>
                                                <td><p>Ramesh</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Client Email</p></td>
                                                <td><p>ramesh@gmail.com</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Client Phone Number</p></td>
                                                <td><p>+91 1232423443</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Origin</p></td>
                                                <td><p>Delhi</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Destination</p></td>
                                                <td><p>London</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Approved By</p></td>
                                                <td><p>Vicky Khanna</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Converted By</p></td>
                                                <td><p>Ravi Sharma</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Docket Date</p></td>
                                                <td><p>12-12-2024</p></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <table className="posts-table">

                                        <tbody>
                                            <tr>

                                                <td className="table_info_color"><p>Clearance Date</p></td>
                                                <td><p>12-12-2024</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Shipment Type</p></td>
                                                <td><p>Console</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Type</p></td>
                                                <td><p>House</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Cargo Type</p></td>
                                                <td><p>HAZ</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>HAWB Nature</p></td>
                                                <td><p>Console</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>MAWB Nature</p></td>
                                                <td><p>Non-Console</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Shipment Type</p></td>
                                                <td><p>Import</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Shipment Via</p></td>
                                                <td><p>Air</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Mode</p></td>
                                                <td><p>Direct</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Shipment Term</p></td>
                                                <td><p>FA</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Shipment Mode</p></td>
                                                <td><p>Port to Door</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                       
                        <h4 className="docket-text my-3">Party Details</h4>
                  
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="posts-table border-right-main">

                                        <tbody>
                                            <tr>
                                                <td className="table_info_color"><p>Importer</p></td>
                                                <td><p className='text-blue'>View</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Address</p></td>
                                                <td><p>12 Street California</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Supplier</p></td>
                                                <td><p>Aman</p></td>

                                            </tr>
                                            <tr>
                                                <td className="table_info_color"><p>Broker</p></td>
                                                <td><p>Ramesh</p></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <table className="posts-table">

                                        <tbody>
                                            <tr>

                                                <td className="table_info_color"><p>Sub Agent1</p></td>
                                                <td><p>Rahul</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Address</p></td>
                                                <td><p>12 Street California</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Sub Agent2</p></td>
                                                <td><p>Ram</p></td>
                                            </tr>
                                            <tr>

                                                <td className="table_info_color"><p>Address</p></td>
                                                <td><p>12 Street California</p></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        <h4 className="docket-text my-3">Shipment Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table border-right-main">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>MAWB No.</p></td>
                                            <td><p >12345678</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>MAWB Date</p></td>
                                            <td><p>12/12/2024</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>HAWB No</p></td>
                                            <td><p>12345678</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>HAWB Date</p></td>
                                            <td><p>12/12/2024</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>PO NO.</p></td>
                                            <td><p>12345678</p></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="posts-table">

                                    <tbody>
                                        <tr>

                                            <td className="table_info_color"><p>GRN No.</p></td>
                                            <td><p>12345678</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>AirLine</p></td>
                                            <td><p>Airline</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>DO No</p></td>
                                            <td><p>12345678</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>LR No.</p></td>
                                            <td><p>12345678</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>LR No. Date</p></td>
                                            <td><p>12/12/2024</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <h4 className="docket-text my-3">Goods Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table border-right-main">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>No. Of Pkgs.</p></td>
                                            <td><p >12</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Unit</p></td>
                                            <td><p>KG</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Gross Weight</p></td>
                                            <td><p>122 KG</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Unit</p></td>
                                            <td><p>KG</p></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="posts-table">

                                    <tbody>
                                        <tr>

                                            <td className="table_info_color"><p>Chargeable Wt.</p></td>
                                            <td><p>14</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Goods Type</p></td>
                                            <td><p>Liquid</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Goods Description</p></td>
                                            <td><p>Lorem Ipsum</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Marks & Nos</p></td>
                                            <td><p>Marks & Nos</p></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <h4 className="docket-text my-3">Flight Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table border-right-main">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>1st Carrier</p></td>
                                            <td><p >DHL</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>2nd Carrier</p></td>
                                            <td><p>DTDC</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>ETD</p></td>
                                            <td><p>ETD</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>ETA</p></td>
                                            <td><p>ETA</p></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="posts-table">

                                    <tbody>
                                        <tr>

                                            <td className="table_info_color"><p>IGM Date</p></td>
                                            <td><p>12-12-2024</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Inward Date</p></td>
                                            <td><p>12-12-2024</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Rotation No</p></td>
                                            <td><p>1223</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>ATD</p></td>
                                            <td><p>ATD</p></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <h4 className="docket-text my-3">Port Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table border-right-main">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>Origin Port</p></td>
                                            <td><p >Delhi</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Destination Port</p></td>
                                            <td><p>London</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Discharge Port</p></td>
                                            <td><p>London</p></td>

                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="posts-table">

                                    <tbody>
                                        <tr>

                                            <td className="table_info_color"><p>Clearance At</p></td>
                                            <td><p>Clearance At</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Destuffing At</p></td>
                                            <td><p>Destuffing At</p></td>
                                        </tr>



                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <h4 className="docket-text my-3">Dimension Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>Dimensions</p></td>
                                            <td><p className='text-blue'>View</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <h4 className="docket-text my-3">Other Details</h4>
                        <div className=" row">
                            <div className="col-md-6">
                                <table className="posts-table border-right-main">

                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>Docket Owner</p></td>
                                            <td><p >Ramesh</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Reference No/PO</p></td>
                                            <td><p>1234</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Status</p></td>
                                            <td><p>Status</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Remarks</p></td>
                                            <td><p>1234</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Ass. Value</p></td>
                                            <td><p>1234</p></td>

                                        </tr>
                                        <tr>
                                            <td className="table_info_color"><p>Total Duty</p></td>
                                            <td><p>1234</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="posts-table">
                                    <tbody>
                                        <tr>
                                            <td className="table_info_color"><p>Quotation No.</p></td>
                                            <td><p>2123</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Reference No</p></td>
                                            <td><p>1234</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Verified By</p></td>
                                            <td><p>Rahul</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Sales Person</p></td>
                                            <td><p>Ram</p></td>
                                        </tr>
                                        <tr>

                                            <td className="table_info_color"><p>Place of Supply</p></td>
                                            <td><p>Supply</p></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('/admin-dashboard/export-docket-number-generated-list') }}>
                                    Back to list
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocketInformation