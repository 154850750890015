import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonTableLoader = ({ colSpan, count, length }) => {
    const arr = new Array(length ?? 5).fill(null);
    return (
        <>
            {arr.map((value, index) => {
                return (
                    <tr key={index}>
                        <td
                            colSpan={colSpan ?? 5}
                            style={{
                                height: "70px",
                                padding: "0px 27px",
                                width: "100%",
                                textAlign: "center"
                            }}
                        >
                            <Skeleton count={count ?? 2} />
                        </td>

                    </tr>
                )
            })}
        </>
    )
}

export default SkeletonTableLoader