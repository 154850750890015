import { Dashboard } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = ({ showSideBar, setShowSideBar }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsMenuOpen1(false);
    handleSetActiveMenu("sales-crm");
  };
  const DashboardBtn = () => {
    setShowSideBar(false);
    setIsMenuOpen(false);
    setIsMenuOpen1(false);
  };
  const handleSetActiveMenu = (menu) => {
    setActiveMenu(menu);
  };
  return (
    <aside className={`sidebar sidebar_admin ${showSideBar && "hidden"}`}>
      <div className="sidebar-start">
        <div className="head">
          {showSideBar ? (
            <img className="logo-side" src="/img/logo-side.png" />
          ) : (
            <img className="logo-default" src="/img/logo.png" alt="" />
          )}
        </div>
        <div className="sidebar-body">
          <div className="menu menu_admin">
            <ul className="admin_header_dash">
              <li
                className={
                  location.pathname === "/dashboard/sales-dashboard" ||
                  activeMenu === "dashboard"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  DashboardBtn();
                }}
              >
                <Link
                  to="/dashboard/sales-dashboard"
                  onClick={() => {
                    handleSetActiveMenu("dashboard");
                  }}
                  className="admin_header_list dash_board"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25276 3.88415L8.53116 8.02411L8.66936 10.1049C8.67086 10.3189 8.70436 10.5315 8.76906 10.7358C8.93596 11.1324 9.33756 11.3844 9.77446 11.3668L16.4317 10.9313C16.72 10.9266 16.9984 11.0344 17.2056 11.2311C17.3783 11.395 17.4898 11.6094 17.525 11.84L17.5368 11.98C17.2613 15.7947 14.4596 18.9765 10.6528 19.7978C6.84591 20.6191 2.94222 18.8841 1.06107 15.5347C0.518754 14.5616 0.180014 13.4921 0.0647445 12.3888C0.0165945 12.0622 -0.00460556 11.7323 0.00134444 11.4023C-0.00460556 7.31254 2.90783 3.77677 6.98469 2.92438C7.47537 2.84797 7.95639 3.10773 8.15316 3.55534C8.20406 3.659 8.23766 3.77002 8.25276 3.88415Z"
                      fill="#1B7BB1"
                    />
                    <path
                      opacity="0.4"
                      d="M20.0002 7.81229L19.9932 7.84488L19.973 7.89227L19.9758 8.0224C19.9654 8.1947 19.8988 8.3605 19.7842 8.4945C19.6647 8.634 19.5015 8.729 19.3218 8.7659L19.2122 8.7809L11.5314 9.2786C11.2759 9.3038 11.0215 9.2214 10.8316 9.052C10.6732 8.9107 10.572 8.7201 10.5434 8.5147L10.0279 0.84506C10.0189 0.81913 10.0189 0.791019 10.0279 0.765079C10.0349 0.553669 10.128 0.353839 10.2863 0.210229C10.4445 0.0666194 10.6549 -0.00880074 10.8702 0.000819257C15.4301 0.116829 19.2625 3.39579 20.0002 7.81229Z"
                      fill="#1B7BB1"
                    />
                  </svg>
                  <span className="text">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.startsWith(
                    "/dashboard/quote-request-form"
                  ) ||
                  location.pathname.startsWith("/dashboard/query-list") ||
                  activeMenu === "sales-crm"
                    ? "active"
                    : ""
                }
              >
                <a
                  href="#"
                  onClick={handleMenuToggle}
                  className="admin_header_list list_admin-main"
                >
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 16H1V1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 3L11 10L7 6L1 11"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text">Sales &amp; CRM</span>
                  <i
                    className={`arrow ph-bold ph-caret-down ${
                      isMenuOpen && "arrow-active"
                    }`}
                  >
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/dashboard/quote-request-form"
                      className={
                        location.pathname === "/dashboard/quote-request-form"
                          ? "active"
                          : ""
                      }
                    >
                      Quote Request Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/dashboard/query-list"
                      className={
                        location.pathname === "/dashboard/query-list"
                          ? "active"
                          : ""
                      }
                    >
                      Query List
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${
                  location.pathname === "/dashboard/all-rate-request" ||
                  activeMenu === "pricing"
                    ? "active"
                    : ""
                } main_color_nav`}
              >
                <a
                  href="#"
                  onClick={() => {
                    setIsMenuOpen1(!isMenuOpen1);
                    setIsMenuOpen(false);
                    handleSetActiveMenu("pricing");
                  }}
                  className="admin_header_list menu_admin-new"
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99979 14.1431H1.71408C1.48675 14.1431 1.26873 14.2334 1.10798 14.3942C0.947239 14.5549 0.856934 14.773 0.856934 15.0003V22.286C0.856934 22.5133 0.947239 22.7313 1.10798 22.8921C1.26873 23.0528 1.48675 23.1431 1.71408 23.1431H8.99979C9.22712 23.1431 9.44514 23.0528 9.60588 22.8921C9.76663 22.7313 9.85693 22.5133 9.85693 22.286V15.0003C9.85693 14.773 9.76663 14.5549 9.60588 14.3942C9.44514 14.2334 9.22712 14.1431 8.99979 14.1431ZM22.2855 14.1431H14.9998C14.7725 14.1431 14.5544 14.2334 14.3937 14.3942C14.233 14.5549 14.1426 14.773 14.1426 15.0003V22.286C14.1426 22.5133 14.233 22.7313 14.3937 22.8921C14.5544 23.0528 14.7725 23.1431 14.9998 23.1431H22.2855C22.5128 23.1431 22.7309 23.0528 22.8916 22.8921C23.0523 22.7313 23.1426 22.5133 23.1426 22.286V15.0003C23.1426 14.773 23.0523 14.5549 22.8916 14.3942C22.7309 14.2334 22.5128 14.1431 22.2855 14.1431ZM15.6512 0.857422H8.36551C8.13818 0.857422 7.92016 0.947728 7.75941 1.10847C7.59867 1.26922 7.50836 1.48724 7.50836 1.71456V9.00028C7.50836 9.22761 7.59867 9.44563 7.75941 9.60637C7.92016 9.76712 8.13818 9.85742 8.36551 9.85742H15.6512C15.8785 9.85742 16.0966 9.76712 16.2573 9.60637C16.4181 9.44563 16.5084 9.22761 16.5084 9.00028V1.71456C16.5084 1.48724 16.4181 1.26922 16.2573 1.10847C16.0966 0.947728 15.8785 0.857422 15.6512 0.857422Z"
                      stroke="black"
                      strokeWidth="1.71429"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="text">Pricing</span>
                  <i
                    className={`arrow ph-bold ph-caret-down ${
                      isMenuOpen1 && "arrow-active"
                    }`}
                  >
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden"
                  style={{ display: isMenuOpen1 ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/dashboard/all-rate-request"
                      className={
                        location.pathname === "/dashboard/all-rate-request"
                          ? "active"
                          : ""
                      }
                    >
                      Rate Request
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
