import Cookies from 'js-cookie';
const adminMainUrl = process.env.REACT_APP_API_SERVER;

//gender master

export const fetchGenderRequest = () => ({
    type: "FIND_GENDER_REQUEST",
});

export const fetchGenderSuccess = (data) => ({
    type: "FIND_GENDER_SUCCESS",
    payload: data,
});

export const fetchGenderFailure = (error) => ({
    type: "FIND_GENDER_FAILURE",
    payload: error,
});

export const getGender = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/gender/get`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchGenderSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getGender function(redux):", err);
            });
    };
};

//gender master


//id proof master

export const fetchIdProofRequest = () => ({
    type: "FIND_IDPROOF_REQUEST",
});

export const fetchIdProofSuccess = (data) => ({
    type: "FIND_IDPROOF_SUCCESS",
    payload: data,
});

export const fetchIdProofFailure = (error) => ({
    type: "FIND_IDPROOF_FAILURE",
    payload: error,
});

export const getIdProof = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/id-proof-type/get`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchIdProofSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getIdProof function(redux):", err);
            });
    };
};

//id proof master


//weight master

export const fetchWeightRequest = () => ({
    type: "FIND_WEIGHT_REQUEST",
});

export const fetchWeightSuccess = (data) => ({
    type: "FIND_WEIGHT_SUCCESS",
    payload: data,
});

export const fetchWeightFailure = (error) => ({
    type: "FIND_WEIGHT_FAILURE",
    payload: error,
});

export const getWeight = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/weight-unit/get`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchWeightSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getWeight function(redux):", err);
            });
    };
};

//weight master




//currency master

export const fetchCurrencyRequest = () => ({
    type: "FIND_CURRENCY_REQUEST",
});

export const fetchCurrencySuccess = (data) => ({
    type: "FIND_CURRENCY_SUCCESS",
    payload: data,
});

export const fetchCurrencyFailure = (error) => ({
    type: "FIND_CURRENCY_FAILURE",
    payload: error,
});

export const getCurrency = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/currency/list`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCurrencySuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getCurrency function(redux):", err);
            });
    };
};

//currency master


//country master

export const fetchCountryRequest = () => ({
    type: "FIND_COUNTRY_REQUEST",
});

export const fetchCountrySuccess = (data) => ({
    type: "FIND_COUNTRY_SUCCESS",
    payload: data,
});

export const fetchCountryFailure = (error) => ({
    type: "FIND_COUNTRY_FAILURE",
    payload: error,
});

export const getCountry = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/country/list`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCountrySuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getCountry function(redux):", err);
            });
    };
};

//country master

//state master

export const fetchStateRequest = () => ({
    type: "FIND_STATE_REQUEST",
});

export const fetchStateSuccess = (data) => ({
    type: "FIND_STATE_SUCCESS",
    payload: data,
});

export const fetchStateFailure = (error) => ({
    type: "FIND_STATE_FAILURE",
    payload: error,
});

export const getState = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/state/list?country_id=${id}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchStateSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getState function(redux):", err);
            });
    };
};

//state master


//city master

export const fetchCityRequest = () => ({
    type: "FIND_CITY_REQUEST",
});

export const fetchCitySuccess = (data) => ({
    type: "FIND_CITY_SUCCESS",
    payload: data,
});

export const fetchCityFailure = (error) => ({
    type: "FIND_CITY_FAILURE",
    payload: error,
});

export const getCity = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/city/list?state_id=${id}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCitySuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getCity function(redux):", err);
            });
    };
};

//city master

//residential country master

export const fetchResidentialCountryRequest = () => ({
    type: "FIND_R_COUNTRY_REQUEST",
});

export const fetchResidentialCountrySuccess = (data) => ({
    type: "FIND_R_COUNTRY_SUCCESS",
    payload: data,
});

export const fetchResidentialCountryFailure = (error) => ({
    type: "FIND_R_COUNTRY_FAILURE",
    payload: error,
});

export const getResidentialCountry = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/country/list`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchResidentialCountrySuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getResidentialCountry function(redux):", err);
            });
    };
};

//residential country master

//residential state master

export const fetchResidentialStateRequest = () => ({
    type: "FIND_R_STATE_REQUEST",
});

export const fetchResidentialStateSuccess = (data) => ({
    type: "FIND_R_STATE_SUCCESS",
    payload: data,
});

export const fetchResidentialStateFailure = (error) => ({
    type: "FIND_R_STATE_FAILURE",
    payload: error,
});

export const getResidentialState = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/state/list?country_id=${id}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchResidentialStateSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getResidentialState function(redux):", err);
            });
    };
};

//residential state master

//residential city master

export const fetchResidentialCityRequest = () => ({
    type: "FIND_R_CITY_REQUEST",
});

export const fetchResidentialCitySuccess = (data) => ({
    type: "FIND_R_CITY_SUCCESS",
    payload: data,
});

export const fetchResidentialCityFailure = (error) => ({
    type: "FIND_R_CITY_FAILURE",
    payload: error,
});

export const getResidentialCity = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/city/list?state_id=${id}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchResidentialCitySuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getResidentialCity function(redux):", err);
            });
    };
};

//residential city master

//department master

export const fetchDepartmentRequest = () => ({
    type: "FIND_DEPARTMENT_REQUEST",
});

export const fetchDepartmentSuccess = (data) => ({
    type: "FIND_DEPARTMENT_SUCCESS",
    payload: data,
});

export const fetchDepartmentFailure = (error) => ({
    type: "FIND_DEPARTMENT_FAILURE",
    payload: error,
});

export const getDepartment = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/department/get`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchDepartmentSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getDepartment function(redux):", err);
            });
    };
};

//department master

//designation master

export const fetchDesignationRequest = () => ({
    type: "FIND_DESIGNATION_REQUEST",
});

export const fetchDesignationSuccess = (data) => ({
    type: "FIND_DESIGNATION_SUCCESS",
    payload: data,
});

export const fetchDesignationFailure = (error) => ({
    type: "FIND_DESIGNATION_FAILURE",
    payload: error,
});

export const getDesignation = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/designation/get`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchDesignationSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getDesignation function(redux):", err);
            });
    };
};

//designation master


//employee details master

export const fetchEmployeeRequest = () => ({
    type: "FIND_EMPLOYEE_REQUEST",
});

export const fetchEmployeeSuccess = (data) => ({
    type: "FIND_EMPLOYEE_SUCCESS",
    payload: data,
});

export const fetchEmployeeFailure = (error) => ({
    type: "FIND_EMPLOYEE_FAILURE",
    payload: error,
});

export const getEmployee = (id) => {
    return async function (dispatch) {
        const token = Cookies.get('token');
        let config = {
            method: "GET",
            headers: {
                'auth-token': token, // Use 'auth-token' header as required by your API
                'Content-Type': 'application/json',
            }
        };
        await fetch(`${adminMainUrl}/api/employee/view?emply_id=${id}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchEmployeeSuccess(resData.data));
                })
            }).catch((err) => {
                console.log("ERROR in getEmployee function(redux):", err);
            });
    };
};

//employee details master


//role list

export const fetchRoleListRequest = () => ({
    type: "FIND_ROLE_LIST_REQUEST",
});

export const fetchRoleListSuccess = (data) => ({
    type: "FIND_ROLE_LIST_SUCCESS",
    payload: data,
});

export const fetchRoleListFailure = (error) => ({
    type: "FIND_ROLE_LIST_FAILURE",
    payload: error,
});

export const getRoleList = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');

        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        
        const apiUrl = `${adminMainUrl}/api/role/list`;
        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchRoleListSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in getRoleList function(redux):", err);
            });
    };
};

//role list

//user list

export const fetchUserListRequest = () => ({
    type: "FIND_USER_LIST_REQUEST",
});

export const fetchUserListSuccess = (data) => ({
    type: "FIND_USER_LIST_SUCCESS",
    payload: data,
});

export const fetchUserListFailure = (error) => ({
    type: "FIND_USER_LIST_FAILURE",
    payload: error,
});

export const getUserList = () => {
    return async function (dispatch) {
        const token = Cookies.get('token');

        let config = {
            method: "GET",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            }
        };
        
        const apiUrl = `${adminMainUrl}/api/get/user`;
        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchUserListSuccess(resData?.data));
                });
            }).catch((err) => {
                console.log("ERROR in getUserList function(redux):", err);
            });
    };
};

//user list
