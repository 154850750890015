
import React, { useState } from 'react'
import { Stepper, Step, StepLabel, Button, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ShipmentOption from '../DocketNumberCreation/ShipmentOption';
import Party from '../DocketNumberCreation/Party';
import Shipment from '../DocketNumberCreation/Shipment';
import Goods from '../DocketNumberCreation/Goods';
import Flight from '../DocketNumberCreation/Flight';
import Port from '../DocketNumberCreation/Port';
import Dimentions from '../DocketNumberCreation/Dimentions';

function DocketNumberExport() {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Shipment Option',
            content: (<>
                <ShipmentOption />
            </>
            ),
        },
        {
            label: 'Party',
            content: (<>
                <Party />

            </>),
        },
        {
            label: 'Shipment',
            content: (<>
                <Shipment />
                {/* 3 */}
            </>),
        },
        {
            label: 'Goods',
            content: (<>
                <Goods />
                {/* 3 */}
            </>),
        },
        {
            label: 'Flight',
            content: (<>
                <Flight />
                {/* 3 */}
            </>),
        },
        {
            label: 'Port',
            content: (<>
                <Port />
                {/* 3 */}
            </>),
        },
        {
            label: 'Dimension',
            content: (<>
                <Dimentions />
                {/* 3 */}
            </>),
        },

    ];
    const handleNext = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // navigate('/Customer-dashboard/registration-agreement')
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        // navigate('/admin-dashboard/shipment-registration')
        navigate('/admin-dashboard/export-docket-number-generated-list')
    };
    return (
        <>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-det">
                                <h2>Docket Number Creation</h2>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="col-md-12">
                            <div className="query_bg_info_kyc">
                                <div className="main_section_detail">
                                    <div className='count-step custom-resume-stepper d-flex flex-col align-items-center justify-content-center' >
                                        <div className=' w-100 overflow-auto d-lg-flex justify-content-center'>

                                            <Stepper activeStep={activeStep} orientation="horizontal" style={{ width: "900px" }}>
                                                {steps.map((step, index) => (
                                                    <Step key={step.label} className='step-label' >
                                                        <StepLabel style={{ display: "grid" }} className='step-label'>{step.label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </div>
                                    </div>
                                    <div className='overflow-auto' >


                                        {steps[activeStep] && (
                                            <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>
                                                <div>

                                                    <div>{steps[activeStep].content}</div>

                                                </div>
                                                <div className='button-container' style={{ marginTop: '20px' }}>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                        className=''
                                                    >
                                                        Previous
                                                    </Button>
                                                    <Button
                                                        className=''
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {activeStep === steps.length - 1 ? <Link className='text-white' sx={{ mt: 1, mr: 1 }}>
                                                            Submit
                                                        </Link> : 'Next'}
                                                    </Button>
                                                </div>
                                            </Paper>
                                        )}

                                        {activeStep === steps.length && (
                                            // <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                            //     Go to All Employee List
                                            // </Button>
                                            <main className="main users chart-page" id="skip-target">
                                                <div className="container">
                                                    <div className="col-md-12">
                                                        <div className="main_section_detail">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="main-nav-det">
                                                                        <h2>Docket Number Creation</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="query_bg_info_kyc">
                                                                        <div className="main_section_detail1">
                                                                            <div className="doceket_section">
                                                                                <div className="doceket_section_img">
                                                                                    <img src="/img/check-circle.png" />
                                                                                </div>
                                                                                <p>Your Docket Number is successfully generated</p>
                                                                                <h4>Docket Number</h4>
                                                                                <h2>1234567890</h2>
                                                                            </div>
                                                                            <div className="docket_btn text-center mt-3">
                                                                                <div onClick={handleReset} className="cursor-pointer btn search-btn">
                                                                                    Okay
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        )}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default DocketNumberExport