import React, { useState } from 'react'

const Documentation = () => {
    const [panCard,setPanCard]=useState(false);
   const [adharCard,setAadharCard]=useState(false);
   const [rent,setRent]=useState(false);
   const [gst,setGst]=useState(false);
   const [letter,setLetter]=useState(false);
   const [iec,setIec]=useState(false);
   const [cheque,setCheque]=useState(false);
    return (
        <>
            <form action="#">
                <div className="main-nav-det">
                    <h4>Documents Required </h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>PAN Card of Authorized Signatory</label>
                            <a className="folder_main_update">
                            
                                John Pan.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{setPanCard(!panCard)}} src="/img/document-fail.png" alt="file" id="toggleDesc-1" />
                                </span>
                            </a>
                        </div>
                        {
                            panCard &&
                        <div id="rejectionReason-1" style={{ marginBottom: 10 }}>
                            <label htmlFor="rejection-description-1">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-1"
                                name="rejection-description-1"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Copy of Aadhaar Card of Authorized Signatory</label>
                            <a className="folder_main_update">
                            
                                John Aadhar.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{
                                        setAadharCard(!adharCard)
                                    }} src="/img/document-fail.png" alt="file" id="toggleDesc-2" />
                                </span>
                            </a>
                        </div>
                        {adharCard &&
                        <div id="rejectionReason-2" style={{marginBottom: 10 }}>
                            <label htmlFor="rejection-description-2">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-2"
                                name="rejection-description-2"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Rent Agreement/ Address Proof of Office premises</label>
                            <a className="folder_main_update">
                            
                                Rent Agreement.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{
                                        setRent(!rent)
                                    }} src="/img/document-fail.png" alt="file" id="toggleDesc-7" />
                                </span>
                            </a>
                        </div>
                        {rent &&
                        <div id="rejectionReason-7" style={{ marginBottom: 10 }}>
                            <label htmlFor="rejection-description-7">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-7"
                                name="rejection-description-7"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>GST registration certificate</label>
                            <a className="folder_main_update">
                            
                                GST registration certificate.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{setGst(!gst)}} src="/img/document-fail.png" alt="file" id="toggleDesc-3" />
                                </span>
                            </a>
                        </div>
                        {gst &&
                        <div id="rejectionReason-3" style={{  marginBottom: 10 }}>
                            <label htmlFor="rejection-description-3">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-3"
                                name="rejection-description-3"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Letter of Authorization to Committed</label>
                            <a className="folder_main_update">
                            
                                Letter of Authorization.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{
                                        setLetter(!letter)
                                    }} src="/img/document-fail.png" alt="file" id="toggleDesc-4" />
                                </span>
                            </a>
                        </div>
                        {
                            letter &&

                        <div id="rejectionReason-4" style={{ marginBottom: 10 }}>
                            <label htmlFor="rejection-description-4">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-4"
                                name="rejection-description-4"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>IEC Certificate Copy</label>
                            <a className="folder_main_update">
                            
                                IEC Certificate.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{setIec(!iec)}} src="/img/document-fail.png" alt="file" id="toggleDesc-5" />
                                </span>
                            </a>
                        </div>
                        {
                            iec &&

                        <div id="rejectionReason-5" style={{ marginBottom: 10 }}>
                            <label htmlFor="rejection-description-5">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-5"
                                name="rejection-description-5"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Copy of cancelled cheque</label>
                            <a className="folder_main_update">
                            
                                Copy of cancelled cheque.pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                    <img onClick={()=>{
                                        setCheque(!cheque)
                                    }} src="/img/document-fail.png" alt="file" id="toggleDesc-6" />
                                </span>
                            </a>
                        </div>
                        {
                            cheque &&

                        <div id="rejectionReason-6" style={{  marginBottom: 10 }}>
                            <label htmlFor="rejection-description-6">Reason for Rejection:</label>
                            <textarea
                                id="rejection-description-6"
                                name="rejection-description-6"
                                className="form-control"
                                placeholder="Please provide a reason for rejection here..."
                                defaultValue={""}
                            />
                        </div>
                        }
                    </div>
                </div>
            </form>

        </>
    )
}

export default Documentation