const seekerInitialState = {
    //product-type
    loadingProductTypeApi: true,
    findProductTypeDataError: "",
    findProductTypeData: [],

    //shipment-type
    loadingShipmentTypeApi: true,
    findShipmentTypeData: [],
    findShipmentTypeDataError: '',

    //shipment-mode
    loadingShipmentModeApi: true,
    findShipmentModeData: [],
    findShipmentModeDataError: '',

    //shipment-via
    loadingShipmentViaApi: true,
    findShipmentViaData: [],
    findShipmentViaDataError: '',

    //cargo-type
    loadingCargoTypeApi: true,
    findCargoTypeData: [],
    findCargoTypeDataError: '',

    //department type
    loadingDepartmentTypeApi: true,
    findDepartmentTypeData: [],
    findDepartmentTypeDataError: '',

    //id proof type
    loadingIdProofTypeApi: true,
    findIdProofTypeData: [],
    findIdProofTypeDataError: '',

    //package type
    loadingPackageTypeApi: true,
    findPackageTypeData: [],
    findPackageTypeDataError: '',

    //gender type
    loadingGenderTypeApi: true,
    findGenderTypeData: [],
    findGenderTypeDataError: '',

    //designation type
    loadingDesignationTypeApi: true,
    findDesignationTypeData: [],
    findDesignationTypeDataError: '',

    //weight unit
    loadingWeightUnitTypeApi: true,
    findWeightUnitTypeData: [],
    findWeightUnitTypeDataError: '',

    //required service
    loadingRequiredServiceTypeApi: true,
    findRequiredServiceTypeData: [],
    findRequiredServiceTypeDataError: '',

    //view-rates
    loadingViewRate: true,
    viewRateData: [],
    viewRateError: "",

    //download quotation
    loadingDownloadQuotation: true,
    DownloadQuotationData: [],
    DownloadQuotationDataError: '',

    //profile page
    loadingProfile: true,
    ProfileData: [],
    ProfileDataError: '',


}

const MasterReducer = (state = seekerInitialState, action) => {
    switch (action.type) {
        //product-type//
        case 'FIND_PRODUCT_PAGE_REQUEST':
            return {
                ...state,
                loadingProductTypeApi: true,
                findProductTypeData: "",
                findProductTypeDataError: '',
            };
        case 'FIND_PRODUCT_PAGE_SUCCESS':
            return {
                ...state,
                loadingProductTypeApi: false,
                findProductTypeData: action.payload,
            };
        case 'FIND_PRODUCT_PAGE_FAILURE':
            return {
                ...state,
                loadingProductTypeApi: false,
                findProductTypeDataError: action.payload,
            };
        //product-type//

        //shipment-type//
        case 'FIND_SHIPMENT_PAGE_REQUEST':
            return {
                ...state,
                loadingShipmentTypeApi: true,
                findShipmentTypeData: "",
                findShipmentTypeDataError: '',
            };
        case 'FIND_SHIPMENT_PAGE_SUCCESS':
            return {
                ...state,
                loadingShipmentTypeApi: false,
                findShipmentTypeData: action.payload,
            };
        case 'FIND_SHIPMENT_PAGE_FAILURE':
            return {
                ...state,
                loadingShipmentTypeApi: false,
                findShipmentTypeDataError: action.payload,
            };
        //shipment-type//

        //shipment-mode//
        case 'FIND_SHIPMENTMODE_PAGE_REQUEST':
            return {
                ...state,
                loadingShipmentModeApi: true,
                findShipmentModeData: "",
                findShipmentModeDataError: '',
            };
        case 'FIND_SHIPMENTMODE_PAGE_SUCCESS':
            return {
                ...state,
                loadingShipmentModeApi: false,
                findShipmentModeData: action.payload,
            };
        case 'FIND_SHIPMENTMODE_PAGE_FAILURE':
            return {
                ...state,
                loadingShipmentModeApi: false,
                findShipmentModeDataError: action.payload,
            };
        //shipment-mode//

        //shipment-via//
        case 'FIND_SHIPMENTVIA_PAGE_REQUEST':
            return {
                ...state,
                loadingShipmentViaApi: true,
                findShipmentViaData: "",
                findShipmentViaDataError: '',
            };
        case 'FIND_SHIPMENTVIA_PAGE_SUCCESS':
            return {
                ...state,
                loadingShipmentViaApi: false,
                findShipmentViaData: action.payload,
            };
        case 'FIND_SHIPMENTVIA_PAGE_FAILURE':
            return {
                ...state,
                loadingShipmentViaApi: false,
                findShipmentViaDataError: action.payload,
            };
        //shipment-via//

        //CARGO TYPE//
        case 'FIND_CARGO_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingCargoTypeApi: true,
                findCargoTypeData: "",
                findCargoTypeDataError: '',
            };
        case 'FIND_CARGO_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingCargoTypeApi: false,
                findCargoTypeData: action.payload,
            };
        case 'FIND_CARGO_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingCargoTypeApi: false,
                findCargoTypeDataError: action.payload,
            };
        //CARGO TYPE//

        //DEPARTMENT TYPE//
        case 'FIND_DEPARTMENT_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingDepartmentTypeApi: true,
                findDepartmentTypeData: "",
                findDepartmentTypeDataError: '',
            };
        case 'FIND_DEPARTMENT_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingDepartmentTypeApi: false,
                findDepartmentTypeData: action.payload,
            };
        case 'FIND_DEPARTMENT_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingDepartmentTypeApi: false,
                findDepartmentTypeDataError: action.payload,
            };
        //DEPARTMENT TYPE//

        //ID PROOF//
        case 'FIND_ID_PROOF_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingIdProofTypeApi: true,
                findIdProofTypeData: "",
                findIdProofTypeDataError: '',
            };
        case 'FIND_ID_PROOF_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingIdProofTypeApi: false,
                findIdProofTypeData: action.payload,
            };
        case 'FIND_ID_PROOF_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingIdProofTypeApi: false,
                findIdProofTypeDataError: action.payload,
            };
        //ID PROOF//

        //PACKAGE//
        case 'FIND_PACKAGE_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingPackageTypeApi: true,
                findPackageTypeData: "",
                findPackageTypeDataError: '',
            };
        case 'FIND_PACKAGE_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingPackageTypeApi: false,
                findPackageTypeData: action.payload,
            };
        case 'FIND_PACKAGE_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingPackageTypeApi: false,
                findPackageTypeDataError: action.payload,
            };
        //PACKAGE//


        //gender//
        case 'FIND_GENDER_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingGenderTypeApi: true,
                findGenderTypeData: "",
                findGenderTypeDataError: '',
            };
        case 'FIND_GENDER_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingGenderTypeApi: false,
                findGenderTypeData: action.payload,
            };
        case 'FIND_GENDER_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingGenderTypeApi: false,
                findGenderTypeDataError: action.payload,
            };
        //gender//

        //DESIGNATION//
        case 'FIND_DESIGNATION_TYPE_PAGE_REQUEST':
            return {
                ...state,
                loadingDesignationTypeApi: true,
                findDesignationTypeData: "",
                findDesignationTypeDataError: '',
            };
        case 'FIND_DESIGNATION_TYPE_PAGE_SUCCESS':
            return {
                ...state,
                loadingDesignationTypeApi: false,
                findDesignationTypeData: action.payload,
            };
        case 'FIND_DESIGNATION_TYPE_PAGE_FAILURE':
            return {
                ...state,
                loadingDesignationTypeApi: false,
                findDesignationTypeDataError: action.payload,
            };
        //DESIGNATION//

        //WeightUnit//
        case 'FIND_WEIGHT_UNIT_PAGE_REQUEST':
            return {
                ...state,
                loadingWeightUnitTypeApi: true,
                findWeightUnitTypeData: "",
                findWeightUnitTypeDataError: '',
            };
        case 'FIND_WEIGHT_UNIT_PAGE_SUCCESS':
            return {
                ...state,
                loadingWeightUnitTypeApi: false,
                findWeightUnitTypeData: action.payload,
            };
        case 'FIND_WEIGHT_UNIT_PAGE_FAILURE':
            return {
                ...state,
                loadingWeightUnitTypeApi: false,
                findWeightUnitTypeDataError: action.payload,
            };
        //WeightUnit//

        //RequiredService//
        case 'FIND_REQUIRED_SERVICE_PAGE_REQUEST':
            return {
                ...state,
                loadingRequiredServiceTypeApi: true,
                findRequiredServiceTypeData: "",
                findRequiredServiceTypeDataError: '',
            };
        case 'FIND_REQUIRED_SERVICE_PAGE_SUCCESS':
            return {
                ...state,
                loadingRequiredServiceTypeApi: false,
                findRequiredServiceTypeData: action.payload,
            };
        case 'FIND_REQUIRED_SERVICE_PAGE_FAILURE':
            return {
                ...state,
                loadingRequiredServiceTypeApi: false,
                findRequiredServiceTypeDataError: action.payload,
            };
        //RequiredService//

        //view rate//
        case 'FIND_VIEW_RATE_REQUEST':
            return {
                ...state,
                loadingViewRate: true,
                viewRateData: "",
                viewRateDataError: '',
            };
        case 'FIND_VIEW_RATE_SUCCESS':
            return {
                ...state,
                loadingViewRate: false,
                viewRateData: action.payload,
            };
        case 'FIND_VIEW_RATE_FAILURE':
            return {
                ...state,
                loadingViewRate: false,
                viewRateDataError: action.payload,
            };
        //view rate//
        //download quotation//
        case 'FIND_DOWNLOAD_QUOTATION_REQUEST':
            return {
                ...state,
                loadingDownloadQuotation: true,
                DownloadQuotationData: "",
                DownloadQuotationDataError: '',
            };
        case 'FIND_DOWNLOAD_QUOTATION_SUCCESS':
            return {
                ...state,
                loadingDownloadQuotation: false,
                DownloadQuotationData: action.payload,
            };
        case 'FIND_DOWNLOAD_QUOTATION_FAILURE':
            return {
                ...state,
                loadingDownloadQuotation: false,
                DownloadQuotationDataError: action.payload,
            };
        //download quotation//

        //profile page//
        case 'FIND_PROFILE_PAGE_REQUEST':
            return {
                ...state,
                loadingProfile: true,
                ProfileData: "",
                ProfileDataError: '',
            };
        case 'FIND_PROFILE_PAGE_SUCCESS':
            return {
                ...state,
                loadingProfile: false,
                ProfileData: action.payload,
            };
        case 'FIND_PROFILE_PAGE_FAILURE':
            return {
                ...state,
                loadingProfile: false,
                ProfileDataError: action.payload,
            };
        //profile page//

        default:
            return state;
    }
}

export default MasterReducer;