import React from "react";
import { Link } from "react-router-dom";

const QueryCRM = () => {
  return (
    <main className="main users chart-page" id="skip-target">
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="main-nav-det">
                <h2>CRM</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="button-det-list">
                <Link to="#"> Add</Link>
              </div>
            </div>
          </div>
          <div className="Air_Export_Form_bg">
            <div className="new_infometion_table Air_Export_bg_main none_border">
              <div className="table-responsive">
                <table className="posts-table">
                  <tbody>
                    <tr>
                      <td className="table_info_color" width="50%">
                        <p>Request Number</p>
                      </td>
                      <td width="50%">
                        <p>#1234</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Request Date</p>
                      </td>
                      <td>
                        <p>12-May-2024</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Validity Period</p>
                      </td>
                      <td>
                        <p>20-may-2024</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Client Information</p>
                      </td>
                      <td>
                        <p>
                          {" "}
                          <span className="color_main_alex">Name: -</span> Alex
                          Jones
                        </p>
                        <p>
                          {" "}
                          <span className="color_main_alex">
                            Contact Details: -
                          </span>{" "}
                          +91 12312412412
                        </p>
                        <p>
                          {" "}
                          <span className="color_main_alex">
                            Any Other Information: -
                          </span>
                          Lorem Ipsum&nbsp;is simply dummy text of the printing
                          and typesetting industry.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Delivery Information</p>
                      </td>
                      <td>
                        <p>
                          Details regarding delivery or shipping, including
                          estimated delivery dates and any associated costs.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Total Amount</p>
                      </td>
                      <td>
                        <p>$120000</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Additional Charges</p>
                      </td>
                      <td>
                        <p>
                          Any additional charges or fees not included in the
                          quoted items/services.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="table_info_color">
                        <p>Quoted Items/Services</p>
                      </td>
                      <td>
                        <p>
                          A list of items or services included in the quotation,
                          along with descriptions, quantities, unit prices, and
                          total prices for each.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="mar_none_Summary">
            <div className="row ">
              <div className="col-md-6 dr_left">
                <div className="Summary_Sender_bg bg_left_color">
                  <h4>Summary of Sender mail conversation</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Nisi arcu sapien
                    tortor id eget. Mattis vehicula tellus accumsan enim
                    egestas. Ut nisi amet ac curabitur. Netus vulputate feugiat
                    pulvinar ac.
                  </p>
                </div>
              </div>
            </div>
            <div className="row dr_right">
              <div className="col-md-6">
                <div className="Summary_Sender_bg bg_right_color">
                  <h4>Summary of Receiver mail conversation</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Nisi arcu sapien
                    tortor id eget. Mattis vehicula tellus accumsan enim
                    egestas. Ut nisi amet ac curabitur. Netus vulputate feugiat
                    pulvinar ac.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
              <div className="email-list">
                <div className="email-item">
                  <div className="email-content">
                    <div className="email-header">
                      <h4>Client Name</h4>
                      <span className="email-date">Jul 12</span>
                    </div>
                    <div className="email-description mt-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Cupiditate quod ex beatae nobis, aspernatur excepturi
                      ipsam dolores nesciunt sit atque aut corrupti! Pariatur
                      sequi iure error, architecto perferendis sit optio?
                    </div>
                  </div>
                </div>
                <div className="email-item">
                  <div className="email-content">
                    <div className="email-header">
                      <h4>Client Name</h4>
                      <span className="email-date">Jul 12</span>
                    </div>
                    <div className="email-description mt-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Cupiditate quod ex beatae nobis, aspernatur excepturi
                      ipsam dolores nesciunt sit atque aut corrupti! Pariatur
                      sequi iure error, architecto perferendis sit optio?
                    </div>
                  </div>
                </div>
                <div className="email-item">
                  <div className="email-content">
                    <div className="email-header">
                      <h4>Client Name</h4>
                      <span className="email-date">Jul 12</span>
                    </div>
                    <div className="email-description mt-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Cupiditate quod ex beatae nobis, aspernatur excepturi
                      ipsam dolores nesciunt sit atque aut corrupti! Pariatur
                      sequi iure error, architecto perferendis sit optio?
                    </div>
                  </div>
                </div>
                <div className="email-item">
                  <div className="email-content">
                    <div className="email-header">
                      <h4>Client Name</h4>
                      <span className="email-date">Jul 12</span>
                    </div>
                    <div className="email-description mt-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Cupiditate quod ex beatae nobis, aspernatur excepturi
                      ipsam dolores nesciunt sit atque aut corrupti! Pariatur
                      sequi iure error, architecto perferendis sit optio?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default QueryCRM;
