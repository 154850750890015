import React from 'react'
import { BeatLoader } from 'react-spinners'

const Beatloader = () => {
    return (
        <>
            <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>
                <BeatLoader />
            </div>
        </>
    )
}

export default Beatloader