import React from 'react'

const BankDetails = () => {
  return (
    <>
       <form action="#">
                                        <div className="main-nav-det">
                                            <h4>Bank Account Details </h4>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <div className="input_box_section ">
                                                    <label>Bank Name </label>
                                                    <input
                                                        type="text"
                                                        className="form-control color_place"
                                                        placeholder="Enter here"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box_section ">
                                                    <label>Bank Address </label>
                                                    <input
                                                        type="text"
                                                        className="form-control color_place"
                                                        placeholder="Enter here"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box_section ">
                                                    <label>Beneficiary Name </label>
                                                    <input
                                                        type="text"
                                                        className="form-control color_place"
                                                        placeholder="Enter here"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box_section ">
                                                    <label>Account No. </label>
                                                    <input
                                                        type="text"
                                                        className="form-control color_place"
                                                        placeholder="Enter here"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box_section ">
                                                    <label>IFSC Code </label>
                                                    <input
                                                        type="text"
                                                        className="form-control color_place"
                                                        placeholder="Enter here"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Bank Name</label>
          <input
            type="text"
            className="form-control color_place"
            placeholder="City Bank"
            readOnly
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Bank Address</label>
          <input
            type="text"
            className="form-control color_place"
            placeholder="H-103, Nehru Nagar, Ghaziabad"
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Beneficiary Name</label>
          <input
            type="text"
            className="form-control color_place"
            placeholder="John"
            readOnly
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Account No.</label>
          <input
            type="text"
            className="form-control color_place"
            placeholder="1234 1234 1234"
            readOnly
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>IFSC Code</label>
          <input
            type="text"
            className="form-control color_place"
            placeholder="123456"
            readOnly
          />
        </div>
      </div>
    </div>
                                    </form>
    </>
  )
}

export default BankDetails