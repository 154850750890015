import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DueDates() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade"
            id="due-dates"
            role="tabpanel"
            aria-labelledby="due-dates-tab"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Provisional Cost Details</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Select Document Type</label>
                            <select name="#" id="#">
                                <option value="#">Select</option>
                                <option value="#">Document 1</option>
                                <option value="#">Document 2</option>
                                <option value="#">Document 3</option>
                                <option value="#">Document 4</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>Due Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="button-shipment-registration d-flex mt-2">
                <div className="btn btn1">
                    Set Due Date
                </div>
            </div>
            <div className="border_divider mt-3" />
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Activity Details</h4>
                    </div>
                </div>
            </div>
            <div className="scroll_listing_detail">
                <div className="users-table ">
                    <table className="posts-table">
                        <thead>
                            <tr className="users-table-info">
                                <th>Select</th>
                                <th>Docket No.</th>
                                <th>Bill Type</th>
                                <th>Due Date</th>
                                <th>Enter By</th>
                                <th>Enter Date</th>
                                <th>Modified Date</th>
                                <th>Modified By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/5104/24</td>
                                <td className="" />
                                <td className="">12/04/2024</td>
                                <td className="" />
                                <td className="">12/04/2022</td>
                                <td className="">29/04/2024</td>
                                <td className="">Admin</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_list_next_page">
                        <ul>
                            <li>
                                {" "}
                                <a href="#">
                                    <img src="/img/right.png" alt="right" />
                                </a>{" "}
                            </li>
                            <li className="main_list_next_list active">
                                <a href="#">1</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">2</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">3</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">4</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">5</a>
                            </li>
                            <li className="main_list_next_list img_icon_sec">
                                <a href="#">
                                    <img src="/img/Menu-icon.png" alt="manu" />
                                </a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">10</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">11</a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="/img/left.png" alt="left" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DueDates