import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
// import KycApprovalRequest from '../../Kyc_Onboarding/Dashboard/KycApprovalRequest';
import SideBar from './SideBar';
import Navbar from './Navbar';
// import CustomerRegistration from '../../Kyc_Onboarding/Dashboard/KycRegistrationForm/KycRegistration';
// import RegistrationAgreement from '../../Kyc_Onboarding/Dashboard/KycRegistrationAgreement/RegistrationAgreement';
import QuoteRequestForm from './QuoteRequestForm/QuoteRequestForm';
import QueryList from './QueryList/QueryList';
import AllRateRequest from './AllRateRequest/AllRateRequest';
import EmployeeDashboard from './Employeedashboard/EmployeeDashboard';
import ViewRate from './ViewRate/ViewRate';
import QueryInfoGiveRate from './QueryInfoGiveRate/QueryInfoGiveRate';
import QueryInfo from './QueryInfo/QueryInfo';
import QuotationAirExportForm from './QuotationAirExportForm/QuotationAirExportForm';
import QuotationAirImportForm from './QuotationAirImportForm/QuotationAirImportForm';
import QuotationSeaExportForm from './QuotationSeaExportForm/QuotationSeaExportForm';
import QuotationSeaImportForm from './QuotationSeaImportForm/QuotationSeaImportForm';
import QuotationConverted from './QuotationConverted/QuotationConverted';
import QueryFollowUpForm from './QueryFollowUpForm/QueryFollowUpForm';
import QueryFollowUpSummary from './QueryFollowUpSummary/QueryFollowUpSummary';
import PickedLeads from './PickedLeads/PickedLeads';
import ConvertedLeads from './ConvertedLeads/ConvertedLeads';
import NonConvertedLeads from './NonConvertedLeads/NonConvertedLeads';
import QueryInfoPicked from './QueryInfoPicked/QueryInfoPicked';
import QueryInfoConverted from './QueryInfoConverted/QueryInfoConverted';
import QueryInfoNonConverted from './QueryInfoNonConverted/QueryInfoNonConverted';
import QueryFollowUpSummaryConverted from './QueryFollowUpSummaryConverted/QueryFollowUpSummaryConverted';

const Dashboard = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [userdata, setUserData] = useState({})
  const userData = sessionStorage.getItem('userData');
  useEffect(() => {
    if (userData) {
      setUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      <div className="page-flex">
        {/* ! Sidebar */}
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div className="main-wrapper">
          {/* ! Main nav */}
          <Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} userdata={userdata} />
          {/* ! Main */}
          <main className="main users chart-page" id="skip-target">
            <Routes>
              <Route index element={<Navigate to="dashboard" />} />
              {/* <Route path='Kyc-approval-request' element={<KycApprovalRequest/>} /> */}
              {/* <Route path='customer-registration-form' element={<CustomerRegistration/>} /> */}
              {/* <Route path='customer-registration-agreement' element={<RegistrationAgreement/>} /> */}
              <Route path='query-info-give-rates' element={<QueryInfoGiveRate />} />
              <Route path='quote-request-form' element={<QuoteRequestForm />} />
              <Route path='query-list' element={<QueryList />} />
              <Route path='all-rate-request' element={<AllRateRequest />} />
              <Route path='dashboard' element={<EmployeeDashboard />} />
              <Route path='view-rates' element={<ViewRate />} />
              <Route path='query-information' element={<QueryInfo />} />
              <Route path='quotation-air-export' element={<QuotationAirExportForm />} />
              <Route path='quotation-air-import' element={<QuotationAirImportForm />} />
              <Route path='quotation-sea-export' element={<QuotationSeaExportForm />} />
              <Route path='quotation-sea-import' element={<QuotationSeaImportForm />} />
              <Route path='quotation-converted' element={<QuotationConverted />} />
              <Route path='query-follow-up-form' element={<QueryFollowUpForm />} />
              <Route path='query-follow-up-summary' element={<QueryFollowUpSummary />} />
              <Route path='picked-leads' element={<PickedLeads />} />
              <Route path='converted-leads' element={<ConvertedLeads />} />
              <Route path='non-converted-leads' element={<NonConvertedLeads />} />
              <Route path='query-info-picked' element={<QueryInfoPicked />} />
              <Route path='query-info-converted' element={<QueryInfoConverted />} />
              <Route path='query-info-non-converted' element={<QueryInfoNonConverted />} />
              <Route path='query-follow-up-summary-converted' element={<QueryFollowUpSummaryConverted />} />
            </Routes>

          </main>
        </div>
      </div>
    </div>
  )
}

export default Dashboard