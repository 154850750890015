import React from 'react'

export const Documentation = () => {
  return (
    <div id="section3" className="section3">
    <form action="#">
      <div className="main-nav-det">
        <h4>Documents Required </h4>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>PAN Card of Authorized Signatory</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>Copy of Aadhaar Card of Authorized Signatory</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>Rent Agreement/ Address Proof of Office premises</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>GST registration certificate</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>Letter of Authorization to Committed</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>IEC Certificate Copy</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input_box_section file-input-main">
            <label>Copy of cancelled cheque</label>
            <input
              type="file"
              name="userfile"
              className="form-control"
              placeholder="Upload Here"
            />
            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
          </div>
        </div>
        <div className="col-md-12 mt-2">
          Note: Please ensure that your file does not exceed 2 MB.
        </div>
      </div>
    </form>
  </div>
  
  )
}
