import React from 'react'

function ShipmentMapping() {
    return (
        <div
            className="tab-pane fade"
            id="shipment-mapping"
            role="tabpanel"
            aria-labelledby="shipment-mapping-tab"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Shipment Details</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Docket Number</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="NDL/AE/5104/24"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Shipper</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="NDL/AE/5104/24"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Pkgs</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>Gross Weight</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section">
                            <label>EDI Location</label>
                            <select name="#" id="#">
                                <option value="#">Select Country</option>
                                <option value="#">ACC, Ahemdabad</option>
                                <option value="#">ACC, Banglore</option>
                                <option value="#">ACC, Mumbai</option>
                                <option value="#">ACC, Kolkata</option>
                                <option value="#">ACC, New Delhi</option>
                                <option value="#">ACC Hyderabad</option>
                                <option value="#">ACC Jaipur</option>
                                <option value="#">ACC, Trivendrum</option>
                                <option value="#">Amritsar ACC</option>
                                <option value="#">Indore</option>
                                <option value="#">Kattupali</option>
                                <option value="#">Pithampur</option>
                                <option value="#">Indhp1</option>
                                <option value="#">Dabhol</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <div className="scroll_listing_detail">
                <div className="users-table ">
                    <table className="posts-table">
                        <thead>
                            <tr className="users-table-info">
                                <th>Select</th>
                                <th>Job No.</th>
                                <th>Job Date</th>
                                <th>Shipper</th>
                                <th>SB No.</th>
                                <th>SB Date</th>
                                <th>Origin</th>
                                <th>Dstn</th>
                                <th>PKGs</th>
                                <th>Gr Wt</th>
                                <th>Consignee</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">KH/22-23/3099</td>
                                <td className="">29/04/2022</td>
                                <td className="" />
                                <td className="">Hongkong</td>
                                <td className="">1</td>
                                <td className="">3.5</td>
                                <td className="">K&amp;T GMBH</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_list_next_page">
                        <ul>
                            <li>
                                {" "}
                                <a href="#">
                                    <img src="/img/right.png" alt="right" />
                                </a>{" "}
                            </li>
                            <li className="main_list_next_list active">
                                <a href="#">1</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">2</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">3</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">4</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">5</a>
                            </li>
                            <li className="main_list_next_list img_icon_sec">
                                <a href="#">
                                    <img src="/img/Menu-icon.png" alt="manu" />
                                </a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">10</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">11</a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="/img/left.png" alt="left" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="button-shipment-registration d-flex mt-2">
                <div className="btn">
                    Map
                </div>
            </div>
        </div>
    )
}

export default ShipmentMapping