import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function AirExport() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <main className="main users chart-page" id="skip-target">
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>Docket Creation Request</h2>
                                </div>
                            </div>

                        </div>
                        {/* =============Search bar============= */}
                        <div className="main_detail_filter">
                            <form action="#">
                                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                                <div className="row ">
                                    <div className="col-lg-8">
                                        <div className="search_main_filter">
                                            <div className="row main_row">
                                                <div className="col-md-4">
                                                    <div className="input_box_filet filter_icon">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search"
                                                        />
                                                        <span>
                                                            <img src="/img/search.png" alt="" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 ">
                                                    <div className="dropdown dropdown-filter-section">
                                                        <Link
                                                            className="dropdown-toggle input_box_id_button"
                                                            to="#"
                                                            role="button"
                                                            id="dropdownMenuLink"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <span>
                                                                <img src="/img/bi_filter.png" alt="" />
                                                            </span>{" "}
                                                            Filter
                                                        </Link>
                                                        <div
                                                            className="dropdown-menu main_drop_filter"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <table className="content-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">REQUEST NO.</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Request No."
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">DATE &amp; TIME</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Date"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">NAME</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Name"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">EMAIL</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Email"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">PHONE NUMBER</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Number"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">ORIGIN</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Origin"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">DESTINATION</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Destination"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">SHIPMENT TYPE</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Shipment Type"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">SHIPMENT VIA</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Shipment Via"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="#">PICKED BY</label>
                                                                        </td>
                                                                        <td className="input_box_filter">
                                                                            {" "}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Picked By"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <Link to="#">Apply</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 ">
                                                    <div className="input_box_section por_main_detail-date custom-datepicker">
                                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                        <span>
                                                            <img src="/img/date_icon.png" alt="Calendar" style={{ top: "20px" }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 ">
                                                    <div className="input_box_section por_main_detail-date custom-datepicker">
                                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                        <span>
                                                            <img src="/img/date_icon.png" alt="Calendar" style={{ top: "20px" }} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* =============Search bar============= */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll_listing_detail">
                                    <div className="users-table ">
                                        <table className="posts-table">
                                            <thead>
                                                <tr className="users-table-info">
                                                    <th>Quotation No.</th>
                                                    <th>Date &amp; Time</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Origin</th>
                                                    <th>Destination</th>
                                                    <th>Approved By</th>
                                                    <th>Converted By</th>
                                                    <th>Quotation Summary</th>
                                                    <th>Document</th>
                                                    <th>Docket Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>#1234</td>
                                                    <td>
                                                        12 May 2024 <br /> 09:12 pm
                                                    </td>
                                                    <td> Dummy Name</td>
                                                    <td>ramesh@gmail.com</td>
                                                    <td>+91 1232423443</td>
                                                    <td>Delhi</td>
                                                    <td> London</td>
                                                    <td>Raj Sharma</td>
                                                    <td>Ravi Sharma</td>
                                                    <td>
                                                        <Link to="/admin-dashboard/query-follow-up-summary" className="d-flex justify-content-center align-items-center py-2">
                                                            <div
                                                                type="button"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="View Summary"
                                                            >
                                                                <img
                                                                    src="/img/viewSummary.png"
                                                                    alt="view"
                                                                    className="pointer w-fit border-radius-none"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to="" className="view_info_button">
                                                            View Documents
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to="/admin-dashboard/freight-forwarding/docket-number-creation-export"
                                                            className="view_info_button"
                                                        >
                                                            Docket Number Creation
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="main_list_next_page">
                                            <ul>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <img src="/img/right.png" alt="right" />
                                                    </Link>{" "}
                                                </li>
                                                <li className="main_list_next_list active">
                                                    <Link to="#">1</Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">2</Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">3</Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">4</Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">5</Link>
                                                </li>
                                                <li className="main_list_next_list img_icon_sec">
                                                    <Link to="#">
                                                        <img src="/img/Menu-icon.png" alt="manu" />
                                                    </Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">10</Link>
                                                </li>
                                                <li className="main_list_next_list">
                                                    <Link to="#">11</Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <img src="/img/left.png" alt="left" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AirExport