import * as Yup from "yup";

export const validationLoginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter your email address."),
  password: Yup.string()
    .required("Please enter your password.")
    .min(6, "Password must be at least 6 character"),
  role_id: Yup.string().required('Please Select Your Role'),
  agreement: Yup.bool().oneOf([true], 'You must accept the terms and conditions')
});
export const adminLoginSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter your email address."),
  password: Yup.string()
    .required("Please enter your password.")
    .min(4, "Password must be at least 4 character"),
  role_id: Yup.string().required('Please select role'),
  // agreement: Yup.bool().oneOf([true], 'You must accept the terms and conditions')
});

export const validationCreateServiceSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter full name "),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone Number must contain exactly 10 digits")
    .required("Please enter your mobile number"),
  email: Yup.string()
    .trim("Email cannot have leading or trailing spaces.")
    .email("Please enter a valid email address.")
    .required("Please enter your email address."),
  // request_via: Yup.string().required("Please enter request via "),
  origin: Yup.string().required("Please enter origin "),
  destination: Yup.string().required("Please enter destination"),
  product_name: Yup.string().required("Please enter product name"),
  product_type_id: Yup.string().required("Please select product type"),
  shipment_type_id: Yup.string().required("Please select shipment type"),
  shipment_mode_id: Yup.string().required("Please select shipment mode"),
  shipment_via_id: Yup.string().required("Please select shipment via"),
  cargo_type_id: Yup.string().required("Please select cargo type"),
  required_servie_id: Yup.string().required("Please select service"),
  expected_transit_date: Yup.date().required("Please enter expected transit date"),
  detailed_requirement: Yup.string().required("Please enter detailed requirement"),
  agreement: Yup.number()
    .oneOf([1], 'You must accept the terms and conditions')
    .required('Agreement is required'),
  package_info: Yup.array().of(
    Yup.object({
      package_type_id: Yup.string().required("Please enter package type"),
      // length: Yup.number().required("Please enter length"),
      length: Yup.string()
        .required('Please enter dimensions')
        .matches(/^\d+$/, 'Length must be a number'),
      width: Yup.string().when('length', {
        is: (length) => length,
        then: Yup.string()
          .required('Width is required')
          .matches(/^\d+$/, 'Width must be a number'),
      }),
      height: Yup.string().when(['length', 'width'], {
        is: (length, width) => length && width,
        then: Yup.string()
          .required('Height is required')
          .matches(/^\d+$/, 'Height must be a number'),
      }),
      width: Yup.number().required("Please enter dimensions "),
      height: Yup.number().required("Please enter dimensions "),
      max_weight: Yup.number().required("Please enter max weight"),
      max_weight_unit_id: Yup.string().required("Please enter max_weight_unit_id"),
      gross_weight: Yup.number().required("Please enter gross weight"),
      gross_weight_unit_id: Yup.number().required("Please enter gross weight unit id"),
      total_boxes: Yup.number().required("please enter quantity"),


    })
  ).notRequired()
});

export const uploadDocumentSchema = Yup.object().shape({
  userfile: Yup.mixed().notRequired('A file is required'),
  uservideo: Yup.mixed().notRequired('A video is required')
});
export const uploadImageSchema = Yup.object().shape({
  images: Yup.mixed().notRequired('A file is required'),
});
export const uploadVideoSchema = Yup.object().shape({
  images: Yup.mixed().notRequired('A video is required'),
});
export const RateRequestSchema = Yup.object().shape({
  shipment_info: Yup.object().shape({
    quotation_number: Yup.string().required('Please enter Quotation Number '),
    date_of_creation: Yup.date().required('Please enter Date of Creation '),
    buy_ref_number: Yup.string().required('Please enter Buying Reference Number '),
    rate_validity: Yup.date().required('Please enter Rate Validity '),
    validity_against: Yup.string().required('Please enter Validity Against '),
    quotation_rise_in: Yup.string().required('Please enter Quotation Rise in '),
  }).notRequired(),
  company_info: Yup.object().shape({
    company_name: Yup.string().required('Please enter Company Name '),
    address: Yup.string().required('Please enter Address '),
    phone_number: Yup.string()
      .matches(/^\d{10}$/, "Phone Number must contain exactly 10 digits")
      .required("Please enter Phone Number Number "),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
    gstin: Yup.string().required('Please enter GSTIN '),
    country: Yup.string().required('Please enter Country '),
    state: Yup.string().required('Please enter State '),
  }).notRequired(),
  general_info: Yup.object().shape({
    company_name: Yup.string().required('Please enter Company Name '),
    address: Yup.string().required('Please enter Address '),
    billing_account: Yup.string().required('Please enter Billing Account '),
    attention_to: Yup.string().required('Please enter Attention To '),
    airline: Yup.string().required('Please enter Airline '),
    place_of_receipt: Yup.string().required('Please enter Place of Receipt '),
    commodity: Yup.string().required('Please enter Commodity '),
    airport_of_loading: Yup.string().required('Please enter Airport of Loading '),
    cargo_weight: Yup.string().required('Please enter Cargo Weight '),
    airport_of_discharge: Yup.string().required('Please enter Airport of Discharge '),
    incoterms: Yup.string().required('Please enter Incoterms '),
    tentative_transit_time: Yup.date().required('Please enter Tentative Transit Time '),
    final_delivery_place: Yup.string().required('Please enter Final Place of Delivery '),
    notes: Yup.string().required('Please enter Notes '),
  }).notRequired(),
  tarrif_details: Yup.array().of(
    Yup.object().shape({
      airline_name: Yup.string().required('Please enter Airline Name '),
      charge_head: Yup.string().required('Please enter Charge Head '),
      buying_price: Yup.number().required('Please enter Buying Price '),
      selling_price: Yup.number().required('Please enter Selling Price '),
      narration: Yup.string().required('Please enter Narration '),
      valid_upto: Yup.date().required('Please enter Valid Upto '),
      currency: Yup.string().required('Please enter Currency '),
      exchange_rate: Yup.number().required('Please enter Exchange Rate '),
      unit: Yup.string().required('Please enter Unit '),
      fc_amount: Yup.number().required('Please enter FC Amount '),
      rate: Yup.number().required('Please enter Rate '),
    })
  ).notRequired(),
});
export const traficDetailsSchema = Yup.object({
  airline_name: Yup.string().required('Please enter Airline Name '),
  charge_head: Yup.string().required('Please enter Charge Head '),
  buying_price: Yup.number().required('Please enter Buying Price '),
  selling_price: Yup.number().required('Please enter Selling Price '),
  narration: Yup.string().required('Please enter Narration '),
  valid_upto: Yup.date().required('Please enter Valid Upto '),
  currency: Yup.string().required('Please enter Currency '),
  exchange_rate: Yup.number().required('Please enter Exchange Rate '),
  unit: Yup.string().required('Please enter Unit '),
  fc_amount: Yup.number().required('Please enter FC Amount '),
  rate: Yup.number().required('Please enter Rate '),
});


export const AdminRateRequestSchema = Yup.object().shape({
  ship_quotation_no: Yup.string().required('Please enter quotation number '),
  ship_date_of_creation: Yup.date().required('Please enter date of creation '),
  ship_buy_ref_no: Yup.string().required('Please enter buying reference number '),
  ship_rate_validity: Yup.date().required('Please enter rate validity '),
  // ship_validity_against: Yup.string().required('Please select quotation currency '),
  ship_currency_id: Yup.string().required('Please select quotation currency '),
  ship_quotation_rise_in: Yup.string().required('Please enter quotation rise in '),

  // com_company_name: Yup.string().required('Please enter company name '),
  // com_address: Yup.string().required('Please enter address '),
  // com_phone_no: Yup.string()
  //   .matches(/^\d{10}$/, "Phone Number must contain exactly 10 digits")
  //   .required("Please enter phone number"),
  // com_email: Yup.string()
  //   .email("Please enter a valid email ID")
  //   .required("Please enter your email ID"),
  // com_gstin: Yup.string().required('Please enter GSTIN '),
  // com_country_id: Yup.string().required('Please select country '),
  // com_state_id: Yup.string().required('Please select state '),
  gen_customer_name: Yup.string().required('Please enter company name '),
  gen_address: Yup.string().required('Please enter address '),
  gen_billing_account: Yup.string().required('Please enter billing account '),
  gen_attention_to: Yup.string().required('Please enter attention to '),
  gen_airline: Yup.string().required('Please enter airline '),
  gen_place_of_receipt: Yup.string().required('Please enter place of receipt '),
  gen_commodity: Yup.string().required('Please enter commodity '),
  gen_airport_of_landing: Yup.string().required('Please enter air port of loading '),
  gen_cargo_weight: Yup.string().required('Please select cargo weight '),
  gen_airport_of_discharge: Yup.string().required('Please enter airport of discharge '),
  gen_incoterms: Yup.string().required('Please enter incoterms '),
  gen_tentative_transit_time: Yup.string().required('Please enter tentative transit time '),
  gen_find_place_of_delivery: Yup.string().required('Please enter final place of delivery '),
  gen_notes: Yup.string().required('Please enter notes '),

  tarrifArr: Yup.array().of(
    Yup.object().shape({
      airline_code: Yup.string().required('Please enter airline name '),
      charge_head: Yup.string().required('Please enter charge head '),
      buying_price: Yup.number().required('Please enter buying price '),
      selling_price: Yup.number().required('Please enter selling price '),
      narration: Yup.string().required('Please enter narration '),
      valid_upto: Yup.date().required('Please enter valid upto '),
      currency: Yup.string().required('Please enter currency '),
      exchange_rate: Yup.number().required('Please enter exchange rate '),
      unit_id: Yup.string().required('Please select unit '),
      fc_amount: Yup.number().required('Please enter FC amount '),
      rate: Yup.number().required('Please enter rate '),
      cargo_type_id: Yup.string().required('Please select cargo type '),
    })
  ).notRequired(),
});
export const AdminTraficDetailsSchema = Yup.object({
  airline_code: Yup.string().required('Please enter airline code '),
  charge_head: Yup.string().required('Please enter charge head '),
  buying_price: Yup.number().required('Please enter buying price '),
  selling_price: Yup.number().required('Please enter selling price '),
  narration: Yup.string().required('Please enter narration '),
  valid_upto: Yup.date().required('Please enter valid upto '),
  currency: Yup.string().required('Please enter currency '),
  exchange_rate: Yup.number().required('Please enter exchange rate '),
  unit_id: Yup.string().required('Please enter unit '),
  fc_amount: Yup.number().required('Please enter FC amount '),
  rate: Yup.number().required('Please enter rate '),
  cargo_type_id: Yup.string().required('Please enter unit '),
});

export const remarkSchema = Yup.object().shape({
  remarks: Yup.string().required('Please enter remark'),
 
});