const initialState = {
    //emp List 
    loadingEmpListApi: false,
    empListDataError: "",
    empListData: [],
    empListCurrentPage: 0,
    empListTotalPage: 0,
    empListTotalData: 0,

    //module details 
    loadingRoleModuleDetail: false,
    roleModuleDetailDataError: "",
    roleModuleDetailData: {},
    // roleModuleDetailPermissionData: [],
}

const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {

        //emp List//
        case 'FIND_EMP_LIST_REQUEST':
            return {
                ...state,
                loadingEmpListApi: true,
                empListData: "",
                empListDataError: '',
            };
        case 'FIND_EMP_LIST_SUCCESS':
            return {
                ...state,
                loadingEmpListApi: false,
                empListData: action.payload.data,
                empListCurrentPage: action.payload.pagination.currentPage,
                empListTotalPage: action.payload.pagination.totalPages,
                empListTotalData: action.payload.pagination.totalRecords,
            };
        case 'FIND_EMP_LIST_FAILURE':
            return {
                ...state,
                loadingEmpListApi: false,
                empListDataError: action.payload,
            };
        //emp List//

        //module Detail//
        case 'FIND_ROLE_MODULE_DETAIL_REQUEST':
            return {
                ...state,
                loadingRoleModuleDetail: true,
                roleModuleDetailData: {},
                roleModuleDetailPermissionData: [],
                roleModuleDetailDataError: '',
            };

        case 'FIND_ROLE_MODULE_DETAIL_SUCCESS':
            return {
                ...state,
                loadingRoleModuleDetail: false,
                roleModuleDetailData: action.payload.data,
                // roleModuleDetailPermissionData: action.payload.modules,
            };
        case 'FIND_ROLE_MODULE_DETAIL_FAILURE':
            return {
                ...state,
                loadingRoleModuleDetail: false,
                roleModuleDetailDataError: action.payload,
            };
        //module Detail//


        default:
            return state;
    }
}

export default EmployeeReducer;