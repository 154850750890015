import React, { useState } from 'react'
import Select from 'react-select';

const AirwayBillStockForm = () => {
    const [selectedAirwayBillType, setSelectedAirwayBillType] = useState(null);
    const country = [
        { value: 'India', label: 'India' },
    ]
    const airwayBillType = [
        { value: 'House', label: 'House' },
        { value: 'Normal', label: 'Normal' },
        { value: 'Master', label: 'Master' },
    ]
    const documentType = [
        { value: 'Shipment with documents', label: 'Shipment with documents' },
        { value: 'Shipment without documents', label: 'Shipment without documents' },

    ]
    const ChgsType = [
        { value: "PP", label: "PP" },
        { value: "PC", label: "PC" },
        { value: "CP", label: "CP" },
        { value: "CC", label: "CC" }
    ]
    const CtoType = [
        { value: 'DCSC', label: 'DCSC' },
        { value: 'CELEBI', label: 'CELEBI' },
    ]
    const rateClass = [];
    const kgType = [];
    const handleSelectChange = (selectedOption) => {
        setSelectedAirwayBillType(selectedOption.value);
    };
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className='airway-bill-text mb-3'>Airway Bill Stock </h3>
                    </div>
                </div>
                <form action="#" className='p-4 bg-blur radius-sm'>
                    <div className="bg-white p-4 radius-sm">

                        <div className="row">
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Docket Number</label>
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter here"
                                    />


                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Airway Bill Type</label>
                                    <Select
                                        className='mt-2'
                                        options={airwayBillType}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </div>

                        </div>

                        {
                            selectedAirwayBillType === 'House' &&
                            <div className="row pt-4 mt-3 border-top-2px">
                                <h5 >* All fields are mandatory for sending e-AWB Data</h5>

                                <h4 className='docket-text'>Party Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>AWB NO * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>HAWB NO </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Shipper Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Consignee Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Buyer Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Sub Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Account Info</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>EORI Number</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Documents</label>
                                    <Select
                                        className='mt-2'
                                        options={documentType}
                                    />
                                </div>
                                <h4 className='docket-text'>Flight Bookings / Routing</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Departure * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Destination * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By First Carrier *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>DO No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CTO</label>
                                        <Select
                                            className='mt-2'
                                            options={CtoType}
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Charge Declarations</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Currency </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CHGS Code </label>
                                        <Select
                                            className='mt-2'
                                            options={ChgsType}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Wt/Val(P/C) </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Other(P/C)</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Carriage</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Customs </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Amount of Insurance </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Handling Info</label>
                                        <textarea
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Rate Description</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>No.of Pkg * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Gross Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate/ Charge *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Comm. Item No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Chargeable Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate Class</label>
                                        <Select
                                            className='mt-2'
                                            options={rateClass}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Total Freight</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Kg/ Lb</label>
                                        <Select
                                            className='mt-2'
                                            options={kgType}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Remarks</label>
                                        <textarea class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                                <h4 className='docket-text'>Prepaid / Collect Charge Declaration</h4>
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Prepaid</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Collect </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Weight Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Valuation Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Tax</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Agent</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Carrier</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Charges / Dimension Details</h4>
                                <div className="col-md-6">

                                    <div className="input_box_section file-input-main">
                                        <label>Add Dimension</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Handling Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Carrier Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Agent Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Commodity</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Shipping Bill</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>HS Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Total</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airwaybill Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Clearance/Exec.Dt</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CSR Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Executed At</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center py-3">
                                    <button type='button' className='btn search-btn w-fit '>
                                        Submit</button></div>
                            </div>


                        }
                        {
                            selectedAirwayBillType === 'Normal' &&
                            <div className="row pt-4 mt-3 border-top-2px">
                                <h5 >* All fields are mandatory for sending e-AWB Data</h5>

                                <h4 className='docket-text'>Party Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>AWB NO * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>HAWB NO </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Shipper Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Consignee Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Buyer Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Sub Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Account Info</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>EORI Number</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Documents</label>
                                    <Select
                                        className='mt-2'
                                        options={documentType}
                                    />
                                </div>

                                <h4 className='docket-text'>Flight Bookings / Routing</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Departure * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Destination * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By First Carrier *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>DO No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CTO</label>
                                        <Select
                                            className='mt-2'
                                            options={CtoType}
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Charge Declarations</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Currency </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CHGS Code </label>
                                        <Select
                                            className='mt-2'
                                            options={ChgsType}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Wt/Val(P/C) </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Other(P/C)</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Carriage</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Customs </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Amount of Insurance </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Handling Info</label>
                                        <textarea
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Rate Description</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>No.of Pkg * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Gross Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate/ Charge *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Comm. Item No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Chargeable Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate Class</label>
                                        <Select
                                            className='mt-2'
                                            options={rateClass}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Total Freight</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Kg/ Lb</label>
                                        <Select
                                            className='mt-2'
                                            options={kgType}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Remarks</label>
                                        <textarea class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                                <h4 className='docket-text'>Prepaid / Collect Charge Declaration</h4>
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Prepaid</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Collect </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Weight Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Valuation Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Tax</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Agent</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Carrier</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Charges / Dimension Details</h4>
                                <div className="col-md-6">

                                    <div className="input_box_section file-input-main">
                                        <label>Add Dimension</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Handling Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Carrier Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Agent Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Commodity</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Shipping Bill</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>HS Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Total</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airwaybill Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Clearance/Exec.Dt</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CSR Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Executed At</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center py-3">
                                    <button type='button' className='btn search-btn w-fit '>
                                        Submit
                                    </button>
                                </div>
                            </div>


                        }
                        {
                            selectedAirwayBillType === 'Master' &&
                            <div className="row pt-4 mt-3 border-top-2px">
                                <h5 >* All fields are mandatory for sending e-AWB Data</h5>

                                <h4 className='docket-text'>Party Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>AWB NO * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>HAWB NO </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Shipper Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Consignee Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Buyer Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Sub Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>City </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Country</label>
                                        <Select
                                            className='mt-2'
                                            options={country}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>


                                <h4 className='docket-text'>Agent Details</h4>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Name * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Address * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section ">
                                        <label>Account Info</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Code</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>EORI Number</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Documents</label>
                                    <Select
                                        className='mt-2'
                                        options={documentType}
                                    />
                                </div>

                                <h4 className='docket-text'>Flight Bookings / Routing</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Departure * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airport of Destination * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By First Carrier *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>By </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>To  </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>DO No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CTO</label>
                                        <Select
                                            className='mt-2'
                                            options={CtoType}
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Charge Declarations</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Currency </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CHGS Code </label>
                                        <Select
                                            className='mt-2'
                                            options={ChgsType}
                                        />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Wt/Val(P/C) </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Other(P/C)</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Carriage</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Declared Value for Customs </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Amount of Insurance </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Handling Info</label>
                                        <textarea
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <h4 className='docket-text'>Rate Description</h4>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>No.of Pkg * </label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Gross Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate/ Charge *</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Comm. Item No</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Chargeable Wt.*</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Rate Class</label>
                                        <Select
                                            className='mt-2'
                                            options={rateClass}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Total Freight</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Kg/ Lb</label>
                                        <Select
                                            className='mt-2'
                                            options={kgType}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="input_box_section ">
                                        <label>Remarks</label>
                                        <textarea class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                                <h4 className='docket-text'>Prepaid / Collect Charge Declaration</h4>
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Prepaid</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Collect </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Weight Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Valuation Charge</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Tax</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Agent</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total Due Carrier</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">
                                        <label>Total</label>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input_box_section ">

                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <h4 className='docket-text'>Charges / Dimension Details</h4>
                                <div className="col-md-6">

                                    <div className="input_box_section file-input-main">
                                        <label>Add Dimension</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Handling Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Carrier Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Due Agent Charges</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Add Commodity</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Shipping Bill</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>HS Code</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box_section file-input-main">
                                        <label>Total</label>
                                        <input
                                            type="file"
                                            name="userfile"
                                            className="form-control"
                                            placeholder="Upload Here"
                                        />
                                        <button className="folder_main_update">
                                            {" "}
                                            Upload Here{" "}
                                            <span>
                                                <img src="/img/upload.png" alt="file" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Airwaybill Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Clearance/Exec.Dt</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>CSR Date</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="input_box_section ">
                                        <label>Executed At</label>
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter here"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-center py-3">
                                    <button type='button' className='btn search-btn w-fit '>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        }
                    </div>

                </form>
            </div>
        </>
    )
}

export default AirwayBillStockForm