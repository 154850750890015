import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepLabel, Button, Paper } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PersonalDetails from './PersonalDetails';
import OfficialDetails from './OfficialDetails';
import { AccountCredential } from './AccountCredential';
import Cookies from 'js-cookie';

const EmployeeRegistration = ({ accessedModules, accessedPermission }) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //check if admin is logged in.
    const token = Cookies.get('token');
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;

    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
        }
    }, [])


    const steps = [
        {
            label: 'Personal Details',
            content: (<>
                <PersonalDetails handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} />
            </>
            ),
        },
        {
            label: 'Official Details',
            content: (<>
                <OfficialDetails handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} />
            </>),
        },
        {
            label: 'Account Credentials',
            content: (<>
                <AccountCredential handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} />
            </>),
        },

    ];


    const handleReset = () => {
        setActiveStep(0);
        navigate('/admin-dashboard/all-employee-list')
    };

    return (
        <div className="container">
            <Toaster />
            <section>
                <div className="col-md-12">
                    <div className="query_bg_info_kyc">
                        <div className="main_section_detail">
                            <div className='count-step custom-resume-stepper d-flex flex-col align-items-center justify-content-center' >
                                <div className=' w-100 overflow-auto d-lg-flex justify-content-center'>

                                    <Stepper className='employee-registration-class' activeStep={activeStep} orientation="horizontal" >
                                        {steps.map((step, index) => (
                                            <Step key={step.label} className='step-label' >
                                                <StepLabel style={{ display: "grid" }} className='step-label'>{step.label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                            </div>
                            <div className='overflow-auto' >


                                {steps[activeStep] && (
                                    <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>
                                        <div>

                                            <div>{steps[activeStep].content}</div>

                                        </div>
                                        {/* <div className='button-container' style={{ marginTop: '20px' }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                                className=''
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                className=''
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {activeStep === steps.length - 1 ? <Link className='text-white' to='/admin-dashboard/all-employee-list' sx={{ mt: 1, mr: 1 }}>
                                                    Submit
                                                </Link> : 'Next'}
                                            </Button>
                                        </div> */}
                                    </Paper>
                                )}

                                {activeStep === steps.length && (

                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Go to All Employee List
                                    </Button>

                                )}
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>

    )
}

export default EmployeeRegistration