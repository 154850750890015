import React, { useState } from 'react'

const Declaration = () => {
    const [signature,setSignature]=useState(false)
  return (
 <>
<form action="#">
  <div className="main-nav-det">
    <h4>Declaration </h4>
  </div>
  <div className="row">
    <div className="col-md-6">
      <div className="input_box_section ">
        <label>Credit Period Required by Party in Days</label>
        <input
          type="text"
          className="form-control color_place"
          placeholder="20 days"
          readOnly
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="input_box_section ">
        <label>
          Total Credit Limit Required by Party in numeric figure (In Lacs)
        </label>
        <input
          type="text"
          className="form-control color_place"
          placeholder={5}
          readOnly
        />
      </div>
    </div>
    <div className="col-md-6">
      <div className="input_box_section file-input-main">
        <label>Digital Signature</label>
        <a className="folder_main_update">
          {" "}
          Signature.pdf
          <span>
            <img src="/img/download-icon.png" alt="file" />
            <img onClick={()=>{
                setSignature(!signature)
            }} src="/img/document-fail.png" alt="file" id="toggleDesc-8" />
          </span>
        </a>
      </div>
      {
        signature &&

      <div id="rejectionReason-8" style={{  marginBottom: 10 }}>
        <label htmlFor="rejection-description-8">Reason for Rejection:</label>
        <textarea
          id="rejection-description-8"
          name="rejection-description-8"
          className="form-control"
          placeholder="Please provide a reason for rejection here..."
          defaultValue={""}
        />
      </div>
      }
    </div>
    <div className="col-md-12">
      I/We hereby declare that the particulars given herein above are true,
      correct and complete to the best of my/our knowledge and belief, the
      documents submitted in support of this Form, KYC are genuine and obtained
      legally from the respective issuing authority. In case of any change in
      any of the aforementioned particulars, I/we undertake to notify you in
      writing failing which the above particulars may be relied upon including
      all shipments/documents executed and tendered by the individual so
      authorized and mentioned above. I/we hereby authorize you to submit the
      above particulars to the customs and other regulatory authorities on
      my/our behalf as may be required in order to transport and customs clear
      my/our shipments.
    </div>
  </div>
</form>

 </>
  )
}

export default Declaration