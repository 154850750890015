const initialState = {
    //emp List 
    loadingPickedRateReqListApi: false,
    pickedRateReqListDataError: "",
    pickedRateReqListData: [],
    pickedRateReqListCurrentPage: 0,
    pickedRateReqListTotalPage: 0,
    pickedRateReqListTotalData: 0,

    //module details 
    // loadingRoleModuleDetail: false,
    // roleModuleDetailDataError: "",
    // roleModuleDetailData: {},
    // roleModuleDetailPermissionData: [],
}

const PickedRateRequestReducer = (state = initialState, action) => {
    switch (action.type) {

        //pickedRateReq List//
        case 'FIND_PICKED_RATE_REQUEST_REQUEST':
            return {
                ...state,
                loadingPickedRateReqListApi: true,
                pickedRateReqListData: "",
                pickedRateReqListDataError: '',
            };
        case 'FIND_PICKED_RATE_REQUEST_SUCCESS':
            return {
                ...state,
                loadingPickedRateReqListApi: false,
                pickedRateReqListData: action.payload.data,
                pickedRateReqListCurrentPage: action.payload.pagination.currentPage,
                pickedRateReqListTotalPage: action.payload.pagination.totalPages,
                pickedRateReqListTotalData: action.payload.pagination.totalRecords,
            };
        case 'FIND_PICKED_RATE_REQUEST_FAILURE':
            return {
                ...state,
                loadingPickedRateReqListApi: false,
                pickedRateReqListDataError: action.payload,
            };
        //emp List//



        default:
            return state;
    }
}

export default PickedRateRequestReducer;