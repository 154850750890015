const initialState = {
    //emp List 
    loadingQuoteCompGenInfoApi: false,
    quoteCompGenInfoDataError: "",
    quoteCompGenInfoData: [],

}

const QuoteAirExportCompGenInfoReducer = (state = initialState, action) => {
    switch (action.type) {

        //quoteCompGenInfot//
        case 'FIND_QUOTE_COMP_GEN_INFO_REQUEST':
            return {
                ...state,
                loadingQuoteCompGenInfoApi: true,
                quoteCompGenInfoData: "",
                quoteCompGenInfoDataError: '',
            };
        case 'FIND_QUOTE_COMP_GEN_INFO_SUCCESS':
            return {
                ...state,
                loadingQuoteCompGenInfoApi: false,
                quoteCompGenInfoData: action.payload.data,
            };
        case 'FIND_QUOTE_COMP_GEN_INFO_FAILURE':
            return {
                ...state,
                loadingQuoteCompGenInfoApi: false,
                quoteCompGenInfoDataError: action.payload,
            };
        //emp List//



        default:
            return state;
    }
}

export default QuoteAirExportCompGenInfoReducer;