import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import axios from "axios";
import OtpInput from "react-otp-input";
import Cookies from "js-cookie";
// import { ToastContainer, toast } from 'react-toastify';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as Yup from "yup";
import { adminMainUrl } from "../../../Config";
import { adminLoginSchema } from "../../../ValidationScema";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { toast } from 'react-toastify';
import Api from "../../../Api";
const Login = () => {
  const location = useLocation();
  // const notify = () => toast("Wow so easy!");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [roleId, setRoleId] = useState([]);
  const [mail, setMail] = useState(null);
  useEffect(() => {
    if (location.state?.email) {
      setMail(location.state.email);
    }
    if (location.state?.otp) {
      // toast.success(location.state.otp);
    }
  }, [location.state]);

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: async () => {
      setIsSubmitting(true);
      try {
        const response = await fetch(`${adminMainUrl}/api/forgot-otp-verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"), // Add auth-token if required
          },
          body: JSON.stringify({
            email: mail, // Pre-filled email
            otp: otp, // OTP entered by the user
          }),
        });
        const data = await response.json();
        if (response.ok) {
          toast.success(data?.msg);
          navigate("/admin-login-forget-change", { state: { email: mail } }); // Redirect to the next step
        } else {
          toast.error(data?.msg);
          const errorData = await response.json();
          console.error("Error verifying OTP:", errorData.message);
        }
      } catch (error) {
        console.error("Network error:", error);
      } finally {
        setIsSubmitting(false); // Stop the spinner
      }
    },
  });

  return (
    <section className="login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 second-body">
            <div className="card-body align-items-center mx-md-4 card-padding">
              <div className="logo-section pt-4">
                <Link
                  to="/admin-login-forget-email"
                  className="query_info"
                >
                  <img src="/img/back-icon.png" alt="Query" />
                </Link>
              </div>
              <div className="logo-section text-center pt-4">
                <img src="/img/committed-logo.png" alt="logo" />
              </div>
              <form
                className="login-form mx-auto"
                onSubmit={formik.handleSubmit}
              >
                <div className="forget-head">
                  <h4>Enter OTP</h4>
                </div>
                <div
                  data-mdb-input-init=""
                  className="form-outline otp-input mb-2"
                >
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="text-center pt-1 mb-2 pb-1">
                  <button
                    type="submit"
                    className="btn-login mb-3"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-7 p-0">
            <div className="img-form">
              <img
                src="/img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
