import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { adminMainUrl } from '../../../Config';
import { adminLoginSchema } from '../../../ValidationScema';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../../Api';
import { getRoleList, getRoleView } from '../../../Redux/action/Admin/RolePermissionAction';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [roleId, setRoleId] = useState([]);
    let [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        dispatch(getRoleView());
    }, []);

    let roleData = useSelector(state => state?.RolePermissionReducer ? state?.RolePermissionReducer?.roleViewData : []);

    useEffect(() => {
        if (roleData) {
            let roleArr = [];
            roleData.map(val => { roleArr.push({ value: val.id, label: val.name }) });
            setRoleOptions(roleArr);
        }
    }, [roleData]);

    const initialValues = {
        email: "",
        password: "",
        role_id: "",
        // agreement: false,
    };

    // const token = Cookies.get('token');
    // const urlString = window.location.href;
    // const url = new URL(urlString);
    // const path = url.pathname.slice(1);
    // const searchParams = url.search;
    // const q = path + searchParams;

    // useEffect(() => {
    //     if (!token) {
    //         toast.error("Please login to continue.");
    //         navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    //     }
    // }, []);
    // const burl = new URLSearchParams(window.location.search).get('burl');
    // In your login component, after successful login
    const burl = new URLSearchParams(window.location.search).get('burl');
    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            navigate('/admin-dashboard/admin-dashboard');
        }
    }, [navigate]);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: adminLoginSchema,
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                const response = await fetch(`${adminMainUrl}/api/login`, {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                });

                const data = await response.json();

                if (data?.code === 200) {
                    Cookies.set("token", data.authToken);

                    // Check if the token was successfully set
                    if (Cookies.get("token")) {
                        toast.success(data?.msg);
                        sessionStorage.setItem('userData', JSON.stringify(data));
                        if (burl) {
                            navigate(`/${burl}`);
                        }
                        else {
                            navigate('/admin-dashboard/admin-dashboard');
                        }
                        formik.resetForm();
                    } else {
                        toast.error("Failed to set token. Redirecting to login.");
                        navigate('/admin-login');
                    }
                } else {
                    toast.error(data?.msg);
                    // handle login failure
                }
            } catch (error) {
                console.error("Login error:", error);
                toast.error("An error occurred. Please try again.");
            } finally {
                setIsSubmitting(false); // Stop the spinner
            }
        },
    });



    return (
        <section className="login">
            {/* <Toaster /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 col-md-6 second-body">
                        <div className="card-body align-items-center mx-md-4 card-padding">
                            <div className="logo-section text-center pt-4">
                                <img src="/img/committed-logo.png" alt="logo" />
                            </div>
                            <form className="login-form mx-auto" onSubmit={formik.handleSubmit}>
                                <div data-mdb-input-init="" className="pt-4 mb-2">
                                    <label htmlFor="select">Select Your Role</label>

                                    <Select
                                        className='mt-2'
                                        options={roleOptions}
                                        name="role_id"
                                        value={roleOptions.find(option => option.value === formik.values.role_id)}
                                        onChange={option => formik.setFieldValue('role_id', option.value)}
                                        onBlur={formik.handleBlur}
                                    />

                                    {formik.errors.role_id && formik.touched.role_id && (
                                        <div className="text-red-500 text-base">{formik.errors.role_id}</div>
                                    )}
                                </div>
                                <div data-mdb-input-init="" className="form-outline mb-2">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        style={{
                                            background: "#fff",
                                            border: formik.errors.email && formik.touched.email ? "1px solid red" : "",
                                        }}
                                    />
                                    {formik.errors.email && formik.touched.email && (
                                        <div className="text-red-500 text-base">{formik.errors.email}</div>
                                    )}
                                </div>
                                <div data-mdb-input-init="" className="form-outline mb-1" style={{ position: "relative" }}>
                                    <label htmlFor="password">Password</label>
                                    <div className='relative password-input'>
                                        <input
                                            placeholder='Enter Your Password'
                                            type={showPassword ? "password" : "text"}
                                            id="password"
                                            className="form-control"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            style={{
                                                background: "#fff",
                                                border: formik.errors.password && formik.touched.password ? "1px solid red" : "",
                                            }}
                                        />
                                        {
                                            showPassword ?
                                                <VisibilityOffIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                                :
                                                <VisibilityIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                        }
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <div className="text-red-500 text-base">{formik.errors.password}</div>
                                    )}
                                </div>
                                <div className="mt-2  mb-2">
                                    <div className="form-check d-flex align-items-center justify-content-start gap-2 ps-0">
                                        <input
                                            type="checkbox"
                                            className="check"
                                        />
                                        <label className="form-check-label cursor-pointer " htmlFor="rememberMe">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                                <div className="text-center pt-1 mb-2 pb-1">
                                    <button className="btn-login mb-3" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : "Login"}
                                    </button>
                                </div>
                                <div className="mt-2  mb-2">
                                    <div className="form-check d-flex align-items-center justify-content-start gap-2 ps-0">
                                        <Link to="/admin-login-forget-email">
                                            <label className="form-check-label cursor-pointer " htmlFor="rememberMe">
                                                Forgot Password?
                                            </label>
                                        </Link>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 p-0">
                        <div className="img-form">
                            <img
                                src="/img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
