import React from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
const AirwayBillNumberStoring = () => {
    const navigate = useNavigate()
    const partyType = [
        { value: 'Airline', label: 'Airline' },
        { value: 'IATA', label: 'IATA' },
    ]
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className='airway-bill-text mb-3'>Airway Bill Number Storing</h3>
                    </div>
                </div>
                <form className='p-4 bg-blur radius-sm'>
                    <div className="bg-white p-4 radius-sm">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-nav-det">
                                    <h4>Airway Bill Stock</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Party Type</label>
                                    <Select
                                        className='mt-2'
                                        options={partyType}
                                    />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Stock Date</label>
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter here"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>No of AWB</label>
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter here"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Last Stock Seq No</label>
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter here"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input_box_section ">
                                    <label>Bill No</label>
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter here"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h4>Bill Details</h4>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="add-Company-button">
                                    <a href="">
                                        <span>
                                            <img src="/img/twotone-add.png" alt="Add Dimensions" />
                                        </span>
                                        Add Airway
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table_detail_tariff table-responsive">
                                    <table className="posts-table geeks">
                                        <thead>
                                            <tr className="n_detail_comp">
                                                <th className="users-table-th">Party Type</th>
                                                <th className="users-table-th">Stock Date</th>
                                                <th className="users-table-th">No of AWB</th>
                                                <th className="users-table-th">Last Stock Seq No</th>
                                                <th className="users-table-th">Bill No</th>
                                                <th width={100} />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="main_table_row_new n_detail_comp n_detail_none">
                                                <td>Airway</td>
                                                <td>12/02/2023</td>
                                                <td>10</td>
                                                <td>10</td>
                                                <td>123 1234 1234</td>
                                                <td className="main_table_last">
                                                    <div className="main_icon_click">
                                                        <a href="#main_id_sec">
                                                            <img src="/img/edit-line.png" alt="edit" />
                                                        </a>
                                                        <a href="#">
                                                            <img src="/img/delete-outline.png" alt="delete" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="main_table_row_new n_detail_comp n_detail_none">
                                                <td>Airway</td>
                                                <td>12/02/2023</td>
                                                <td>10</td>
                                                <td>10</td>
                                                <td>123 1234 1234</td>
                                                <td className="main_table_last">
                                                    <div className="main_icon_click">
                                                        <a href="#main_id_sec">
                                                            <img src="/img/edit-line.png" alt="edit" />
                                                        </a>
                                                        <a href="#">
                                                            <img src="/img/delete-outline.png" alt="delete" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="py-4 text-center">

                            <button type='button' onClick={() => { navigate('/admin-dashboard/airway-bill-stock-list') }} className='btn search-btn w-fit '>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AirwayBillNumberStoring