import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableLoader from "../../../Common/Loaders/SkeletonTableLoader";
import ReactPaginate from "react-paginate";
import { Formik, useFormik } from "formik";
import { getRoleList } from "../../../Redux/action/Admin/RolePermissionAction";
import Beatloader from "../../../Common/Loaders/Beatloader";

const adminMainUrl = process.env.REACT_APP_API_SERVER;
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import Tooltip from '@mui/material/Tooltip';

const RoleList = ({ accessedModules, accessedPermission }) => {
  // console.log(accessedPermission)
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page_no = queryParams.get("page");

  const [originalData, setOriginalData] = useState([]);
  const [searchString, setSearchString] = useState("");


  const [spinnerLoader, setSpinnerLoader] = useState(false)
  const [reload, setReload] = useState(false);
  const [list, setList] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const dataPerPage = 10;

  useEffect(() => {

    const endOffset = dataOffset + dataPerPage;
    setCurrentData(list.slice(dataOffset, endOffset));
    setPageCount(Math.ceil(list.length / dataPerPage));
  }, [dataOffset, dataPerPage, list])

  const handlePageClick = (event) => {
    window.scroll(0, 0);
    const newOffset = (event.selected * dataPerPage) % list.length;
    setDataOffset(newOffset);
  };


  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;
  const token = Cookies.get("token");

  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    } else {
      dispatch(getRoleList());
    }
  }, []);


  const {
    loadingRoleListApi,
    roleListDataError,
    roleListData,
  } = useSelector((state) => state?.RolePermissionReducer);

  useEffect(() => {
    if (loadingRoleListApi) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
    if (roleListData) {
      setList(roleListData);
      setOriginalData(roleListData);
    }
  }, [
    loadingRoleListApi,
    roleListDataError,
    roleListData,
  ]);

  useEffect(() => {
    if (searchString) {
      const filteredRoles = list.filter((role) =>
        role.name.toLowerCase().includes(searchString.toLowerCase())
      );
      setList(filteredRoles);
    } else {
      setList(originalData);
    }
  }, [searchString])



  //search and filter data
  useEffect(() => {
    dispatch(getRoleList());
  }, [adminMainUrl, reload]);


  return (
    <>
      <Toaster />
      {showLoader ?
        <Beatloader />
        : <div className="container">
          <div className="col-md-12">
            <div className="row stat-cards">
              <div className="col-md-6 col-xl-6">
                <div className="main-nav-det">
                  <h2>Roles list </h2>
                </div>
              </div>
              <div className="col-md-6">
                {accessedPermission.includes("add_roles") && <div className="button-det-list">
                  <Link
                    to="/admin-dashboard/role/add"
                    onClick={() => {
                      Cookies.remove("rolely_id");
                    }}
                  >
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Add New Role
                  </Link>
                </div>}
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-9">
                <div className="search_main_filter">
                  <form>
                    {/* <form onSubmit={formik.handleSubmit}> */}
                    <div className="row pt-1 pb-1 main_row">
                      <div className="col-md-4">
                        <div className="input_box_filet filter_icon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => { setSearchString(e.target.value) }}
                          />
                          <span>
                            <img src="/img/search.png" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex align-items-center buttons-filter">
                        {/* <button
                          type="submit"
                          className="search-btn text-white w-fit"
                          style={{
                            background: "#04aed2",
                            marginTop: "7px",
                            marginBottom: "15px",
                          }}
                        >
                          Search
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
              </div>
            </div>
            <div className="row pb-2 pt-2">
              <div className="col-lg-12">
                <div className="users-table-admin table-responsive border-redi">
                  <table className="posts-table">
                    <thead>
                      <tr className="users-table-admin-th">
                        <th>S. No.</th>
                        <th>Role</th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    {showLoader ? (
                      <tbody>
                        <SkeletonTableLoader colSpan={8} count={2} length={5} />
                      </tbody>
                    ) : (
                      <tbody>
                        {
                          list.length > 0 ?
                            list.map((role, index) => {
                              return (
                                <tr className="users-table-admin-td" key={index + 1}>
                                  <td>{(index + 1)}</td>
                                  <td>
                                    {role.name}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="mx-1"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="View role details"
                                      >
                                        <Link
                                          to={`/admin-dashboard/role/view/${btoa(role?.id)}`}
                                        >
                                          <img
                                            src="/img/Vector.png"
                                            alt="view"
                                            className="pointer w-fit border-radius-none"
                                          /></Link>
                                      </div>
                                      {accessedPermission.includes("edit_roles") && <div
                                        className="mx-1"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="Edit role details"
                                      >
                                        <Link
                                          to={`/admin-dashboard/role/edit/${btoa(role?.id)}`}
                                        >
                                          <img
                                            src="/img/edit.png"
                                            alt="edit"
                                            className="pointer w-fit border-radius-none"
                                          /></Link>
                                      </div>}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }) :
                            <tr className="users-table-admin-td">
                              <td colSpan={8}>
                                <i>No data found.</i>
                              </td>
                            </tr>
                        }
                      </tbody>
                    )}
                  </table>

                  {list.length > 0 && <div className="pagination-controls my-5 d-flex justify-content-center align-items-center react-custom-paginate">
                    <ReactPaginate
                      previousLabel={<img src="/img/right.png" alt="Previous" />}
                      nextLabel={<img src="/img/left.png" alt="Next" />}
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                    // forcePage={currentPage - 1} // To sync ReactPaginate's internal state with currentPage
                    />

                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default RoleList;
