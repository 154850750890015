import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { mainUrl } from '../../Config';
import Swal from 'sweetalert2';
import Api from '../../Api';
const ViewRateModal = ({ modal, tariffDetails, GetUserInfo,airlineId }) => {
  const [updateTraficDetails, setUpdateTraficDetails] = useState([]);
  useEffect(() => {
    setUpdateTraficDetails(airlineId)
  }, [airlineId]);
  const [tariffData, setTariffData] = useState({
    airline_name: "",
    charge_head: "",
    buying_price: "",
    selling_price: "",
    narration: "",
    valid_upto: "",
    currency: "",
    exchange_rate: "",
    unit: "",
    fc_amount: "",
    rate: ""
  });

  const [tariffId, setTariffId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTariffData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await updateTariff(tariffId, tariffData);
  };

  const updateTariff = async (tariffId, data) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${mainUrl}/api/v.1/updateTariff/${tariffId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }


      const result = await response.json();
      
      GetUserInfo()
      setTariffData({
        airline_name: "",
    charge_head: "",
    buying_price: "",
    selling_price: "",
    narration: "",
    valid_upto: "",
    currency: "",
    exchange_rate: "",
    unit: "",
    fc_amount: "",
    rate: ""
      });
      toast.success(result?.message);
      const updatedTariffDetails = airlineId.map((value) => {
        if (value.id === tariffId) {
          return { ...value, ...data };
        }
        return value;
      });

      // Update the state with the new tariffDetails array
      setUpdateTraficDetails(updatedTariffDetails);
      return result;
    } catch (error) {
      toast.error(error);
      console.error('Error updating tariff:', error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const data = await Api(
        `${mainUrl}/api/v.1/deleteTariff/${userId}`,
        "DELETE"
      );
      if (data.status === 200) {
        Swal.fire('Are you sure , you want to delete this data');
        GetUserInfo()
        toast.success(data?.message);
        const updatedTariffDetails = airlineId.map((value) => {
          if (value.id === tariffId) {
            return { ...value, ...data };
          }
          return value;
        });

        // Update the state with the new tariffDetails array
        setUpdateTraficDetails(updatedTariffDetails);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error deleting Service:", error);
    }
  };
  return (
    <>
      <div className="modal" id={modal}>
        <div className="modal-dialog detail_popup_width modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="btn-close-new-section">
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body detail_con_popup_main">
              <div className="main-nav-det">
                <h4>New Rate</h4>
              </div>
              <div className="main_detail_form " id="main_id_sec">
                <form onSubmit={handleUpdate}>
                  <div className="Air_Export_Form_bg">
                    <div className="Air_Export_bg_main">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Airline Code </label>
                            <input
                              type="text"
                              name="airline_name"
                              className="form-control"
                              placeholder="Enter Airline Name"
                              value={tariffData.airline_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Charge Head </label>
                            <input
                              type="text"
                              name="charge_head"
                              className="form-control"
                              placeholder="Enter Charge Head"
                              value={tariffData.charge_head}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Buying Price </label>
                            <input
                              type="text"
                              name="buying_price"
                              className="form-control"
                              placeholder="Buying Price"
                              value={tariffData.buying_price}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Selling Price </label>
                            <input
                              type="text"
                              name="selling_price"
                              className="form-control"
                              placeholder="Selling Price"
                              value={tariffData.selling_price}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Narration </label>
                            <input
                              type="text"
                              name="narration"
                              className="form-control"
                              placeholder="Narration"
                              value={tariffData.narration}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Valid Up To</label>
                            <input
                              type="text"
                              name="valid_upto"
                              className="form-control"
                              placeholder="Enter Valid Up To"
                              value={tariffData.valid_upto}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Currency </label>
                            <input
                              type="text"
                              name="currency"
                              className="form-control"
                              placeholder="Enter Currency"
                              value={tariffData.currency}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Exchange Rate </label>
                            <input
                              type="text"
                              name="exchange_rate"
                              className="form-control"
                              placeholder="Exchange Rate"
                              value={tariffData.exchange_rate}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Unit </label>
                            <input
                              type="text"
                              name="unit"
                              className="form-control"
                              placeholder="Unit"
                              value={tariffData.unit}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>FC Amount</label>
                            <input
                              type="text"
                              name="fc_amount"
                              className="form-control"
                              placeholder="FC Amount"
                              value={tariffData.fc_amount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Rate </label>
                            <input
                              type="text"
                              name="rate"
                              className="form-control"
                              placeholder="Rate"
                              value={tariffData.rate}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="info-det-button-add ">
                            <button type="submit" className='search-btn text-white py-1'>Update Tariff</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="Air_Export_Form_bg">
                  <div className="Air_Export_bg_main">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                          <table className="posts-table geeks">
                            <thead>
                              <tr className="users-table-tariff n_detail_comp bg_table_new">
                                <th>Airline N.</th>
                                <th>Charge Head</th>
                                <th>Buyer Amount</th>
                                <th>Seller Amount</th>
                                <th>Narration</th>
                                <th>Valid Up To</th>
                                <th>Currency</th>
                                <th>Exchange Rate</th>
                                <th>Unit</th>
                                <th>FC Amount</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                updateTraficDetails?.map((value, index) => (
                                  <tr className="main_table_row_new n_detail_comp" key={index}>
                                    <td>{value?.airline_name}</td>
                                    <td>{value?.charge_head}</td>
                                    <td>{value?.buying_price}</td>
                                    <td>{value?.selling_price}</td>
                                    <td>{value?.narration}</td>
                                    <td>{value?.valid_upto}</td>
                                    <td>{value?.currency}</td>
                                    <td>{value?.exchange_rate}</td>
                                    <td>{value?.unit}</td>
                                    <td>{value?.fc_amount}</td>
                                    <td>{value?.rate}</td>
                                    <td>
                                      <div className="main_icon_click">
                                        <div onClick={() => {
                                          setTariffId(value?.id);

                                          setTariffData({
                                            airline_name: value?.airline_name,
                                            charge_head: value?.charge_head,
                                            buying_price: value?.buying_price,
                                            selling_price: value?.selling_price,
                                            narration: value?.narration,
                                            valid_upto: value?.valid_upto,
                                            currency: value?.currency,
                                            exchange_rate: value?.exchange_rate,
                                            unit: value?.unit,
                                            fc_amount: value?.fc_amount,
                                            rate: value?.rate,
                                          });
                                        }}
                                          style={{ cursor: "pointer" }}>
                                          <img src="/img/edit-line.png" alt="edit" />
                                        </div>
                                        <div onClick={() => { handleDelete(value?.id) }} style={{ cursor: "pointer" }}>
                                          <img src="/img/delete-outline.png" alt="delete" />
                                        </div>

                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ViewRateModal