import React, { useEffect, useState } from 'react'
import ViewRateModal from '../../Modal/ViewRateModal'
import { Link, useParams } from 'react-router-dom'
import { adminMainUrl, mainUrl } from '../../../Config';
import Api from '../../../Api';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { RateRequestSchema } from '../../../ValidationScema';
import Select from 'react-select'
import { format } from 'date-fns';
import { FocusError } from 'focus-formik-error';
const EditAirExportForm = () => {
    // const { status_id } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [cargoType, setCargoType] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [rateValidity, setRateValidity] = useState(null);
    const [rate, setRate] = useState([]);
    const [edit, setEdit] = useState(false);
    const [airlineId, setAirlineId] = useState();
    const [updateTraficDetails, setUpdateTraficDetails] = useState([]);
    const country = [
        { value: '1', label: 'india' },
    ]
    const state = [
        { value: '1', label: 'Uttar Pradesh' },
    ]
    
    const formik = useFormik({
        initialValues: {
            quotation_id: "7",
            ship_quotation_no: '',
            ship_date_of_creation: "",
            ship_buy_ref_no: '',
            ship_rate_validity: "",
            ship_validity_against: '',
            ship_quotation_rise_in: '',

            com_company_name: '',
            com_address: '',
            com_phone_no: '',
            com_email: '',
            com_gstin: '',
            com_country_id: '',
            com_state_id: '',

            gen_customer_name: '',
            gen_address: '',
            gen_billing_account: '',
            gen_attention_to: '',
            gen_airline: '',
            gen_place_of_receipt: '',
            gen_commodity: '',
            gen_airport_of_landing: '',
            gen_cargo_weight: '',
            gen_airport_of_discharge: '',
            gen_incoterms: '',
            gen_tentative_transit_time: null,
            gen_find_place_of_delivery: '',
            gen_notes: '',

            quote_tarrif: [{
                airline_code: '',
                charge_head: '',
                buying_price: '',
                selling_price: '',
                narration: '',
                valid_upto: '',
                currency: '',
                exchange_rate: '',
                unit_id: '',
                fc_amount: '',
                rate: '',
                cargo_type_id: '',
            }
            ],

        },
        validationSchema: RateRequestSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const token = Cookies.get("token");

                const response = await fetch(`${mainUrl}/api/v.1/updateQuotationRate`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(values),
                });
                if (response.ok) {
                    const responseData = await response.json();
                    GetUserInfo();
                    setEdit(false)
                    // const updatedInformation = responseData?.uhqpa_id
                    // await createQuotationStatus(updatedInformation);
                    // navigate('/dashboard/view-updated-query-list');
                    resetForm()
                    toast.success(responseData?.message);
                } else {
                    toast.error("Wrong Credentials");
                }
            } catch (error) {
                toast.error(error);
            }
        },
    });
    // const transformedArray = rate[0]?.quote_tarrif
    //     ? Object.entries(rate[0]?.quote_tarrif)?.map(([key, value]) => ({
    //         [key]: value
    //     }))
    //     : [];
    useEffect(() => {
        if (rate[0]?.quote_tarrif) {
            const tariffDetails = rate[0]?.quote_tarrif.map((value) => ({
                airline_code: value.airline_code || '',
                charge_head: value.charge_head || '',
                buying_price: value.buying_price || '',
                selling_price: value.selling_price || '',
                narration: value.narration || '',
                valid_upto: value.valid_upto || '',
                currency: value.currency || '',
                exchange_rate: value.exchange_rate || '',
                unit_id: value.unit_id || '',
                fc_amount: value.fc_amount || '',
                rate: value.rate || '',
                cargo_type_id: value.cargo_type_id || '',

                // id: value?.id,
                // airline_name: value.airline_name || '',
                // charge_head: value.charge_head || '',
                // buying_price: value.buying_price || '',
                // selling_price: value.selling_price || '',
                // narration: value.narration || '',
                // valid_upto: value.valid_upto || '',
                // currency: value.currency || '',
                // exchange_rate: value.exchange_rate || '',
                // unit: value.unit || '',
                // fc_amount: value.fc_amount || '',
                // rate: value.rate || '',

            }));

            formik.setFieldValue('quote_tarrif', tariffDetails);
        }

        if (rate) {
            const companyInfo = rate[0];
            formik.setValues({
                id: companyInfo?.id || '',
                com_company_name: companyInfo?.com_company_name || '',
                com_address: companyInfo?.com_address || '',
                com_phone_no: companyInfo?.com_phone_no || '',
                com_email: companyInfo?.com_email || '',
                com_gstin: companyInfo?.com_gstin || '',
                com_country_id: companyInfo?.com_country_id || '',
                com_state_id: companyInfo?.com_state_id || '',

                ship_quotation_no: companyInfo?.ship_quotation_no || '',
                ship_date_of_creation: companyInfo?.ship_date_of_creation || '',
                ship_buy_ref_no: companyInfo?.ship_buy_ref_no || '',
                ship_rate_validity: companyInfo?.ship_rate_validity || '',
                ship_validity_against: companyInfo?.ship_validity_against || '',
                ship_quotation_rise_in: companyInfo?.ship_quotation_rise_in || '',

                gen_customer_name: companyInfo?.gen_customer_name || '',
                gen_address: companyInfo?.gen_address || '',
                gen_billing_account: companyInfo?.gen_billing_account || '',
                gen_attention_to: companyInfo?.gen_attention_to || '',
                gen_airline: companyInfo?.gen_airline || '',
                gen_place_of_receipt: companyInfo?.gen_place_of_receipt || '',
                gen_commodity: companyInfo?.gen_commodity || '',
                gen_airport_of_landing: companyInfo?.gen_airport_of_landing || '',
                gen_cargo_weight: companyInfo?.gen_cargo_weight || '',
                gen_airport_of_discharge: companyInfo?.gen_airport_of_discharge || '',
                gen_incoterms: companyInfo?.gen_incoterms || '',
                gen_tentative_transit_time: companyInfo?.gen_tentative_transit_time || '',
                gen_find_place_of_delivery: companyInfo?.ship_quotation_no || '',
                gen_notes: companyInfo?.gen_notes || '',
            });
        }


    }, [rate]);
    const GetCargoType = async () => {
        try {
            const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
            const options = data?.data
                ?.filter((item) => item.status === true).map((item) => ({
                    cargoId: item.id,
                    value: item.name,
                    label: item.name,
                }));
            setCargoType(options);
        } catch (error) {
            setCargoType([]);

            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        GetCargoType();
    }, []);
    const groupedByAirlineCode = rate[0]?.quote_tarrif.reduce((acc, current) => {
        const { airline_code } = current;
        if (!acc[airline_code]) {
            acc[airline_code] = [];
        }
        acc[airline_code].push(current);
        return acc;
    }, {});

    const GetUserInfo = async (information) => {
        // setSpinnerLoader(true)
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `7`,
            };
            const response = await fetch(`${adminMainUrl}/api/pricing/list-export-info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            if (!response.ok) {
                // setSpinnerLoader(false);
                // setIsVisible(false)
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setRate(data?.data);
            // setIsVisible(false)
            // setSpinnerLoader(false);
        } catch (error) {
            // setIsVisible(false)
            // setSpinnerLoader(false);
            console.log(error);
        }
    };
    useEffect(() => {
        GetUserInfo();
    }, []);
    const convertTo12HourDate = (timeString) => {
        if (!timeString) return null;
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours), parseInt(minutes));
        return date;
    };

    return (
        <>
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="main-nav-det d-sm-flex justify-content-between align-items-center">
                            <h2>
                                <Link to='/dashboard/query-list' className="query_info">
                                    <img src="/img/re_darect.png" alt="Query" />
                                </Link>
                                Quotation Air Export Form
                            </h2>
                            <div style={{ cursor: "pointer" }} className='search-btn text-white py-1' onClick={() => { setEdit(!edit) }}>
                                Edit
                            </div>
                        </div>
                        <div className="main_detail_form Air_Export_Form_bg">
                            <form onSubmit={formik.handleSubmit} className="Air_Export_bg_main">
                                <FocusError formik={formik} />
                                {/* <input type="hidden" name="uhqpa_id" value={status_id} /> */}
                                {/* Shipment Information */}
                                <div className="main-nav-det">
                                    <h4>
                                        <span>
                                            <img src="/img/gen.png" alt="gen" />
                                        </span>
                                        Shipment Information
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>Quotation Number <span>*</span></label>
                                            <input
                                                type="text"
                                                placeholder='Enter quotation number'
                                                maxLength={4}
                                                className="form-control"
                                                value={formik.values.ship_quotation_no}
                                                onChange={(e) => {
                                                    const num = e.target.value.replace(/[^0-9]/g, '');
                                                    formik.setFieldValue('ship_quotation_no', num);
                                                }}
                                                onBlur={formik.handleBlur}
                                                name="ship_quotation_no"
                                            />
                                            {formik.touched.ship_quotation_no && formik.errors.ship_quotation_no ? (
                                                <div className="error">{formik.errors.ship_quotation_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section por_main_detail">
                                            <label>Date of Creation <span>*</span></label>
                                            <div className="custom-datepicker">
                                                <DatePicker
                                                    placeholderText='Enter date of creation'
                                                    style={{ width: "100%" }}
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                                        setStartDate(date);
                                                        formik.setFieldValue("ship_date_of_creation", formattedDate);
                                                    }}
                                                    // onChange={(date) => {
                                                    //   setStartDate(date);
                                                    //   formik.setFieldValue("ship_date_of_creation", date);
                                                    // }}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.ship_date_of_creation && formik.errors.ship_date_of_creation ? (
                                                <div className="error">{formik.errors.ship_date_of_creation}</div>
                                            ) : null}
                                            <span>
                                                <img src="/img/simple-calende.png" alt="Calendar" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>Buying Reference Number</label>
                                            <input
                                                // maxLength={6}
                                                placeholder='Enter buying reference number'
                                                type="text"
                                                className="form-control"
                                                value={formik.values.ship_buy_ref_no}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="ship_buy_ref_no"
                                            />
                                            {formik.touched.ship_buy_ref_no && formik.errors.ship_buy_ref_no ? (
                                                <div className="error">{formik.errors.ship_buy_ref_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section por_main_detail">
                                            <label>Rate Validity <span>*</span> </label>
                                            <div className="custom-datepicker">
                                                <DatePicker
                                                    placeholderText='Enter rate validity'
                                                    style={{ width: "100%" }}
                                                    selected={endDate}
                                                    onChange={(date) => {
                                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                                        setEndDate(date);
                                                        formik.setFieldValue("ship_rate_validity", formattedDate);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            {formik.touched.ship_rate_validity && formik.errors.ship_rate_validity ? (
                                                <div className="error">{formik.errors.ship_rate_validity}</div>
                                            ) : null}
                                            <span>
                                                <img src="/img/simple-calende.png" alt="Calendar" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>Validity Against </label>
                                            <input
                                                type="text"
                                                placeholder='Enter validity against'
                                                className="form-control"
                                                value={formik.values.ship_validity_against}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="ship_validity_against"
                                            />
                                            {formik.touched.ship_validity_against && formik.errors.ship_validity_against ? (
                                                <div className="error">{formik.errors.ship_validity_against}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input_box_section ">
                                            <label>Quotation Rise in </label>
                                            <input
                                                type="text"
                                                placeholder='Enter quotation rise in'
                                                className="form-control"
                                                value={formik.values.ship_quotation_rise_in}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="ship_quotation_rise_in"
                                            />
                                            {formik.touched.ship_quotation_rise_in && formik.errors.ship_quotation_rise_in ? (
                                                <div className="error">{formik.errors.ship_quotation_rise_in}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                {/* Company Information */}
                                <div className="main-nav-det">
                                    <h4>
                                        <span>
                                            <img src="/img/Product.png" alt="gen" />
                                        </span>
                                        Company Information
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>Company Name <span>*</span> </label>
                                            <input
                                                type="text"
                                                placeholder='Enter company name'
                                                className="form-control"
                                                value={formik.values.com_company_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="com_company_name"
                                            />
                                            {formik.touched.com_company_name && formik.errors.com_company_name ? (
                                                <div className="error">{formik.errors.com_company_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>Address <span>*</span> </label>
                                            <input
                                                type="text"
                                                placeholder='Enter address'
                                                className="form-control"
                                                value={formik.values.com_address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="com_address"
                                            />
                                            {formik.touched.com_address && formik.errors.com_address ? (
                                                <div className="error">{formik.errors.com_address}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>Phone Number <span>*</span> </label>
                                            <input
                                                placeholder='Enter phone number'
                                                maxLength={10}
                                                type="text"
                                                className="form-control"
                                                value={formik.values.com_phone_no}
                                                onChange={(e) => {
                                                    const num = e.target.value.replace(/[^0-9]/g, '');
                                                    formik.setFieldValue('com_phone_no', num);
                                                }}
                                                onBlur={formik.handleBlur}
                                                name="com_phone_no"
                                            />
                                            {formik.touched.com_phone_no && formik.errors.com_phone_no ? (
                                                <div className="error">{formik.errors.com_phone_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>Email ID <span>*</span> </label>
                                            <input
                                                placeholder='Enter email ID'
                                                type="text"
                                                className="form-control"
                                                value={formik.values.com_email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="com_email"
                                            />
                                            {formik.touched.com_email && formik.errors.com_email ? (
                                                <div className="error">{formik.errors.com_email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>GSTIN <span>*</span> </label>
                                            <input
                                                type="text"
                                                placeholder='Enter GSTIN'
                                                className="form-control"
                                                value={formik.values.com_gstin}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="com_gstin"
                                            />
                                            {formik.touched.com_gstin && formik.errors.com_gstin ? (
                                                <div className="error">{formik.errors.com_gstin}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>Country <span>*</span> </label>
                                            <Select
                                                placeholder='Enter country'
                                                className="mt-2"
                                                options={country}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue('com_country_id', selectedOption.value)
                                                }
                                                onBlur={formik.handleBlur}
                                            />
                                            {/* <input
                        type="text"
                        className="form-control"
                        value={formik.values.com_country_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="com_country_id"
                      /> */}
                                            {formik.touched.com_country_id && formik.errors.com_country_id ? (
                                                <div className="error">{formik.errors.com_country_id}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section ">
                                            <label>State <span>*</span> </label>
                                            <Select
                                                placeholder='Enter state'
                                                className="mt-2"
                                                options={state}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue('com_state_id', selectedOption.value)
                                                }
                                                onBlur={formik.handleBlur}
                                            />
                                            {/* <input
                        type="text"
                        className="form-control"
                        value={formik.values.com_state_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="com_state_id"
                      /> */}
                                            {formik.touched.com_state_id && formik.errors.com_state_id ? (
                                                <div className="error">{formik.errors.com_state_id}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="main-nav-det">
                                    <h4>
                                        <span>
                                            <img src="/img/Product.png" alt="gen" />
                                        </span>
                                        General Information
                                    </h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Customer Name <span>*</span>
                                            </label>
                                            <input
                                                placeholder='Enter customer name'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_customer_name}
                                                name="gen_customer_name"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_customer_name && formik?.errors?.gen_customer_name ? (
                                                <div className="error">{formik?.errors?.gen_customer_name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Address <span>*</span></label>
                                            <input
                                                placeholder='Enter address'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_address}
                                                name="gen_address"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_address && formik?.errors?.gen_address ? (
                                                <div className="error">{formik?.errors?.gen_address}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Billing Account <span>*</span></label>
                                            <input
                                                type="text"
                                                placeholder='Enter billing account'
                                                className="form-control"
                                                value={formik?.values?.gen_billing_account}
                                                name="gen_billing_account"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_billing_account && formik?.errors?.gen_billing_account ? (
                                                <div className="error">{formik?.errors?.gen_billing_account}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Attention To</label>
                                            <input
                                                type="text"
                                                placeholder='Enter attention to'
                                                className="form-control"
                                                value={formik?.values?.gen_attention_to}
                                                name="gen_attention_to"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_attention_to && formik?.errors?.gen_attention_to ? (
                                                <div className="error">{formik?.errors?.gen_attention_to}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Airline <span>*</span></label>
                                            <input
                                                placeholder='Enter airline'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_airline}
                                                name="gen_airline"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_airline && formik?.errors?.gen_airline ? (
                                                <div className="error">{formik?.errors?.gen_airline}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Place of Receipt</label>
                                            <input
                                                placeholder='Enter place of receipt'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_place_of_receipt}
                                                name="gen_place_of_receipt"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_place_of_receipt && formik?.errors?.gen_place_of_receipt ? (
                                                <div className="error">{formik?.errors?.gen_place_of_receipt}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Commodity </label>
                                            <input
                                                placeholder='Enter commodity'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_commodity}
                                                name="gen_commodity"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_commodity && formik?.errors?.gen_commodity ? (
                                                <div className="error">{formik?.errors?.gen_commodity}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Air Port of Loading <span>*</span>
                                            </label>
                                            <input
                                                placeholder='Enter air port of loading '
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_airport_of_landing}
                                                name="gen_airport_of_landing"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_airport_of_landing && formik?.errors?.gen_airport_of_landing ? (
                                                <div className="error">{formik?.errors?.gen_airport_of_landing}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Cargo Weight (Kg) <span>*</span></label>
                                            <Select
                                                className='mt-2'
                                                options={cargoType}
                                                value={cargoType.find(option => option.cargoId === formik.values.gen_cargo_weight) || null}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue('gen_cargo_weight', selectedOption.cargoId)
                                                }
                                                onBlur={formik.handleBlur}
                                            />

                                            {/* <input
                        placeholder='Enter cargo weight'
                        type="text"
                        className="form-control"
                        value={formik?.values?.gen_cargo_weight}
                        name="gen_cargo_weight"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      /> */}
                                            {formik?.touched?.gen_cargo_weight && formik?.errors?.gen_cargo_weight ? (
                                                <div className="error">{formik?.errors?.gen_cargo_weight}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Air Port of Discharge <span>*</span>
                                            </label>
                                            <input
                                                placeholder='Enter air port of discharge'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_airport_of_discharge}
                                                name="gen_airport_of_discharge"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_airport_of_discharge && formik?.errors?.gen_airport_of_discharge ? (
                                                <div className="error">{formik?.errors?.gen_airport_of_discharge}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Incoterms</label>
                                            <input
                                                placeholder='Enter incoterms'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_incoterms}
                                                name="gen_incoterms"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_incoterms && formik?.errors?.gen_incoterms ? (
                                                <div className="error">{formik?.errors?.gen_incoterms}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Tentative Transit Time <span>*</span>
                                            </label>
                                            <div className='custom-datepicker'>
                                                <DatePicker
                                                    placeholderText="Enter transit time"
                                                    selected={formik.values.gen_tentative_transit_time ? convertTo12HourDate(formik.values.gen_tentative_transit_time) : null}
                                                    onChange={(date) => formik.setFieldValue('gen_tentative_transit_time', date || null)}
                                                    onBlur={formik.handleBlur}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    className="form-control"
                                                    name="gen_tentative_transit_time"
                                                />
                                            </div>
                                            {/* <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.tentative_transit_time}
                        name="general_info.tentative_transit_time"
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('general_info.tentative_transit_time', num);
                        }}
                        onBlur={formik?.handleBlur}
                      /> */}
                                            {formik?.touched?.gen_tentative_transit_time && formik?.errors?.gen_tentative_transit_time ? (
                                                <div className="error">{formik?.errors?.gen_tentative_transit_time}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>
                                                Final Place of Delivery <span>*</span>
                                            </label>
                                            <input
                                                placeholder='Enter final place of delivery'
                                                type="text"
                                                className="form-control"
                                                value={formik?.values?.gen_find_place_of_delivery}
                                                name="gen_find_place_of_delivery"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_find_place_of_delivery && formik?.errors?.gen_find_place_of_delivery ? (
                                                <div className="error">{formik?.errors?.gen_find_place_of_delivery}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input_box_section">
                                            <label>Notes </label>
                                            <textarea
                                                rows={3}
                                                className="form-control"
                                                placeholder='Enter notes'
                                                value={formik?.values?.gen_notes}
                                                name="gen_notes"
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                            />
                                            {formik?.touched?.gen_notes && formik?.errors?.gen_notes ? (
                                                <div className="error">{formik?.errors?.gen_notes}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                    <div className="add-Company-button" style={{ cursor: "pointer" }} onClick={handleAddAirline}>
                      <span>
                        <img src="/img/twotone-add.png" alt="Add Company" />
                      </span>
                      Add Airline
                    </div>
                  </div> */}

                                </div>

                                <button className='search-btn text-white py-1' type="submit">Submit</button>
                            </form>
                            <div className="main-nav-det">
                                <h4>
                                    <span>
                                        <img src="/img/Product.png" alt="gen" />
                                    </span>
                                    Tariff Details
                                </h4>
                            </div>
                            {/* {rate[0]?.quote_tarrif && (typeof rate[0]?.quote_tarrif === 'object' && !Array.isArray(rate[0]?.quote_tarrif)) &&
                                Object.entries(rate[0]?.quote_tarrif).map(([airlineName, details]) => (
                                    <div className="flex_detail_main ">
                                        <div className="table_detail_tariff table-responsive">

                                            <table key={airlineName} className="posts-table geeks table-responsive">
                                                <thead>
                                                    <tr className="users-table-tarif-main">
                                                        <th>
                                                            <div className="main-box-det-check">
                                                                <label className="users-table__checkbox" id="new_text">
                                                                    <input type="checkbox" className="check" />
                                                                    {airlineName}
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th>Charge Head</th>
                                                        <th>Narration</th>
                                                        <th>Currency</th>
                                                        <th>Unit</th>
                                                        <th>Rate</th>
                                                        <th>FC Amount</th>
                                                        <th>Exchange Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {details.map((value, index) => (
                                                        <tr className="main_table_row_new" key={`${airlineName}-${index}`}>
                                                            <td>{value.airline_name}</td>
                                                            <td>{value.charge_head}</td>
                                                            <td>{value.narration}</td>
                                                            <td>{value.currency}</td>
                                                            <td>{value.unit}</td>
                                                            <td>{value.rate}</td>
                                                            <td>{value.fc_amount}</td>
                                                            <td>{value.exchange_rate}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="box_button_main">
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => {
                                                setAirlineId(details)
                                            }}>
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                        </div>
                                    </div>
                                ))
                            } */}
                            {/* {rate[0]?.quote_tarrif.map(([airlineName, details]) => ( */}
                            <div className="flex_detail_main ">
                                <div className="table_detail_tariff table-responsive">
                                    {groupedByAirlineCode && Object.entries(groupedByAirlineCode).map(([airline_code, tariffDetails], index) => (
                                        <React.Fragment key={airline_code}>
                                            <table className="posts-table geeks table-responsive">
                                                <thead>
                                                    <tr className="users-table-tarif-main">
                                                        <th>
                                                            {/* <div className="main-box-det-check">
                                                        <label className="users-table__checkbox" id="new_text">
                                                            <input type="checkbox" className="check" />
                                                         
                                                        </label>
                                                    </div> */}
                                                            Airline Code
                                                        </th>
                                                        <th>Charge Head</th>
                                                        <th>Narration</th>
                                                        <th>Currency</th>
                                                        <th>Unit</th>
                                                        <th>Rate</th>
                                                        <th>FC Amount</th>
                                                        <th>Exchange Rate</th>
                                                    </tr>
                                                </thead>
                                                {tariffDetails.map((value, subIndex) => (
                                                    <tr className="main_table_row_new" key={`${airline_code}-${subIndex}`}>
                                                        <td>{value.airline_code}</td>
                                                        <td>{value.charge_head}</td>
                                                        <td>{value.narration}</td>
                                                        <td>{value.currency}</td>
                                                        <td>{value.unit_id}</td>
                                                        <td>{value.rate}</td>
                                                        <td>{value.fc_amount}</td>
                                                        <td>{value.exchange_rate}</td>
                                                    </tr>
                                                ))}
                                            </table>
                                            <div className="box_button_main">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => {
                                                    setAirlineId(rate[0]?.quote_tarrif)
                                                }}>
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                            </div>
                                        </React.Fragment>
                                    ))}



                                </div>
                                {/* <div className="box_button_main">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => {
                                        setAirlineId(rate[0]?.quote_tarrif)
                                    }}>
                                        <img src="/img/edit-line.png" alt="edit" />
                                    </a>
                                </div> */}
                            </div>
                            {/* ))
                            } */}

                            <div className="main-nav-det-button text-right main-Export-button">
                                <button className='search-btn text-white py-1'>Download Quotation</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ViewRateModal airlineId={airlineId} modal={"myModal"} tariffDetails={rate[0]?.quote_tarrif} GetUserInfo={GetUserInfo} />
        </>
    )
}

export default EditAirExportForm