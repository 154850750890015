import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminMainUrl } from "../../../Config";
import Cookies from "js-cookie";
import { BeatLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RemarkModal } from "../../Modal/RemarkModal";
import DatePicker from "react-datepicker";
import Select from 'react-select'
import * as Yup from "yup";
import Modal from 'react-bootstrap/Modal';
import QueryInfoAirExportForm from "./QueryInfoAirExportForm";
import { useDispatch, useSelector } from "react-redux";
import Beatloader from "../../../Common/Loaders/Beatloader";
import { getQuoteCompGenInfo } from "../../../Redux/action/Sales/QuoteAirExportCompGenInfoAction";
import QueryInfoAirExportFormRepricing from "./QueryInfoAirExportFormRepricing";
import EditQuotationForm from "../../Modal/EditQuotationForm";

const QueryInfo = () => {
    const dispatch = useDispatch();
    const [quotationId, setQuotationId] = useState(null);
    const [spinnerLoader, setSpinnerLoader] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [viewData, setViewData] = useState(null);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const [navigateBack, setNavigateBack] = useState("all");
    const [showPageLoader, setShowPageLoader] = useState(false);
    //Modal
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showRepricing, setShowRepricing] = useState(false);
    const handleCloseRepricing = () => setShowRepricing(false);
    const handleShowRepricing = () => setShowRepricing(true);

    const token = Cookies.get("token");
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;

    useEffect(() => {
        if (location.state?.quotationId) {
            setQuotationId(location.state.quotationId);
        }
    }, [location.state]);


    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
        } else {
            dispatch(getQuoteCompGenInfo(token, location.state.quotationId));
        }
    }, [token]);

    const { loadingQuoteCompGenInfoApi, quoteCompGenInfoDataError, quoteCompGenInfoData } = useSelector(state => state?.QuoteAirExportCompGenInfoReducer);

    useEffect(() => {
        if (loadingQuoteCompGenInfoApi) {
            setShowPageLoader(true)
        } else {
            setShowPageLoader(false)
        }

        // console.log(quoteCompGenInfoData);

    }, [loadingQuoteCompGenInfoApi, quoteCompGenInfoDataError, quoteCompGenInfoData])

    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    const handleToggle = () => {
        setIsVisible(!isVisible);
    };

    const ViewQuotation = async (information) => {
        setSpinnerLoader(true)
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/view`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            if (!response.ok) {
                setSpinnerLoader(false);
                toast.error(data?.msg);
                setIsVisible(false);
                navigate(-1)
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // toast.success(data?.msg);
            setViewData(data?.data);
            setIsVisible(false)
            setSpinnerLoader(false);
        } catch (error) {
            setIsVisible(false)
            setSpinnerLoader(false);
            console.log(error);
        }
    };

    const priceView = async (quotationId) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/to_be_price_viewed`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            const data = await response.json();
            if (!response.ok) {
                // toast.error(data?.msg);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            toast.success(data?.msg);
        } catch (error) {
            // toast.error(error);
            console.log(error);
        }
    };


    const sendPricing = async (quotationId) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/to_be_sent_to_pricing`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            const data = await response.json();
            if (!response.ok) {
                toast.error(data?.msg);
                throw new Error(`HTTP error! status: ${response.status}`);

            }
            toast.success(data?.msg);
            navigate("/admin-dashboard/query-list", { state: { quotationId: quotationId } });

        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    };
    const sentQuotation = async (quotationId) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/to_be_quotation_sent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
                toast.error(data?.msg);
            }
            toast.success(data?.msg);

        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    };

    const ConvertedType = async (quotationId) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/to_be_converted`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
                toast.error(data?.msg);
            }
            toast.success(data?.msg);
            navigate(`/admin-dashboard/query-list`, { state: { quotationId: "7" } });
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    };
    const NotConverted = async (quotationId) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quotation_id: `${quotationId}`,
            };
            const response = await fetch(`${adminMainUrl}/api/quotation/to_be_not_converted`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(requesId),
            });
            const data = await response.json();
            if (!response.ok) {
                toast.error(data?.msg);
                // throw new Error(`HTTP error! status: ${response.status}`);
            }
            toast.success(data?.msg);
            navigate(`/admin-dashboard/query-list`, { state: { quotationId: "8" } });
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    };
    useEffect(() => {
        if (quotationId) {
            ViewQuotation();
        }
    }, [quotationId]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const parsedFiles = JSON.parse(viewData?.files || "[]");

    useEffect(() => {
        if (viewData) {
            if (viewData?.quote_status && viewData?.quote_status?.name == "Sent for Price") {
                setNavigateBack("3");
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Picked") {
                setNavigateBack("2")
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Sent for Price") {
                setNavigateBack("3")
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Price Received") {
                setNavigateBack("4")
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Price Viewed") {
                setNavigateBack("5")
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Quotation Sent") {
                setNavigateBack("6")
            }
            else if (viewData?.quote_status && viewData?.quote_status?.name == "Quotation Sent") {
                setNavigateBack("6")
            }
            else {
                setNavigateBack("all");
            }


            // console.log(viewData.quote_status)
        }
    }, [viewData]);

    const formik = useFormik({
        initialValues: {
            remarks: '', // Add an initial value for remarks
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setIsSubmitting(true);

            try {
                const token = Cookies.get("token");
                const requestId = {
                    quotation_id: `${quotationId}`,
                    remarks: values.remarks, // Pass the remarks from the form
                };
                const response = await fetch(`${adminMainUrl}/api/quotation/store/remarks`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-token": token,
                    },
                    body: JSON.stringify(requestId),
                });

                const result = await response.json(); // Parse the response

                resetForm(); // Reset form fields
                toast.success(result.msg); // Display the success message
                ViewQuotation();
                setSubmitting(false);
            } catch (error) {
                console.error(error);
                setSubmitting(false);
            } finally {
                setIsSubmitting(false); // Stop the spinner
                setIsVisible(false)
            }
        },
    });
    return (
        <>
            <div className="container">
                <div className="col-md-12 px-0">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2 className="d-flex justify-content-start align-items-center gap-2">
                                        {/* <Link
                                            to="/admin-dashboard/query-list"
                                            className="query_info"
                                        > */}
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => { navigate("/admin-dashboard/query-list", { state: { quotationId: navigateBack } }) }}
                                        >

                                            <img src="/img/re_darect.png" alt="Query" />
                                        </div>
                                        {/* </Link> */}
                                        Query Information
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        {spinnerLoader ? (
                            <div
                                className="d-flex justify-content-center align-items-center new_infometion_table"
                                style={{ height: "400px", width: "100%" }}
                            >
                                <BeatLoader />
                            </div>
                        ) : (
                            <>
                                <div className="new_infometion_table p-0">
                                    <div className="information-box d-sm-flex justify-content-between py-0 pb-sm-0 pb-3">
                                        <h4 className="docket-text mb-0 text-white">General Information</h4>
                                        <div className="d-flex justify-content-start gap-2 align-items-center">

                                            <p className="mb-0 request-no text-white">Request Number</p>
                                            <p className="mb-0 text-white">:</p>
                                            <p className="mb-0 text-white">{viewData?.request_no}
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" px-md-4 px-3 view-info-box">
                                        {/* <h4 className="docket-text mba-3">General Information</h4> */}

                                        <div className="row">

                                            <div className="col-lg-4 col-md-4 py-2">
                                                <div className="d-flex justify-content-start gap-2 py-2 align-items-center">
                                                    <p className="text-custom mb-0">Name</p>
                                                    <p className="mb-0">{viewData?.full_name}</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 py-2">
                                                <div className="d-flex justify-content-md-center gap-2 py-2 align-items-center">
                                                    <p className="text-custom mb-0">Email</p>
                                                    <p className="mb-0">{viewData?.email}</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 py-2">
                                                <div className="d-flex justify-content-md-end gap-2 py-2 align-items-center">
                                                    <p className="text-custom mb-0">Phone Number</p>
                                                    <p className="mb-0">{viewData?.phone}</p>

                                                </div>
                                            </div>

                                            {/* <div className="col-lg-4 col-md-6 py-2">
                                                <p className="font-medium">Email</p>
                                                <div className="box-query">
                                                    <p className="mb-0">{viewData?.email}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 py-2">
                                                <p className="font-medium">Phone Number</p>
                                                <div className="box-query">
                                                    <p className="mb-0">{viewData?.phone}</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="new_infometion_table p-0">
                                    <div className="information-box">  <h4 className="docket-text mba-3 text-white">Pickup Information</h4></div>
                                    <div className="pb-4 px-md-4 px-3 view-info-box">

                                        <div className="row">
                                            <div className=" col-md-6 py-2">
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Origin </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.origin}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Destination</p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.destination}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Product Name </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.product_name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Product Type</p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.product_type?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Shipment Type</p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.shipment_type?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className=" col-md-6 py-2">
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Shipment Mode </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.shipment_mode?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Shipment Via </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.shipment_via?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Cargo Type </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.cargo_type?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Required Service </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{viewData?.required_service?.name}</p>
                                                    {/* </div> */}
                                                </div>
                                                <div className="d-flex justify-content-start gap-2 py-2">
                                                    <p className="font-medium">Expected Transit Date </p>
                                                    {/* <div className="box-query"> */}

                                                    <p className="mb-0 text-sm">{formatDate(viewData?.expected_transit_date)}</p>
                                                    {/* </div> */}
                                                </div>
                                            </div>


                                            <div className="col-md-12 py-2">
                                                <p className="font-medium mb-3">Detailed Requirement</p>
                                                <div className="box-query">
                                                    <p className="mb-0">{viewData?.detailed_requirement}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 py-2 ">
                                                <p className="font-medium mb-3">Package Dimensions/Weight</p>
                                                <div className="table-responsive">
                                                    <table className="posts-table mb-0 ">

                                                        <tbody>
                                                            <tr>
                                                                <td className="p-0">
                                                                    <tr>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Length
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Width
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Height
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Type
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Max Weight
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Unit
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Gross Weight
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Gross Weight Unit
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            Total Box
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        Array.isArray(viewData?.package_info) && viewData.package_info.length > 0 ? (
                                                                            viewData.package_info.map((value, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.length || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.width || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.height || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.package_type?.name || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.max_weight || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.gross_weight_unit?.name || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.gross_weight || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.gross_weight_unit?.name || 'N/A'}
                                                                                        </td>
                                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                                            {value?.total_boxes || 'N/A'}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7" style={{ border: "1px solid #c7c7c7", textAlign: "center" }}>
                                                                                    No data available
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="posts-table ">
                                                    <tbody>
                                                        <tr>
                                                            <td className="send_query_list_button w-fit p-0 send-query" style={{ background: "none", color: "#000" }}>
                                                                <div className="remark-section" >
                                                                    <div className="view-remark-btn">
                                                                        <p id="remarks" onClick={handleToggle} className="text-start mb-0" style={{ marginRight: '10px', cursor: 'pointer' }}>
                                                                            Add Remark
                                                                        </p>
                                                                    </div>
                                                                    <form onSubmit={formik.handleSubmit}>
                                                                        <div id="remarks" style={{ display: isVisible ? "block" : "none" }}>
                                                                            <textarea
                                                                                id="remarks-input"
                                                                                name="remarks"
                                                                                className="form-control"
                                                                                value={formik.values.remarks}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                placeholder="Write Something Here...."
                                                                                style={{ marginRight: '10px' }}
                                                                            ></textarea>
                                                                            {formik.touched.remarks && formik.errors.remarks ? (
                                                                                <div className="error" style={{ color: 'red' }}>{formik.errors.remarks}</div>
                                                                            ) : null}
                                                                            <div className="text-end">
                                                                                <button className="send_query_list_button send-query desc-btn py-1" disabled={isSubmitting}>
                                                                                    {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Add</div> : "Add"}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="new_infometion_table p-0">
                                    <div className="information-box">  <h4 className="docket-text mba-3 text-white">Documents or Videos</h4></div>
                                    <div className="pb-4 px-md-4 px-3 view-info-box">

                                        <div className="row">
                                            <div className=" col-md-8 py-2">
                                                <p className="font-medium">Shipment Document </p>
                                                <div className="col-8 input_box_section file-input-main query-info-flex">
                                                    {parsedFiles?.length > 0 ? (
                                                        parsedFiles?.map((value, index) => {
                                                            const fileType = value.split('.').pop().toLowerCase();
                                                            return (
                                                                <a
                                                                    href={`${adminMainUrl}/${value}`}
                                                                    target="blank"
                                                                >
                                                                    <button
                                                                        className="folder_main_update_query"
                                                                        key={index}
                                                                    >

                                                                        {fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? (
                                                                            <img className="document-img" src={`${adminMainUrl}/${value}`} alt="" />
                                                                        ) : (
                                                                            fileType === 'doc' || fileType === 'docx' || fileType === 'xls' || fileType === 'xlsx' ? (

                                                                                <iframe
                                                                                    src={`https://docs.google.com/viewer?url=${adminMainUrl}/${value}&embedded=true&embedded=true&toolbar=0`}
                                                                                    frameBorder="0"
                                                                                    width="100%"
                                                                                    height="100%"
                                                                                    scrolling="no"
                                                                                    style={{
                                                                                        overflow: 'auto',
                                                                                        backgroundColor: 'white',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <iframe
                                                                                    src={`${adminMainUrl}/${value}`}
                                                                                    frameBorder="0"
                                                                                    width="100%"
                                                                                    height="100%"
                                                                                    scrolling="no"
                                                                                    style={{ overflow: 'hidden' }}
                                                                                />
                                                                            )
                                                                        )}
                                                                        <span>
                                                                            <img
                                                                                src="/img/download-icon.png"
                                                                                alt="file"
                                                                            />
                                                                        </span>
                                                                    </button>
                                                                </a>
                                                            );
                                                        })
                                                    ) : (
                                                        <div>no file selected</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className=" col-md-4 py-2">
                                                <p className="font-medium">Shipment Video</p>
                                                <div className="col-6 input_box_section file-input-main query-info-flex">
                                                    {viewData?.videos ? (
                                                        <a
                                                            href={`${adminMainUrl}/${viewData?.videos}`}
                                                            target="blank"
                                                        >
                                                            <button className="folder_main_update_query">

                                                                <video className="document-video" width="100%" height="100%" controls>
                                                                    <source src={`${adminMainUrl}/${viewData?.videos}`} type="video/mp4" />
                                                                </video>
                                                                {/* <img className="document-img" src={`${adminMainUrl}/${viewData?.videos}`} alt="" /> */}


                                                                <span>
                                                                    <img
                                                                        src="/img/download-icon.png"
                                                                        alt="file"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </a>
                                                    ) : (
                                                        <div>no file selected</div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>




                                    </div>
                                </div>

                                <div className="new_infometion_table">
                                    <div className=" px-md-4 px-3 view-info-box">
                                        <div className=" mt-2">
                                            {
                                                viewData?.quote_status?.name === "Price Received" ?
                                                    <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                        <div className="">
                                                            <div className="send_query_list_button send-query" onClick={() => {
                                                                navigate(`/admin-dashboard/view-rates/${btoa(quotationId)}`);
                                                                priceView(quotationId)
                                                            }}>
                                                                View Rates
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    viewData?.quote_status?.name === "Converted" ?
                                                        <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                            <div className="">
                                                                <div className="send_query_list_button send-query" onClick={() => { navigate('/admin-dashboard/query-follow-up-summary-converted') }}>
                                                                    Follow-Up
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        viewData?.quote_status?.name === "Not Converted" ?
                                                            <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                                <div className="">
                                                                    <div className="send_query_list_button send-query" onClick={() => { navigate('/admin-dashboard/query-follow-up-summary-converted') }}>
                                                                        Follow-Up
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            viewData?.quote_status?.name === "Price Viewed" ?
                                                                <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                                    <div className="">
                                                                        <button className="send_query_list_button send-query" onClick={() => {
                                                                            // sendPricing(viewData?.id)
                                                                            // navigate("/admin-dashboard/all-rate-request", { state: { quotationId: quotationId } });
                                                                            handleShowRepricing();
                                                                        }}>
                                                                            Repricing
                                                                        </button>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="send_query_list_button send-query" onClick={() => {
                                                                            navigate(`/admin-dashboard/query-list`, { state: { quotationId: "6" } });
                                                                            sentQuotation(viewData?.id)
                                                                        }}>
                                                                            Send to Client
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="send_query_list_button send-query" onClick={() => { navigate(`/admin-dashboard/view-rates/${btoa(quotationId)}`) }}>
                                                                            View Rates
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                viewData?.quote_status?.name === "Quotation Sent" ?
                                                                    <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                                        <div className="">
                                                                            <div className="send_query_list_button send-query" onClick={() => {
                                                                                ConvertedType(viewData?.id)
                                                                            }}>
                                                                                Converted
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="send_query_list_button send-query" onClick={() => {
                                                                                NotConverted(viewData?.id)
                                                                            }}>
                                                                                Not Converted
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
                                                                        <div className="">
                                                                            <div
                                                                                className="send_query_list_button send-query"
                                                                                onClick={() => {
                                                                                    navigate("/admin-dashboard/query-list", { state: { quotationId: navigateBack } });
                                                                                }}
                                                                            >
                                                                                Back
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <div
                                                                                className="send_query_list_button send-query"
                                                                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                                                            // onClick={() => {
                                                                            //     navigate("/admin-dashboard/edit-quote-request-form", {
                                                                            //         state: {
                                                                            //             ViewDetails: viewData,
                                                                            //             quotationId: quotationId,
                                                                            //         },
                                                                            //     });
                                                                            // }}
                                                                            >
                                                                                Edit
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            viewData?.last_status_id !== 3 &&
                                                                            <div className="">
                                                                                <div
                                                                                    className="send_query_list_button send-query"
                                                                                    onClick={() => {
                                                                                        // sendPricing(viewData?.id)
                                                                                        // navigate("/admin-dashboard/query-list", { state: { quotationId: 3 } });
                                                                                        handleShow()
                                                                                    }}
                                                                                >

                                                                                    Send for Pricing
                                                                                </div>



                                                                            </div>
                                                                        }
                                                                        {
                                                                            viewData?.quote_remarks?.length > 0 &&

                                                                            <div className="">
                                                                                <div
                                                                                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft"
                                                                                    // data-bs-toggle="modal" data-bs-target="#offcanvas"
                                                                                    className="send_query_list_button send-query"
                                                                                >
                                                                                    View Remark
                                                                                </div>
                                                                            </div>

                                                                        }
                                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                </div >
            </div >
            <EditQuotationForm id="offcanvasRight" QuotationId={quotationId} ViewDetails={viewData} />
            {
                viewData !== null &&
                <RemarkModal viewData={viewData} id="offcanvasLeft" />
            }
            {/* {/ Pricing /} */}
            <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Quotation Air Export Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main_detail_form Air_Export_Form_bg">
                        <QueryInfoAirExportForm quotationId={quotationId} handleClose={handleClose} quoteCompGenInfoData={quoteCompGenInfoData} />
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                            <button variant="secondary" onClick={handleClose}>
                                Close
                            </button>
                            <button variant="primary">Understood</button>
                        </Modal.Footer> */}
            </Modal>

            {/* Repricing  */}
            <Modal
                size="xl"
                show={showRepricing}
                onHide={handleCloseRepricing}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Quotation Air Export Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main_detail_form Air_Export_Form_bg">
                        <QueryInfoAirExportFormRepricing quotationId={quotationId} handleClose={handleCloseRepricing} quoteCompGenInfoData={quoteCompGenInfoData} />
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                            <button variant="secondary" onClick={handleCloseRepricing}>
                                Close
                            </button>
                            <button variant="primary">Understood</button>
                        </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default QueryInfo;
