import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
// import KycApprovalRequest from '../../Kyc_Onboarding/Dashboard/KycApprovalRequest';
import SideBar from './SideBar';
import Navbar from './Navbar';
// import CustomerRegistration from '../../Kyc_Onboarding/Dashboard/KycRegistrationForm/KycRegistration';
// import RegistrationAgreement from '../../Kyc_Onboarding/Dashboard/KycRegistrationAgreement/RegistrationAgreement';
import QuoteRequestForm from './QuoteRequestForm/QuoteRequestForm';
import QueryList from './QueryList/QueryList';
import AllRateRequest from './AllRateRequest/AllRateRequest';
import AdminDashboard from './AdminDashboard/AdminDashboard';
import ViewRate from './ViewRate/ViewRate';
import QueryInfoGiveRate from './QueryInfoGiveRate/QueryInfoGiveRate';
import QueryInfo from './QueryInfo/QueryInfo';
import QuotationAirExportForm from './QuotationAirExportForm/QuotationAirExportForm';
import QuotationAirImportForm from './QuotationAirImportForm/QuotationAirImportForm';
import QuotationSeaExportForm from './QuotationSeaExportForm/QuotationSeaExportForm';
import QuotationSeaImportForm from './QuotationSeaImportForm/QuotationSeaImportForm';
import QuotationConverted from './QuotationConverted/QuotationConverted';
import QueryFollowUpForm from './QueryFollowUpForm/QueryFollowUpForm';
import QueryFollowUpSummary from './QueryFollowUpSummary/QueryFollowUpSummary';
import QueryList1 from './QueryList/QueryList1';
import AllRateRequest1 from './AllRateRequest/AllRateRequest1';
import AllEmployeeList from './AllEmployeeList/AllEmployeeList';
import EmployeeDetails from './EmployeeDetails/EmployeeDetails';
import EmployeeRegistration from './EmployeeRegistration/EmployeeRegistration';
import SalesDashboard from './SalesDashboard/SalesDashboard';
import PickedLeads from './PickedLeads/PickedLeads';
import ConvertedLeads from './ConvertedLeads/ConvertedLeads';
import NonConvertedLeads from './NonConvertedLeads/NonConvertedLeads';
import QueryInfoPicked from './QueryInfoPicked/QueryInfoPicked';
import QueryInfoConverted from './QueryInfoConverted/QueryInfoConverted';
import QueryInfoNonConverted from './QueryInfoNonConverted/QueryInfoNonConverted';
import QueryFollowUpSummaryConverted from './QueryFollowUpSummaryConverted/QueryFollowUpSummaryConverted';
import QueryCRM from './QueryCRM/QueryCRM';
import PickedRateRequestList from './PickedRateRequestList/PickedRateRequestList';
import SendPricingList from './SendPricingList/SendPricingList';
import QueryInfoPricingPicked from './QueryInfoPricingPicked/QueryInfoPricingPicked';
import QueryInfoSend from './QueryInfoSend/QueryInfoSend';
import AirImport from './FreightForwarding/AirImport';
import { DocketNumberStepper } from './FreightForwarding/DocketNumberCreation/DocketNumberStepper';
import KycRegistration from './KycRegistrationForm/KycRegistration';
import KycApprovalRequest from './KycApprovalRequest/KycApprovalRequest';
import OperationDashboard from './OperationDashboard/OperationDashboard';
import Transport from './Transport/Transport';
import Edi from './Edi/Edi';
import DocumentManagement from './DocumentManagement/DocumentManagement';
import Warehouse from './Warehouse/Warehouse';
import RegistrationAgreement from './KycRegistrationAgreement/RegistrationAgreement';
import CustomClearanceAirExport from './CustomClearanceAirExport/CustomClearanceAirExport';
import CustomClearanceAirImport from './CustomClearanceAirImport/CustomClearanceAirImport';
import CustomClearanceSeaExport from './CustomClearanceSeaExport/CustomClearanceSeaExport';
import CustomClearanceSeaImport from './CustomClearanceSeaImport/CustomClearanceSeaImport';
import AirExport from './FreightForwarding/AirExport';
import SeaImport from './FreightForwarding/SeaImport';
import SeaExport from './FreightForwarding/SeaExport';
import DocketNumberExport from './FreightForwarding/DocketNumberExport/DocketNumberExport';
import DocketNumberList from './FreightForwarding/DocketNumberList';
import ShipmentRegistration from './FreightForwarding/ShipmentRegistration/ShipmentRegistration';
import DocketNumberGeneratedList from './FreightForwarding/DocketNumberGeneratedList';
import AgentDetails from './FreightForwarding/AgentDetails';
import TrackingGoods from './FreightForwarding/TrackingGoods';
import { SeaImportDocketNumberList } from './FreightForwarding/SeaImport/SeaImportDocketNumberList';
import SeaImportDocketNumberGeneratedList from './FreightForwarding/SeaImport/SeaImportDocketNumberGeneratedList';
import SeaImportAgentDetails from './FreightForwarding/SeaImport/SeaImportAgentDetails';
import TrackingGoodSeaImport from './FreightForwarding/SeaImport/TrackingGoodSeaImport';
import ExportDocketNumberGeneratedList from './FreightForwarding/DocketNumberExport/ExportDocketNumberGeneratedList';
import AirwayBillNumberStoring from './FreightForwarding/DocketNumberExport/AirwayBillNumberStoring';
import DocketInformation from './FreightForwarding/DocketInformation';
import AirwaybillStockList from './FreightForwarding/AirwaybillStockList';
import AirwayBillStockForm from './FreightForwarding/AirwayBillStockForm';
import PersonalDetailsPage from './EmployeeRegistration/SeperateForms/PersonalDetailsPage';
import ProductType from './Masters/ProductType';
import ShipmentType from './Masters/ShipmentType';
import ShipmentMode from './Masters/ShipmentMode';
import ShipmentVia from './Masters/ShipmentVia';
import CargoType from './Masters/CargoType';
import Department from './Masters/Department';
import IdProof from './Masters/IdProofType';
import Package from './Masters/PackageType';
import Gender from './Masters/Gender';
import Designation from './Masters/Designation';
import WeightUnit from './Masters/WeightUnit';
import RequiredService from './Masters/RequiredService';
import EmployeeRegistrationUpdate from './EmployeeRegistration/EmployeeRegistrationUpdate';
import RoleList from './RolePermission/RoleList';
import RoleAddForm from './RolePermission/RoleAddForm';
import EditQuoteRequestForm from './QuoteRequestForm/EditQuoteRequestForm';
import RoleView from './RolePermission/RoleView';
import RoleEdit from './RolePermission/RoleEdit';
import Unauthorized from '../../Common/Unauthorized/Unauthorized';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Beatloader from '../../Common/Loaders/Beatloader';
import EditAirExportForm from './QuotationAirExportForm/EditAirExportForm';
import ViewPriceList from './SendPricingList/ViewPriceList';
// import PickedRateRequestList2 from './PickedRateRequestList/PickedRateRequestList2';
import PickedRateRequestListInProcess from './PickedRateRequestList/PickedRateRequestListInProcess';
import PickedRateRequestListReprice from './PickedRateRequestList/PickedRateRequestListReprice';
import { ProfilePage } from '../Auth/Login/ProfilePage';
// import TrackingGoodSeaImport from './FreightForwarding/SeaImport/TrackingGoodSeaImport';
// import HRMS from './HRMS/HRMS';
import Account from './Accounts/Account';
// import Operation from './Operations/Operation';

const Dashboard = ({ accessedModules, accessedPermission, userInfo, roleInfo }) => {
  // console.log(accessedPermission, accessedModules);
  // console.log(userInfo)
  const [showSideBar, setShowSideBar] = useState(false);
  const [userdata, setUserData] = useState({})
  const userData = sessionStorage.getItem('userData');

  useEffect(() => {
    if (userData) {
      setUserData(JSON.parse(userData));
    }
  }, [userData]);

  const navigate = useNavigate()
  const token = Cookies.get('token');
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  const hasPermission = (requiredPermissions) => {
    return requiredPermissions.every(permission => accessedPermission.includes(permission));
  };

  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.");
      navigate(`/admin-login?burl=${encodeURIComponent(q)}`);
    }
  }, []);

  // In your login component, after successful login
  // useEffect(() => {
  //   const burl = new URLSearchParams(window.location.search).get('burl');
  //   if (burl) {
  //     navigate(burl);
  //   }
  // }, []);

  // if (encodeURIComponent(q)) {
  //   const decodedString = decodeURIComponent(encodeURIComponent(q));
  //   navigate(decodedString);
  // } else {
  //   // If no `burl` exists, navigate to the default dashboard
  //   // navigate('/admin-dashboard/admin-dashboard');
  // }
  const [mode, setMode] = useState(true)
  return (
    <div>
      {Cookies.get("token") ?
        accessedModules && accessedPermission ?
          <div className="page-flex">
            {/* ! Sidebar */}
            <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} accessedModules={accessedModules} accessedPermission={accessedPermission} userInfo={userInfo} />
            <div className="main-wrapper">
              {/* ! Main nav */}
              <Navbar setMode={setMode} mode={mode} setShowSideBar={setShowSideBar} showSideBar={showSideBar} userInfo={userInfo} roleInfo={roleInfo} />
              {/* ! Main */}
              <main className="main users chart-page" id="skip-target">
                <Routes>
                  <Route index element={<Navigate to="/admin-dashboard" />} />
                  <Route path='profile-page' element={<ProfilePage />} />
                  {/* <Route path='Kyc-approval-request' element={<KycApprovalRequest/>} /> */}
                  {/* <Route path='customer-registration-form' element={<CustomerRegistration/>} /> */}
                  {/* <Route path='customer-registration-agreement' element={<RegistrationAgreement/>} /> */}
                  <Route path='all-rate-request' element={<AllRateRequest />} />
                  <Route path='admin-dashboard' element={<AdminDashboard />} />
                  <Route path='query-info-give-rates' element={hasPermission(["view_pricing_query"]) ? <QueryInfoGiveRate accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='view-rates/:b64id' element={<ViewRate />} />
                  <Route path='query-information' element={<QueryInfo />} />
                  <Route path='quotation-air-export' element={<QuotationAirExportForm />} />
                  <Route path='quotation-air-import' element={<QuotationAirImportForm />} />
                  <Route path='quotation-sea-export' element={<QuotationSeaExportForm />} />
                  <Route path='quotation-sea-import' element={<QuotationSeaImportForm />} />
                  <Route path='quotation-converted' element={<QuotationConverted />} />
                  <Route path='query-follow-up-form' element={<QueryFollowUpForm />} />
                  <Route path='query-follow-up-summary' element={<QueryFollowUpSummary />} />
                  <Route path='view-updated-query-list' element={<QueryList1 />} />
                  <Route path='selected-rate-list' element={<AllRateRequest1 />} />
                  <Route path='query-list' element={hasPermission(["query_list"]) ? <QueryList accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='all-employee-list' element={hasPermission(['view_employees']) ? <AllEmployeeList accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='employee-details/:id' element={hasPermission(['view_employees']) ? <EmployeeDetails accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='employee-registration' element={hasPermission(['add_employee']) ? <EmployeeRegistration accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='employee-registration-update' element={hasPermission(['view_employees', "edit_employee"]) ? <EmployeeRegistrationUpdate accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  {/* <Route path='employee-registration/personal-details' element={<PersonalDetailsPage />} />
              <Route path='employee-registration/official-details' element={<EmployeeRegistration />} />
              <Route path='employee-registration/account-details' element={<EmployeeRegistration />} /> */}
                  <Route path='quote-request-form' element={hasPermission(["add_quote_request_form"]) ? <QuoteRequestForm accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='edit-quote-request-form' element={hasPermission(["add_quote_request_form"]) ? <EditQuoteRequestForm accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='sales-dashboard' element={hasPermission(["view_sales_dashboard"]) ? <SalesDashboard accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='picked-leads' element={hasPermission(["picked_lead"]) ? <PickedLeads accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='converted-leads' element={hasPermission(["converted_lead"]) ? <ConvertedLeads accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='non-converted-leads' element={hasPermission(["non_converted_lead"]) ? <NonConvertedLeads accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='query-info-picked' element={<QueryInfoPicked />} />
                  <Route path='query-info-converted' element={<QueryInfoConverted />} />
                  <Route path='query-info-non-converted' element={<QueryInfoNonConverted />} />
                  <Route path='query-follow-up-summary-converted' element={<QueryFollowUpSummaryConverted />} />
                  <Route path='query-crm' element={<QueryCRM />} />
                  <Route path='picked-rate-request-list' element={<PickedRateRequestListReprice />} />
                  <Route path='picked-rate-request-list-inprocess' element={<PickedRateRequestListInProcess />} />
                  <Route path='send-pricing-list' element={<SendPricingList />} />
                  <Route path='query-info-pricing-picked' element={<QueryInfoPricingPicked />} />
                  <Route path='query-info-send' element={<QueryInfoSend />} />
                  <Route path='freight-forwarding/air-import' element={<AirImport />} />
                  <Route path='freight-forwarding/docket-number-creation' element={<DocketNumberStepper />} />
                  <Route path='freight-forwarding/docket-number-creation-export' element={<DocketNumberExport />} />
                  <Route path='kyc-approval-request' element={<KycApprovalRequest />} />
                  <Route path='operation-dashboard' element={<OperationDashboard />} />
                  <Route path='transport' element={<Transport />} />
                  <Route path='edi' element={<Edi />} />
                  <Route path='document-management' element={<DocumentManagement />} />
                  <Route path='warehouse' element={<Warehouse />} />
                  <Route path='customer-registration-form' element={<KycRegistration />} />
                  <Route path='customer-registration-agreement' element={<RegistrationAgreement />} />
                  <Route path='customer-clearance-air-export' element={<CustomClearanceAirExport />} />
                  <Route path='customer-clearance-air-import' element={<CustomClearanceAirImport />} />
                  <Route path='customer-clearance-sea-export' element={<CustomClearanceSeaExport />} />
                  <Route path='customer-clearance-sea-import' element={<CustomClearanceSeaImport />} />
                  <Route path='freight-forwarding/air-export' element={<AirExport />} />
                  <Route path='freight-forwarding/sea-import' element={<SeaImport />} />
                  <Route path='freight-forwarding/sea-export' element={<SeaExport />} />
                  <Route path='freight-forwarding/docket-number-list' element={<DocketNumberList />} />
                  <Route path='docket-number-generated-list' element={<DocketNumberGeneratedList />} />
                  <Route path='shipment-registration' element={<ShipmentRegistration />} />
                  <Route path='agent-details' element={<AgentDetails />} />
                  <Route path='tracking-goods' element={<TrackingGoods />} />
                  <Route path='sea-import-docket-number-list' element={<SeaImportDocketNumberList />} />
                  <Route path='sea-import-docket-number-generated-list' element={<SeaImportDocketNumberGeneratedList />} />
                  <Route path='export-docket-number-generated-list' element={<ExportDocketNumberGeneratedList />} />
                  <Route path='airway-bill-number-storing' element={<AirwayBillNumberStoring />} />
                  <Route path='sea-import-agent-details' element={<SeaImportAgentDetails />} />
                  <Route path='sea-import-tracking-goods' element={<TrackingGoodSeaImport />} />
                  <Route path='docket-information' element={<DocketInformation />} />
                  <Route path='airway-bill-stock-list' element={<AirwaybillStockList />} />
                  <Route path='airway-bill-stock-form' element={<AirwayBillStockForm />} />
                  <Route path='edit-air-export-form' element={<EditAirExportForm />} />
                  <Route path='view-price-information' element={hasPermission(['view_pricing_query']) ? <ViewPriceList accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  {/* role permission */}
                  <Route path='roles' element={hasPermission(['view_roles']) ? <RoleList accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='roles/list' element={hasPermission(['view_roles']) ? <RoleList accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='role/add' element={hasPermission(['add_roles']) ? <RoleAddForm accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='role/view/:b64id' element={hasPermission(['view_roles']) ? <RoleView accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />
                  <Route path='role/edit/:b64id' element={hasPermission(['edit_roles']) ? <RoleEdit accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />} />

                  {/* masters */}
                  <Route path='product-type' element={hasPermission(["view_master_data"]) ? <ProductType accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='shipment-type' element={hasPermission(["view_master_data"]) ? <ShipmentType accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='shipment-mode' element={hasPermission(["view_master_data"]) ? <ShipmentMode accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='shipment-via' element={hasPermission(["view_master_data"]) ? <ShipmentVia accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='cargo-type' element={hasPermission(["view_master_data"]) ? <CargoType accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='department' element={hasPermission(["view_master_data"]) ? <Department accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='id-proof' element={hasPermission(["view_master_data"]) ? <IdProof accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='package-type' element={hasPermission(["view_master_data"]) ? <Package accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='gender-type' element={hasPermission(["view_master_data"]) ? <Gender accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='designation' element={hasPermission(["view_master_data"]) ? <Designation accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='weight-unit' element={hasPermission(["view_master_data"]) ? <WeightUnit accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  <Route path='required-service' element={hasPermission(["view_master_data"]) ? <RequiredService accessedModules={accessedModules} accessedPermission={accessedPermission} /> : <Navigate to="/unauthorized-access" />}></Route>
                  {/* <Route path='sea-import-tracking-good' element={<TrackingGoodSeaImport/>} /> */}
                  {/* <Route path='HRMS' element={<HRMS/>} /> */}
                  <Route path='accounts' element={<Account />} />
                  {/* <Route path='operations' element={<Operation/>} /> */}
                </Routes>

              </main>
            </div>
          </div> :
          <Beatloader />
        : <Navigate to="/admin-login" />
      }
    </div>
  )
}

export default Dashboard