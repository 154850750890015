const adminMainUrl = process.env.REACT_APP_API_SERVER;

// company general info get
export const fetchQuoteCompGenInfoRequest = () => ({
    type: "FIND_QUOTE_COMP_GEN_INFO_REQUEST",
});

export const fetchQuoteCompGenInfoSuccess = (data) => ({
    type: "FIND_QUOTE_COMP_GEN_INFO_SUCCESS",
    payload: data,
});

export const fetchQuoteCompGenInfoFailure = (error) => ({
    type: "FIND_QUOTE_COMP_GEN_INFO_FAILURE",
    payload: error,
});

export const getQuoteCompGenInfo = (token, quotation_id) => {

    return async function (dispatch) {
        dispatch(fetchQuoteCompGenInfoRequest());

        let config = {
            method: "POST",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ quotation_id: quotation_id })
        };
        
        let apiUrl = `${adminMainUrl}/api/pricing/get-quote-comp-gen-info`;
        
        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchQuoteCompGenInfoSuccess(resData));
                });
            }).catch((err) => {
                console.log("ERROR in getQuoteCompGenInfo function(redux):", err);
                fetchQuoteCompGenInfoFailure(err.message);
            });
    };
};

// company general info get