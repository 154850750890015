import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import axios from 'axios';
import Cookies from 'js-cookie'
// import { ToastContainer, toast } from 'react-toastify';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as Yup from 'yup';
import { mainUrl } from '../../../Config';
import { validationLoginSchema } from '../../../ValidationScema';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../../Api';
const Login = () => {
    // const notify = () => toast("Wow so easy!");
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(true);
    const [roleId, setRoleId] = useState([]);
    // const location = useLocation();

    useEffect(() => {
        navigate("/admin-login");
    }, [])

    const initialValues = {
        email: "",
        password: "",
        role_id: "",
        // agreement: false,
    };
    const GetRoleId = async () => {
        try {
            const data = await Api(`${mainUrl}/api/v.1/roleList`, "GET");
            const options = data?.data?.map(item => ({
                roleId: item.id.toString(),
                value: item.name,
                label: item.name,
            }));
            setRoleId(options);
        } catch (error) {
            setRoleId([]);
            console.error("Error fetching data:", error);
        }
    };

    const burl = new URLSearchParams(window.location.search).get('burl');
    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            navigate('/admin-dashboard/admin-dashboard');
        }
    }, [navigate]);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationLoginSchema,
        onSubmit: (values) => {
            fetch(`${mainUrl}/api/v.1/login`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => {

                    if (data?.status === 200) {
                        Cookies.set("token", data.data.token);
                        navigate('/dashboard')
                        sessionStorage.setItem('userData', JSON.stringify(data));
                        formik.resetForm();
                        // handle successful login
                        toast.success(data?.message);
                    }
                    if (burl) {
                        navigate(`/${burl}`);
                    }
                    else {
                        // navigate('/admin-dashboard')
                        toast.error(data?.message);
                        // handle login failure

                    }
                });
        },
    });


    useEffect(() => {
        GetRoleId();

    }, []);
    return (
        <section className="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 order-lg-1 order-md-1 order-sm-2 p-0">
                        <div className="img-form">
                            <img
                                src="/img/scene-with-photorealistic-logistics-operations-proceedings (1).jpg"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-md-5 order-sm-2 order-lg-2 second-body">
                        <div className="card-body align-items-center mx-md-4 card-padding">
                            <div className="logo-section text-center pt-4">
                                <img src="/img/committed-logo.png" alt="logo" />
                            </div>
                            <form className="login-form mx-auto" onSubmit={formik.handleSubmit}>
                                <div data-mdb-input-init="" className="pt-4 mb-2">
                                    <label htmlFor="select">Select Your Role</label>

                                    <Select
                                        options={roleId}

                                        onChange={(selectedOptions) => {
                                            formik.setFieldValue('role_id', selectedOptions.roleId);
                                        }}
                                        onBlur={formik.handleBlur}
                                        className='mb-2'
                                    />

                                    {formik.errors.role_id && formik.touched.role_id && (
                                        <div className="text-red-500 text-base">{formik.errors.role_id}</div>
                                    )}
                                </div>
                                <div data-mdb-input-init="" className="form-outline mb-2">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        style={{
                                            background: "#fff",
                                            border: formik.errors.email && formik.touched.email ? "1px solid red" : "",
                                        }}
                                    />
                                    {formik.errors.email && formik.touched.email && (
                                        <div className="text-red-500 text-base">{formik.errors.email}</div>
                                    )}
                                </div>
                                <div data-mdb-input-init="" className="form-outline mb-1" style={{ position: "relative" }}>
                                    <label htmlFor="password">Password</label>
                                    <div className='relative password-input'>
                                        <input
                                            placeholder='Enter Your Password'
                                            type={showPassword ? "password" : "text"}
                                            id="password"
                                            className="form-control"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            style={{
                                                background: "#fff",
                                                border: formik.errors.password && formik.touched.password ? "1px solid red" : "",
                                            }}
                                        />
                                        {
                                            showPassword ?
                                                <VisibilityOffIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                                :
                                                <VisibilityIcon className='cursor-pointer' style={{ fill: "rgb(82 80 80)" }} onClick={() => { setShowPassword(!showPassword) }} />
                                        }
                                    </div>
                                    {formik.errors.password && formik.touched.password && (
                                        <div className="text-red-500 text-base">{formik.errors.password}</div>
                                    )}
                                </div>
                                <div className="mt-2  mb-2">
                                    <div className="form-check d-flex align-items-center justify-content-start gap-2 ps-0">
                                        <input
                                            type="checkbox"
                                            className="check"


                                        />

                                        <label className="form-check-label cursor-pointer " htmlFor="rememberMe">
                                            Remember me
                                        </label>
                                    </div>

                                </div>
                                <div className="text-center pt-1 mb-2 pb-1">
                                    <button className="btn-login mb-3" type="submit">
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
