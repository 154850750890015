import React, { useState } from 'react'
import { Stepper, Step, StepLabel, Button, Typography, Box, TextField, Paper } from '@mui/material';
// import CompanyDetails from './CompanyDetails';
// import BankDetails from './BankDetails';
// import Documentation from './Documentation';
// import Declaration from './Declaration';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import CompanyDetails from './CompanyDetails';
import BankDetails from './BankDetails';
import { Documentation } from './Documentation';
import Declaration from './Declaration';
const CustomerRegistration = () => {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Company Details',
            content: (<>
                <CompanyDetails />

            </>
            ),
        },
        {
            label: 'Bank Details',
            content: (<>
                <BankDetails />

            </>),
        },
        {
            label: 'Documentation',
            content: (<>
                <Documentation />
                {/* 3 */}
            </>),
        },
        {
            label: 'Declaration',
            content: (<>
                <Declaration />

            </>),
        },

    ];
    const handleNext = () => {
        window.scroll(0, 0);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // navigate('/Customer-dashboard/registration-agreement')
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        navigate('/Customer-dashboard/registration-agreement')
    };

    return (
        <div className="container">

            <section>
                <div className="col-md-12">
                    <div className="query_bg_info_kyc">
                        <div className="main_section_detail">
                            <div className='count-step custom-resume-stepper d-flex flex-col align-items-center justify-content-center' >
                                <div className=' w-100 overflow-auto d-lg-flex justify-content-center'>

                                    <Stepper activeStep={activeStep} orientation="horizontal" className='employee-registration-class'>
                                        {steps.map((step, index) => (
                                            <Step key={step.label} className='step-label' >
                                                <StepLabel style={{ display: "grid" }} className='step-label'>{step.label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                            </div>
                            <div className='overflow-auto' >


                                {steps[activeStep] && (
                                    <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>
                                        <div>

                                            <div>{steps[activeStep].content}</div>

                                        </div>
                                        <div className='button-container' style={{ marginTop: '20px' }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                                className='btn-stepper'
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                className='btn-stepper'
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {activeStep === steps.length - 1 ? <Link className='text-white' to='/Customer-dashboard/registration-agreement' sx={{ mt: 1, mr: 1 }}>
                                                    Submit
                                                </Link> : 'Next'}
                                            </Button>
                                        </div>
                                    </Paper>
                                )}

                                {activeStep === steps.length && (

                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Fill Agreement
                                    </Button>

                                )}
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>

    )
}

export default CustomerRegistration