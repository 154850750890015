import React from 'react'
import './Email.css';
import { Link } from 'react-router-dom';
const Email = () => {
  return (
    <>
    <div className='h-vh'>

    <div className="email-container">
   
    <div className="email-content">
      <p>Hello Alex,</p>
      <p>Greetings of the day.</p>
      <p>
        You are now onboard into the Logistics portal. Here we are providing you
        the email and password, so you can login into the portal and complete your
        profile.
      </p>
      <div className="login-details">
        <p>
          Login: <span>Alex@gmail.com</span>
        </p>
        <p>
          Password: <span>********</span>
        </p>
      </div>
      <p>
        Link: <Link to="/client-login">Logistics.com</Link>
      </p>
      <p>Kindly complete your profile so we can start your forwarding.</p>
    </div>
    <div className="email-footer">

    <Link  to="/client-login">
      <p>© 2024 Logistics.com</p>
    </Link>
    </div>
  </div>
    </div>
    </>
  
  )
}

export default Email