import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = ({ showSideBar, setShowSideBar }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const DashboardBtn = () => {
    setShowSideBar(false);
    setIsMenuOpen(false);
  };
  const handleSetActiveMenu = (menu) => {
    setActiveMenu(menu);
  };
  return (
    <aside className={`sidebar sidebar_admin ${showSideBar && "hidden"}`}>
      <div className="sidebar-start">
        <div className="head">
          {showSideBar ? (
            <img className="logo-side" src="/img/logo-side.png" />
          ) : (
            <img className="logo-default" src="/img/logo.png" alt="" />
          )}
        </div>
        <div className="sidebar-body">
          <div className="menu  menu_admin">
            <ul className="admin_header_dash">
              <li
                className={
                  location.pathname === "/employee-dashboard/dashboard" ||
                  activeMenu === "dashboard"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  DashboardBtn();
                }}
              >
                <Link
                  to="/employee-dashboard/dashboard"
                  className="admin_header_list dash_board"
                  onClick={() => {
                    handleSetActiveMenu("dashboard");
                  }}
                >
                  {/* <i className="icon ph-bold ph-Dashboard" /> */}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25276 3.88415L8.53116 8.02411L8.66936 10.1049C8.67086 10.3189 8.70436 10.5315 8.76906 10.7358C8.93596 11.1324 9.33756 11.3844 9.77446 11.3668L16.4317 10.9313C16.72 10.9266 16.9984 11.0344 17.2056 11.2311C17.3783 11.395 17.4898 11.6094 17.525 11.84L17.5368 11.98C17.2613 15.7947 14.4596 18.9765 10.6528 19.7978C6.84591 20.6191 2.94222 18.8841 1.06107 15.5347C0.518754 14.5616 0.180014 13.4921 0.0647445 12.3888C0.0165945 12.0622 -0.00460556 11.7323 0.00134444 11.4023C-0.00460556 7.31254 2.90783 3.77677 6.98469 2.92438C7.47537 2.84797 7.95639 3.10773 8.15316 3.55534C8.20406 3.659 8.23766 3.77002 8.25276 3.88415Z"
                      fill="#1B7BB1"
                    />
                    <path
                      opacity="0.4"
                      d="M20.0002 7.81229L19.9932 7.84488L19.973 7.89227L19.9758 8.0224C19.9654 8.1947 19.8988 8.3605 19.7842 8.4945C19.6647 8.634 19.5015 8.729 19.3218 8.7659L19.2122 8.7809L11.5314 9.2786C11.2759 9.3038 11.0215 9.2214 10.8316 9.052C10.6732 8.9107 10.572 8.7201 10.5434 8.5147L10.0279 0.84506C10.0189 0.81913 10.0189 0.791019 10.0279 0.765079C10.0349 0.553669 10.128 0.353839 10.2863 0.210229C10.4445 0.0666194 10.6549 -0.00880074 10.8702 0.000819257C15.4301 0.116829 19.2625 3.39579 20.0002 7.81229Z"
                      fill="#1B7BB1"
                    />
                  </svg>
                  <span className="text">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.startsWith(
                    "/employee-dashboard/quote-request-form"
                  ) ||
                  location.pathname.startsWith(
                    "/employee-dashboard/query-list"
                  ) ||
                  location.pathname.startsWith(
                    "/employee-dashboard/picked-leads"
                  ) ||
                  location.pathname.startsWith(
                    "/employee-dashboard/picked-leads"
                  ) ||
                  location.pathname.startsWith(
                    "/employee-dashboard/converted-leads"
                  ) ||
                  location.pathname.startsWith(
                    "/employee-dashboard/non-converted-leads"
                  ) ||
                  activeMenu === "sales-crm" ||
                  isMenuOpen
                    ? "active"
                    : ""
                }
              >
                <a
                  className="admin_header_list list_admin-main"
                  href="#"
                  onClick={() => {
                    handleMenuToggle();
                    handleSetActiveMenu("sales-crm");
                  }}
                >
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 16H1V1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 3L11 10L7 6L1 11"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text">Sales &amp; CRM</span>
                  <i
                    className={`arrow ph-bold ph-caret-down ${
                      isMenuOpen && "arrow-active"
                    }`}
                  >
                    <img src="/img/dropdown.png" alt="" />
                  </i>
                </a>
                <ul
                  className="sub-menu sub-menu-hidden main_color_nav"
                  style={{ display: isMenuOpen ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/employee-dashboard/quote-request-form"
                      className={
                        location.pathname ===
                        "/employee-dashboard/quote-request-form"
                          ? "active"
                          : ""
                      }
                    >
                      Quote Request Form
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/employee-dashboard/query-list"
                      className={
                        location.pathname === "/employee-dashboard/query-list"
                          ? "active"
                          : ""
                      }
                    >
                      Open Query List
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/employee-dashboard/picked-leads"
                      className={
                        location.pathname === "/employee-dashboard/picked-leads"
                          ? "active"
                          : ""
                      }
                    >
                      Picked Leads
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/employee-dashboard/converted-leads"
                      className={
                        location.pathname ===
                        "/employee-dashboard/converted-leads"
                          ? "active"
                          : ""
                      }
                    >
                      Converted Leads
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setShowSideBar(false);
                    }}
                  >
                    <Link
                      to="/employee-dashboard/non-converted-leads"
                      className={
                        location.pathname ===
                        "/employee-dashboard/non-converted-leads"
                          ? "active"
                          : ""
                      }
                    >
                      Non-Converted Leads
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
