import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const RegistrationAgreement = () => {
  const [cheque,setCheque]=useState(false)
  const [cheque1,setCheque1]=useState(false)
  return (
    <>
      <div className="container">
        <section>
          <div className="col-md-12">
            <div className="query_bg_info_kyc">
              <div className="main_section_detail">
                <h4 className="text-center mb-5">Freight Forwarder Agreement</h4>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  This agreement (hereinafter referred to as the “Agreement”) is
                  executed at
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  on this{" "}
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />{" "}
                  day of{" "}
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  .
                </p>
                <h5 className="mt-5 mb-2">By and Between</h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  COMMITTED CARGO CARE LIMITED, a Company incorporated under the
                  Companies Act 1956, having its registered Office at Kh. No. 406,
                  A-Block, Road No. 4, Gali No. 8, Mahipalpur Extn. New Delhi - 110037
                  acting through its Director/authorized signatory{" "}
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />{" "}
                  herein after referred to as "Committed/ Company" (which expression
                  shall, unless it be repugnant to the context and meaning hereof,
                  shall deemed to include its Administrators, successors-in-interest
                  and permitted assigns) of the ONE PART;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  And
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  , a Company/Proprietary concern/Partnership firm, having its
                  Registered Office at
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  , represented herein by its authorised signatory{" "}
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  hereinafter referred to as "Customer" (which expression shall,
                  unless it be repugnant to the context and meaning hereof shall
                  deemed to include its Administrators, legal representatives,
                  partners, executors, successors in – interest and permitted assigns)
                  of the OTHER PART.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  The Committed/Company and the Customer shall hereinafter be
                  collectively referred to as “the Parties” and where the context
                  permits, shall individually be referred to as “the Party”.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  WHEREAS the Company is engaged in seamless handling of Import and
                  Export Cargo and is a well-established in the business of Freight
                  Forwarding, Customs Brokerage including both outbound and inbound,
                  courier services across India or elsewhere, transportation along
                  with other miscellaneous services;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  AND WHEREAS, the Customer, who has represented that they have
                  adequate experience and are carrying on the business of{" "}
                  <input
                    type="text"
                    className="input_box_agreement color_place w-20"
                    placeholder="Enter here"
                  />
                  , has approached the Company for availing varied freight forwarding/
                  custom broking services.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  AND WHEREAS, pursuant to the discussions and agreement between the
                  Parties, the Customer hereby agrees to appoint Committed to function
                  as a Custom Broker/ Freight Forwarder of the Customer and the
                  parties hereto desire to enter into this Agreement to define and set
                  forth the terms and conditions herein after.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  Now therefore in consideration of the mutual covenants and the
                  agreement set forth below, it is hereby witnessth and agreed by the
                  parties as under:
                </h5>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  1. Definitions
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  For the purpose of this Agreement, the following terms shall have
                  the meanings Set out below:
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.1 "Agreement" means this agreement together with its annexure.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.2 "Applicable Laws" means, with respect to any Person, all laws,
                  statutes, treaties, rules, regulations, determinations, orders,
                  notifications, writs, processes, decrees, injunctions, judgments; or
                  awards of an arbitrator, a court or any other governmental
                  authority, and all government authorizations binding upon or
                  applicable to such Person or to any of its properties or assets.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.3 "Confidential Information" means, all tangible and intangible
                  information obtained/ received/ gained/ developed or disclosed to
                  the Customer and/or Customer's Personnel for the carrying of
                  business under this agreement, including all details, documents,
                  data, business/ customer information and the Company's practices and
                  trade secrets that Customer and/ or Customer's Personnel may be,
                  privy to.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.4 "Scope and standard of Services" means the services to be
                  performed or rendered by the Committed, in relation to the business
                  and operations of the company and matters related or incidental to
                  the foregoing as described in Clause 3 (Appointment and Scope) and
                  any alteration thereof.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.5 “Commercial Services” — means and includes the freight forwarding
                  (outbound &amp; inbound)/ custom broking services (outbound &amp;
                  inbound)/ Courier services (outbound &amp; inbound), transportation
                  as stipulated under the terms of this agreement, and any other
                  services incidental thereto;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  a. Support/Operational Services — means the services performed by
                  the Company in respect of management of the commercial services and
                  first mile services as and when required by the Customer.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  b. Clearance Services — means and includes the custom clearing
                  services, payment of duty, levy and taxes by the Company in respect
                  of the international bookings done by the Customer.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.6 "Fees" means, the amount payable by the Customer to the
                  Committed and any alteration thereof.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.7 "Good Industry Practice" means, the exercise of degree of
                  skill, diligence and prudence which is expected from a highly
                  skilled and experienced and internationally recognized and reputed
                  service provider engaged in the same type of undertaking engaged in
                  the activity of providing the commercial services to the Customer
                  under similar circumstances and acting generally in accordance with
                  the prevailing laws, rules, regulation, courts and industry
                  standards.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                 1.8 "Good Industry Practice" means, the exercise of degree of
                  skill, diligence and prudence which is expected from a highly
                  skilled and experienced and internationally recognized and reputed
                  service provider engaged in the same type of undertaking engaged in
                  the activity of providing the commercial services to the Customer
                  under similar circumstances and acting generally in accordance with
                  the prevailing laws, rules, regulation, courts and industry
                  standards.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  1.9 "Customer" means any natural person, firm, corporation,
                  company, voluntary association (incorporated or otherwise),
                  partnership, joint venture, trust, limited organization, unlimited
                  corporation, or other entity who avails the services of the
                  Committed.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                1.10 "Customer's Personnel" means the authorized agent, employees,
                  representatives of the Customer.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  {" "}
                  x. "Complaint" means any written or electronic correspondence
                  expressing dissatisfaction with the services, or customer service of
                  the Company.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  2. Relationship of the parties
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  The relationship created under this Agreement shall be solely that
                  of independent contractors entering into an agreement. No
                  representations or assertions shall be made or actions taken by
                  either Party which could imply or establish any agency, joint
                  venture, partnership, employment or trust relationship between the
                  Parties with respect to the subject matter of this Agreement or any
                  Schedule. Neither the Company nor the Customer shall have any
                  authority or power whatsoever to enter into any agreement, contract
                  or commitment with any third party on behalf of the other, or to
                  create any liability or obligation whatsoever on behalf of the
                  other. Nothing in this Agreement shall be construed to create an
                  employer-employee relationship between the Principal and the Agent/
                  Committed and the Customer.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  3. Appointment and Scope
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  3.1 The Customer hereby appoints the Company as Custom Broker,
                  Freight Forwarder, Express service provider and also to act as Pure
                  Agent, to carry out the services, hereinafter referred to as the
                  "Services" subject to the terms and conditions set forth
                  hereinafter. Committed shall act as Pure Agent, wherein, all
                  re-imbursable charges shall be paid by the Company on behalf of the
                  customer and same shall be re-imbursed later by the Customer in
                  whole.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  3.2 In providing the services to the Customer, as per the terms of
                  this Agreement, Committed shall
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  a. Provide the best service maintaining the quality standards and
                  carry out the services in accordance with the
                  instructions/specifications of the Customer to their entire
                  satisfaction.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  b. Engage requisite number of qualified and competent personnel to
                  carry out the Services in an efficient and timely manner and to
                  supervise the work.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  c. Extend such other services as may be agreed upon during validity
                  period of the Agreement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  d. Comply with all applicable laws in connection with the Services.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  e. Maintain utmost confidentiality in respect of the confidential
                  information.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  f. Observe and perform all such additional directions and
                  conditions, that may be reasonably made from time to time by the
                  Company at its sole discretion.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 3.3 The Customer only have the authority to bind the Committed is
                  limited to the Services. The Customer does not have the authority to
                  bind Committed in any manner whatsoever beyond the Services
                  hereinabove or mutually decided by the parties.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  3.4 In the event that the Customer fails to comply with the
                  obligations under this Agreement, committed shall be entitled either
                  to claim from the Customer the extra costs which has directly
                  incurred in remedying the Customer’s failure to comply with his
                  obligations or have the right to terminate the agreement by giving
                  seven days’ prior notice to the Customer.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  4. Insurance
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  4.1 The Customer hereby appoints the Company as Custom Broker,
                  Freight Forwarder, Express service provider and also to act as Pure
                  Agent, to carry out the services, hereinafter referred to as the
                  "Services" subject to the terms and conditions set forth
                  hereinafter. Committed shall act as Pure Agent, wherein, all
                  re-imbursable charges shall be paid by the Company on behalf of the
                  customer and same shall be re-imbursed later by the Customer in
                  whole.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 4.2 The Customer shall effect and keep current the relevant insurance
                  coverage for the cargo consigned, in accordance with the requirement
                  specified from time to time.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  4.3 The insurance required by this Clause will not limit Committed’
                  s liability to Customer under this Agreement or limit the rights or
                  remedies available to Customer at law or in equity. If Customer, in
                  writing, requests Committed to procure insurance on Customer’s
                  behalf regarding goods/ shipment, committed shall procure such
                  insurance and Customer will pay all premiums and costs of procuring
                  such insurance.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  4.4 Company shall not be responsible for any loss or damage due to
                  mishandling. However, company shall support by providing the
                  required documents for initiating insurance claim by the
                  Shipper/Consignee of Customer. Company assumes no liability on
                  account of damage and reserves right to receive freight payment for
                  the services rendered including the shipment in which delay, loss or
                  damage may occur.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  5. Commercials
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  The cost of and terms of payment for services performed by company
                  will be specifically stated in the applicable acknowledged quotes by
                  Committed as annexed herein, which shall form integral part of the
                  agreement. However, charges quoted by Committed shall be subject to
                  mandatory compliances, as applicable.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  6. Operations
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 6.1 Committed will book documents and parcels from customers, arrange
                  pickup, inspect and check packaging etc., ensure all contents are
                  permitted for carriage as per existing laws and regulations,
                  complete the paper work and then forward the same to different
                  destinations through the Company network. The Customer must obtain
                  and update the same in the system, in case of organizations, a copy
                  of the KYC document and photo ID in case of individual for each
                  shipment as mandated by the Central Board of Excise and Customs,
                  Department of Revenue, Ministry of Finance, Government of India.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  6.2 All shipments booked by the Customer will always be carried and
                  handled through the Company's network.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  6.3 Customer will engage/appoint necessary personnel at its own
                  discretion and cost, for carrying on the services by the Customer
                  and no relationship of employer and employee shall be created
                  between the Company in respect of the such personnel engaged by the
                  Customer.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  6.4 All statutory payments and liabilities on account of its
                  employees will be that of the Customer. For all legal purposes and
                  statutory obligations as applicable in India, the Customer is a
                  separate entity distinct from the Company independently operating in
                  their place of business.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  7. Customer’s Obligations
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.1 Customer shall ensure that the cargo tendered by Customer to
                  Committed shall be fully and clearly declared and there has not been
                  any disparity between the documentary declarations and physical
                  goods. There should not be any malafide intentions of the Customer
                  while declaration of cargo/goods. (inherited goods).
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.2 The Customer shall place the booking of all shipments (Dox,
                  Non-dox and Cargo) in open condition only and will not book any IATA
                  restricted items or items prohibited by various enactments
                  applicable to the carriage of goods. Customer hereby agrees &amp;
                  undertakes not to book any of the prohibited following items through
                  the Company, namely- currency, gold, restricted drugs, explosives,
                  hazardous chemicals, liquids, radio-active materials, contraband
                  articles, obnoxious materials, liquors and such other items as may
                  be prohibited by the applicable laws and regulations. In case of any
                  breach, the Customer alone shall be responsible for all consequences
                  including payment of damages/ compensation in respect of any breach.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.3 All non-dox shipments/ cargo must be suitably packed and
                  labelled so as to facilitate their movement without damage while in
                  transit and handling, both due to fatigue as well as inclement
                  weather conditions and wherever applicable the packages should be
                  marked with special handling instructions common to the trade, viz.
                  "Fragile-Handle with care", "This side up", "Avoid Water", etc., as
                  the case may be.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.4 Customer shall provide all necessary documentation and fulfill
                  all procedural requirements to expedite forwarding and clearance of
                  the shipment as may be required at any origin and destination
                  country/ customs requirement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.5 Customer confirms that neither Customer nor the consignee is the
                  person or organization with whom Customer or Committed may not
                  legally trade under any applicable laws or regulations.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  7.6 Customer shall ensure that the cargo contents of the shipment
                  are the same as that described on the invoice packing list, or any
                  other document provided by the participating government agencies.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 7.7 Customer shall ensure that all statement and information and
                  documents provided by them relating to the shipment is true and
                  correct. Customer acknowledges that in the event that any untrue or
                  fraudulent statement is made about the Cargo shipment or any of its
                  contents or if it’s been found out that there has been any malafide
                  intention of the Customer behind concealing any fact or otherwise,
                  then Customer may risk a civil claim and/ or criminal prosecution,
                  the penalties for which may include forfeiture and sale or
                  compensation.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 7.8 Under any circumstances the Customer shall not handover or
                  tender to carry certain goods whichever has been indicated or
                  notified by the authorities as prohibited goods /restricted goods,
                  banned goods or contraband goods or equivalent.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  8. Liability
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  8.1 All risk in the cargo including risk of loss or damage of the
                  cargo shall be with the Customer, the Customer must inform Committed
                  of any or all loss or damage to the Cargo as soon as practicable
                  after becoming aware of such loss or damage.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  8.2 Committed shall not be liable:
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  a. Observe and perform all such additional directions and
                  conditions, that may be reasonably made from time to time by the
                  Company at its sole discretion.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  b. if the damage, loss, discrepancy is observed at the time of
                  delivery at destination which shall be the responsibility of
                  Customer to educate consignee/ shipper to have proper documentary
                  evidence duly authenticated by the concerned agency and any claim
                  whatsoever shall be passed on the ground handler or carrier or any
                  other authority involved within the defined timelines as issued by
                  the carrier or ground handler.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  c. if the shipment remains undelivered at the destination due to
                  whatsoever reason. It becomes the responsibility of the Customer to
                  make arrangement to deliver the goods failing which if any penalty,
                  destruction, demurrage or any other charges incurred on the shipment
                  shall be on the account of the Customer only.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  d. if a shipment is misplaced, damage, lost while in the custody of
                  ground handler or carrier.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 8.3 The Customer shall indemnify Committed, its directors and
                  employees against any and all losses, damages, liabilities, claims,
                  demands, proceedings, costs and expenses (including legal costs)
                  which may be incurred or suffered by any one of them arising out of
                  or in respect of the following:
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  a. Any breach of this Agreement by the Customer, its contractors and
                  their employees; and
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  b. Any injury, damage or loss caused by or resulting from any
                  negligent act or omission, willful misconduct by the Customer, its
                  contractors and their employees; and
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  c. Any claim by any third party, including any claims for loss of or
                  damage to other cargo co-loaded in containers, vessels or aircraft.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  9. Force Majeure
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  9.1 During the performance of this Agreement, if all or part of the
                  obligations hereunder are unable to be performed due to any
                  unforeseeable, unpreventable or unavoidable objective condition,
                  including but not limited to natural disasters, government acts,
                  war, warlike situation, hostilities, sanctions, riots, explosion,
                  robbery, strikes, lockouts, pestilence, epidemic diseases, fire,
                  earthquake, flood, etc., suspension of air, rail or road
                  transportation for any reason or any other causes whatsoever beyond
                  the control of either party, the affected party may be discharged
                  from the legal liability for its default of or delay in performing
                  its obligations hereunder due to any of such force majeure events,
                  provided that the Party affected by such force majeure event has
                  informed the other Party of such event within forty-eight (48) hours
                  after occurrence of such event so as to mitigate the losses and
                  clarify the situation. No claim for loss or damage will be
                  entertained by the Company in such events from the customer and in
                  such eventually, the Customer also will not claim any amount of
                  money by way of damages/compensation/interest etc. from the Company.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 9.2 Both Parties shall not be liable for any incidental, special,
                  indirect or consequential damages arising out of or relating to this
                  Agreement.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  10. Warranties and Undertakings
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  10.1 Customer shall not at any time divulge to any customer or to any
                  competitor of the Company, any trade or business secrets or any
                  other matter relating to its courier, transportation, freight
                  forwarding or custom broking business, which may become known to
                  them by virtue of its position as a Customer or otherwise. Customer
                  shall be true and faithful to the Company in all dealings and
                  transactions whatsoever relating to the business. In the event of
                  breach of confidentiality by the Customer, Company shall be entitled
                  to claim damages from them. The confidentiality obligation under
                  this Clause shall continue to bind both Parties within three (3)
                  years after the validity period of this Agreement expires or this
                  Agreement is prematurely terminated.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  10.2 Committed shall inform the Customer expediently where a freight
                  guarantees for “charges collect shipment (CC)/ to-pay shipment” or a
                  security declaration is requested or otherwise, by the airline or
                  carrier. Committed shall provide such freight guarantee or security
                  declaration on behalf of Customer. Once freight guarantee / security
                  declaration has been issued by Committed on behalf of the Customer,
                  it is deemed to be understood that the Customer has accepted the
                  terms of such freight guarantee / security declaration and also
                  undertakes to indemnify Committed in the event of failure of such
                  guarantees / declarations.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  10.3 Customer shall have no authority to raise loans or borrow in
                  the name of the Company and it is expressly understood that the
                  Company shall in no way be responsible for any debts or other
                  liability/obligation created by the Customer for the purpose of
                  courier &amp; cargo business.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  10.4 Each Party warrants that it will not directly or indirectly give
                  any commission, bonus, brokerage fee, rebate, gift or other improper
                  benefit to the relevant managers, employees, agents or
                  representatives of the other Party to solicit the latter to commit
                  any infringement of the other Party’s legal rights and interests so
                  as to obtain such improper benefits. Otherwise, the innocent Party
                  shall have the right to terminate this agreement unilaterally and
                  claim the liable Party for its legal liability.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  11. Billing and Payments
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.1 The Company shall raise bills for the Customer for all service
                  charges payable for consignments booked by the Customer as well as
                  for the customs clearances if any effected in respect of the said
                  consignments.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.2 All bills will be raised as per the mutually agreed terms and
                  shall be payable as follows:
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  a. In case of Shipper: against post shipment documents or credit
                  days agreed with the shipper, whichever is earlier upon due receipt
                  of invoice;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  b. In case of IATA: as per IATA terms i.e. payment of 1st Fortnight
                  billing of a month needs to be paid by 13th of the following month
                  and 2nd Fortnight Billing needs to be paid by 28th of the following
                  month.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  c. In case of Express shipments: Within 10 days of receipt of
                  Invoice.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  {" "}
                  Overdue interest shall be payable at the rate of 24% p.a. and shall
                  be levied till the default continues. In case of default by the
                  Customer, the Company reserves the right to hold shipments.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.3 In case there is any issue in invoice, the same should be
                  communicated through any mode of communication within 3 working days
                  and the same shall be resolved within next three working days. In
                  the event no concern/ issue has been raised against an invoice
                  within three working days of its receipt then the same shall be
                  considered as accepted and payable.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.4 Customer shall not have the right to withhold and set-off any
                  payments due to Committed for any amount owed by the Customer to
                  Committed. And all payments shall be made directly by Customer to
                  Committed.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                11.5 All shipments that are on hold due to confirmation of RTO (return
                  to origin) or delivery not taken by Customer or Consignee, all
                  applicable charges of whatsoever nature will be borne by the
                  Customer;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.6 All amounts paid to the Company should be paid exclusively to
                  the following bank accounts (unless otherwise instructed by the
                  Company in writing).
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Bank Name </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Bank Address </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Beneficiary Name </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Account No. </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>IFSC Code </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  11.7 Whether or not Committed has actually paid to a third party
                  shall not constitute a defense of Customer against the fees payable
                  by them. Unless otherwise agreed by and between the Parties in
                  writing, no offset of freight, commission, compensation or any other
                  amount is acceptable under this Agreement, and the settlement of the
                  forwarding/ broking agency fees shall not be affected by any other
                  dispute.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  12. Bank Guarantee
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  In case of Express Services (Courier services), a bank guarantee
                  equivalent to two month’s approximate billing revenue would be
                  required to be provided by the Customer, before start of operations.
                  In case of any payment pending qua the customer, committed is at
                  liberty to realize the bank guarantee to satisfy their outstanding
                  payment without any further information to customer as the customer
                  acknowledges that the bank guarantee may be utilized for due payment
                  if any.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  13. Confidentially
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  13.1 During the tenure of this Agreement, the Parties hereto may
                  transfer or exchange information in either, oral or written form,
                  which information may include but may not be limited to data,
                  business, techniques, financial, statistical, personal or other
                  information which is proprietary to Party with each other. All
                  proprietary information received by each party under this agreement
                  shall be regarded as such may, intentionally or due to negligence or
                  for any other reason shall not be disclosed to any other person,
                  firm, and agency without the prior express written consent of the
                  other party, both during and for a period of two years after
                  termination or expiration of this Agreement. In case it becomes
                  legally necessary for either party to disclose, any confidential
                  information to a third party, it shall be disclosed only to the
                  extent required by law and only after due notice to the other party
                  of the requirement for such disclosure.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  13.2 Obligations of Parties in terms of this clause, shall not apply
                  to the extent that such confidential information:
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  a. Is at the date hereof, or hereafter, enters the public domain
                  through no fault of the Parties as the case may be;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  b. Can be shown by the Parties, to the reasonable satisfaction of
                  disclosing Party, to have been known to it prior to being disclosed
                  by the Parties to it;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  c. Can be shown by the Parties, to the reasonable satisfaction of
                  disclosing Party, to have been known to it prior to being disclosed
                  by the Parties to it;
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  13.4 This Clause shall continue to be enforceable between the
                  Parties for a period of 1 year from the date of termination of this
                  agreement and is applicable to any written/-documented information
                  revealed to one Party by the other Party, which is not in the public
                  domain.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  14. Indemnification
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  14.1 When Committed acts as the carrier/ independent operator, if no
                  body picks up the cargo or the consignee refuses to pick up the
                  cargo/ abandons the cargo at the destination, Committed shall inform
                  the Customer of such event within the same day when such event
                  occurs, and the Customer shall immediately take all necessary
                  measures to avoid further enlargement of loss and undertake all
                  detention and demurrage and other relevant charges and risks thus
                  arising during this period.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  14.2 If the cargo is disposed of by the customs or any other
                  governmental authority at the destination or the actual carrier in
                  accordance with the local laws and regulations during this period,
                  all the risks, liabilities and charges thus arising shall be
                  undertaken by the Customer. If Customer fails to take measures in
                  due time and Committed, has to pay relevant charges, then the
                  Customer shall indemnify Committed in full.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 13.3 Each Party agrees to indemnify and hold harmless the other
                  party, its respective affiliates, officers, agents, employees, and
                  permitted successors and assigns against any and all claims, losses,
                  damages, liabilities, penalties, punitive damages, expenses,
                  reasonable legal fees and costs of any kind or amount whatsoever,
                  which result from the negligence of or breach of any terms and
                  conditions of this Agreement by the indemnifying party and/or its
                  respective successors and assigns that occurs in connection with
                  this Agreement. This clause remains in full force and effect even
                  after termination of the Agreement by its natural termination or the
                  early termination by either party.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  15. Governing Law and Jurisdiction
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  The parties agree that the construction validity and performance of
                  this Agreement shall be governed, construed and enforced in
                  accordance with the laws of India. All disputes, matters, issues and
                  disputes arising out of or connected with terms, condition and
                  interpretation of this Agreement shall be subject to the sole and
                  exclusive jurisdiction of Delhi, India only.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  16. Dispute Resolution and Arbitration
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.1 If any dispute arising out of or touching upon or in relation to
                  the terms of this Agreement or its termination including the
                  interpretation and validity thereof and the respective rights and
                  obligations of the parties shall be settled amicably and promptly by
                  negotiations and consultations in good faith. If the dispute is not
                  resolved through negotiation and consultation within fifteen (15)
                  Business Days after the written request by either party for the
                  commencement of such negotiations (or such longer period as the
                  Disputing Parties may agree in writing) failing which the same shall
                  be settled through arbitration.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.2 Either party shall be free to refer such dispute to arbitration
                  of a sole arbitrator to be appointed by the Company in consonance
                  with the provisions of the Arbitration and Conciliation Act, 1996 or
                  any subsequent enactment or amendment thereto.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.3 In the event of non-availability of the sole arbitrator so
                  appointed, to conduct the proceeding, due to any reasons like
                  resignation, death or any other reason whatsoever, the Company shall
                  retain the right to appoint an alternate sole arbitrator. The
                  Customer shall not raise any objection on the appointment of the
                  sole arbitrator or the alternate sole arbitrator. The decision of
                  the sole arbitrator or the alternate sole arbitrator shall be final
                  and binding on both the Parties.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.4 The arbitration proceedings shall be governed by the Arbitration
                  and Conciliation Act, 1996 and the rules thereunder along with its
                  statutory amendments, modifications thereof the time being in force.
                  The Arbitration shall be conducted in the English language. The Seat
                  of Arbitration shall be at the registered office of Committed at New
                  Delhi.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.5 Arbitration fees and expenses shall be shared by both the parties
                  concerned equally. However, the Parties shall assume the fees and
                  costs of their own legal representation. However, the decision of
                  the arbitrator shall be final and binding on the Parties.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  16.6 Any reference of any disputes, difference or claim to
                  arbitration under this Agreement shall not affect the performance by
                  the Parties of their respective obligations under this Agreement
                  other than the obligations relating to the dispute, difference or
                  claim referred to arbitration.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  17. Effectiveness, Amendment and Termination
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 17.1 This Agreement as well as its attachments comes into effect as of
                  the date on which this Agreement is signed and stamped by both
                  Parties.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.2 The validity period of this Agreement is one year and will be
                  renewed for another one year when the validity period expires unless
                  either Party notifies the other Party,
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.3 Any outstanding matters that are not discussed between the
                  Parties during the performance of this Agreement, or any adjustment
                  or change of relevant terms or content of this Agreement that are
                  required according to the business need, may be negotiated and
                  agreed on between the Parties by making amendments or supplements,
                  and the amended or supplemental agreement is an integral part of
                  this Agreement and has equivalent effectiveness with this Agreement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.4 This Agreement shall be implemented according to the original
                  terms before the amended or supplemental agreement is confirmed by
                  both Parties in a written form. The invalidity or unenforceability
                  of any of the terms or part of any term of this Agreement shall not
                  affect the effectiveness and implementation of any other term and
                  content of this Agreement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.5 This agreement may be terminated by either party in the following
                  circumstances: -
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  a. By mutual agreement between the parties
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  b. By either Party prematurely at any time without assigning any
                  reasons whatsoever by giving 30 days' prior written notice to the
                  other Party.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  c. By the party not in breach, in the event of any breach of the
                  obligation / covenants of this agreement by the other party, which
                  remains un-remedied for a period of 30 days after due notice by the
                  aggrieved party.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  d. If the Customer fails to make payment of any amount due and
                  payable by them to the Company under or by virtue of this agreement
                  within 60 days after it becomes due and payable.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  e. If due to any reasons including restrictions imposed by the Local
                  Government(s), the carrying on business operations becomes
                  difficult, non-profitable or impossible.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-2">
                  f. In the event that either Party hereto goes into liquidation/
                  becomes insolvent or enters into any compromise with its Creditors.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.6 The termination of this Agreement by any party shall not absolve
                  either party from any liability incurred by the other party till the
                  effective date of termination. Further, the Customer shall not be
                  entitled to any compensation for pre-mature termination by the
                  Company with or without any cause or by virtue of any grounds as
                  aforementioned or at the expiry of this agreement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  17.7 Notwithstanding termination or expiration of the term of this
                  Agreement, each party shall diligently perform the service with
                  respect to any shipment on hand in accordance with the terms and
                  conditions of this agreement till the effective date of termination.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  18. Notices
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  18.1 That the Customer shall get his/her/their complete address
                  registered with the Committed/Company at the time of execution of
                  this Agreement and it shall be his/her/their sole responsibility to
                  inform the Committed/Company by personally or by registered or
                  recorded delivery mail; or by telex and facsimile communication (the
                  latter confirmed by telex or post) about all subsequent changes, if
                  any, in his/her/their address(es) within five days from the date of
                  change, failing which all demand notices, letters posted and
                  correspondence made at the last resided address available with the
                  Committed/Company shall be deemed to have been received by
                  him/her/them at the time when those should ordinarily reach such
                  address and the Customer shall be responsible for any default in
                  payment and other consequences that might occur therefrom. Any
                  change in the address shall be supported with relevant documentary
                  evidence.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                 18.2 That all letters, receipts and/or notices issued by the
                  Committed/Company or its appointed agency and dispatched under a
                  certificate or posting or courier to the last address known to it of
                  the Customer shall be sufficient proof of receipt of the same by the
                  Customer and shall fully and effectually discharge the
                  Committed/Company or its nominees.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  18.3 If due to any unavoidable circumstances, the Committed/Company
                  needs to change its address or place of work or merged with any
                  other company, the same will be intimated to the Customer within
                  five days from the date of such change by personally or by
                  registered or recorded delivery mail; or by telex and facsimile
                  communication (the latter confirmed by telex or post) about all
                  subsequent changes if any.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  19. Supersession
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  This Agreement supersedes all prior discussions, understandings and
                  agreements (whether oral or written, including all correspondence)
                  if any, between the Parties with respect to the subject matter of
                  this Agreement, and this Agreement contains the sole and entire
                  agreement between the Parties hereto with respect to the subject
                  matter hereof.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  20. Waiver
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  No waiver by any Party of any breach by any other Party of the
                  provision of this Agreement shall be construed as a waiver of any
                  subsequent or other breach, whether of the same or a different kind.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  21. Severability
                </h5>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  If any provision of this Agreement shall be determined to be void,
                  prohibited, invalid or unenforceable under applicable law, such
                  provisions shall be deemed amended or deleted in so far as
                  reasonably inconsistent with the purpose of this Agreement and to
                  the extent necessary to conform to the applicable law and the
                  remaining provisions of this Agreement shall remain valid and
                  enforceable as applicable at the time of execution of this
                  Agreement.
                </p>
                <p style={{ lineHeight: "1.5" }} className="mt-4">
                  IN WITNESS WHEREOF, the Parties hereto have hereunto and to a
                  duplicate copy hereof set and subscribed their respective hands at
                  the places and on the day, month and year first here in above
                  mentioned under their respective signatures.
                </p>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  For and on behalf of "Committed Cargo Care Limited"
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Witness Name </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Witness Address </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section file-input-main">
                      <label>Copy of cancelled cheque</label>
                      <a className="folder_main_update">
                        {" "}
                        Copy of cancelled cheque.pdf
                        <span>
                          <img src="/img/download-icon.png" alt="file" />
                          <img
                            src="/img/document-fail.png"
                            alt="file"
                            id="toggleDesc-9"
                            onClick={()=>{
                              setCheque1(!cheque1)
                            }}
                          />
                        </span>
                      </a>
                    </div>
                    {
                      cheque1 &&

                    <div
                      id="rejectionReason-9"
                      style={{ marginBottom: 10 }}
                    >
                      <label htmlFor="rejection-description-9">
                        Reason for Rejection:
                      </label>
                      <textarea
                        id="rejection-description-9"
                        name="rejection-description-9"
                        className="form-control"
                        placeholder="Please provide a reason for rejection here..."
                        defaultValue={""}
                      />
                    </div>
                    }
                  </div>
                </div>
                <h5 className="mt-5 mb-2" style={{ lineHeight: "1.5" }}>
                  For and on behalf of
                </h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Witness Name </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Witness Address </label>
                      <input
                        type="text"
                        className="form-control color_place"
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section file-input-main">
                      <label>Copy of cancelled cheque</label>
                      <a className="folder_main_update">
                        {" "}
                        Copy of cancelled cheque.pdf
                        <span>
                          <img src="/img/download-icon.png" alt="file" />
                          <img
                          onClick={()=>{
                            setCheque(!cheque)
                          }}
                            src="/img/document-fail.png"
                            alt="file"
                            id="toggleDesc-10"
                          />
                        </span>
                      </a>
                    </div>
                    {cheque &&
                    <div
                      id="rejectionReason-10"
                      style={{  marginBottom: 10 }}
                    >
                      <label htmlFor="rejection-description-10">
                        Reason for Rejection:
                      </label>
                      <textarea
                        id="rejection-description-10"
                        name="rejection-description-10"
                        className="form-control"
                        placeholder="Please provide a reason for rejection here..."
                        defaultValue={""}
                      />
                    </div>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 send_query_list_button mt-4">
                    <Link to="/">Not Approved</Link>
                  </div>
                  <div className="col-md-6 col-sm-12 send_query_list_button mt-4">
                    <Link to="/admin-dashboard/Kyc-approval-request">Approved</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  )
}

export default RegistrationAgreement