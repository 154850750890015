import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ProfileApi } from "../../../Redux/action/MasterAction";
import { adminMainUrl } from '../../../Config';
import { Image } from 'antd';
export const ProfilePage = () => {
    const dispatch = useDispatch();
    const {
        loadingProfile,
        ProfileData,
        ProfileDataError,
    } = useSelector((state) => state?.MasterReducer);
    useEffect(() => {
        dispatch(ProfileApi(adminMainUrl));
    }, [adminMainUrl]);
    console.log(ProfileData, "ProfileData....")
    return (
        <>
            <div className="user-profile-container d-lg-flex d-grid gap-3">
                <div className="user-box">

                    <div className="user-card mb-3">
                        <div className="user-card-box">

                            <div className="profile-image">
                                <img
                                    src="/img/profile-img.png"
                                    alt="Profile"
                                    className="profile-pic"
                                />
                            </div>
                            <div className="profile-info">
                                <h2 className="profile-name">Jannie Regel</h2>
                                <div className="profile-rating">
                                    {/* <span>★ ★ ★ ★ ★</span> */}
                                    <span>sjain@gmail.com</span>
                                </div>
                                {/* <div className="profile-trust">
                            <span>85% trust</span>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="user-card">
                        <div className="section">
                            <h3>Address</h3>
                            <hr />
                            <div className="detail flex-col">
                                <span className='font-medium'>Permanent Address: </span>
                                <span className="bold-text">2848 El Caminito Street, Los Angeles, LA 91214</span>
                            </div>
                            <div className="detail flex-col">
                                <span className='font-medium'>Residential Address: </span>
                                <span className="bold-text">2848 El Caminito Street, Los Angeles, LA 91214</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-details">
                    <div className="section">
                        <div className="d-sm-flex justify-content-between py-0 pb-sm-0 pb-3">
                            <h3 className='mb-0'>Personal Details</h3>
                            <div className="d-flex justify-content-start gap-2 align-items-center">

                                <p className="mb-0 request-no text-black">Employee ID</p>
                                <p className="mb-0 text-black">:</p>
                                <p className="mb-0 text-black">123242
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className="detail">
                            <span className='font-medium'>Contact Number: </span>
                            <span className="bold-text">7651992986</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Gender: </span>
                            <span className="bold-text">Female</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Marital Status: </span>
                            <span className="bold-text">Single</span>
                        </div>
                    </div>

                    <div className="section">
                        <h3>Official Information</h3>
                        <hr />
                        <div className="detail">
                            <span className='font-medium'>Contact Number: </span>
                            <span className="bold-text">9898987678</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Email: </span>
                            <span className="bold-text">Single@gmail.com</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Department: </span>
                            <span className="bold-text">HR Department</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Designation: </span>
                            <span className="bold-text">Frontend developer</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Role: </span>
                            <span className="bold-text">admin</span>
                        </div>
                        <div className="detail">
                            <span className='font-medium'>Reporting Manager: </span>
                            <span className="bold-text">Single</span>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
