import React from 'react'

const CompanyDetails = () => {
    return (
        <>
            <form action="#">
                <div className="main-nav-det">
                    <h4>Company Details</h4>
                </div>
                <div className="row">
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Name of the Company / Partnership Firm / Agency / Proprietorship</label>
          <input type="text" className="form-control color_place" placeholder="KSG Tech" readOnly />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Year of establishment (DD/MM/YYYY)</label>
          <input type="text" className="form-control color_place" placeholder="12/04/2011" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>CIN No.</label>
          <input type="text" className="form-control color_place" placeholder="1234 1234" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>GST Regn. No.</label>
          <input type="text" className="form-control color_place" placeholder="1234 1234 1234" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>TAN No. (if not please mention N/A)</label>
          <input type="text" className="form-control color_place" placeholder="1234 1234 1234" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>PAN No.</label>
          <input type="text" className="form-control color_place" placeholder="1234 1234 1234" readOnly />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Name of the Directors / Partners / Agents / Proprietor etc.</label>
          <input type="text" className="form-control color_place" placeholder="John" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Permanent or Registered address</label>
          <input type="text" className="form-control color_place" placeholder="G-102, sec-2, Noida" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Principal Business address</label>
          <input type="text" className="form-control color_place" placeholder="G-102, sec-2, Pune" readOnly />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Type of Business Activity</label>
          <select className="form-control color_place" name="#" id="#">
            <option value="#">Individual/Proprietary firm</option>
          </select>
        </div>
      </div>
      <div className="col-md-6">
        <div className="input_box_section">
          <label>Brief Description of Business of your entity</label>
          <input type="text" className="form-control color_place" placeholder="Tech Vendor" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Website of the business entity</label>
          <input type="text" className="form-control color_place" placeholder="ksgtech.com" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Name of Director/ Proprietor</label>
          <input type="text" className="form-control color_place" placeholder="John" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Contact No. of Director/ Proprietor</label>
          <input type="text" className="form-control color_place" placeholder="1234567890" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Email of Director/ Proprietor</label>
          <input type="text" className="form-control color_place" placeholder="John@gmail.com" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Name of Sales</label>
          <input type="text" className="form-control color_place" placeholder="Alex" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Contact No. of Sales</label>
          <input type="text" className="form-control color_place" placeholder="1234567890" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Email of Sales</label>
          <input type="text" className="form-control color_place" placeholder="Alex@gmail.com" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Name of Accounts</label>
          <input type="text" className="form-control color_place" placeholder="David" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Contact No. of Accounts</label>
          <input type="text" className="form-control color_place" placeholder="1234567890" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Email of Accounts</label>
          <input type="text" className="form-control color_place" placeholder="david@gmail.com" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Name of Finance Controller</label>
          <input type="text" className="form-control color_place" placeholder="Macky" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Contact No. of Finance Controller</label>
          <input type="text" className="form-control color_place" placeholder="1234567890" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Email of Finance Controller</label>
          <input type="text" className="form-control color_place" placeholder="macky@gmail.com" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>MSME Industry Type</label>
          <select className="form-control color_place" name="#" id="#">
            <option value="#">Micro</option>
          </select>
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>MSME Registration No.</label>
          <input type="text" className="form-control color_place" placeholder="12345678" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Previous Agent’s Name</label>
          <input type="text" className="form-control color_place" placeholder="Alen Neil" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Previous Agent’s Address</label>
          <input type="text" className="form-control color_place" placeholder="F-12, sec-4, Noida" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Reason for leaving them</label>
          <input type="text" className="form-control color_place" placeholder="Contract Completed" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Currently Working With</label>
          <input type="text" className="form-control color_place" placeholder="Not working" readOnly />
        </div>
      </div>
      <div className="col-md-3">
        <div className="input_box_section">
          <label>Licenses and certifications</label>
          <input type="text" className="form-control color_place" placeholder="DL" readOnly />
        </div>
      </div>
    </div>
            </form>
        </>
    )
}

export default CompanyDetails