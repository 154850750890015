import React,{useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function SearchTab() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade show active"
            id="search"
            role="tabpanel"
            aria-labelledby="search-tab"
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Search</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="alfa_listing-kg  shimpment_range">
                        {/* <div class="input_box_section ">                          */}
                        <label>Date Range </label>
                        <ul>
                            <li>
                                <div className="input_box_section">
                                    <div className="por_main_detail1 custom-datepicker">
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                        {/* <span>
                                            <img
                                                src="/img/Calendar.png"
                                                alt="Calendar"
                                            />
                                        </span> */}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="input_box_section">
                                    <div className="por_main_detail1 custom-datepicker">
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                        {/* <span>
                                            <img
                                                src="/img/Calendar.png"
                                                alt="Calendar"
                                            />
                                        </span> */}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section">
                        <label>Docket No</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>AWB No</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Shipper</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Sub Agt</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Consignee</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>OverSeas Agt</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Origin Port</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Dstn Port</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Party Inv. No.</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>Reference No.</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="input_box_section ">
                        <label>SB No.</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter here"
                        />
                    </div>
                </div>
                <div className="button-shipment-registration d-flex mt-2">
                    <div className="btn">
                        {" "}
                        Search
                    </div>
                    <div className="btn">
                        {" "}
                        Clear
                    </div>
                    <div className="btn">
                        Print
                    </div>
                </div>
            </div>
            <div className="main_listing_button shipment_status mt-3 mb-3">
                <ul>
                    <li>
                        <a href="#" className="">
                            All Status Updated
                        </a>
                    </li>
                    <li>
                        <a href="#" className="">
                            Not All Status Updated
                        </a>
                    </li>
                    <li>
                        <a href="#" className="">
                            No Status Updated
                        </a>
                    </li>
                </ul>
            </div>
            <div className="scroll_listing_detail">
                <div className="users-table ">
                    <table className="posts-table">
                        <thead>
                            <tr className="users-table-info">
                                <th>Select</th>
                                <th>Docket No.</th>
                                <th>Date</th>
                                <th>Shipper</th>
                                <th>Consignee</th>
                                <th>Over Seas Agent</th>
                                <th>Sub Agent</th>
                                <th>Status</th>
                                <th>Current Status</th>
                                <th>EDI Job N0</th>
                                <th>Mawb No</th>
                                <th>Hawb No</th>
                                <th>Reference</th>
                                <th>PKGs</th>
                                <th>Unit</th>
                                <th>Gross Weight</th>
                                <th>Gross Unit</th>
                                <th>Chargeable Weight</th>
                                <th>Net Unit</th>
                                <th>From</th>
                                <th>Dstn</th>
                                <th>Entered by</th>
                                <th>Entered Date</th>
                                <th>Modified by</th>
                                <th>Modified Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                            <tr>
                                <td className="shipment_check">
                                    <img
                                        src="/img/checked.png"
                                        className="shipment-check-img"
                                    />
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Shrinagar Exports PVT LTD</td>
                                <td className="">K&amp;T GMBH</td>
                                <td className="" />
                                <td className="">UPX Express</td>
                                <td className="">Registered</td>
                                <td className="" />
                                <td className="" />
                                <td className="">020-2345678</td>
                                <td className="">234512342</td>
                                <td className="">234512342</td>
                                <td className="">1</td>
                                <td className="">PKGs</td>
                                <td className="">3.5</td>
                                <td className="">KGs</td>
                                <td className="">1</td>
                                <td className="">KGs</td>
                                <td className="">Delhi</td>
                                <td className="">Hongkong</td>
                                <td className="">Exp4</td>
                                <td className="">20/06/2024</td>
                                <td className="" />
                                <td className="" />
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_list_next_page">
                        <ul>
                            <li>
                                {" "}
                                <a href="#">
                                    <img src="/img/right.png" alt="right" />
                                </a>{" "}
                            </li>
                            <li className="main_list_next_list active">
                                <a href="#">1</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">2</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">3</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">4</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">5</a>
                            </li>
                            <li className="main_list_next_list img_icon_sec">
                                <a href="#">
                                    <img src="/img/Menu-icon.png" alt="manu" />
                                </a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">10</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">11</a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="/img/left.png" alt="left" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchTab