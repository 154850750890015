import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Cookies from "js-cookie";
import { format } from 'date-fns';
import QuotationAirExportModal from '../../Modal/QuotationAirExportModal'
import DatePicker from "react-datepicker";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import Select from 'react-select'
import Swal from 'sweetalert2';

import { RateRequestSchema } from '../../../ValidationScema';
import { mainUrl } from '../../../Config';
import { v4 as uuidv4 } from 'uuid';
const QuotationAirExportForm = () => {
  // const [quoteId, setQuoteId] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uhqpa_id = queryParams.get('uhqpa_id');
  const quotationId = queryParams.get('quotation_id');
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [validUpto, setValidUpto] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [updateTraficDetails, setUpdateTraficDetails] = useState([]);
  const [tableId, setTableId] = useState();
  const [id, setId] = useState();
  const country = [
    { value: 'india', label: 'india' },
  ]
  const state = [
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  ]
  const isLastRowFilled = () => {
    const lastRow = formik.values.tarrif_details[formik.values.tarrif_details.length - 1];
    return Object.values(lastRow).every(field => field !== '' && field !== null);
  };

  const createQuotationStatus = async () => {

    try {
      const token = Cookies.get("token");
      const requesId = {
        quote_status_id: 12,
        quote_ids: [quotationId]
      };
      const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requesId),
      });
    } catch (error) {
      console.log(error)
    }
  }
  const formik = useFormik({
    initialValues: {
      uhqpa_id: uhqpa_id,
      shipment_info: {
        quotation_number: '',
        date_of_creation: "",
        buy_ref_number: '',
        rate_validity: "",
        validity_against: '',
        quotation_rise_in: '',
      },
      company_info: {
        company_name: '',
        address: '',
        phone_number: '',
        email: '',
        gstin: '',
        country: '',
        state: '',
      },
      general_info: {
        company_name: '',
        address: '',
        billing_account: '',
        attention_to: '',
        airline: '',
        place_of_receipt: '',
        commodity: '',
        airport_of_loading: '',
        cargo_weight: '',
        airport_of_discharge: '',
        incoterms: '',
        tentative_transit_time: null,
        final_delivery_place: '',
        notes: '',
      },
      tarrif_details: updateTraficDetails,

    },

    validationSchema: RateRequestSchema,
    onSubmit: async (values, { resetForm }) => {
      values.tarrif_details = updateTraficDetails;
      try {
        const token = Cookies.get("token");

        const response = await fetch(`${mainUrl}/api/v.1/createQuotationRate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        });
        if (response.ok) {
          const responseData = await response.json();
          const updatedInformation = responseData?.uhqpa_id
          await createQuotationStatus(updatedInformation);
          navigate('/dashboard/view-updated-query-list');
          resetForm()
          toast.success(responseData?.message);
        } else {
          toast.error("Wrong Credentials");
        }
      } catch (error) {
        toast.error(error);
      }
    },
  });
  let addTableId;
  let tableDetailsId;

  const [addTable, setAddTable] = useState([1]);
  const handleAddAirline = () => {
    setAddTable([...addTable, { id: addTable.length, updateTraficDetails: [] }]);

  }
  const removeTable = (idToRemove) => {
    const updatedAddTable = addTable.filter((value, i) => i !== idToRemove);
    setAddTable(updatedAddTable);
  };
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det">
              <h2>
                <Link to="/dashboard/query-list" className="query_info">
                  <img src="/img/re_darect.png" alt="Query" />
                </Link>
                Quotation Air Import Form 
              </h2>
            </div>
            <div className="main_detail_form Air_Export_Form_bg">
              <form className="Air_Export_bg_main" onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();

              }}>
                {/* <input type="hidden" name="uhqpa_id" value={status_id} /> */}
                {/* Shipment Information */}
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/gen.png" alt="gen" />
                    </span>
                    Shipment Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>Quotation Number <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.quotation_number}
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('shipment_info.quotation_number', num);
                        }}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_number"
                      />
                      {formik.touched.shipment_info?.quotation_number && formik.errors.shipment_info?.quotation_number ? (
                        <div className="error">{formik.errors.shipment_info.quotation_number}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section por_main_detail">
                      <label>Date of Creation <span>*</span></label>
                      <div className="custom-datepicker">
                        <DatePicker
                          style={{ width: "100%" }}
                          selected={startDate}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setStartDate(date);
                            formik.setFieldValue("shipment_info.date_of_creation", formattedDate);
                          }}
                          // onChange={(date) => {
                          //   setStartDate(date);
                          //   formik.setFieldValue("shipment_info.date_of_creation", date);
                          // }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.shipment_info?.date_of_creation && formik.errors.shipment_info?.date_of_creation ? (
                        <div className="error">{formik.errors.shipment_info.date_of_creation}</div>
                      ) : null}
                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>Buying Reference Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.buy_ref_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.buy_ref_number"
                      />
                      {formik.touched.shipment_info?.buy_ref_number && formik.errors.shipment_info?.buy_ref_number ? (
                        <div className="error">{formik.errors.shipment_info.buy_ref_number}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section por_main_detail">
                      <label>Rate Validity <span>*</span> </label>
                      <div className="custom-datepicker">
                        <DatePicker
                          style={{ width: "100%" }}
                          selected={endDate}
                          onChange={(date) => {
                            const formattedDate = format(date, 'yyyy-MM-dd');
                            setStartDate(date);
                            formik.setFieldValue("shipment_info.rate_validity", formattedDate);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.shipment_info?.rate_validity && formik.errors.shipment_info?.rate_validity ? (
                        <div className="error">{formik.errors.shipment_info.rate_validity}</div>
                      ) : null}
                      <span>
                        <img src="/img/simple-calende.png" alt="Calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>Validity Against</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.validity_against}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.validity_against"
                      />
                      {formik.touched.shipment_info?.validity_against && formik.errors.shipment_info?.validity_against ? (
                        <div className="error">{formik.errors.shipment_info.validity_against}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>Quotation Rise in</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.shipment_info.quotation_rise_in}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="shipment_info.quotation_rise_in"
                      />
                      {formik.touched.shipment_info?.quotation_rise_in && formik.errors.shipment_info?.quotation_rise_in ? (
                        <div className="error">{formik.errors.shipment_info.quotation_rise_in}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Company Information */}
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Company Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Company Name <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.company_name"
                      />
                      {formik.touched.company_info?.company_name && formik.errors.company_info?.company_name ? (
                        <div className="error">{formik.errors.company_info.company_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Address <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.address"
                      />
                      {formik.touched.company_info?.address && formik.errors.company_info?.address ? (
                        <div className="error">{formik.errors.company_info.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Phone Number <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.phone_number}
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('company_info.phone_number', num);
                        }}
                        onBlur={formik.handleBlur}
                        name="company_info.phone_number"
                      />
                      {formik.touched.company_info?.phone_number && formik.errors.company_info?.phone_number ? (
                        <div className="error">{formik.errors.company_info.phone_number}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Email ID <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.email"
                      />
                      {formik.touched.company_info?.email && formik.errors.company_info?.email ? (
                        <div className="error">{formik.errors.company_info.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>GSTIN <span>*</span> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.gstin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.gstin"
                      />
                      {formik.touched.company_info?.gstin && formik.errors.company_info?.gstin ? (
                        <div className="error">{formik.errors.company_info.gstin}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>Country <span>*</span> </label>
                      <Select
                        className="mt-2"
                        options={country}
                        onChange={(selectedOption) =>
                          formik.setFieldValue('company_info.country', selectedOption.value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.country"
                      /> */}
                      {formik.touched.company_info?.country && formik.errors.company_info?.country ? (
                        <div className="error">{formik.errors.company_info.country}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section ">
                      <label>State <span>*</span> </label>
                      <Select
                        className="mt-2"
                        options={state}
                        onChange={(selectedOption) =>
                          formik.setFieldValue('company_info.state', selectedOption.value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        value={formik.values.company_info.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="company_info.state"
                      /> */}
                      {formik.touched.company_info?.state && formik.errors.company_info?.state ? (
                        <div className="error">{formik.errors.company_info.state}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    General Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Customer Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.company_name}
                        name="general_info.company_name"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.company_name && formik?.errors?.general_info?.company_name ? (
                        <div className="error">{formik?.errors?.general_info?.company_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Address <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.address}
                        name="general_info.address"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.address && formik?.errors?.general_info?.address ? (
                        <div className="error">{formik?.errors?.general_info?.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Billing Account <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.billing_account}
                        name="general_info.billing_account"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.billing_account && formik?.errors?.general_info?.billing_account ? (
                        <div className="error">{formik?.errors?.general_info?.billing_account}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Attention To</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.attention_to}
                        name="general_info.attention_to"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.attention_to && formik?.errors?.general_info?.attention_to ? (
                        <div className="error">{formik?.errors?.general_info?.attention_to}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Airline <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.airline}
                        name="general_info.airline"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.airline && formik?.errors?.general_info?.airline ? (
                        <div className="error">{formik?.errors?.general_info?.airline}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Place of Receipt</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.place_of_receipt}
                        name="general_info.place_of_receipt"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.place_of_receipt && formik?.errors?.general_info?.place_of_receipt ? (
                        <div className="error">{formik?.errors?.general_info?.place_of_receipt}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Commodity</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.commodity}
                        name="general_info.commodity"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.commodity && formik?.errors?.general_info?.commodity ? (
                        <div className="error">{formik?.errors?.general_info?.commodity}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Air Port of Loading <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.airport_of_loading}
                        name="general_info.airport_of_loading"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.airport_of_loading && formik?.errors?.general_info?.airport_of_loading ? (
                        <div className="error">{formik?.errors?.general_info?.airport_of_loading}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Cargo Weight (Kg) <span>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.cargo_weight}
                        name="general_info.cargo_weight"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.cargo_weight && formik?.errors?.general_info?.cargo_weight ? (
                        <div className="error">{formik?.errors?.general_info?.cargo_weight}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Air Port of Discharge <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.airport_of_discharge}
                        name="general_info.airport_of_discharge"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.airport_of_discharge && formik?.errors?.general_info?.airport_of_discharge ? (
                        <div className="error">{formik?.errors?.general_info?.airport_of_discharge}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Incoterms</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.incoterms}
                        name="general_info.incoterms"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.incoterms && formik?.errors?.general_info?.incoterms ? (
                        <div className="error">{formik?.errors?.general_info?.incoterms}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Tentative Transit Time <span>*</span>
                      </label>
                      <div className='custom-datepicker'>
                        <DatePicker
                          selected={formik.values.general_info.tentative_transit_time}
                          onChange={(date) => formik.setFieldValue('general_info.tentative_transit_time', date)}
                          onBlur={formik.handleBlur}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select Time"
                          className="form-control"
                          name="general_info.tentative_transit_time"
                        />
                      </div>
                      {/* <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.tentative_transit_time}
                        name="general_info.tentative_transit_time"
                        onChange={(e) => {
                          const num = e.target.value.replace(/[^0-9]/g, '');
                          formik.setFieldValue('general_info.tentative_transit_time', num);
                        }}
                        onBlur={formik?.handleBlur}
                      /> */}
                      {formik?.touched?.general_info?.tentative_transit_time && formik?.errors?.general_info?.tentative_transit_time ? (
                        <div className="error">{formik?.errors?.general_info?.tentative_transit_time}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>
                        Final Place of Delivery <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formik?.values?.general_info?.final_delivery_place}
                        name="general_info.final_delivery_place"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.final_delivery_place && formik?.errors?.general_info?.final_delivery_place ? (
                        <div className="error">{formik?.errors?.general_info?.final_delivery_place}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_box_section">
                      <label>Notes</label>
                      <textarea
                        rows={3}
                        className="form-control"
                        placeholder="Examples..."
                        value={formik?.values?.general_info?.notes}
                        name="general_info.notes"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                      />
                      {formik?.touched?.general_info?.notes && formik?.errors?.general_info?.notes ? (
                        <div className="error">{formik?.errors?.general_info?.notes}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="add-Company-button" style={{ cursor: "pointer" }} onClick={handleAddAirline}>
                      <span>
                        <img src="/img/twotone-add.png" alt="Add Company" />
                      </span>
                      Add Airline
                    </div>
                  </div>

                </div>

                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="add-Company-button">
                      <a href="">
                        <span>
                          <img src="/img/twotone-add.png" alt="Add Company" />
                        </span>
                        Airline Name
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="main-nav-det">
                  <h4>
                    <span>
                      <img src="/img/Product.png" alt="gen" />
                    </span>
                    Tariff Details
                  </h4>
                </div>

                {
                  addTable?.map((value, index) => {
                    addTableId = index + 1;
                    const Id = value.id;

                    return (
                      <>
                        <div className={`flex_detail_main ${index}-tarrif-details`}>
                          <div className="table_detail_tariff table-responsive">
                            <table className={`posts-table geeks `}>
                              <thead>
                                <tr className="users-table-tariff n_detail_comp">
                                  <th>Airline N.</th>
                                  <th>Charge Head</th>
                                  <th>Buyer Amount</th>
                                  <th>Seller Amount</th>
                                  <th>Narration</th>
                                  <th>Valid Up To</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>

                                </tr>
                              </thead>
                              <tbody>

                                {updateTraficDetails.map((detail, indexData) => {
                                  tableDetailsId = detail.tableId
                                  return (addTableId == detail.tableId && <tr key={indexData} className="main_table_row_new n_detail_comp">
                                    <td>{detail.airline_name}</td>
                                    <td>{detail.charge_head}</td>
                                    <td>{detail.buying_price}</td>
                                    <td>{detail.selling_price}</td>
                                    <td>{detail.narration}</td>
                                    <td>{detail.valid_upto}</td>
                                    <td>{detail.currency}</td>
                                    <td>{detail.exchange_rate}</td>
                                    <td>{detail.unit}</td>
                                    <td>{detail.fc_amount}</td>
                                    <td>{detail.rate}</td>

                                  </tr>
                                  )
                                })}

                              </tbody>
                            </table>
                          </div>
                          <div className="box_button_main" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => { setTableId(index + 1); setId(Id) }}>
                            <div style={{ cursor: "pointer" }}>
                              <img src="/img/twotone-add.png" alt="" />
                            </div>
                          </div>
                          {index !== 0 && index === addTable.length - 1 && (
                            <div className="box_button_main">
                              <div style={{ cursor: "pointer" }} onClick={() => removeTable(index)}>
                                <CloseIcon style={{ width: "15px" }} className='text-danger' />
                              </div>
                            </div>
                          )}
                        </div>


                      </>
                    )
                  })
                }
                <div className="main-nav-det-button text-right main-Export-button">
                  {/* <Link to="/dashboard/query-list"> */}
                  <button type='submit' className="btn search-btn">

                    Submit
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <QuotationAirExportModal tableDetailsId={tableDetailsId} addTableId={addTableId} tableId={tableId} updateTraficDetails={updateTraficDetails} setUpdateTraficDetails={setUpdateTraficDetails} modal="myModal" traficDetails />

    </>
  )
}

export default QuotationAirExportForm