import Cookies from "js-cookie";
const adminMainUrl = process.env.REACT_APP_API_SERVER;

// module
export const fetchPickedRateReqListRequest = () => ({
    type: "FIND_PICKED_RATE_REQUEST_REQUEST",
});

export const fetchPickedRateReqListSuccess = (data) => ({
    type: "FIND_PICKED_RATE_REQUEST_SUCCESS",
    payload: data,
});

export const fetchPickedRateReqListFailure = (error) => ({
    type: "FIND_PICKED_RATE_REQUEST_FAILURE",
    payload: error,
});

export const getPickedRateReqList = (token, listType, current_page, queryParams) => {

    return async function (dispatch) {
        dispatch(fetchPickedRateReqListRequest());
        // const token = Cookies.get('token');
        // Remove the 'page' parameter from queryParams if it exists
        let params = new URLSearchParams(queryParams);
        let requestBody = {};
        params.forEach((value, key) => {
            requestBody[key] = value;
        });
        params.delete('page');  // Remove the page from the query params

        let config = {
            method: "POST",
            headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        };
        if (!current_page) {
            current_page = 1;
        }

        // Append the page and items per page to the URL
        let apiUrl;
        // let apiUrl = `${adminMainUrl}/api/employee/list?page=${current_page}&${params.toString()}`;
        if (!listType) {
            apiUrl = `${adminMainUrl}/api/pricing/repricing-list?page=${current_page}`
        } else if (listType == "REPRICING") {
            apiUrl = `${adminMainUrl}/api/pricing/repricing-list?page=${current_page}`
        } else if (listType == "INPROCESS") {
            apiUrl = `${adminMainUrl}/api/pricing/inprocess-list?page=${current_page}`
        }

        await fetch(apiUrl, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchPickedRateReqListSuccess(resData));
                });
            }).catch((err) => {
                console.log("ERROR in getPickedRateReqList function(redux):", err);
                fetchPickedRateReqListFailure(err.message);
            });
    };
};

// module