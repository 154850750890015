import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Paper } from '@mui/material';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartment, getDesignation, getEmployee, getRoleList, getUserList } from '../../../Redux/action/Admin/EmployeeMasterAction';
import { BeatLoader } from 'react-spinners';
// import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getModuleDetail, getRoleModuleDetail } from '../../../Redux/action/Admin/EmployeeAction';

const adminMainUrl = process.env.REACT_APP_API_SERVER;

const OfficialDetailsUpdate = ({ handleBack, handleNext, activeStep }) => {
  const dispatch = useDispatch();
  let [departmentOptions, setDepartmentOptions] = useState([]);
  let [designationOptions, setDesignationOptions] = useState([]);
  let [roleOptions, setRoleOptions] = useState([]);
  let [userOptions, setUserOptions] = useState([]);

  let [disable, setDisable] = useState(false);
  let [showLoader, setShowLoader] = useState(true);
  let [viewPermissions, setViewPermissions] = useState(false);
  // let [detail, setDetail] = useState({});
  let [permissionList, setPermissionList] = useState({
    assignModules: [],
    notAssignModules: [],
  });


  let emply_id = Cookies.get("emply_id");

  useEffect(() => {
    //get masters data
    dispatch(getDepartment());
    dispatch(getDesignation());
    dispatch(getRoleList());
    dispatch(getUserList());
  }, []);

  //master -> department
  let departmentData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.departmentData : []);

  useEffect(() => {
    if (departmentData) {
      let departmentArr = [];
      departmentData.map(val => { if (val.status) { departmentArr.push({ value: val.id, label: val.name }) } });
      setDepartmentOptions(departmentArr);
    }
  }, [departmentData]);

  //master -> designation

  let roleData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.roleListData : []);

  useEffect(() => {
    if (roleData) {
      let roleArr = [];
      roleData.map(val => { roleArr.push({ value: val.id, label: val.name }) });
      setRoleOptions(roleArr);
    }
  }, [roleData]);

  //master -> role
  let userData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.viewUserData : []);

  useEffect(() => {
    if (userData) {
      let userArr = [];
      // userData.map(val => { userArr.push({ value: val.id, label: `${val.first_name} ${val.middle_name} ${val?.last_name} (${val?.unique_id})` }) });
      userData.forEach(val => {
        // Only push to userArr if val.id is not equal to the employeeId
        if (val.id !== Cookies.get("emply_id")) {
          userArr.push({
            value: val.id,
            label: `${val.first_name} ${val.middle_name} ${val?.last_name} (${val?.unique_id})`
          });
        }
      });
      setUserOptions(userArr);
    }
  }, [userData]);

  //master -> role
  let designationData = useSelector(state => state?.EmployeeMasterReducer ? state?.EmployeeMasterReducer?.designationData : []);

  useEffect(() => {
    if (designationData) {
      let designationArr = [];
      designationData.map(val => { if (val.status) { designationArr.push({ value: val.id, label: val.name }) } });
      setDesignationOptions(designationArr);
    }
  }, [designationData]);

  let { employeeData, loadingEmployeeApi } = useSelector(state => state?.EmployeeMasterReducer);




  let initialValues = {
    department_id: "",
    designation_id: "",
    role_id: "",
    reporting_user_id: [],
    offical_contact_number: "",
    offical_contact_email: "",
    permissionList: [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      department_id: Yup.string().required("Please select department."),
      designation_id: Yup.string().required("Please select designation."),
      role_id: Yup.string().required("Please select role."),
      offical_contact_number: Yup.string().required('Please enter official contact number.'),
      offical_contact_email: Yup.string().required('Please enter official email.').matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid email"),
      permissionList: Yup.array().min(1, 'At least one permission must be selected')
    }),
    onSubmit: (values, { resetForm }) => {
      values.emply_id = Cookies.get("emply_id");
      // console.log(values);

      const convertedPermission = values.permissionList.reduce((acc, current) => {
        const existingModule = acc.find((module) => module.module_short_name === current.module_short_name);
        if (existingModule) {
          existingModule.permission_list.push({ permission_short_name: current.permission_short_name });
        } else {
          acc.push({
            module_short_name: current.module_short_name,
            permission_list: [{ permission_short_name: current.permission_short_name }]
          });
        }
        return acc;
      }, []);

      values.permissionList = convertedPermission;

      // console.log(values);
      setDisable(true);
      try {
        let config = { headers: { "auth-token": Cookies.get('token'), 'Content-Type': 'application/json', } }

        axios.post(`${adminMainUrl}/api/employee/official-detail-add`, values, config)
          .then(res => {
            if (res.status === 200) {
              Cookies.set("emply_id", res?.data?.data?.emply_id)
              toast.success(res.data.msg);
              handleNext();
              resetForm();
              setDisable(false);
            } else {
              toast.error(res.data.msg);
              setDisable(false);
            }
          }).catch(err => {
            console.log(err);
            toast.error(err.response.data.msg);
            setDisable(false);
          });

      } catch (error) {
        console.log(error);
        toast.error(error.response.data.msg);
        setDisable(false);
      }
    }

  });


  const handleRolePerm = (id) => {
    dispatch(getRoleModuleDetail(id, emply_id));
  }

  //permissions getting
  let { loadingRoleModuleDetail, roleModuleDetailDataError, roleModuleDetailData } = useSelector(state => state?.EmployeeReducer);

  useEffect(() => {
    if (loadingRoleModuleDetail) {
      // console.log("loading11")
      setViewPermissions(false);
    } else {
      // console.log("loading22")
      setViewPermissions(true);
    };

    if (roleModuleDetailData) {

      // console.log("roleModuleDetailData", roleModuleDetailData);
      setPermissionList({
        assignModules: roleModuleDetailData.assignModules,
        notAssignModules: roleModuleDetailData.notAssignModule,
      });
      
      // let result = [];
      // // Combine assignModules and notAssignModule arrays
      // if (roleModuleDetailData.assignModules && roleModuleDetailData.notAssignModule) {
      //   const allModules = [...roleModuleDetailData.assignModules, ...roleModuleDetailData.notAssignModule];
      //   // Function to filter permissions with assign_permission = true


      //   result = allModules.flatMap(module =>
      //     module.permission
      //       .filter(permission => permission.assign_permission) // Only permissions with assign_permission = true
      //       .map(permission => ({
      //         module_short_name: module.short_name, // Module short name
      //         permission_short_name: permission.short_name // Permission short name
      //       }))
      //   );

      //   console.log("result", result);
      // }

      // formik.setFieldValue("permissionList", result);
    }

    // console.log(roleModuleDetailData);

  }, [loadingRoleModuleDetail, roleModuleDetailDataError, roleModuleDetailData]);




  useEffect(() => {
    if (emply_id) {
      dispatch(getEmployee(emply_id));
      if (loadingEmployeeApi) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    } else {
      toast.error("Please fill personal details first.")
      handleBack();
    }
  }, [emply_id, loadingEmployeeApi]);

  useEffect(() => {
    if (employeeData && employeeData?.userInfo) {
      formik.setValues({
        department_id: employeeData?.userInfo?.department_id ? employeeData?.userInfo?.department_id : "",
        designation_id: employeeData?.userInfo?.designation_id ? employeeData?.userInfo?.designation_id : "",
        role_id: employeeData?.userInfo?.role_id ? employeeData?.userInfo?.role_id : "",
        offical_contact_number: employeeData?.userInfo?.offical_contact_number ?? "",
        offical_contact_email: employeeData?.userInfo?.offical_contact_email ?? "",
      });

      if (employeeData?.userInfo?.user_reporting_list && Array.isArray(employeeData?.userInfo?.user_reporting_list)) {
        // console.log(employeeData?.userInfo?.user_reporting_list?.map(man => man?.id))
        formik.setFieldValue("reporting_user_id", employeeData?.userInfo?.user_reporting_list?.map(man => man?.reporting_user_info?.id))
      }


      if (employeeData?.userInfo?.role_id) {
        setViewPermissions(true);
        dispatch(getRoleModuleDetail(employeeData?.userInfo?.role_id, emply_id));
      } else {
        setViewPermissions(false);
      }

    }
  }, [employeeData]);

  const handleRoleChange = (option) => {
    formik.setFieldValue('role_id', option.value);
    formik.setFieldValue('permissionList', []);
    handleRolePerm(option.value);
  };





  // Tooltip
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Upto 2 Reporting Managers can be added.
    </Tooltip>
  );


  const handlePermissionChange = (moduleType, moduleIndex, permIndex, isChecked) => {
    const updatedList = { ...permissionList };
    const selectedModule = updatedList[moduleType][moduleIndex];

    // Update the specific permission
    selectedModule.permission[permIndex].assign_permission = isChecked;

    // Ensure permissionList is an array
    let updatedPermissionList = Array.isArray(formik.values.permissionList) ? [...formik.values.permissionList] : [];

    const permissionShortName = selectedModule.permission[permIndex].short_name;
    const moduleShortName = selectedModule.short_name;

    if (isChecked) {
      updatedPermissionList.push({
        module_short_name: moduleShortName,
        permission_short_name: permissionShortName,
      });
    } else {
      updatedPermissionList = updatedPermissionList.filter(
        (perm) => !(perm.module_short_name === moduleShortName && perm.permission_short_name === permissionShortName)
      );
    }

    formik.setFieldValue('permissionList', updatedPermissionList);

    // Check if all permissions in the module are selected
    const allChecked = selectedModule.permission.every((perm) => perm.assign_permission);
    selectedModule.allPermissionsChecked = allChecked;

    setPermissionList(updatedList);
  };


  const handleModuleHeadChange = (moduleType, moduleIndex, isChecked) => {
    const updatedList = { ...permissionList };
    const selectedModule = updatedList[moduleType][moduleIndex];

    // Update all permissions inside the module
    selectedModule.permission = selectedModule.permission.map((perm) => ({
      ...perm,
      assign_permission: isChecked,
    }));

    // Ensure permissionList is an array
    let updatedPermissionList = Array.isArray(formik.values.permissionList) ? [...formik.values.permissionList] : [];

    selectedModule.permission.forEach((perm) => {
      const permissionShortName = perm.short_name;
      const moduleShortName = selectedModule.short_name;

      if (isChecked) {
        // Check if the permission already exists to avoid duplicates
        if (!updatedPermissionList.some(p => p.permission_short_name === permissionShortName && p.module_short_name === moduleShortName)) {
          updatedPermissionList.push({
            module_short_name: moduleShortName,
            permission_short_name: permissionShortName,
          });
        }
      } else {
        updatedPermissionList = updatedPermissionList.filter(
          (perm) => !(perm.module_short_name === moduleShortName && perm.permission_short_name === permissionShortName)
        );
      }
    });

    formik.setFieldValue('permissionList', updatedPermissionList);

    // Set the "all checked" status for the module
    selectedModule.allPermissionsChecked = isChecked;

    setPermissionList(updatedList);
  };



  const renderPermissions = (modules = [], moduleType) => {
    return modules.map((module, moduleIndex) => (
      <div key={moduleIndex} className="col-md-4 mb-3">
        <div
          className="table-responsive role_css acl-role"
          style={{ height: '200px', overflowY: 'scroll', margin: '10px 0px' }}
        >
          <table className="table table-striped permission_table">
            <thead>
              <tr>
                <th>{module.name}</th>
                <th style={{ width: 40 }}>
                  <input
                    type="checkbox"
                    className="modulePerm"
                    name={module.short_name}
                    title={`Click on this box to Select/Unselect all permissions of ${module.name}`}
                    style={{ cursor: 'pointer' }}
                    checked={module.allPermissionsChecked || false}
                    onChange={(e) => handleModuleHeadChange(moduleType, moduleIndex, e.target.checked)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {module.permission.map((permission, permIndex) => (
                <tr key={permIndex}>
                  <td>{permission.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      style={{ cursor: 'pointer' }}
                      name={permission.short_name}
                      checked={permission.assign_permission || false}
                      onChange={(e) => handlePermissionChange(moduleType, moduleIndex, permIndex, e.target.checked)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ));
  };


  return (
    <>
      {/* <Toaster /> */}
      {showLoader ?
        <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>
          <BeatLoader />
        </div>
        : <div id="section2" className="section2">
          <form onSubmit={formik.handleSubmit}>
            <FocusError formik={formik} />

            <div className="main-nav-det">
              <div className="admin_form_detail">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="input_box_section ">
                      <label>Official Contact Number <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Official Contact Number"
                        name="offical_contact_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.offical_contact_number}
                        maxLength={10}
                        onInput={(event) => {
                          let value = event.target.value;
                          value = value.replace(/[^0-9]/g, '');
                          event.target.value = value;
                        }}

                      />
                      <div className="help-block with-errors">
                        {formik.touched.offical_contact_number && formik.errors.offical_contact_number ? (
                          <div className='text-danger small'>{formik.errors.offical_contact_number}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="input_box_section ">
                      <label>Official E-mail ID <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Official E-mail ID"
                        name="offical_contact_email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.offical_contact_email}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.offical_contact_email && formik.errors.offical_contact_email ? (
                          <div className='text-danger small'>{formik.errors.offical_contact_email}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="select_box_section  ">
                      <label>Department <span className="text-danger">*</span></label>
                      <Select
                        className='mt-2'
                        options={departmentOptions}
                        name="department_id"
                        value={departmentOptions.find(option => option.value === formik.values.department_id)}
                        onChange={option => formik.setFieldValue('department_id', option.value)}
                        onBlur={formik.handleBlur}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.department_id && formik.errors.department_id ? (
                          <div className='text-danger small'>{formik.errors.department_id}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="select_box_section ">
                      <label>Designation <span className="text-danger">*</span></label>
                      <Select
                        className='mt-2'
                        options={designationOptions}
                        name="designation_id"
                        value={designationOptions.find(option => option.value === formik.values.designation_id)}
                        onChange={option => formik.setFieldValue('designation_id', option.value)}
                        onBlur={formik.handleBlur}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.designation_id && formik.errors.designation_id ? (
                          <div className='text-danger small'>{formik.errors.designation_id}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>



                  <div className="col-md-6 col-lg-4">
                    <div className="select_box_section">
                      <label>Reporting Manager: <span className="text-danger"></span>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <i className="fa-solid fa-circle-info" style={{ cursor: 'pointer' }}></i>
                        </OverlayTrigger>
                      </label>
                      <Select
                        className='mt-2'
                        options={userOptions}
                        name="reporting_user_id"
                        value={userOptions.filter(option => (formik.values.reporting_user_id).includes(option.value))}
                        // value={[75,76]}
                        onChange={selectedOptions => {
                          if (selectedOptions.length <= 2) {
                            formik.setFieldValue('reporting_user_id', selectedOptions.map(option => option.value));
                          }
                        }}
                        onBlur={formik.handleBlur}
                        isMulti={true}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.reporting_user_id && formik.errors.reporting_user_id ? (
                          <div className='text-danger small'>{formik.errors.reporting_user_id}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>


                  <div className="col-md-6 col-lg-4">
                    <div className="select_box_section">
                      <label>Role<span className="text-danger">*</span></label>
                      <Select
                        className='mt-2'
                        options={roleOptions}
                        name="role_id"
                        value={roleOptions.find(option => option.value === formik.values.role_id)}
                        onChange={handleRoleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="help-block with-errors">
                        {formik.touched.role_id && formik.errors.role_id ? (
                          <div className='text-danger small'>{formik.errors.role_id}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {viewPermissions && roleModuleDetailData && (
                <>
                  <div className="main_detail_form">
                    <div className="main-nav-det">
                      <h4>Assigned Permissions</h4>
                    </div>
                    <div className="row">{renderPermissions(permissionList.assignModules, 'assignModules')}</div>
                    <div className="main-nav-det">
                      <h4>Other Permissions</h4>
                    </div>
                    <div className="row">{renderPermissions(permissionList.notAssignModules, 'notAssignModules')}</div>

                    {formik.errors.permissionList && (
                      <div className="text-danger small">At least one permission must be selected</div>
                    )}
                  </div>
                </>
              )}

            </div >
            <Paper className='h-100 d-flex flex-col justify-content-between' square elevation={0} sx={{ p: 3 }}>

              <div className='button-container' style={{ marginTop: '20px' }}>
                <Button
                  type="button"
                  disabled={disable}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                  className='btn-stepper'
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  className='btn-stepper'
                  variant="contained"
                  // onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                  disabled={disable}
                >
                  {!disable ? "Next" : <i className="fas fa-spinner fa-spin" style={{ color: "#fff" }}></i>}
                </Button>
              </div>
            </Paper>

          </form >
        </div >
      }
    </>
  )
}

export default OfficialDetailsUpdate