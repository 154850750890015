import { combineReducers } from "redux"

import MasterReducer from "./MasterReducer";
import EmployeeReducer from "./Admin/EmployeeReducer";
import EmployeeMasterReducer from "./Admin/EmployeeMasterReducer";
import RolePermissionReducer from "./Admin/RolePermissionReducer";
import PickedRateRequestReducer from "./Sales/PickedRateRequestReducer";
import NavbarReducer from "./NavbarReducer";
import QuoteAirExportCompGenInfoReducer from "./Sales/QuoteAirExportCompGenInfoReducer";

const rootReducer = combineReducers({
    MasterReducer,
    EmployeeReducer,
    EmployeeMasterReducer,
    RolePermissionReducer,
    PickedRateRequestReducer,
    NavbarReducer,
    QuoteAirExportCompGenInfoReducer
})

export default rootReducer;