import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
const ShipmentOption = () => {
    const [startDate, setStartDate] = useState(new Date());
    const shipmentType = [
        { value: 'import', label: 'import' },
        { value: 'export', label: 'export' },
    ];
    const type = [
        { value: 'Master', label: 'Master' },
        { value: 'House', label: 'House' },
    ];
    const cargoType = [
        { value: 'HAZ', label: 'HAZ' },
        { value: 'Temperature Control', label: 'Temperature Control' },
        { value: 'NON- HAZ', label: 'NON- HAZ' },
        { value: 'General', label: 'General' },
        { value: 'Valuable', label: 'Valuable' },
        { value: 'Perishable', label: 'Perishable' },
    ]
    const HawbNature = [
        { value: 'Console', label: 'Console' },
        { value: 'Non-Console', label: 'Non-Console' },
    ]

    const shipmentMode = [
        { value: 'Door to Door', label: 'Door to Door' },
        { value: 'Port to Port', label: 'Port to Port' },
        { value: 'Door to Door', label: 'Door to Door' },
        { value: 'Port to Port', label: 'Port to Port' },
    ]

    const shipmentVia =[
        { value: 'Air', label: 'Air' },
        { value: 'Road', label: 'Road' },
        { value: 'Ship', label: 'Ship' },
        { value: 'Rail', label: 'Rail' },
        { value: 'Courier', label: 'Courier' },
    ]
   
    const mode=[
        { value: 'Direct', label: 'Direct' },
        { value: 'Nominated to us', label: 'Nominated to us' },
        { value: 'Nominated to others', label: 'Nominated to others' },
    ]

    const shipmentTerm=[
        { value: 'DAT', label: 'DAT' },
        { value: 'CIP', label: 'CIP' },
        { value: 'CPT', label: 'CPT' },
        { value: 'FCA', label: 'FCA' },
        { value: 'EXW', label: 'EXW' },
    ]
    return (
        <>
            <div
                className="main_detail_form section1 border-top"
                id="section1"
                style={{ display: "block" }}
            >
                <form action="#">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-nav-det">
                                <h4>Shipment Options</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="input_box_section por_main_detail">
                                <label>Docket Date </label>
                                <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Aug 20, 2022"
                                /> */}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section por_main_detail">
                                <label>Clearance Date</label>
                                <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Shipment Type</label>
                                <Select
                                    className='mt-2'
                                    options={shipmentType}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Type </label>
                                <Select
                                    className='mt-2'
                                    options={type}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Cargo Type </label>
                                <Select
                                    className='mt-2'
                                    options={cargoType}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>HAWB Nature </label>
                                <Select
                                    className='mt-2'
                                    options={HawbNature}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>MAWB Nature </label>
                                <Select
                                    className='mt-2'
                                    options={HawbNature}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Shipment Type</label>
                                <Select
                                    className='mt-2'
                                    options={shipmentType}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Shipment Mode</label>
                                <Select
                                    className='mt-2'
                                    options={shipmentMode}
                                />

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Shipment Via</label>
                                <Select
                                    className='mt-2'
                                    options={shipmentVia}
                                />
                             
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Mode</label>
                                <Select
                                    className='mt-2'
                                    options={mode}
                                />
                              
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input_box_section ">
                                <label>Shipment Term</label>
                                <Select
                                    className='mt-2'
                                    options={shipmentTerm}
                                />
                               
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default ShipmentOption