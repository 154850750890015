// import React, { useState, useEffect } from 'react'
// import Select from 'react-select';
// import DatePicker from 'react-datepicker';
// import { useFormik } from 'formik';
// import { AdminTraficDetailsSchema } from '../../ValidationScema';
// import { format } from 'date-fns';
// import Api from '../../Api';
// import { adminMainUrl } from '../../Config';
// import { FocusError } from 'focus-formik-error';
// const QuotationAirExportModal = ({ rate, addTableId, tableId, modal, setUpdateTraficDetails, updateTraficDetails }) => {
//   const [currentRowData, setCurrentRowData] = useState([]);
//   const [weightUnit, setWeightUnit] = useState([]);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [startDate, setStartDate] = useState(null);
//   const [cargoType, setCargoType] = useState([]);
//   const [dataId, setDataId] = useState(null);
//   const GetWeightUnit = async () => {
//     try {
//       const data = await Api(`${adminMainUrl}/api/weight-unit/get`, "GET");
//       const options = data?.data
//         ?.filter((item) => item.status === true).map((item) => ({
//           WeightUnitId: item.id,
//           value: item.name,
//           label: item.name,
//         }));
//       setWeightUnit(options);
//     } catch (error) {
//       setWeightUnit([]);

//       console.error("Error fetching data:", error);
//     }
//   };
//   const GetCargoType = async () => {
//     try {
//       const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
//       const options = data?.data?.map(item => ({
//         cargoId: item.id,
//         value: item.name,
//         label: item.name,
//       }));
//       setCargoType(options);

//     } catch (error) {
//       setCargoType([]);

//       console.error("Error fetching data:", error);
//     }
//   };



//   const chargeHead = [
//     { value: 'A/F', label: 'A/F' },
//     { value: 'SSC', label: 'SSC' },
//     { value: 'FSC', label: 'FSC' },
//     { value: 'XRAY', label: 'XRAY' },
//     { value: 'MISC', label: 'MISC' },
//     { value: 'AMS', label: 'AMS' },
//     { value: 'EXTRA', label: 'EXTRA' },

//   ]
//   const handleEditClick = (detail) => {
//     setCurrentRowData(detail);
//   };

//   const formik = useFormik({
//     initialValues: currentRowData || {
//       airline_code: '',
//       charge_head: '',
//       buying_price: '',
//       selling_price: '',
//       narration: '',
//       valid_upto: '',
//       currency: '',
//       exchange_rate: '',
//       unit_id: '',
//       fc_amount: '',
//       rate: '',
//       cargo_type_id: '',  // Already correctly populated
//     },
//     validationSchema: AdminTraficDetailsSchema,
//     onSubmit: (values, { resetForm }) => {
//       console.log('Form values on submit:', values);  // Check that all values are populated here

//       values.tableId = tableId;  // Add tableId before submission

//       // Update traffic details logic
//       if (currentRowData) {
//         const updatedDetails = updateTraficDetails.map((detail) =>
//           detail === currentRowData ? { ...values, valid_upto: values.valid_upto.split('T')[0] } : detail
//         );
//         setUpdateTraficDetails(updatedDetails);
//         setCurrentRowData(null);
//       } else {
//         setUpdateTraficDetails([...updateTraficDetails, values]);
//       }

//       setIsSubmitting(false);
//       resetForm();  // Reset the form after submission
//     },
//   });
//   useEffect(() => {
//     if (currentRowData) {
//       console.log('Populating form with current row data:', currentRowData);
//       formik.setValues(currentRowData);
//     }
//   }, [currentRowData]);

//   const handleDeleteClick = (index) => {
//     const updatedDetails = updateTraficDetails.filter((_, i) => i !== index);
//     setUpdateTraficDetails(updatedDetails);
//   };
//   useEffect(() => {
//     GetCargoType();
//     GetWeightUnit();
//   }, []);
//   // useEffect(() => {
//   //   if (rate[0]?.quote_tarrif) {
//   //     // Map over the `quote_tarrif` and transform the data
//   //     const tariffDetails = rate[0].quote_tarrif.map((value) => ({
//   //       airline_code: value.airline_code || '',
//   //       charge_head: value.charge_head || '',
//   //       buying_price: value.buying_price || '',
//   //       selling_price: value.selling_price || '',
//   //       narration: value.narration || '',
//   //       valid_upto: value.valid_upto || '',
//   //       currency: value.currency || '',
//   //       exchange_rate: value.exchange_rate || '',
//   //       unit_id: value.unit_id || '',
//   //       fc_amount: value.fc_amount || '',
//   //       rate: value.rate || '',
//   //       cargo_type_id: value.cargo_type_id || '',
//   //     }));

//   //     // Set the transformed data to the state
//   //     // setUpdateTraficDetails(tariffDetails);

//   //     // Update Formik's field value
//   //     formik.setFieldValue('quote_tarrif', tariffDetails);
//   //   }
//   // }, [rate]);  // Add `rate` and `formik` as dependencies
//   console.log(rate,"rate...................................")
//   useEffect(() => {
//     if (rate[0]?.quote_tarrif) {
//       const tariffDetails = rate[0]?.quote_tarrif.map((value) => ({
//         airline_code: value.airline_code || '',
//         charge_head: value.charge_head || '',
//         buying_price: value.buying_price || '',
//         selling_price: value.selling_price || '',
//         narration: value.narration || '',
//         valid_upto: value.valid_upto || '',
//         currency: value.currency || '',
//         exchange_rate: value.exchange_rate || '',
//         unit_id: value.unit_id || '',
//         fc_amount: value.fc_amount || '',
//         rate: value.rate || '',
//         cargo_type_id: value.cargo_type_id || '',
//       }));

//       console.log("Tariff details after mapping:", tariffDetails);

//       formik.setFieldValue('quote_tarrif', tariffDetails);
//       setUpdateTraficDetails(tariffDetails);
//     }
//   }, [rate]);
//   return (
//     <>
//       <div className="modal fade" id={modal} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//         <div className="modal-dialog detail_popup_width modal-xl modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="btn-close-new-section">
//               <button type="button" className="btn-close" data-bs-dismiss="modal" />
//             </div>
//             <div className="modal-body detail_con_popup_main">
//               <div className="main-nav-det">
//                 <h4>New Rate</h4>
//               </div>
//               <div className="main_detail_form " id="main_id_sec">
//                 <form onSubmit={formik.handleSubmit}>
//                   <FocusError formik={formik} />
//                   <div className="Air_Export_Form_bg">
//                     <div className="Air_Export_bg_main">
//                       <div className="row">
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Airline Code <span>*</span></label>
//                             <input
//                               type="text"
//                               placeholder="Enter airline name"
//                               value={formik.values.airline_code}
//                               onChange={formik.handleChange}
//                               onBlur={formik.handleBlur}
//                               name="airline_code"

//                             />
//                             {formik.touched.airline_code && formik.errors.airline_code ? (
//                               <div className="error">{formik.errors.airline_code}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Charge Head <span>*</span></label>
//                             <input
//                               type="text"
//                               placeholder="Enter charge head"
//                               value={formik.values.charge_head}
//                               onChange={formik.handleChange}
//                               onBlur={formik.handleBlur}
//                               name="charge_head"
//                             />
//                             {formik.touched.charge_head && formik.errors.charge_head ? (
//                               <div className="error">{formik.errors.charge_head}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Buying Price </label>
//                             <input
//                               type="text"
//                               placeholder="Enter buying price"
//                               value={formik.values.buying_price}
//                               onChange={(e) => {
//                                 const num = e.target.value.replace(/[^0-9]/g, '');
//                                 formik.setFieldValue('buying_price', num);
//                               }}
//                               onBlur={formik.handleBlur}
//                               name="buying_price"
//                             />
//                             {formik.touched.buying_price && formik.errors.buying_price ? (
//                               <div className="error">{formik.errors.buying_price}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Selling Price <span>*</span></label>
//                             <input
//                               type="text"
//                               placeholder="Enter selling price"
//                               value={formik.values.selling_price}
//                               onChange={(e) => {
//                                 const num = e.target.value.replace(/[^0-9]/g, '');
//                                 formik.setFieldValue('selling_price', num);
//                               }}
//                               onBlur={formik.handleBlur}
//                               name="selling_price"
//                             />
//                             {formik.touched.selling_price && formik.errors.selling_price ? (
//                               <div className="error">{formik.errors.selling_price}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Narration</label>
//                             <input
//                               type="text"
//                               placeholder="Enter narration"
//                               value={formik.values.narration}
//                               onChange={formik.handleChange}
//                               onBlur={formik.handleBlur}
//                               name="narration"
//                             />
//                             {formik.touched.narration && formik.errors.narration ? (
//                               <div className="error">{formik.errors.narration}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Valid Upto <span>*</span></label>
//                             <DatePicker
//                               placeholderText='Enter valid upto'
//                               style={{ width: '100%' }}
//                               // selected={formik.values.valid_upto}
//                               selected={startDate}
//                               onChange={(date) => {
//                                 const formattedDate = format(date, 'yyyy-MM-dd');
//                                 setStartDate(date);
//                                 formik.setFieldValue("valid_upto", formattedDate);
//                               }}
//                               // onChange={(date) => {
//                               //   const formattedDate = date.toISOString().split('T')[0];
//                               //   formik.setFieldValue('valid_upto', formattedDate);
//                               // }}
//                               onBlur={formik.handleBlur}
//                               name="valid_upto"
//                             />
//                             {formik.touched.valid_upto && formik.errors.valid_upto ? (
//                               <div className="error">{formik.errors.valid_upto}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Currency</label>
//                             <input
//                               type="text"
//                               placeholder="Enter currency"
//                               value={formik.values.currency}
//                               onChange={formik.handleChange}
//                               onBlur={formik.handleBlur}
//                               name="currency"
//                             />
//                             {formik.touched.currency && formik.errors.currency ? (
//                               <div className="error">{formik.errors.currency}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Exchange Rate</label>
//                             <input
//                               type="text"
//                               placeholder="Enter exchange rate"
//                               value={formik.values.exchange_rate}
//                               onChange={(e) => {
//                                 const num = e.target.value.replace(/[^0-9]/g, '');
//                                 formik.setFieldValue('exchange_rate', num);
//                               }}
//                               onBlur={formik.handleBlur}
//                               name="exchange_rate"
//                             />
//                             {formik.touched.exchange_rate && formik.errors.exchange_rate ? (
//                               <div className="error">{formik.errors.exchange_rate}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section ">
//                             <label>Unit</label>
//                             <Select
//                               className='mt-2'
//                               options={weightUnit}
//                               onChange={(selectedOption) =>
//                                 formik.setFieldValue(
//                                   "unit_id",
//                                   selectedOption.WeightUnitId
//                                 )
//                               }
//                               onBlur={formik.handleBlur}
//                             />
//                             {formik.touched.unit_id && formik.errors.unit_id ? (
//                               <div className="error">{formik.errors.unit_id}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>FC Amount</label>
//                             <input
//                               type="text"
//                               placeholder="Enter FC amount"
//                               value={formik.values.fc_amount}
//                               onChange={(e) => {
//                                 const num = e.target.value.replace(/[^0-9]/g, '');
//                                 formik.setFieldValue('fc_amount', num);
//                               }}
//                               onBlur={formik.handleBlur}
//                               name="fc_amount"
//                             />
//                             {formik.touched.fc_amount && formik.errors.fc_amount ? (
//                               <div className="error">{formik.errors.fc_amount}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="input_box_section input_popup_rate">
//                             <label>Rate</label>
//                             <input
//                               type="text"
//                               placeholder="Enter rate"
//                               value={formik.values.rate}
//                               onChange={(e) => {
//                                 const num = e.target.value.replace(/[^0-9]/g, '');
//                                 formik.setFieldValue('rate', num);
//                               }}
//                               onBlur={formik.handleBlur}
//                               name="rate"
//                             />
//                             {formik.touched.rate && formik.errors.rate ? (
//                               <div className="error">{formik.errors.rate}</div>
//                             ) : null}
//                           </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6 col-12">
//                           <div className="">
//                             <label>Cargo Type</label>

//                             <Select
//                               className='mt-2'
//                               options={cargoType}
//                               onChange={(selectedOption) =>
//                                 formik.setFieldValue(
//                                   "cargo_type_id",
//                                   selectedOption.cargoId
//                                 )
//                               }
//                               onBlur={formik.handleBlur}
//                             />
//                           </div>
//                         </div>
//                         {/* <div className="col-md-12">
//                           <div className="info-det-button-add">
//                             <button type="button" onClick={() => formik.setFieldValue('tarrif_details', [...formik.values.tarrif_details, {}])}>
//                               + Add
//                             </button>
//                           </div>
//                         </div> */}
//                         <div className="col-md-12">
//                           <button className="btn search-btn" type="submit" disabled={isSubmitting}>
//                             {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Submit</div> : "Submit"}
//                           </button>

//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//                 <div className="Air_Export_Form_bg">
//                   <div className="Air_Export_bg_main">
//                     <div className="row">
//                       <div className="col-md-12">
//                         <div className="table_detail_tariff table-responsive">
//                           <table className="posts-table geeks">
//                             <thead>
//                               <tr className="users-table-tariff n_detail_comp">
//                                 <th>Airline Code</th>
//                                 <th>Charge Head</th>
//                                 <th>Buyer Amount</th>
//                                 <th>Seller Amount</th>
//                                 <th>Narration</th>
//                                 <th>Valid Up To</th>
//                                 <th>Currency</th>
//                                 <th>Exchange Rate</th>
//                                 <th>Unit</th>
//                                 <th>FC Amount</th>
//                                 <th>Rate</th>
//                                 <th>Action</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {
//                                 updateTraficDetails.map((detail, indexData) => {
//                                   // tableDetailsId = detail.tableId;

//                                   // Check the condition for matching tableId and return the row
//                                   // if (tableId === detail.tableId) {
//                                   return (
//                                     <tr key={indexData} className="main_table_row_new n_detail_comp">
//                                       <td>{detail.airline_code}</td>
//                                       <td>{detail.charge_head}</td>
//                                       <td>{detail.buying_price}</td>
//                                       <td>{detail.selling_price}</td>
//                                       <td>{detail.narration}</td>
//                                       <td>{format(new Date(detail.valid_upto), 'dd/MM/yyyy')}</td>
//                                       <td>{detail.currency}</td>
//                                       <td>{detail.exchange_rate}</td>
//                                       <td>{detail.unit}</td>
//                                       <td>{detail.fc_amount}</td>
//                                       <td>{detail.rate}</td>
//                                       <td>
//                                         <div className="main_icon_click">
//                                           <div style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
//                                             <img src="/img/edit-line.png" alt="edit" />
//                                           </div>
//                                           <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(indexData)}>
//                                             <img src="/img/delete-outline.png" alt="delete" />
//                                           </div>
//                                         </div>
//                                       </td>
//                                     </tr>
//                                   );
//                                   // } else {
//                                   //   return null; // Return null if the condition doesn't match
//                                   // }
//                                 })
//                               }
//                               {/* {

//                                 updateTraficDetails.map((detail, indexData) => {
//                                   // tableDetailsId = detail.tableId;

//                                   // Check the condition for matching tableId and return the row
//                                   // if (tableId === detail.tableId) {
//                                   return (
//                                     <tr key={indexData} className="main_table_row_new n_detail_comp">
//                                       <td>{detail.airline_code}</td>
//                                       <td>{detail.charge_head}</td>
//                                       <td>{detail.buying_price}</td>
//                                       <td>{detail.selling_price}</td>
//                                       <td>{detail.narration}</td>
//                                       <td>{detail.valid_upto}</td>
//                                       <td>{detail.currency}</td>
//                                       <td>{detail.exchange_rate}</td>
//                                       <td>{detail.unit}</td>
//                                       <td>{detail.fc_amount}</td>
//                                       <td>{detail.rate}</td>
//                                       <td>
//                                         <div className="main_icon_click">
//                                           <div style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
//                                             <img src="/img/edit-line.png" alt="edit" />
//                                           </div>
//                                           <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(indexData)}>
//                                             <img src="/img/delete-outline.png" alt="delete" />
//                                           </div>
//                                         </div>
//                                       </td>
//                                     </tr>
//                                   );
//                                   // } else {
//                                   //   return null; // Return null if the condition doesn't match
//                                   // }
//                                 })} */}


//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="main-nav-det-button text-right main-Export-button cursor-pointer">
//                     <a data-bs-dismiss="modal" >Submit</a>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     </>
//   )
// }

// export default QuotationAirExportModal



import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import { AdminTraficDetailsSchema } from '../../ValidationScema';
import Api from '../../Api';
import { adminMainUrl, mainUrl } from '../../Config';
import { FocusError } from 'focus-formik-error';
import { format } from 'date-fns';
const QuotationAirExportModal = ({ modalData, rate, addTableId, tableDetailsId, tableId, modal, traficDetails, setUpdateTraficDetails, updateTraficDetails }) => {
  const closeButtonRef = useRef(null);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [weightUnit, setWeightUnit] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tableIdData, setTableIdData] = useState(1)
  const [cargoType, setCargoType] = useState([]);
  // const unit = [
  //   { value: 'Kg', label: 'Kg' }
  // ]
  // console.log(modalData,"updateTraficDetails")
  // Check if there's any data and if tableId matches any row's tableId
  const matchingDetail = modalData.find(detail => detail.tableId === tableId);

  // Get the airline code if both conditions are met (data exists and tableId matches)
  const initialAirlineCode = matchingDetail ? matchingDetail.airline_code : ''; // Set as empty string if no data
  const GetWeightUnit = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/weight-unit/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          WeightUnitId: item.id,
          value: item.name,
          label: item.name,
        }));
      setWeightUnit(options);
    } catch (error) {
      setWeightUnit([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetCargoType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/cargo-type/get`, "GET");
      const options = data?.data?.map(item => ({
        cargoId: item.id,
        value: item.name,
        label: item.name,
      }));
      setCargoType(options);

    } catch (error) {
      setCargoType([]);

      console.error("Error fetching data:", error);
    }
  };
  const chargeHead = [
    { value: 'A/F', label: 'A/F' },
    { value: 'SSC', label: 'SSC' },
    { value: 'FSC', label: 'FSC' },
    { value: 'XRAY', label: 'XRAY' },
    { value: 'MISC', label: 'MISC' },
    { value: 'AMS', label: 'AMS' },
    { value: 'EXTRA', label: 'EXTRA' },

  ]
  const modalRef = useRef(null);
  const handleEditClick = (detail) => {
    if (modalRef.current) {
      modalRef.current.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    setCurrentRowData(detail);
  };

  const formik = useFormik({
    initialValues: {
      airline_code: "", // Pre-fill airline_code if available, otherwise empty
      charge_head: '',
      buying_price: '',
      selling_price: '',
      narration: '',
      valid_upto: '',
      currency: '',
      exchange_rate: '',
      unit_id: '',
      fc_amount: '',
      rate: '',
      cargo_type_id: '',
    },
    validationSchema: AdminTraficDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmitting(true);
      values.tableId = tableId;
      setTableIdData(values.tableId);
      closeButtonRef.current.click();
      if (currentRowData) {
        const updatedDetails = updateTraficDetails.map((detail) =>
          detail === currentRowData ? { ...values, valid_upto: values.valid_upto.split('T')[0] } : detail
        );
        setUpdateTraficDetails(updatedDetails);
        setCurrentRowData(null);
        setIsSubmitting(false);
      } else {
        setUpdateTraficDetails([...updateTraficDetails, values]);
        setIsSubmitting(false);
      }

      resetForm({
        values: {
          ...formik.initialValues, // Reset all fields to initial values
          airline_code: formik.values.airline_code, // Preserve the airline_code
        },
      });
    },
  });
  // useEffect(() => {
  //   if (initialAirlineCode) {
  //     formik.setValues(initialAirlineCode);
  //   }
  // }, [initialAirlineCode]);
  useEffect(() => {
    if (rate?.length > 0 && rate[0].quote_tarrif) {
      setUpdateTraficDetails(rate[0].quote_tarrif);
    }
  }, [rate]);
  useEffect(() => {
    if (currentRowData) {
      formik.setValues(currentRowData);
    }
  }, [currentRowData]);
  const handleDeleteClick = (index) => {
    const updatedDetails = updateTraficDetails.filter((_, i) => i !== index);
    closeButtonRef.current.click();
    setUpdateTraficDetails(updatedDetails);
  };
  useEffect(() => {
    GetCargoType();
    GetWeightUnit();
  }, []);
  const groupedData = updateTraficDetails.reduce((acc, detail) => {
    if (!acc[detail.airline_code]) {
      acc[detail.airline_code] = [];
    }
    acc[detail.airline_code].push(detail);
    return acc;
  }, {});
  return (
    <>
      <div className="modal fade" id={modal} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog detail_popup_width modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="btn-close-new-section">
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body detail_con_popup_main">
              <div className="main-nav-det">
                <h4>New Rate</h4>
              </div>
              <div className="main_detail_form " id="main_id_sec">
                <form onSubmit={formik.handleSubmit}>
                  <FocusError formik={formik} />
                  <div className="Air_Export_Form_bg">
                    <div className="Air_Export_bg_main">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Airline Code <span>*</span></label>
                            <input
                              maxLength={2}
                              type="text"
                              placeholder="Enter airline code"
                              value={initialAirlineCode || formik.values.airline_code || ''} // Display airline_code
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="airline_code"
                              disabled={!!initialAirlineCode} // Disable only if initialAirlineCode is present
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.airline_code && formik.errors.airline_code ? (
                              <div className="error">{formik.errors.airline_code}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Charge Head <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter charge head"
                              value={formik.values.charge_head}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="charge_head"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.charge_head && formik.errors.charge_head ? (
                              <div className="error">{formik.errors.charge_head}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Buying Price </label>
                            <input
                              type="text"
                              placeholder="Enter buying price"
                              value={formik.values.buying_price}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('buying_price', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="buying_price"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.buying_price && formik.errors.buying_price ? (
                              <div className="error">{formik.errors.buying_price}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Selling Price <span>*</span></label>
                            <input
                              type="text"
                              placeholder="Enter selling price"
                              value={formik.values.selling_price}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('selling_price', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="selling_price"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.selling_price && formik.errors.selling_price ? (
                              <div className="error">{formik.errors.selling_price}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Narration</label>
                            <input
                              type="text"
                              placeholder="Enter narration"
                              value={formik.values.narration}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="narration"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.narration && formik.errors.narration ? (
                              <div className="error">{formik.errors.narration}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Valid Upto <span>*</span></label>
                            <DatePicker
                              minDate={new Date()}
                              placeholderText='Enter valid upto'
                              style={{ width: '100%' }}
                              selected={formik.values.valid_upto}
                              onChange={(date) => {
                                const formattedDate = date.toISOString().split('T')[0];
                                formik.setFieldValue('valid_upto', formattedDate);
                              }}
                              onBlur={formik.handleBlur}
                              name="valid_upto"
                            />
                            {formik.touched.valid_upto && formik.errors.valid_upto ? (
                              <div className="error">{formik.errors.valid_upto}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Currency</label>
                            <input
                              type="text"
                              placeholder="Enter currency"
                              value={formik.values.currency}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="currency"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.currency && formik.errors.currency ? (
                              <div className="error">{formik.errors.currency}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Exchange Rate</label>
                            <input
                              type="text"
                              placeholder="Enter exchange rate"
                              value={formik.values.exchange_rate}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('exchange_rate', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="exchange_rate"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.exchange_rate && formik.errors.exchange_rate ? (
                              <div className="error">{formik.errors.exchange_rate}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section ">
                            <label>Unit</label>
                            <Select
                              className="mt-2"
                              options={weightUnit}
                              value={weightUnit.find(option => option.WeightUnitId === formik.values.unit_id) || null} // Control the value based on Formik's state
                              onChange={(selectedOption) => formik.setFieldValue("unit_id", selectedOption.WeightUnitId)}
                              onBlur={formik.handleBlur}
                            />
                            {/* <Select
                              placeholder='Enter unit'
                              className="mt-2"
                              options={unit}
                              onChange={(selectedOption) =>
                                formik.setFieldValue('unit_id', selectedOption.value)
                              }
                              onBlur={formik.handleBlur}
                            /> */}
                            {/* <input
                              type="text"
                              placeholder="unit_id"
                              value={formik.values.unit_id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name="unit_id"
                            /> */}
                            {formik.touched.unit_id && formik.errors.unit_id ? (
                              <div className="error">{formik.errors.unit_id}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>FC Amount</label>
                            <input
                              type="text"
                              placeholder="Enter FC amount"
                              value={formik.values.fc_amount}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('fc_amount', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="fc_amount"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.fc_amount && formik.errors.fc_amount ? (
                              <div className="error">{formik.errors.fc_amount}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="input_box_section input_popup_rate">
                            <label>Rate</label>
                            <input
                              type="text"
                              placeholder="Enter rate"
                              value={formik.values.rate}
                              onChange={(e) => {
                                const num = e.target.value.replace(/[^0-9]/g, '');
                                formik.setFieldValue('rate', num);
                              }}
                              onBlur={formik.handleBlur}
                              name="rate"
                              onKeyPress={(event) => {
                                const inputValue = event.target.value;
                                // Prevent space as the first character
                                if (inputValue.length === 0 && event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.rate && formik.errors.rate ? (
                              <div className="error">{formik.errors.rate}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="">
                            <label>Cargo Type</label>
                            <Select
                              className='mt-2'
                              options={cargoType}
                              value={cargoType.find(option => option.cargoId === formik.values.cargo_type_id) || null}
                              onChange={(selectedOption) =>
                                formik.setFieldValue(
                                  "cargo_type_id",
                                  selectedOption.cargoId
                                )
                              }
                              onBlur={formik.handleBlur}
                            />
                            {/* <Select
                              placeholder='Enter cargo type'
                              className="mt-2"
                              options={cargoType}
                              onChange={(selectedOption) =>
                                formik.setFieldValue('cargo_type_id', selectedOption.cargoId)
                              }
                              onBlur={formik.handleBlur}
                            /> */}
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="info-det-button-add">
                            <button type="button" onClick={() => formik.setFieldValue('tarrif_details', [...formik.values.tarrif_details, {}])}>
                              + Add
                            </button>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <button className="btn search-btn" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <div><i className="fa fa-spinner fa-spin" /> Submit</div> : "Submit"}
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="Air_Export_Form_bg">
                  <div className="Air_Export_bg_main">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                          {/* <table className="posts-table geeks">
                            <thead>
                              <tr className="users-table-tariff n_detail_comp">
                                <th>Airline Code</th>
                                <th>Charge Head</th>
                                <th>Buyer Amount</th>
                                <th>Seller Amount</th>
                                <th>Narration</th>
                                <th>Valid Up To</th>
                                <th>Currency</th>
                                <th>Exchange Rate</th>
                                <th>Unit</th>
                                <th>FC Amount</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {updateTraficDetails.map((detail, index) => (

                                <tr key={index} className="main_table_row_new n_detail_comp">
                                  <td>{detail.airline_code}</td>
                                  <td>{detail.charge_head}</td>
                                  <td>{detail.buying_price}</td>
                                  <td>{detail.selling_price}</td>
                                  <td>{detail.narration}</td>
                                  <td>{detail.valid_upto}</td>
                                  <td>{detail.currency}</td>
                                  <td>{detail.exchange_rate}</td>
                                  <td>{detail.unit}</td>
                                  <td>{detail.fc_amount}</td>
                                  <td>{detail.rate}</td>
                                  <td>
                                    <div className="main_icon_click">
                                      <div ref={modalRef} style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
                                        <img src="/img/edit-line.png" alt="edit" />
                                      </div>
                                      <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(index)}>
                                        <img src="/img/delete-outline.png" alt="delete" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                              {
                                updateTraficDetails.map((detail, indexData) => {
                                  // tableDetailsId = detail.tableId;

                                  // Check the condition for matching tableId and return the row
                                  if (tableId === detail.tableId) {
                                    return (
                                      <tr key={indexData} className="main_table_row_new n_detail_comp">
                                        <td>{detail.airline_code}</td>
                                        <td>{detail.charge_head}</td>
                                        <td>{detail.buying_price}</td>
                                        <td>{detail.selling_price}</td>
                                        <td>{detail.narration}</td>
                                        <td>{detail.valid_upto}</td>
                                        <td>{detail.currency}</td>
                                        <td>{detail.exchange_rate}</td>
                                        <td>{detail.unit}</td>
                                        <td>{detail.fc_amount}</td>
                                        <td>{detail.rate}</td>
                                        <td>
                                          <div className="main_icon_click">
                                            <div style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
                                              <img src="/img/edit-line.png" alt="edit" />
                                            </div>
                                            <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(indexData)}>
                                              <img src="/img/delete-outline.png" alt="delete" />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return null; // Return null if the condition doesn't match
                                  }
                                })
                              }

                            </tbody>
                          </table> */}
                          <table className="posts-table geeks">
                            <thead>
                              <tr className="users-table-tariff n_detail_comp">
                                <th>Airline Code</th>
                                <th>Charge Head</th>
                                <th>Buying Price</th>
                                <th>Selling Price</th>
                                <th>Narration</th>
                                <th>Valid Upto</th>
                                <th>Currency</th>
                                <th>Exchange Rate</th>
                                <th>Unit ID</th>
                                <th>FC Amount</th>
                                <th>Rate</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {modalData?.length > 0 ? modalData.map((detail, indexData) => (
                                <tr key={indexData} className="main_table_row_new n_detail_comp">
                                  <td>{detail.airline_code}</td>
                                  <td>{detail.charge_head}</td>
                                  <td>{detail.buying_price}</td>
                                  <td>{detail.selling_price}</td>
                                  <td>{detail.narration}</td>
                                  <td>{detail.valid_upto ? format(new Date(detail.valid_upto), 'dd/MM/yyyy') : 'N/A'}</td>
                                  <td>{detail.currency}</td>
                                  <td>{detail.exchange_rate}</td>
                                  <td>{detail.unit_id}</td>
                                  <td>{detail.fc_amount}</td>
                                  <td>{detail.rate}</td>
                                  <td>
                                    <td>
                                      <div className="main_icon_click">
                                        <div style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
                                          <img src="/img/edit-line.png" alt="edit" />
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(indexData)}>
                                          <img src="/img/delete-outline.png" alt="delete" />
                                        </div>
                                      </div>
                                    </td>
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan="12" className="text-center">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {/* {Object.keys(groupedData).map((airlineCode) => (

                            <table className="posts-table geeks">
                              <thead>
                                <tr className='users-table-tariff n_detail_comp'>
                                  <th>Airline Code</th>
                                  <th>Charge Head</th>
                                  <th>Buying Price</th>
                                  <th>Selling Price</th>
                                  <th>Narration</th>
                                  <th>Valid Upto</th>
                                  <th>Currency</th>
                                  <th>Exchange Rate</th>
                                  <th>Unit</th>
                                  <th>FC Amount</th>
                                  <th>Rate</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {groupedData[airlineCode].map((detail, index) => (
                                  <tr key={index} className="main_table_row_new n_detail_comp">
                                    <td>{detail.airline_code}</td>
                                    <td>{detail.charge_head}</td>
                                    <td>{detail.buying_price}</td>
                                    <td>{detail.selling_price}</td>
                                    <td>{detail.narration}</td>
                                    <td>{detail.valid_upto
                                      ? format(new Date(detail.valid_upto), 'dd/MM/yyyy')
                                      : 'N/A'}</td>
                                    <td>{detail.currency}</td>
                                    <td>{detail.exchange_rate}</td>
                                    <td>{detail.unit}</td>
                                    <td>{detail.fc_amount}</td>
                                    <td>{detail.rate}</td>
                                    <td>
                                      <div className="main_icon_click">
                                        <div ref={modalRef} style={{ cursor: "pointer" }} onClick={() => handleEditClick(detail)}>
                                          <img src="/img/edit-line.png" alt="edit" />
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(index)}>
                                          <img src="/img/delete-outline.png" alt="delete" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          ))} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-nav-det-button text-right main-Export-button cursor-pointer">
                    <a data-bs-dismiss="modal"     ref={closeButtonRef}>Close</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuotationAirExportModal