import React,{useState} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";



const AirExport = () => {

    const [startDate, setStartDate] = useState(new Date());

    const navigate = useNavigate();

    const Invoice = [
        { value: 'Option 1', label: 'Option 1' },
        { value: 'Option 2', label: 'Option 2' },
        { value: 'Option 3', label: 'Option 3' }
      ];

    return (
        <>
            <main className="main users chart-page" id="skip-target">
                <div className="container">
                    <div className="col-md-12">
                        <div className="main_section_detail">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="main-nav-det">
                                        <h2>Job Number Creation</h2>
                                    </div>
                                </div>
                            </div>
                            {/* =============Search bar============= */}
                            <div className="row">
                                <div className="col-md-12">
                                <div className="query_bg_info_kyc">
                                <div className="main_section_detail">
                                    <div className="row border-bottom">
                                      <div className="col-md-3">
                                        <div className="input_box_section ">
                                           <label>
                                              Financial Year <span>*</span>
                                           </label>
                                           <input
                                               type="text"
                                               name="full_name"
                                               className="form-control"
                                               placeholder="Enter here"
                                           />
                                           </div>
                                       </div>
                                      <div className="col-md-3">
                                        <div className="input_box_section ">
                                           <label>
                                           Job No <span>*</span>
                                           </label>
                                           <input
                                               type="text"
                                               name="full_name"
                                                                  className="form-control"
                                               placeholder="Enter here"
                                           />
                                           </div>
                                       </div>
                                      <div className="col-md-3">
                                        <div className="input_box_section ">
                                           <label>
                                           Job Date <span>*</span>
                                           </label>
                                           <input
                                               type="text"
                                               name="full_name"
                                                                  className="form-control"
                                               placeholder="Enter here"
                                           />
                                           </div>
                                       </div>
                                            <div className="col-md-3">
                                                <div className="input_box_section">
                                                    <label>
                                                        Select Invoice <span>*</span>
                                                    </label>
                                                    <div className='mt-2'>
                                                        <Select options={Invoice} />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="container mt-4">
              <ul className="nav nav-pills nav-slider mb-3 border-2 box-shadow" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold active position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">SB 1</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">SB 2</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-pending" data-bs-toggle="pill" data-bs-target="#pills-pending" type="button" role="tab" aria-controls="pills-pending" aria-selected="false">Invoice</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-send" data-bs-toggle="pill" data-bs-target="#pills-send" type="button" role="tab" aria-controls="pills-send" aria-selected="false">Items</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-scheme" data-bs-toggle="pill" data-bs-target="#pills-scheme" type="button" role="tab" aria-controls="pills-scheme" aria-selected="false">Scheme</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-report" data-bs-toggle="pill" data-bs-target="#pills-report" type="button" role="tab" aria-controls="pills-report" aria-selected="false">Get Report</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-query" data-bs-toggle="pill" data-bs-target="#pills-query" type="button" role="tab" aria-controls="pills-query" aria-selected="false">Query</button>
                </li>
              </ul>
              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Shipment Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Exporter Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Location <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Govt. IEC <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            CHA No. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            IEC Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Branch Sr. No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 1 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 2 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            City <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            State <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Pin Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            NFEI <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            NFEI <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            GSTN Type <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            GSTIN No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Merchant / Manufacturer <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Class <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            AD Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div> 
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>SubAgent Details</p>
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SubAgent <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SubAgent Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SubAgent Address <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Consignee Details</p>
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Consignee Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 1 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 2 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 3 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 4 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Country <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Port Details</p>
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Loading Port <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Country of Destination <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Destination Port <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>               
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            State Origin <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Country of Discharge <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                 
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Discharge Port <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Other Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            EPZ/ICD <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Drawback A/C No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Fr Bank <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PAN No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            User Job No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Excise Rs <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Custom Rs. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Cess Amount <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Shipment Type <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Ex-Bond Job</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Ex-Bond Job <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            WareHouse Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                   
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            WareHouse Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Save
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Modify
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Clear
                                </div>
                            </div>
                        </div>                   
                </div>
                </div>
                <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>SB2 Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Sample Accomp. <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Factory Stuffed <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Nature Of Cargo <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total Packages <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-9">
                        <div className="input_box_section">
                            <label>
                              Marks No <span>*</span>
                            </label>
                            <div className='d-flex'>
                            <div className="checkbox-and-textarea">
                                <input 
                                    type="checkbox" 
                                    name="agreement_checkbox" 
                                    id="agreement_checkbox" 
                                    style={{marginRight: "10px"}}
                                />
                            </div>
                            <textarea
                                type="text"
                                name="full_name"
                                className="form-control"
                                style={{height: "85px"}}
                                placeholder="I/we undertake to abide by foreign exchange management act, 1999, as amended from time to time, including realization/repatriation of foreign exchange to/from India. We intend to claim benefit under RODTEP scheme as applicable."
                            />
                            </div>
                        </div>
                    </div>                
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total Loose Pkgs <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Gross Wt. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Net Wt. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Seal Type [A/S/W] <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            MAWB No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            MAWB Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            HAWB No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            HAWB Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Airline Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="row">     
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SB Number <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            SB Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            EP Copy No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            EP Copy Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            GRN No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PO No. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Service Type <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Head Desc <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Packing Details <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    </div>  
                    <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Save
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Clear
                                </div>
                            </div>
                        </div>                   
                </div>
                </div>
                <div className="tab-pane fade" id="pills-pending" role="tabpanel" aria-labelledby="pills-send-pending">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Invoice</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                                <thead>
                                    <tr className="n_detail_comp">
                                        <th className="users-table-th">SR NO.</th>
                                        <th className="users-table-th">Invoice No.</th>
                                        <th className="users-table-th">Invoice Date</th>
                                        <th className="users-table-th">Invoice Value</th>
                                        <th className="users-table-th">Nature of Contract</th>
                                        <th className="users-table-th">Buyer Name</th>
                                        <th width={100} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                        <div className="sub-head">
                            <p>Invoice details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Invoice Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice Value <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Curr/Exch Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Non-Stnd. Curr. <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>          
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Pymt. Nature <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Pymt. Period <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Contract No. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Is Consignee and Buyer same <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Nature of Contract <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Add (F/I/N/B) <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Inv FOB(INR) <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>                  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            IGST (Based On) <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>AEO Details</p>
                        </div>
                    </div>
                    <div className="row">     
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            AEO Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            AEO Country <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            AEO Role <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Term Place <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Charges Details</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-primary">
                            <p>Packing & Misc_Charge</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Currency <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Amount <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-primary">
                            <p>Discount on FOB Rate</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Currency <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Amount <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-primary">
                            <p>Other Deductions</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Currency <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Amount <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-primary">
                            <p>Commission</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Currency <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Amount <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Third Party</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Third Party Details <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            TP Name <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 1 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Address 2 <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            City <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Cntry Div. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            TP Cntry <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Pin <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Other  Details</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Select Invoice <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Goods Available At <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Means of Transport <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Goods Quantity <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Goods Description <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total Packages <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Vessel/Flight No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            No of Containers <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Mark No. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total Loose Packages <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Other Documents attached <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Gross Wt <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Net Wt <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Origin Criterion <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Buyer's Order No Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            BE/BL No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Statistical Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rotation No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Rotation/LEO Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            TEXPROCIL Comp. Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            TEXPROCIL Regn No Date <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Bond No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Bond/SAIL Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Bonded Warehouse <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Drawback Amt <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            DBK A/C NO <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Bank Address <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Description <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Remarks <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>BE Details [Re-export]</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Be number <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Be date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            BE invoice no <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            BE item no <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Quantity Used <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Unit of Quantity <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Freight value <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Insurance value <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Shipping Line <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Export Trade Control <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Consignment No / STO No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Initials <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PreCarraige <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Export Trade Control <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Precarriage <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    </div>  
                    <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Save
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Clear
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Duplicate
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Update Rate
                                </div>
                            </div>
                        </div>                   
                </div>
                </div>
                <div className="tab-pane fade" id="pills-send" role="tabpanel" aria-labelledby="pills-send-send">
                <div className="col-md-12">
                        <div className="sub-head">
                            <p>Items</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12 mb-3">
                        <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                                <thead>
                                    <tr className="n_detail_comp">
                                        <th className="users-table-th">INV SR No.</th>
                                        <th className="users-table-th">Item No.</th>
                                        <th className="users-table-th">Scheme</th>
                                        <th className="users-table-th">RITC</th>
                                        <th className="users-table-th">Goods Description</th>
                                        <th className="users-table-th">Quantity</th>
                                        <th className="users-table-th">Rate</th>
                                        <th className="users-table-th">Value</th>
                                        <th width={100} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>1</td>
                                        <td>19</td>
                                        <td>23452345</td>
                                        <td>100% Got Leather</td>
                                        <td>2</td>
                                        <td>4</td>
                                        <td>8</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>1</td>
                                        <td>19</td>
                                        <td>23452345</td>
                                        <td>100% Got Leather</td>
                                        <td>2</td>
                                        <td>4</td>
                                        <td>8</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>1</td>
                                        <td>19</td>
                                        <td>23452345</td>
                                        <td>100% Got Leather</td>
                                        <td>2</td>
                                        <td>4</td>
                                        <td>8</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>1</td>
                                        <td>19</td>
                                        <td>23452345</td>
                                        <td>100% Got Leather</td>
                                        <td>2</td>
                                        <td>4</td>
                                        <td>8</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Invoice Date <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice Value <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice Val(INR) <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Sub Total <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Items Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Item Sr. No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Scheme Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Scheme Code Desc <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            RITC Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Non-Stnd. Curr. <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Non-Stnd. Curr. Desc <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Qty <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Qty Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Per <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Rate Unit <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total FC <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PMV Options <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PMV [%] <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            PMV<span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total PMV<span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Item FOB(INR) <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Single Window Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            End Use <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            IGST Pay Status <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Taxable Value <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            IGST Rate <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            IGST Amt <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            RoDTEP <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SW Statement <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Supp Docs <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Re-Export <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Manf. Details <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Source State <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            State Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            HAWB <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Total Pkgs <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SW Const <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SW Prod <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SW Ctrl <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Other Details</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Accessory <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Cess <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Third Party <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            AR4 <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Reward <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Add In Template <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Add In Middle [Item No] <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Copy Licence [Item No] <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Save
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Clear
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Arrange Item Sr No.
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Duplicate 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-2">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Validate Job
                                </div>
                            </div>
                        </div> 
                </div>
                </div>
                <div className="tab-pane fade" id="pills-scheme" role="tabpanel" aria-labelledby="pills-send-scheme">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Select Invoice <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Duty Drawback Details</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                                <thead>
                                    <tr className="n_detail_comp">
                                        <th className="users-table-th">Job No</th>
                                        <th className="users-table-th">Inv No.</th>
                                        <th className="users-table-th">Item No.</th>
                                        <th className="users-table-th">Dbk No.</th>
                                        <th className="users-table-th">Quantity</th>
                                        <th className="users-table-th">Unit</th>
                                        <th className="users-table-th">Rate</th>
                                        <th className="users-table-th">CAP Value</th>
                                        <th className="users-table-th">DBK Value</th>
                                        <th width={100} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>2994</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>430399B</td>
                                        <td>2.00000</td>
                                        <td>NOS</td>
                                        <td>1.50</td>
                                        <td>0.00</td>
                                        <td>59.54</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>2994</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>430399B</td>
                                        <td>2.00000</td>
                                        <td>NOS</td>
                                        <td>1.50</td>
                                        <td>0.00</td>
                                        <td>59.54</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>2994</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>430399B</td>
                                        <td>2.00000</td>
                                        <td>NOS</td>
                                        <td>1.50</td>
                                        <td>0.00</td>
                                        <td>59.54</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>2994</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>430399B</td>
                                        <td>2.00000</td>
                                        <td>NOS</td>
                                        <td>1.50</td>
                                        <td>0.00</td>
                                        <td>59.54</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>2994</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>430399B</td>
                                        <td>2.00000</td>
                                        <td>NOS</td>
                                        <td>1.50</td>
                                        <td>0.00</td>
                                        <td>59.54</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Save
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                    Clear
                                </div>
                            </div>
                        </div> 
                </div>
                <div className="tab-pane fade" id="pills-report" role="tabpanel" aria-labelledby="pills-send-report">
                <div className="row">
                      <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                            Select Invoice <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Get Report</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Complete Annexure Part 1</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Complete Annexure Part 2</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>CHECKLIST</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>RoDTEP wise CHECKLIST</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Anneexure - A</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Annexure - D (DEPB Declaration)</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Annexure - I</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Annexure - III (Drawback/DEEC)</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Annexure - IV</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input_box_section file-input-main">
                            <label>Annexure - V</label>
                            <a className="folder_main_update">
                            
                                Document .pdf
                                <span>
                                    <img src="/img/download-icon.png" alt="file" />
                                </span>
                            </a>
                        </div>
                        </div>
                        </div>
                </div>
                <div className="tab-pane fade" id="pills-query" role="tabpanel" aria-labelledby="pills-send-query">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sub-head">
                            <p>Search Document</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Job No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>
                            Date Range <span>*</span>
                            </label>
                            <div className="custom-datepicker">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span>
                                        <img src="/img/Calendar.png" alt="Calendar" />
                                    </span>
                                </div>
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            SB Number <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Shipper <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Consignee <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Destination <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Invoice No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Scheme Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Head Desc <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Licence No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Item Desc <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            User Job No <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Entered By <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            RITC Code <span>*</span>
                            </label>
                            <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>  
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>
                            Display No. Of Job <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select />
                            </div>
                        </div>
                    </div>  
                    <div className="row justify-content-center mt-4">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Query
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Clear
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                New Job
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Read Flat File
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-2">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Download Sample
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-2">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Create Job From SR
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-2">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                Create Job From Excel
                                </div>
                            </div>
                        </div> 
                    <div className="col-md-12 mt-5">
                        <div className="sub-head">
                            <p className='mb-0'>Job Details</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="table_detail_tariff table-responsive">
                            <table className="posts-table geeks">
                                <thead>
                                    <tr className="n_detail_comp">
                                        <th className="users-table-th">Job No</th>
                                        <th className="users-table-th">Job Date</th>
                                        <th className="users-table-th">Duplicate </th>
                                        <th className="users-table-th">Exporter</th>
                                        <th className="users-table-th">Consignee</th>
                                        <th className="users-table-th">Origin</th>
                                        <th className="users-table-th">Destination</th>
                                        <th className="users-table-th">INV No.</th>
                                        <th className="users-table-th">INV Date</th>
                                        <th className="users-table-th">INV Value</th>
                                        <th className="users-table-th">FOB Value</th>
                                        <th className="users-table-th">DBK Value</th>
                                        <th className="users-table-th">SB No. </th>
                                        <th className="users-table-th">SB Date</th>
                                        <th className="users-table-th">Print Checklist</th>
                                        <th className="users-table-th">Shipment</th>
                                        <th className="users-table-th">Submission status</th>
                                        <th className="users-table-th">Submit Job</th>
                                        <th className="users-table-th">Status</th>
                                        <th className="users-table-th">Queue In</th>
                                        <th className="users-table-th">Queue Out</th>
                                        <th className="users-table-th">Excel</th>
                                        <th className="users-table-th">eSanchit</th>
                                        <th className="users-table-th">Scheme</th>
                                        <th className="users-table-th">LIC</th>
                                        <th className="users-table-th">Amend</th>
                                        <th className="users-table-th">Track</th>
                                        <th className="users-table-th">Looked By</th>
                                        <th className="users-table-th">Entered By</th>
                                        <th className="users-table-th">Entered Date</th>
                                        <th className="users-table-th">Submitted By</th>
                                        <th className="users-table-th">VOID</th>
                                        <th width={100} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="main_table_row_new n_detail_comp n_detail_none">
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td>1</td>
                                        <td>KH/22-23/3099</td>
                                        <td>29/04/2022</td>
                                        <td>526.5</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td>FOB</td>
                                        <td>Alex</td>
                                        <td className="main_table_last">
                                            <div className="main_icon_click">
                                                <a href="#main_id_sec">
                                                    <img src="/img/edit-line.png" alt="edit" />
                                                </a>
                                                <a href="#">
                                                    <img src="/img/delete-outline.png" alt="delete" />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>                  
                </div>
                </div>
              </div>
            </div>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default AirExport