import React, { useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Offcanvas } from 'bootstrap';
import { format } from 'date-fns';
export const RemarkModal = ({ id, viewData }) => {
    // console.log(viewData,"viewData....")
    useEffect(() => {
        const offcanvasElement = document.getElementById(id);
        const offcanvasInstance = new Offcanvas(offcanvasElement, {
            backdrop: 'static', // prevents closing on outside click
            keyboard: false, // disables closing with ESC key
        });

        return () => {
            offcanvasInstance.dispose(); // Clean up when the component unmounts
        };
    }, [id]);
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    function formatTime(dateString) {
        if (!dateString) return 'Invalid date'; // handle cases where dateString is undefined or null

        const date = new Date(dateString);

        // Extract time components
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        // const seconds = String(date.getSeconds()).padStart(2, "0");

        // Return only the formatted time string
        return `${hours}:${minutes}`;
    }
    return (

        <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id={id}
            aria-labelledby="offcanvasLeftLabel"
        >
            <div className="offcanvas-header">
                <h5 id="offcanvasLeftLabel"> Remarks</h5>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body">   {
                viewData?.quote_remarks?.length > 0 ?
                    <>
                        <Accordion defaultActiveKey="0">
                            {viewData?.quote_remarks?.map((value, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header className='remark-accordion'>
                                        {/* {new Date(value?.created_at).toISOString().split('T')[0]}{" "} */}
                                        <div className="remark-header-content">
                                            <span className="remark-user">
                                                {value?.remark_user?.first_name} {value?.remark_user?.middle_name} {value?.remark_user?.last_name}
                                            </span>
                                            <div className='d-flex justify-content-start align-items-center gap-3'>
                                                <span className="remark-date">{formatDate(value?.createdAt)}</span>
                                                <span className="remark-date">{formatTime(value?.createdAt)}</span>
                                                {/* <span className="remark-date">12:00</span> */}
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {value?.remarks}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </>
                    :
                    <p>No data available</p>
            }</div>
        </div>

    )
}
