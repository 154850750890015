import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const HandoverAirline = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade"
            id="handover-airline"
            role="tabpanel"
            aria-labelledby="handover-airline-tab"
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="input_box_section">
                        <label>Docket Number</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="NDL/AE/5104/24"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input_box_section">
                        <label>Operation Type</label>
                        <select name="#" id="#">
                            <option value="#">Select</option>
                            <option value="#">Registered</option>
                            <option value="#">Hand Over To Carrier</option>
                            <option value="#">Complete</option>
                            <option value="#">Cancel</option>
                        </select>
                    </div>
                </div>
                <div className="button-shipment-registration d-flex mt-2">
                    <div className="btn">
                        Save
                    </div>
                    <div className="btn">
                        Clear
                    </div>
                </div>
            </div>
            <div className="border_divider mt-4 mb-2" />
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Shipment Status</h4>
                    </div>
                </div>
            </div>
            <div className="scroll_listing_detail">
                <div className="users-table ">
                    <table className="posts-table">
                        <thead>
                            <tr className="users-table-info">
                                <th>Select All </th>
                                <th>Docket No.</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Handover Date</th>
                                <th>Handover Time</th>
                                <th>Goods Send to Carrier Date</th>
                                <th>Remarks</th>
                                <th>Shipper</th>
                                <th>Address</th>
                                <th>Consignee</th>
                                <th>Address</th>
                                <th>SubAgent</th>
                                <th>Overseas</th>
                                <th>Address</th>
                                <th>PKGs</th>
                                <th>Gross Weight</th>
                                <th>House</th>
                                <th>Date</th>
                                <th>Airway Bill No.</th>
                                <th>From</th>
                                <th>Destination</th>
                                <th>Complete Date</th>
                                <th>Remarks</th>
                                <th>MAWB</th>
                                <th>HAWB</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                            <tr>
                                <td className="">
                                    <div className="form-check">
                                        <input type="checkbox" />
                                    </div>
                                </td>
                                <td className="">NDL/AE/2345/05</td>
                                <td className="">12/04/2022</td>
                                <td className="">Registrated</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">Fair Lady Exports</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">BEIKOU</td>
                                <td className="">576, Phase ll, Noida</td>
                                <td className="">VS Global</td>
                                <td className="" />
                                <td className="" />
                                <td className="">1</td>
                                <td className="">8</td>
                                <td className="">12342234</td>
                                <td className="">12/04/2022</td>
                                <td className="">123456789</td>
                                <td className="">Delhi</td>
                                <td className="">Athens</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td>12342234</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="main_list_next_page">
                        <ul>
                            <li>
                                {" "}
                                <a href="#">
                                    <img src="/img/right.png" alt="right" />
                                </a>{" "}
                            </li>
                            <li className="main_list_next_list active">
                                <a href="#">1</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">2</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">3</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">4</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">5</a>
                            </li>
                            <li className="main_list_next_list img_icon_sec">
                                <a href="#">
                                    <img src="/img/Menu-icon.png" alt="manu" />
                                </a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">10</a>
                            </li>
                            <li className="main_list_next_list">
                                <a href="#">11</a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="/img/left.png" alt="left" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Search Panel</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="alfa_listing-kg shimpment_range">
                            <label>Party Type</label>
                            <ul>
                                <li>
                                    <div className="input_box_section">
                                        <div className="por_main_detail1">
                                            <select name="#" id="#">
                                                <option value="#">Select</option>
                                                <option value="#">Shipper</option>
                                                <option value="#">Consignee</option>
                                                <option value="#">S.Agent</option>
                                                <option value="#">Overseas</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="input_box_section">
                                        <div className="por_main_detail1">
                                            <input
                                                type="text"
                                                className="form-control color_place"
                                                placeholder="Enter Here"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Origin Port</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Dstn Port</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Docket No</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>From Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>To Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Docket Stage</label>
                            <select name="#" id="#">
                                <option value="#">Select</option>
                                <option value="#">Registered</option>
                                <option value="#">Hand Over</option>
                                <option value="#">Complete</option>
                                <option value="#">Canceled</option>
                            </select>
                        </div>
                    </div>
                    <div className="button-shipment-registration d-flex mt-2">
                        <div className="btn">
                            Search
                        </div>
                        <div className="btn">
                            Clear
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default HandoverAirline