import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mainUrl } from "../../../Config";
import Api from "../../../Api";
import Cookies from "js-cookie";
import { Height } from "@mui/icons-material";

const QueryInfo = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>
                    <Link
                      to="/admin-dashboard/picked-leads"
                      className="query_info"
                    >
                      <img src="/img/re_darect.png" alt="Query" />
                    </Link>
                    Query Information
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="new_infometion_table">
            <div className="table-responsive">
              <table className="posts-table">
                <tbody>
                  <tr>
                    <td className="table_info_color">
                      <p>Request Number</p>
                    </td>
                    <td>
                      <p>1234</p>
                    </td>
                    {/* <td>
                                            <div className="info-det-button" onClick={() => { navigate('/admin-dashboard/view-rates') }}>View Rates
                                            </div>
                                        </td> */}
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Request Date</p>
                    </td>
                    <td colSpan={2}>
                      <p>12 May 2024</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Name</p>
                    </td>
                    <td colSpan={2}>
                      <p>Rahul</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Email</p>
                    </td>
                    <td colSpan={2}>
                      <p>rahul@gmail.com</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Phone Number</p>
                    </td>
                    <td colSpan={2}>
                      <p>9876543210</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Origin</p>
                    </td>
                    <td colSpan={2}>
                      <p>Delhi</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Destination</p>
                    </td>
                    <td colSpan={2}>
                      <p>London</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Description</p>
                    </td>
                    <td colSpan={2}>
                      <p>
                        Product Name: Name of the product Packaging
                        Specification: 20cmx20cmx20cm, Total of items/cartons
                        etc. Lorem Ipsum
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Expected transit time</p>
                    </td>
                    <td colSpan={2}>
                      <p>Delivery within 20 days</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Type of Shipment Type</p>
                    </td>
                    <td colSpan={2}>
                      <p>Import</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Shipment Via</p>
                    </td>
                    <td colSpan={2}>
                      <p>Ship</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Required Service</p>
                    </td>
                    <td colSpan={2}>
                      <p>Both</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Package dimension/weight</p>
                    </td>
                    <td>
                      <tr>
                        <td style={{ border: "1px solid #c7c7c7" }}>Length</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>Width</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>Height</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>Type</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>
                          Max Weight
                        </td>
                        <td style={{ border: "1px solid #c7c7c7" }}>Unit</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>
                          Total Box
                        </td>
                      </tr>

                      <tr>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                        <td style={{ border: "1px solid #c7c7c7" }}>12</td>
                      </tr>

                      {/* <p>12cmX200cmX299cm 120 KG 2 Boxes</p> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Shipment Document or Video</p>
                    </td>
                    <td colSpan={2}>
                      <div className="col-6 input_box_section file-input-main">
                        <button className="folder_main_update">
                          {" "}
                          Shipment docs.pdf{" "}
                          <span>
                            <img src="/img/download-icon.png" alt="file" />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid #d4d4d4" }}>
                    <td className="table_info_color">
                      <p>Picked by</p>
                    </td>
                    <td colSpan={2}>
                      <p>Aman</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="table_info_color">
                      <p>Status</p>
                    </td>
                    <td colSpan={2}>
                      <a href="#" className="view_in_color">
                        In View
                      </a>
                    </td>
                  </tr>
                  <tr>
                        <td className="table_info_color">
                          <p>Remark</p>
                        </td>
                        <td colSpan={2}>
                          <a href="#" className="view_in_color">
                            
                          </a>
                        </td>
                      </tr>
                      <tr>
                                                <td className="table_info_color">
                                                    <div
                                                        className="send_query_list_button w-fit p-0 send-query" style={{ background: "none", color: "#000" }}
                                                        id="remarks"
                                                        onClick={handleToggle}
                                                    >
                                                        Add Remark
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div
                                                        className="mt-1"
                                                        id="remarks"
                                                        style={{ display: isVisible ? "block" : "none" }}
                                                    >
                                                        {/* <label for="remarks-desc">Remark</label> */}
                                                        <textarea
                                                            id="remarks-desc"
                                                            name="remarks-desc"
                                                            class="form-control"
                                                            placeholder="Write Something Here...."
                                                        ></textarea>
                                                        {/* <button className="send_query_list_button send-query desc-btn" id="sendRemarks" style={{ display: isVisible ? 'inline' : 'none' }}>
                                       Send
                                    </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                </tbody>
              </table>
            </div>
            <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="send_query_list_button send-query" onClick={() => { navigate('/admin-dashboard/quotation-air-export') }}>
                                Give Rates
                                </div>
                            </div>
                        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryInfo;
