import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function ReceivingDocumentTab() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div
            className="tab-pane fade"
            id="receiving-documents"
            role="tabpanel"
            aria-labelledby="receiving-documents-tab"
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="input_box_section">
                        <label>Docket Number</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="NDL/AE/5104/24"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Invoice Details</h4>
                    </div>
                </div>
            </div>
            <form action="">
                <div className="row">
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Invoice No</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Invoice Date</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Currency</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Value</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter Here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section">
                            <label>Pay Mode</label>
                            <select name="#" id="#">
                                <option value="#">Select</option>
                                <option value="#">FOB</option>
                                <option value="#">CI</option>
                                <option value="#">CIF</option>
                                <option value="#">CF</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section por_main_detail">
                            <label>Receiving Date</label>
                            <div className="custom-datepicker">

                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="alfa_listing-kg">
                            <div className="input_box_section ">
                                <label>Receiving Time</label>
                            </div>
                            <ul>
                                <li className="padd_left_inherit">
                                    <div className="input_box_section ">
                                        <input
                                            type="text"
                                            className="form-control color_place"
                                            placeholder="Enter Here"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="input_box_section w-swction_main">
                                        <select name="#" id="#">
                                            <option value="#">AM</option>
                                            <option value="#">PM</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="button-shipment-registration d-flex mt-2">
                        <div className="btn">
                            <img src="/img/plus_new.png" /> Add
                        </div>
                        <div className="btn">
                            <img src="/img/reload.png" />
                            Clear
                        </div>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Invoice</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table_detail_tariff table-responsive shipment_registration_table">
                        <table className="posts-table geeks">
                            <thead>
                                <tr className="n_detail_comp">
                                    <th className="users-table-th">Invoice No.</th>
                                    <th className="users-table-th">Invoice Date</th>
                                    <th className="users-table-th">
                                        Invoice Value
                                    </th>
                                    <th className="users-table-th">Pay Mode</th>
                                    <th className="users-table-th">Currency</th>
                                    <th className="users-table-th">
                                        Received Date
                                    </th>
                                    <th className="users-table-th">
                                        Received Time
                                    </th>
                                    <th className="users-table-th">AM/PM</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="n_detail_comp">KH/22-23/3099</td>
                                    <td className="n_detail_comp">29/04/2022</td>
                                    <td className="n_detail_comp">526.5</td>
                                    <td className="n_detail_comp">FOB</td>
                                    <td className="n_detail_comp">INR</td>
                                    <td className="n_detail_comp">22/04/2024</td>
                                    <td className="n_detail_comp">06:00</td>
                                    <td className="n_detail_comp">PM</td>
                                    <td className="main_table_last">
                                        <div className="main_icon_click">
                                            <a href="#main_id_sec">
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    src="/img/delete-outline.png"
                                                    alt="delete"
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border_divider mt-2 mb-2" />
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>SB Details</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input_box_section">
                        <label>SB No</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter Here"
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                        <label>SB Date</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Aug 20, 2022"
                        />
                        <span>
                            <img src="/img/Calendar.png" alt="Calendar" />
                        </span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                        <label>Receiving Date</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Aug 20, 2022"
                        />
                        <span>
                            <img src="/img/Calendar.png" alt="Calendar" />
                        </span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="alfa_listing-kg">
                        <div className="input_box_section ">
                            <label>Receiving Time</label>
                        </div>
                        <ul>
                            <li className="padd_left_inherit">
                                <div className="input_box_section ">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Here"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="input_box_section w-swction_main">
                                    <select name="#" id="#">
                                        <option value="#">AM</option>
                                        <option value="#">PM</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="button-shipment-registration d-flex mt-2">
                    <div className="btn">

                        <img src="/img/plus_new.png" />
                        Add

                    </div>
                    <div className="btn">

                        <img src="/img/reload.png" />
                        Clear

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Shipment Bills</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table_detail_tariff table-responsive shipment_registration_table">
                        <table className="posts-table geeks">
                            <thead>
                                <tr className="n_detail_comp">
                                    <th className="users-table-th">SB No.</th>
                                    <th className="users-table-th">SB Date</th>
                                    <th className="users-table-th">
                                        Received Date
                                    </th>
                                    <th className="users-table-th">
                                        Received Time
                                    </th>
                                    <th className="users-table-th">AM/PM</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="n_detail_comp">KH/22-23/3099</td>
                                    <td className="n_detail_comp">29/04/2022</td>
                                    <td className="n_detail_comp">22/04/2024</td>
                                    <td className="n_detail_comp">06:00</td>
                                    <td className="n_detail_comp">PM</td>
                                    <td className="main_table_last">
                                        <div className="main_icon_click">
                                            <a href="#main_id_sec">
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    src="/img/delete-outline.png"
                                                    alt="delete"
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border_divider mt-2 mb-2" />
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Document Details</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="input_box_section">
                        <label>Document No</label>
                        <input
                            type="text"
                            className="form-control color_place"
                            placeholder="Enter Here"
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                        <label>Document Date</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Aug 20, 2022"
                        />
                        <span>
                            <img src="/img/Calendar.png" alt="Calendar" />
                        </span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input_box_section por_main_detail">
                        <label>Receiving Date</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Aug 20, 2022"
                        />
                        <span>
                            <img src="/img/Calendar.png" alt="Calendar" />
                        </span>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="alfa_listing-kg">
                        <div className="input_box_section ">
                            <label>Receiving Time</label>
                        </div>
                        <ul>
                            <li className="padd_left_inherit">
                                <div className="input_box_section ">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Here"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="input_box_section w-swction_main">
                                    <select name="#" id="#">
                                        <option value="#">AM</option>
                                        <option value="#">PM</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="button-shipment-registration d-flex mt-2">
                    <div className="btn">
                      
                            <img src="/img/plus_new.png" />
                            Add
                      
                    </div>
                    <div className="btn">
                     
                            <img src="/img/reload.png" />
                            Clear
                       
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-nav-det">
                        <h4>Documents</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table_detail_tariff table-responsive shipment_registration_table">
                        <table className="posts-table geeks">
                            <thead>
                                <tr className="n_detail_comp">
                                    <th className="users-table-th">SB No.</th>
                                    <th className="users-table-th">SB Date</th>
                                    <th className="users-table-th">
                                        Received Date
                                    </th>
                                    <th className="users-table-th">
                                        Received Time
                                    </th>
                                    <th className="users-table-th">AM/PM</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="n_detail_comp">KH/22-23/3099</td>
                                    <td className="n_detail_comp">29/04/2022</td>
                                    <td className="n_detail_comp">22/04/2024</td>
                                    <td className="n_detail_comp">06:00</td>
                                    <td className="n_detail_comp">PM</td>
                                    <td className="main_table_last">
                                        <div className="main_icon_click">
                                            <a href="#main_id_sec">
                                                <img src="/img/edit-line.png" alt="edit" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    src="/img/delete-outline.png"
                                                    alt="delete"
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReceivingDocumentTab