import React from 'react'

const CompanyDetails = () => {
    return (
        <div
            className="main_detail_form section1"
            id="section1"

        >
            <form action="#">
                <div className="main-nav-det">
                    <h4>Company Details</h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                                Name of the Company / Partnership Firm / Agency / Proprietorship
                            </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Year of establishment (DD/MM/YYYY) </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>CIN No. </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>GST Regn. No. </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>TAN No. (if not please mention N/A) </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>PAN No. </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>
                                Name of the Directors / Partners / Agents / Proprietor etc.
                            </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Permanent or Registered address </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Principal Business address </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Type of Business Activity </label>
                            <select name="#" id="#">
                                <option value="#">Select Business Activity</option>
                                <option value="#">Individual/Proprietary firm</option>
                                <option value="#">Private or Public Limited Company</option>
                                <option value="#">
                                    Partnership firm Any three of the stated documents
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Brief Description of Business of your entity </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Website of the business entity </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="input_box_section ">
                            <label>Director/ Proprietor </label>
                            <div className="row">
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter name here"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter contact no here"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter email here"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="input_box_section ">
                            <label> Sales </label>
                            <div className="row">

                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Name here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Contact here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Email here"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="input_box_section ">
                            <label> Accounts </label>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Name here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Email here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Contact here"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="input_box_section ">
                            <label> Finance Controller </label>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter name here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Email here"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Contact here"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="input_box_section ">
                            <label>MSME Industry Type </label>
                            <div className="row">
                                <div className="col-6">

                                    <select name="#" id="#">
                                        <option value="#">Select Industry Type</option>
                                        <option value="#">Micro</option>
                                        <option value="#">Small</option>
                                        <option value="#">Medium</option>
                                    </select>
                                </div>
                                <div className="col-6">

                                    <input
                                        type="text"
                                        className="form-control color_place"
                                        placeholder="Enter Registration No. here"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Previous Agent’s Address </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Reason for leaving them </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section ">
                            <label>Currently Working With </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="input_box_section file-input-main">
                            <label>Licenses and certifications</label>
                            <input
                              type="file"
                              name="userfile"
                              className="form-control"
                              placeholder="Upload Here"
                            />
                            <button className="folder_main_update">
              {" "}
              Upload Here{" "}
              <span>
                <img src="/img/upload.png" alt="file" />
              </span>
            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default CompanyDetails