import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { adminMainUrl } from '../../../Config';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Api from '../../../Api';
import Skeleton from 'react-loading-skeleton'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Select from "react-select";

const QueryList = () => {
  const headers = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'PICKED BY',
    'actions',
  ];
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const otherHeader = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'REQUIRED SERVICE',
    'PICKED BY',
    'actions',
  ];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [full_name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentVia, setShipmentVia] = useState('');
  const [pickedBY, setPickedBy] = useState('');
  const [queryList, setQueryList] = useState([]);
  const [isResetting, setIsResetting] = useState(false);
  const fromDate = startDate ? formatDate(startDate) : null;
  const toDate = endDate ? formatDate(endDate) : null;
  const [count, setCount] = useState(1);
  const [shipmentTypeData, setShipmentTypeData] = useState([]);
  const [shipmentViaData, setShipmentViaData] = useState([]);
  const [RequiredServiceId, setRequiredServiceId] = useState([]);
  const [requiredServiceData, setRequiredServiceData] = useState('')
  const [filterTab, setFilterTab] = useState("2")
  const totalPages = queryList?.pagination?.totalPages || 1;
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCount(pageNumber);
    }
  };
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (count <= 3) {
        pageNumbers.push(1, 2, 3, 4, '...', totalPages);
      } else if (count > 3 && count < totalPages - 2) {
        pageNumbers.push(1, '...', count - 1, count, count + 1, '...', totalPages);
      } else {
        pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      }
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();
  const fetchQuotations = async (page, size) => {
    setLoading(true)
    try {
      const token = Cookies.get("token");
      const requestBody = {
        search_by: searchText,
        request_no: status_id,
        name: full_name,
        email: email,
        phone: mobileNumber,
        origin: origin,
        destination: destination,
        shipment_type_id: shipmentType,
        shipment_via_id: shipmentVia,
        // required_servie_id:,
        required_servie_id: requiredServiceData,
        start_date: fromDate,
        end_date: toDate,
        filter_by: "2"
        // quotation_date:,
        // page: currentPage,
        // pageSize: 10,

      };

      const response = await fetch(`${adminMainUrl}/api/quotation/list?page=${count}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setLoading(false);
      const data = await response.json();
      setQueryList(data)
    } catch (error) {
      setLoading(false);
      alert(error)
    } finally {
      setLoading(false);
    }
  };
  const arr = new Array(5).fill(null);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    fetchQuotations(filterTab);
  }, [filterTab, count]);

  useEffect(() => {
    if (isResetting) {
      fetchQuotations();
      setIsResetting(false);  // Reset the flag after fetching quotations
    }

  }, [isResetting]);
  const statusClassMap = {
    "Not Picked": "color_1",
    "In View": "color_2",
    "Price Received": "color_3",
    "Quotation Sent": "color_5",
    "Client Review": "color_6",
    "Converted": "color_7",
    "Not Converted": "color_8",
    "Picked": "color_4",
    "Price Viewed": "color_1",
  };
  const resetFields = () => {
    setSearchText('');
    setStatus_id('');
    setName('');
    setEmail('');
    setMobileNumber('');
    setOrigin('');
    setDestination('');
    setShipmentType("");
    setShipmentVia('');
    setPickedBy('');
    setRequiredServiceData('');
    // setRequiredServiceId([]);
    // setShipmentViaData([]);
    // setShipmentTypeData([]);
    setStartDate(null);
    setEndDate(null);
    setIsResetting(true);
  };
  const handleStartDateChange = (date) => {
    if (date && date.getTime() === endDate?.getTime()) {
      alert("Start date and end date cannot be the same");
      return;
    }
    setStartDate(date);
    if (endDate && date && endDate.getTime() < date.getTime()) {
      setEndDate(null);
    }
  };
  const handleEndDateChange = (date) => {
    if (date && date.getTime() === startDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    if (date && startDate && date.getTime() < startDate.getTime()) {
      Swal.fire('Error', 'End date cannot be before the start date', 'error');
      return;
    }
    setEndDate(date);
  };

  const filteredQueryList = queryList?.data?.filter(item =>
    item.last_status_id === 2 || item.last_status_id === 3
  );

  const tableRef = useRef(null);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    const slider = tableRef.current;
    setIsGrabbing(true);
    slider.classList.add('grabbing'); // Apply grabbing CSS class
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseUp = () => {
    const slider = tableRef.current;
    setIsGrabbing(false);
    slider.classList.remove('grabbing'); // Remove grabbing CSS class
  };

  const handleMouseMove = (e) => {
    if (!isGrabbing) return;
    e.preventDefault();
    const slider = tableRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2; // Speed of scroll
    slider.scrollLeft = scrollLeft - walk;
  };
  // const PickedQuotation = async (quotationId) => {
  //   try {
  //     const token = Cookies.get("token");
  //     const requesId = {
  //       quotation_id: `${quotationId}`,
  //     };
  //     const response = await fetch(`${adminMainUrl}/api/quotation/to_be_picked`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": token,
  //       },
  //       body: JSON.stringify(requesId),
  //     });
  //     const data = await response.json();
  //     if (!response.ok) {
  //       toast.error(data?.msg);
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     toast.success(data?.msg);
  //   } catch (error) {
  //     toast.error(error);
  //     console.log(error);
  //   }
  // };

  const GetShipmentVia = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-via/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentViaId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentViaData(options);
    } catch (error) {
      setShipmentViaData([]);

      console.error("Error fetching data:", error);
    }
  };
  const RequiredService = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/required-service/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          RequiredServiceId: item.id,
          value: item.name,
          label: item.name,
        }));
      setRequiredServiceId(options);
    } catch (error) {
      setRequiredServiceId([]);

      console.error("Error fetching data:", error);
    }
  };
  const GetShipmentType = async () => {
    try {
      const data = await Api(`${adminMainUrl}/api/shipment-type/get`, "GET");
      const options = data?.data
        ?.filter((item) => item.status === true).map((item) => ({
          shipmentTypeId: item.id,
          value: item.name,
          label: item.name,
        }));
      setShipmentTypeData(options);
    } catch (error) {
      setShipmentTypeData([]);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetShipmentType();
    GetShipmentVia();
    RequiredService()
  }, []);
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>Picked Leads</h2>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="button-det-list">
                  <Link to="/admin-dashboard/quote-request-form">
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Manual Form
                  </Link>
                </div>
              </div> */}
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                        value={status_id}
                                        onChange={(e) => setStatus_id(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">DATE </label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={full_name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                        value={origin}
                                        onChange={(e) => setOrigin(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                        value={destination}
                                        onChange={(e) => setDestination(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={shipmentTypeData}
                                        onChange={(selectedOption) => setShipmentType(selectedOption.shipmentTypeId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                        value={shipmentType}
                                        onChange={(e) => setShipmentType(e.target.value)}
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={shipmentViaData}
                                        onChange={(selectedOption) => setShipmentVia(selectedOption.shipmentViaId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                        value={shipmentVia}
                                        onChange={(e) => setShipmentVia(e.target.value)}
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <Select
                                        options={RequiredServiceId}
                                        onChange={(selectedOption) => setRequiredServiceData(selectedOption.RequiredServiceId)}
                                      />
                                      {/* {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                      /> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                        value={pickedBY}
                                        onChange={(e) => setPickedBy(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="apply-btn cursor-pointer" onClick={() => {
                                fetchQuotations();
                                const dropdownElement = document.getElementById('dropdownMenuLink');
                                const bootstrapDropdown = new window.bootstrap.Dropdown(dropdownElement);
                                bootstrapDropdown.hide();
                              }}>
                                Apply
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="From"
                              selected={startDate}
                              onChange={handleStartDateChange}
                              isClearable
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              dateFormat="dd/MM/yy"
                              placeholderText="To"
                              selected={endDate}
                              onChange={handleEndDateChange}
                              isClearable
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center">
                    <div className='search-btn text-white w-fit ' onClick={() => { fetchQuotations() }} style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}>Search</div>
                    <div
                      className='search-btn text-white w-fit'
                      style={{ background: "#04aed2", marginTop: "7px", marginBottom: "15px" }}
                      onClick={() => { resetFields() }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}

            <div className="container mt-4">

              <div className="scroll_listing_detail">
                <div className="users-table-admin border-redi table-responsive">

                  <div className="table-wrapper" ref={tableRef}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onMouseMove={handleMouseMove}>
                    <table className="posts-table table-responsive ">
                      <thead>
                        <tr className="users-table-admin-th">
                          {headers.map((header) => (
                            <th key={header}>{capitalizeText(header)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loading ?
                            arr.map((value, index) => {
                              return (

                                <tr key={index}>
                                  <td
                                    colSpan="12"
                                    style={{
                                      height: "70px",
                                      padding: "0px 27px",
                                      width: "100%",
                                      textAlign: "center"
                                    }}
                                  >
                                    <Skeleton count={2} />
                                  </td>

                                </tr>
                              )
                            })
                            :
                            queryList?.data?.length > 0 ?
                              (
                                queryList?.data?.map((value, index) => {
                                  return (
                                    <tr key={index} className='users-table-admin-td'>
                                      <td className=" text-center">{value?.request_no}</td>
                                      <td className="">
                                        {formatDate(value?.createdAt)}
                                      </td>
                                      <td className="">{value?.full_name}</td>
                                      <td className="">{value?.email}</td>
                                      <td className="">{value?.phone}</td>
                                      <td className="">{value?.origin}</td>
                                      <td className="">{value?.destination}</td>
                                      <td className="">{value?.shipment_type?.name}</td>
                                      <td className="">{value?.shipment_via?.name}</td>
                                      <td className="">
                                        none
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content-center align-items-center py-2" onClick={() => {
                                          navigate('/admin-dashboard/query-information', { state: { quotationId: value.id } })
                                        }}>
                                          <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="view information">
                                            <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }))
                              :
                              (

                                <tr>
                                  <td colSpan="12" style={{ height: "300px", width: "100%", textAlign: "center" }}>
                                    No data available
                                  </td>
                                </tr>
                              )
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination Controls */}
                  <div className="pagination-controls my-5 d-flex justify-content-center align-items-center">
                    <button
                      className=''
                      onClick={() => handlePageChange(count - 1)}
                      disabled={count === 1}
                    >
                      <img src="/img/right.png" className='mx-2' alt="right" />
                    </button>

                    {pageNumbers.map((number, index) =>
                      number === '...' ? (
                        <span key={index} className="mx-2">...</span>
                      ) : (
                        <span
                          key={number}
                          className={`search-btn-1 py-2 px-3 mx-2 ${number === count ? 'active' : ''}`}
                          onClick={() => handlePageChange(number)}
                          style={{ cursor: 'pointer' }}
                        >
                          {number}
                        </span>
                      )
                    )}

                    <button
                      onClick={() => handlePageChange(count + 1)}
                      disabled={count === totalPages}
                    >
                      <img src="/img/left.png" className='mx-2' alt="right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QueryList