import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';

const QuoteRequestForm = () => {
  const navigate = useNavigate ()
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="main-nav-det">
              <h2>Fill the Quote Request Form</h2>
            </div>
            <form>
              <div className="main_detail_form">
                <div className="main-nav-det">
                  <h4>
                    General Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Full Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        placeholder="Enter Name here"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Phone Number <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        name="mobile"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Email Id <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Origin <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Origin "
                        name="origin"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section ">
                      <label>
                        Destination <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Destination"
                        name="destination"
                      />
                      {/* {formik?.touched?.destination && formik?.errors?.destination ? (
                        <div className="error">{formik?.errors?.destination}</div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    Pickup Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Product Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="product_name"
                        className="form-control"
                        placeholder="Enter Product Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Product Type
                      </label>
                      <div className='mt-2'>

                        <Select/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Type <span>*</span>
                      </label>
                      <div className="mt-2">
                        <Select/>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Mode <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Shipment Via <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Cargo Type <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Required Service <span>*</span>
                      </label>
                      <div className="mt-2">

                        <Select/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input_box_section">
                      <label>
                        Expected Transit Date <span>*</span>
                      </label>
                      <div className="custom-datepicker" style={{ position: "relative" }}>
                        <DatePicker/>
                        <img src="/img/Calendar.png" alt="" style={{ position: "absolute", top: "20px", right: "12px" }} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="input_box_section">
                      <label>
                        Detailed Requirement <span>*</span>
                      </label>
                      <textarea
                        type="text"
                        name="detailed_requirement"
                        className="form-control"
                        placeholder="Enter Detailed Requirement"
                        rows="5"
                      />
                    </div>
                  </div>
                </div>
                <div className="main-nav-det">
                  <h4>
                    Package Dimensions/Weight
                  </h4>
                </div>

                <div>

                
                    <>
                      <div  className="row">
                        <div className="col-md-4">
                          <div className="input_box_section">
                            <label>
                              Type of PKG <span>*</span>
                            </label>
                            <div className='mt-2'>
                              <Select
                              
                              />
                            </div>
                          
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing">
                            <div className="input_box_section">
                              <label>
                                Dimensions (L x W x H per box) <span>*</span>
                              </label>

                              <ul className='mb-0 ps-0 mt-2'>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="L"
                                    
                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="X"
                                      readOnly=""
                                      disabled
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="W"

                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="X"
                                      readOnly=""
                                      disabled
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="H"
                                   
                                    />
                                  </div>
                                </li>

                              </ul>
                           
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing-kg">
                            <div className="input_box_section">
                              <label>
                                Max Weight (per box) <span>*</span>
                              </label>

                              <ul className='mb-0 mt-2'>
                                <li>
                                  <div className="input_box_section mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max weight"
                                    
                                    />
                                  </div>
                                </li>

                                <li className='w-100'>
                                  <div className="input_box_section w-swction_main mb-0 ">
                                    <Select
                                   
                                    />
                                  
                                  </div>
                                </li>

                              </ul>
                           
                            
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="alfa_listing-kg">
                            <div className="input_box_section ">
                              <label>
                                Total Gross Weight <span>*</span>
                              </label>

                              <ul className='mt-2 mb-0'>
                                <li className="padd_left_inherit">
                                  <div className="input_box_section mb-0">
                                    <input
                                      pattern="\d*"
                                    
                                      type="text"
                                      className="form-control"
                                      placeholder="Total Gross Weight"

                                    />
                                  </div>
                                </li>

                                <li className='w-100'>
                                  <div className="input_box_section w-swction_main mb-0">
                                    <Select
                                  
                                    />
                                  
                                  </div>
                                </li>

                              </ul>
                             
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input_box_section ">
                            <label>
                              Total no. of boxes <span>*</span>
                            </label>
                            <input
                              pattern="\d*"
                            
                              type="text"
                              className="form-control"
                              placeholder="Total number of boxes"
                             
                            />
                          
                          </div>
                        </div>

                      </div>
                      <div>
                        
                      </div>
                    </>
               
                  <div className="col-md-6">
                    <div className="main-nav-det ">
                      <div className='d-md-flex justify-content-between align-items-center'>

                        <button
                          type="button"
                          className='bg-none '
                        
                        >
                          <span className='me-2'>
                            <img src="/img/pla.png" alt="img" />
                          </span>
                          Add another package
                        </button>

                      </div>

                      <div className="main_package_formar">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          readOnly=""
                          placeholder="Download Package List Format"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main_section_detail">
                    {/* <div className="main-nav-det">
                      <h2>Fill the Quote Request Form</h2>
                    </div> */}
                    {/* <form onSubmit={uploadDocumentFormik.handleSubmit}> */}


                    <div className="related_to_main"> 
                      <h5>
                        {" "}
                        Related documents or Videos
                      </h5>
                    </div>
                    <div className="related_to_main">
                      <h5>Upload relevant documents and specifications to help the forwarder</h5>
                    </div>
                    <ul className="main_list_numvar">
                      <li>
                        <p>
                          Uploading cargo-related documents, such as the proforma invoice and packing list, will help forwarders return the most accurate quotes.
                        </p>
                      </li>
                      <li>
                        <p>
                          Accepted document formats: .jpg, .jpeg, .png, .pdf, .docx, .doc, .xlsx, .xls. Accepted video formats: .avi, .mp4, .wav.
                        </p>
                      </li>
                      <li>
                        <p>
                          The maximum file size accepted is 10MB per file, with a limit of 3 files. For videos, the maximum size accepted is 100MB, with a limit of 1 video.
                        </p>
                      </li>
                    </ul>
                    <div className="main_select_photo">
                      <ul>
                        <li>
                          <div className="file-input image-input-box">
                            <input
                              type="file"
                              name="images"
                              accept="image/*"
                              multiple
                              id="images"
                            
                            />
                            <button type="button" className="folder_main_bro label_1">
                              <span>
                                <img src="/img/upload.png" alt="file" />
                              </span>{" "}
                              Upload File
                            </button>
                         
                          </div>
                         
                       
                        </li>
                        <li>
                          <div className="file-input video-input-box">
                            <input
                              type="file"
                              accept="video/*"
                              name="upload_video"
                              id="upload_video"
                              // multiple
                          
                            
                            />
                            <button type="button" className="folder_main_bro label_1">
                              <span>
                                <img src="/img/video.png" alt="video" />
                              </span>{" "}
                              Upload Videos
                            </button>
                          
                          </div>
                    
                         
                        </li>
                      </ul>
                    </div>
                    <div className="main-box-det-check mt-3">
                      <label className="users-table__checkbox" id="new_text">
                        <input
                          type="checkbox"
                          className="check"
                          name="agreement"
                        
                        />
                        I have read, understood and agreed to abide by the <b className='ms-2'>Buying Request Posting Rules</b>
                      </label>
                     
                    </div>

                    {/* <button type="submit" className="btn btn-primary mt-3" style={{ background: "#04aed2" }}>Submit</button> */}
                    {/* </form> */}
                  </div>
                </div>
                <div className="row mt-4 mb-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn search-btn" style={{ background: "#04aed2" }}  onClick={() => { navigate('/employee-dashboard/query-list') }}>
                      Submit
                    </button>
                  </div>
                </div>


              </div>
            </form>

            <>



            </>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuoteRequestForm