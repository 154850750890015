import React, { useState, useEffect } from 'react'
import { Link ,useNavigate } from 'react-router-dom';
import { ChangePassword } from '../Modal/ChangePassword';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa6";
const adminMainUrl = process.env.REACT_APP_API_SERVER;
const Navbar = ({ setShowSideBar, showSideBar, userInfo, roleInfo, mode, setMode }) => {
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
    useEffect(() => {
        document.body.className = theme;
        localStorage.setItem("theme", theme); 
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };
    const navigate = useNavigate();
  const handleLogout = () => {
    // Trigger SweetAlert
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be logged out!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log out!'
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, remove the token and navigate to login
        Cookies.remove('token');
        navigate('/admin-login');
        Swal.fire(
          'Logged Out!',
          'You have been logged out successfully.',
          'success'
        );
      }
    });
  };

    return (
        <>
            <nav className={`${mode ? "main-nav--bg" : "bg-black"}`} >
                <div className="container main-nav py-0">
                    <div className='d-flex justify-content-start align-items-center gap-3'>

                        <button
                            className="sidebar-toggle transparent-btn menu-white"
                            title="Menu"
                            type="button"
                            onClick={() => { setShowSideBar(!showSideBar) }}
                        >
                            <span className="sr-only">Toggle menu</span>

                            <span className="icon menu-toggle--gray" aria-hidden="true"></span>

                        </button>
                        <div className="main-nav-start">
                            <h2 className="main-title mb-0">{roleInfo}</h2>
                        </div>
                    </div>
                    <div className="main-nav-end admin_profile_deat">
                        <div className="toggle-container">
                            <input
                                type="checkbox"
                                id="toggle-switch"
                                className="toggle-switch"
                                checked={theme === "dark"}
                                onChange={toggleTheme}
                            />
                            <label htmlFor="toggle-switch" className="toggle-label">
                                <FaSun className="sun-icon" />
                                <FaMoon className="moon-icon" />
                            </label>
                        </div>
                        {/* <button onClick={toggleTheme}>
                            Switch to {theme === "light" ? "Dark" : "Light"} Mode
                        </button> */}
                        {/* <SearchIcon style={{ fill: "#04aed2", cursor: "pointer" }} className='d-block d-sm-none' onClick={() => { setShow(!show) }} />
                        <div className="search-wrapper d-none d-sm-block">
                            <SearchIcon style={{ fill: "#04aed2" }} />
                            <input type="text" placeholder="Search ..." required="" />
                        </div> */}
                        <a href="" className="will_icon_not"><img src="/img/notification.png" alt="will" /> <span></span></a>


                        <div className="dropdown">
                            <button
                                className="nav-user-btn dropdown-btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="nav-user-img" title={userInfo && `${userInfo?.first_name} ${userInfo?.middle_name ?? ""} ${userInfo?.last_name}`}>
                                    <picture>
                                        {/* <source
                                            srcSet={`${adminMainUrl}/${userInfo.profile_image}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/img/no-profile.png";
                                            }}
                                            type="image/webp" /> */}
                                        <img
                                            src={`${adminMainUrl}/${userInfo.profile_image}`}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/img/no-profile.png";
                                            }}
                                            alt="Committed cargo user" />
                                    </picture>
                                </span>
                                <span className="sr-only-new">
                                    {userInfo && `${userInfo?.first_name}`}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-chevron-down"
                                        aria-hidden="true"
                                    >
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#" onClick={handleLogout}>
                                        <span>Log out</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item cursor-pointer" data-bs-toggle="modal" data-bs-target="#changePassword">
                                        <span >Change Password</span>
                                    </a>
                                </li>
                                <li>
                                    <Link className="dropdown-item cursor-pointer" to="/admin-dashboard/profile-page">
                                        <span >View Profile</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="search-wrapper m-3 pb-3">

                    <input type="text" placeholder="Search ..." required="" />
                </div> */}
            </nav>
            <ChangePassword id="changePassword" />
        </>
    )
}

export default Navbar