import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { mainUrl } from '../../../Config';
import Api from '../../../Api';
import Cookies from "js-cookie";
import { BeatLoader } from 'react-spinners';
import { Height } from '@mui/icons-material';

const QueryInfo = () => {
    const [information, setInformation] = useState('');
    const [spinnerLoader, setSpinnerLoader] = useState(true)
    const { status_id } = useParams();
    const navigate = useNavigate()
    const fetchQuotations = async () => {
        try {
            const token = Cookies.get("token");
            const requestBody = {
                quotations_id: information?.quotation_id
            };
            const response = await fetch(`${mainUrl}/api/v.1/sendQuoteForPricing`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
        } catch (error) {
            alert(error)
        } finally {
            // setLoading(false);
        }
    };
    const GetUserInfo = async () => {
        try {
            const data = await Api(
                `${mainUrl}/api/v.1/quotations/${status_id}`,
                "GET"
            );
            setSpinnerLoader(false)
            if (data.status === 200) {
                setInformation(data?.data);
                // const updatedInformation = data?.data;
                // await createQuotationStatus(updatedInformation);
                // await generateUserQuotes(updatedInformation);

            } else {
                console.error("API call was not successful:", data.error);
            }
        } catch (error) {
            setSpinnerLoader(false)
            console.error("Error fetching data:", error);
        }
    };
    const createQuotationStatus = async (information) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quote_status_id: 9,
                quote_ids: [status_id]
            };
            const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requesId),
            });
        } catch (error) {
            console.log(error)
        }
    }
    const generateUserQuotes = async (information) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quote_ids: [status_id]
            };
            const response = await fetch(`${mainUrl}/api/v.1/generateUserQuotes`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requesId),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (status_id) {

            // createQuotationStatus()
            generateUserQuotes()
            GetUserInfo();
        }

    }, [status_id]);
    const sendPricing = () => {
        generateUserQuotes()
        navigate('/dashboard/all-rate-request');
        createQuotationStatus();

        fetchQuotations()
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return (
        <>
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>
                                        <Link to="/dashboard/query-list" className="query_info">
                                            <img src="/img/re_darect.png" alt="Query" />
                                        </Link>
                                        Query Information
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="new_infometion_table">
                        {
                            spinnerLoader ?
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "400px", width: "100%" }}>

                                    <BeatLoader />
                                </div> :
                                <>
                                    <div className="table-responsive">
                                        <table className="posts-table">
                                            <tbody>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Request Number</p>
                                                    </td>
                                                    <td>
                                                        <p>{information?.id}</p>
                                                    </td>
                                                    <td>
                                                        {
                                                            information?.is_quote_has_rate > 0 &&
                                                            <div className="info-det-button">
                                                                <Link to={`/dashboard/view-rates/${information?.uhqpa_id}`}>View Rates</Link>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Request Date</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{formatDate(information?.createdAt)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Name</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.full_name}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Email</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.email}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Phone Number</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.mobile}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Origin</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.origin}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Destination</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.destination}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Picked by</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.query_picked_by}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Status</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <a href="#" className="view_in_color">
                                                            {information?.status_name}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Description</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>
                                                            {information?.detailed_requirement}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Expected transit time</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.expected_transit_time}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Type of Shipment Type</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.shipment_type}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Shipment Via</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>{information?.shipment_via}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Required Service</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p>Both</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Package dimension/weight</p>
                                                    </td>
                                                    <td >
                                                        <tr>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Length
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Width
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Height
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Type
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Max Weight
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Unit
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Gross Weight
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Gross Weight Unit
                                                            </td>
                                                            <td style={{ border: "1px solid #c7c7c7" }}>
                                                                Total Box
                                                            </td>
                                                        </tr>

                                                        {
                                                            information?.packageInfo?.map((value, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.dimension_l}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.dimension_w}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.dimension_h}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.TYPE}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.max_weight}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.mw_unit}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.gross_weight}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.gross_weight_unit?.name}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #c7c7c7" }}>
                                                                            {value?.total_box}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }


                                                        {/* <p>12cmX200cmX299cm 120 KG 2 Boxes</p> */}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Shipment Document or Images</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        {
                                                            information?.images?.map((value, index) => {
                                                                const fileUrl = `${mainUrl}/${value?.uploaded_file}`;
                                                                // const videoUrl = `${mainUrl}/${value?.uploaded_video}`;
                                                                return (
                                                                    <>
                                                                        {
                                                                            value?.uploaded_file &&

                                                                            <div key={index} className="col-12 input_box_section file-input-main">
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={fileUrl}
                                                                                    download
                                                                                    className="folder_main_update w-fit"
                                                                                >
                                                                                    {value?.uploaded_file}
                                                                                    <span>
                                                                                        <img src="/img/download-icon.png" alt="file" />
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        }

                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table_info_color">
                                                        <p>Shipment Document or Videos</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        {
                                                            information?.videos?.map((value, index) => {
                                                                // const fileUrl = `${mainUrl}/${value?.uploaded_video}`;
                                                                const videoUrl = `${mainUrl}/${value?.uploaded_video}`;
                                                                return (
                                                                    <>

                                                                        {
                                                                            value?.uploaded_video &&

                                                                            <a
                                                                                target="_blank"
                                                                                href={videoUrl}
                                                                                download
                                                                                className="folder_main_update w-fit"
                                                                            >
                                                                                {value?.uploaded_video}
                                                                                <span>
                                                                                    <img src="/img/download-icon.png" alt="file" />
                                                                                </span>
                                                                            </a>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="send_query_list_button send-query" onClick={sendPricing}>
                                                {/* <button>Send for Pricing</button> */}
                                                Send for Pricing
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="send_query_list_button send-query" onClick={() => { navigate('#') }}>
                                                Send Quote To Client
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="send_query_list_button send-query" onClick={() => { navigate('/dashboard/quotation-converted') }}>
                                                Quotation Converted
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="send_query_list_button send-query" onClick={() => { navigate('/dashboard/query-follow-up-form') }}>
                                                Follow Up
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default QueryInfo