import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { mainUrl } from '../../../Config';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
const AllRateRequest = () => {
  const navigate =useNavigate()
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [status_id, setStatus_id] = useState('');
  const [full_name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [shipmentType, setShipmentType] = useState('');
  const [shipmentVia, setShipmentVia] = useState('');
  const [pickedBY, setPickedBy] = useState('');
  const [rateRequestList, setRateRequestList] = useState([]);
  const fromDate = startDate ? formatDate(startDate) : null;
  const toDate = endDate ? formatDate(endDate) : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [quotationId,setQuotationId]=useState(null)
  const fetchQuotations = async () => {
    try {
      const token = Cookies.get("token");
      const requestBody = {
        page: currentPage,
        pageSize: 10,
        filterBy: {
          searchText,
          status_id,
          full_name,
          email,
          mobileNumber,
          origin,
          destination,
          shipmentType,
          shipmentVia,
          pickedBY,
          fromDate,
          toDate
        }
      };
      const response = await fetch(`${mainUrl}/api/v.1/allPricingRequestList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRateRequestList(data);
      setPageSize(data?.data?.totalCount)


    } catch (error) {
      alert(error)
    } finally {
      // setLoading(false);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const handleStartDateChange = (date) => {
    if (date && date.getTime() === endDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    setStartDate(date);

    // Reset endDate if it is before the new startDate
    if (endDate && date && endDate.getTime() < date.getTime()) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && date.getTime() === startDate?.getTime()) {
      Swal.fire('Error', 'Start date and end date cannot be the same', 'error');
      return;
    }
    if (date && startDate && date.getTime() < startDate.getTime()) {
      Swal.fire('Error', 'End date cannot be before the start date', 'error');
      return;
    }
    setEndDate(date);
  };
  useEffect(() => {
    fetchQuotations(currentPage, pageSize);
  }, [currentPage, pageSize]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
 
  // const viewPrice=(quote_tobe_approve_id)=>{
  
  // }
  return (
    <>
      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>All Rate Requests</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="button-det-list">
                  <Link to="/admin-dashboard/quote-request-form">
                    <span>
                      <img src="/img/place.png" alt="" />
                    </span>{" "}
                    Manual Form
                  </Link>
                </div>
              </div>
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row align-items-center">
                  <div className="col-xl-8">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>{" "}
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  {/* <tr>
                                    <td>
                                      <label htmlFor="#">QUOTATION NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                        value={status_id}
                                        onChange={(e) => setStatus_id(e.target.value)}
                                      />
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DATE &amp; TIME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={full_name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                        value={origin}
                                        onChange={(e) => setOrigin(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                        value={destination}
                                        onChange={(e) => setDestination(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT TYPE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Type"
                                        value={shipmentVia}
                                        onChange={(e) => setShipmentVia(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">SHIPMENT VIA</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Shipment Via"
                                        value={shipmentVia}
                                        onChange={(e) => setShipmentVia(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUIRED SERVICE</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Required Service"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PICKED BY</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Picked By"
                                        value={pickedBY}
                                        onChange={(e) => setPickedBy(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="apply-btn cursor-pointer" onClick={() => { fetchQuotations() }}>
                                Apply
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="From"
                              selected={startDate}
                              onChange={handleStartDateChange}
                              isClearable
                            />

                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">
                            <DatePicker
                              placeholderText="To"
                              selected={endDate}
                              onChange={handleEndDateChange}
                              isClearable
                            />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className='search-btn text-white' style={{ marginTop: "7px", marginBottom: "15px" }} onClick={() => { fetchQuotations() }}>search</div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}
            <div className="row">
              <div className="col-md-12">
                <div className="main_listing_button d-sm-flex justify-content-between align-items-center mb-3">
                  <ul className='mb-0'>
                    <li>
                      <a href="#" className="color_1">
                        Requested
                      </a>
                    </li>
                    <li>
                      <a href="#" className="color_2">
                        In process
                      </a>
                    </li>
                    <li>
                      <a href="#" className="color_3">
                        Sent
                      </a>
                    </li>
                  </ul>
                  <div className='text-end'>
                    <button className='search-btn text-white py-1' onClick={() => {
                      navigate('/admin-dashboard/selected-rate-list');
                     
                    }}>
                      Selected Rate List
                    </button>
                  </div>
                </div>
                <div className="scroll_listing_detail">
                  <div className="users-table users-table-new ">
                    <table className="posts-table">
                      <thead>
                        <tr className="users-table-info">
                          <th>REQUEST NO.</th>
                          <th>DATE &amp; TIME</th>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          <th>PHONE NUMBER</th>
                          <th>ORIGIN</th>
                          <th>DESTINATION</th>
                          <th>SHIPMENT TYPE</th>
                          <th>SHIPMENT VIA</th>
                          <th>REQUIRED SERVICE</th>
                          <th>PICKED BY</th>
                          <th>VIEW INFORMATION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          rateRequestList?.data?.priceRequests?.length > 0 ?
                            rateRequestList?.data?.priceRequests?.map((value, index) => {
                              return (
                                <tr>
                                  <td className="color_1 text-center py-3">{value?.quotation_id}</td>
                                  <td className="color_1">
                                    {formatDate(value?.createdAt)}
                                  </td>
                                  <td className="color_1">{value?.full_name}</td>
                                  <td className="color_1">{value?.email}</td>
                                  <td className='color_1'>{value?.mobile}</td>
                                  <td className="color_1">{value?.origin}</td>
                                  <td className="color_1">{value?.destination}</td>
                                  <td className="color_1">{value?.shipment_type}</td>
                                  <td className="color_1">{value?.shipment_via}</td>
                                  <td className="color_1">Both</td>
                                  <td className="color_1">
                                    {value?.status_name}
                                  </td>
                                  <td>
                                    <div
                                  onClick={()=>{
                                  
                                    navigate(`/admin-dashboard/query-info-give-rates?quote_tobe_approve_id=${value?.quote_tobe_approve_id}&quotation_id=${value?.quotation_id}`)
                                   
                                  }}
                                   
                                      className="view_info_button"
                                    >
                                      View Information
                                    </div>
                                  </td>
                                </tr>

                              )
                            }) :
                            <tr >
                              <td
                                colSpan="12"
                                style={{
                                  height: "300px",
                                  width: "100%",
                                  textAlign: "center"
                                }}
                              >
                                No data available
                              </td>

                            </tr>
                        }

                      </tbody>
                    </table>
                    <div className="main_list_next_page">
                      {/* Pagination Controls */}
                      <div className="pagination-controls my-5 d-flex justify-content-center align-items-center" >
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <img src="/img/right.png" className='mx-2' alt="right" />
                        </button>
                        <span className='search-btn py-2 px-3 mx-3'> {currentPage}</span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={rateRequestList?.data?.priceRequests?.length < 10}>
                          <img src="/img/left.png" className='mx-2' alt="right" />
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AllRateRequest