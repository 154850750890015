import React from 'react'

const Port = () => {
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Port Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Origin Port</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Destination Port</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Discharge Port</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Clearance At</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Destuffing At</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Port