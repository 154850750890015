import React,{useEffect, useState} from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { mainUrl } from '../../../Config';
import Api from '../../../Api';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

const QueryInfoGiveRate = () => {
    const [information,setInformation]=useState('')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const quoteTobeApproveId = queryParams.get('quote_tobe_approve_id');
    const quotationId = queryParams.get('quotation_id');
    const createQuotationStatus = async (information) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quote_status_id: 9,
                quote_ids: [quotationId]
            };
            const response = await fetch(`${mainUrl}/api/v.1/createQuotationStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requesId),
            });
            generateUserQuotes();
          
        } catch (error) {
            console.log(error)
        }
    }
    const generateUserQuotes = async (information) => {
        try {
            const token = Cookies.get("token");
            const requesId = {
                quote_tobe_approve_id: quoteTobeApproveId
            };
            const response = await fetch(`${mainUrl}/api/v.1/userSelectQuoteForPriceApproval`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requesId),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
              GetUsers();
            const data = await response.json();
        } catch (error) {
            console.log(error)
        }
    }
    const GetUsers = async () => {
        try {
            const data = await Api(
                `${mainUrl}/api/v.1/price/${quotationId}`,
                "GET"
            );

            if (data.status === 200) {
                setInformation(data?.data);
            } else {

                console.error("API call was not successful:", data.error);
            }
        } catch (error) {

            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        createQuotationStatus()
      
    }, []);
    return (
        <>
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>
                                        <Link to="/admin-dashboard/query-list" className="query_info">
                                            <img src="/img/re_darect.png" alt="Query" />
                                        </Link>
                                        Query Information
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="query_bg_info_giver">
                        <div className="new_infometion_table">
                            <table className="posts-table">
                                <tbody>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Request Number</p>
                                        </td>
                                        <td>
                                            <p>{information?.status_id}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Request Date</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.createdAt}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Name</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.full_name}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Email</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.email}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Phone Number</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.mobile}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Origin</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.origin}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Destination</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.destination}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Picked by</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.price_picked_by}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Status</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>In View Mode</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Description</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>
                                               {information?.detailed_requirement}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Expected transit time</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.expected_transit_time}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Type of Shipment Type</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.shipment_type}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Shipment Via</p>
                                        </td>
                                        <td colSpan={2}>
                                            <p>{information?.shipment_via}</p>
                                        </td>
                                    </tr>
                                   
                                      
                                        <tr>
                                        <td className="table_info_color">
                                            <p>Package dimension/weight</p>
                                        </td>
                                        <td >
                                            <tr>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                                    Length
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                                    Width
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                                    Height
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                                    Type
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                                Max Weight
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                             Unit
                                                </td>
                                                <td style={{border:"1px solid #c7c7c7"}}>
                                             Total Box
                                                </td>
                                            </tr>

                                            {
                                                information?.packageInfo?.map((value, index) => {
                                                    return (
                                                        <tr>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                               {value?.dimension_l}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.dimension_w}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.dimension_h}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.TYPE}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.max_weight}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.mw_unit}
                                                            </td>
                                                            <td style={{border:"1px solid #c7c7c7"}}>
                                                            {value?.total_box}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }


                                            {/* <p>12cmX200cmX299cm 120 KG 2 Boxes</p> */}
                                        </td>
                                    </tr>
                                
                                    <tr>
                                        <td className="table_info_color">
                                            <p>Shipment Document or Video</p>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="col-6 input_box_section file-input-main">
                                                <button className="folder_main_update">
                                                    {" "}
                                                    Shipment docs.pdf{" "}
                                                    <span>
                                                        <img src="/img/download-icon.png" alt="file" />
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-nav-det-button text-right mar_10">
                                        {/* <Link to={`/dashboard/quotation-air-export/${information?.uhqpa_id}`}>Give Rates</Link> */}
                                        <Link to={`/admin-dashboard/quotation-air-export?uhqpa_id=${information?.uhqpa_id}&quotation_id=${information?.quotation_id}`}>Give Rates</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default QueryInfoGiveRate