import React, { useState,useEffect } from 'react'
import {  Route, Routes ,Navigate } from 'react-router-dom';
import CustomerRegistration from '../CustomerRegistration/CustomerRegistration';
import SideBar from './SideBar/SideBar';
import Navbar from './Navbar/Navbar';
import RegistrationAgreeMent from '../RegistrationAgreement/RegistrationAgreeMent';
import MyQuotationList from './MyQuotationList';
import QuotationInfo from './QuotationInfo';
import QuoteRequestForm from './QuoteRequestForm';

const CustomerDashboard = () => {
  const [showSideBar,setShowSideBar]=useState(false)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1199) {
        setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    };
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
  <div className="page-flex">
  {/* ! Sidebar */}
  <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
  <div className="main-wrapper">
    {/* ! Main nav */}
  <Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar}/>
    {/* ! Main */}
    <main className="main users chart-page" id="skip-target">
        <Routes>
    <Route index element={<Navigate to="customer-registration" />} />
    {/* <Route path='Kyc-approval-request' element={<KycApprovalRequest/>} /> */}
    {/* <Route path='customer-registration-form' element={<CustomerRegistration/>} /> */}
    {/* <Route path='customer-registration-agreement' element={<RegistrationAgreement/>} /> */}
    <Route path='customer-registration' element={<CustomerRegistration/>} />
    <Route path='registration-agreement' element={<RegistrationAgreeMent/>} />
    <Route path='my-quotation' element={<MyQuotationList/>} />
    <Route path='quotation-info' element={<QuotationInfo/>} />
    <Route path='quotation-form' element={<QuoteRequestForm/>} />
    </Routes>

    </main>
  </div>
</div>
    </div>
  )
}

export default CustomerDashboard