import { useEffect } from 'react';
import { Tab } from 'bootstrap';
import SearchTab from './SearchTab';
import ReceivingDocumentTab from './ReceivingDocumentTab';
import ShipmentStatus from './ShipmentStatus';
import HandoverAirline from './HandoverAirline';
import ShipmentMapping from './ShipmentMapping';
import ActivityDetailsTab from './ActivityDetailsTab';
import ProvisionalSheetTab from './ProvisionalSheetTab';
import DueDates from './DueDates';
function ShipmentRegistration() {
    useEffect(() => {
        const elements = document.querySelectorAll('.shipment-check-img');

        elements.forEach((element) => {
            element.addEventListener('click', () => {
                const receivingDocumentsTab = new Tab(document.getElementById('receiving-documents-tab'));
                receivingDocumentsTab.show();
            });
        });

        // Clean up event listeners when the component unmounts
        return () => {
            elements.forEach((element) => {
                element.removeEventListener('click', () => {
                    const receivingDocumentsTab = new Tab(document.getElementById('receiving-documents-tab'));
                    receivingDocumentsTab.show();
                });
            });
        };
    }, []);
    return (
        <main className="main users chart-page" id="skip-target">
            <div className="container">
                <div className="col-md-12">
                    <div className="main_section_detail">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="main-nav-det">
                                    <h2>Shipment Registration</h2>
                                </div>
                            </div>
                        </div>
                        {/* =============Search bar============= */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll_listing_detail">
                                    <div className="Air_Export_bg_main freight_forwarding">
                                        {/* Tab Section */}
                                        <div className="nav-tabs-container">
                                            <ul
                                                className="nav nav-pills mb-3"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold active position-relative"
                                                        id="search-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#search"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="search"
                                                        aria-selected="true"
                                                    >
                                                        Search
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="receiving-documents-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#receiving-documents"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="receiving-documents"
                                                        aria-selected="false"
                                                    >
                                                        Receiving Documents
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="shipment-status-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#shipment-status"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="shipment-status"
                                                        aria-selected="false"
                                                    >
                                                        Shipment Status
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="handover-airline-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#handover-airline"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="handover-airline"
                                                        aria-selected="false"
                                                    >
                                                        Handover to Airline
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="shipment-mapping-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#shipment-mapping"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="shipment-mapping"
                                                        aria-selected="false"
                                                    >
                                                        Shipment Mapping
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="activity-details-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#activity-details"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="activity-details"
                                                        aria-selected="false"
                                                    >
                                                        Activity Details
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="provisional-sheet-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#provisional-sheet"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="provisional-sheet"
                                                        aria-selected="false"
                                                    >
                                                        Provisional Sheet
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link text-primary fw-semibold position-relative"
                                                        id="due-dates-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#due-dates"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="due-dates"
                                                        aria-selected="false"
                                                    >
                                                        Due Dates
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="pills-tabContent">
                                            {/* ===============Search Tab================= */}
                                            <SearchTab />
                                            {/* ===============Search Tab================= */}
                                            {/* ===============Receiving Documents================= */}
                                            <ReceivingDocumentTab />
                                            {/* ===============Receiving Documents================= */}
                                            {/* ===============Shipment Status================= */}
                                            <ShipmentStatus />
                                            {/* ===============Shipment Status================= */}
                                            {/* ===============Handover Airline================= */}
                                            <HandoverAirline />
                                            {/* ===============Handover Airline================= */}
                                            {/* ===============Shipment Mapping================= */}
                                            <ShipmentMapping />
                                            {/* ===============Shipment Mapping================= */}
                                            {/* ===============Activity Details================= */}
                                            <ActivityDetailsTab />
                                            {/* ===============Activity Details================= */}
                                            {/* ===============Provisional Sheet================= */}
                                            <ProvisionalSheetTab />
                                            {/* ===============Provisional Sheet================= */}
                                            {/* ===============Due Dates================= */}
                                            <DueDates />
                                            {/* ===============Due Dates================= */}
                                        </div>
                                        {/* Tab Section */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}

export default ShipmentRegistration