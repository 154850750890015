import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const KycApprovalRequest = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  function capitalizeText(text) {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const headers = [
    'QUOTATION NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'CONVERTED BY',
    'QUOTATION SUMMARY',
    'CLIENT ONBOARDING DETAILS',
  ];
  const otherHeader = [
    'REQUEST NO.',
    'Date',
    'NAME',
    'EMAIL',
    'PHONE NUMBER',
    'ORIGIN',
    'DESTINATION',
    'SHIPMENT TYPE',
    'SHIPMENT VIA',
    'PICKED BY',
    'VIEW Summary',
  ];
  return (
    <>

      <div className="container">
        <div className="col-md-12">
          <div className="main_section_detail">
            <div className="row">
              <div className="col-md-6">
                <div className="main-nav-det">
                  <h2>KYC Documents Approval Request</h2>
                </div>
              </div>
            </div>
            {/* =============Search bar============= */}
            <div className="main_detail_filter">
              <form action="#">
                {/* <h3 class="stat-cards-info__title">Clear filters</h3> */}
                <div className="row ">
                  <div className="col-lg-8">
                    <div className="search_main_filter">
                      <div className="row main_row">
                        <div className="col-md-4">
                          <div className="input_box_filet filter_icon">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span>
                              <img src="/img/search.png" alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2 ">
                          <div className="dropdown dropdown-filter-section">
                            <a
                              className="dropdown-toggle input_box_id_button"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>
                                <img src="/img/bi_filter.png" alt="" />
                              </span>{" "}
                              Filter
                            </a>
                            <div
                              className="dropdown-menu main_drop_filter"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <table className="content-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">REQUEST NO.</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Request No."
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DATE &amp; TIME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Date"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">NAME</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">EMAIL</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">PHONE NUMBER</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Number"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">ORIGIN</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Origin"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">DESTINATION</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Destination"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label htmlFor="#">Converted By</label>
                                    </td>
                                    <td className="input_box_filter">
                                      {" "}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Converted By"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <a href="#">Apply</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          {/* <div className="input_box_section por_main_detail-date">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="From Date"
                            />
                            </div> */}
                          <div className="input_box_section por_main_detail-date">

                            <DatePicker placeholderText='toDate' selected={startDate} onChange={(date) => setStartDate(date)} />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="input_box_section por_main_detail-date">

                            <DatePicker placeholderText='toDate' selected={startDate} onChange={(date) => setStartDate(date)} />
                            <span>
                              <img src="/img/date_icon.png" alt="Calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =============Search bar============= */}

            <div className="container mt-4">
              <ul className="nav nav-pills nav-slider mb-3 border-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold active position-relative" id="pills-rebid-tab" data-bs-toggle="pill" data-bs-target="#pills-rebid" type="button" role="tab" aria-controls="pills-rebid" aria-selected="true">Approved</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-process" data-bs-toggle="pill" data-bs-target="#pills-process" type="button" role="tab" aria-controls="pills-process" aria-selected="false">Disapproved</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-pending" data-bs-toggle="pill" data-bs-target="#pills-pending" type="button" role="tab" aria-controls="pills-pending" aria-selected="false">Pending</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold position-relative" id="pills-send-send" data-bs-toggle="pill" data-bs-target="#pills-send" type="button" role="tab" aria-controls="pills-send" aria-selected="false">Send Document Request</button>
                </li>
              </ul>
              <div className="tab-content rounded-3 text-danger" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-rebid" role="tabpanel" aria-labelledby="pills-rebid-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {headers.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className=" ">#1234</td>
                            <td className=" ">
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td className=" ">Dummy Name</td>
                            <td className=" ">ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td className=" ">Delhi</td>
                            <td className=" ">London</td>
                            <td className=" ">Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td  >#1234</td>
                            <td  >
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td  >Dummy Name</td>
                            <td  >ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td  >Delhi</td>
                            <td  >London</td>
                            <td  >Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"

                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className=" ">#1234</td>
                            <td className=" ">
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td className=" ">Dummy Name</td>
                            <td className=" ">ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td className=" ">Delhi</td>
                            <td className=" ">London</td>
                            <td className=" ">Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td   >#1234</td>
                            <td   >
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td   >Dummy Name</td>
                            <td   >ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td   >Delhi</td>
                            <td   >London</td>
                            <td   >Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className=" ">#1234</td>
                            <td className=" ">
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td className=" ">Dummy Name</td>
                            <td className=" ">ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td className=" ">Delhi</td>
                            <td className=" ">London</td>
                            <td className=" ">Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td  >#1234</td>
                            <td  >
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td  >Dummy Name</td>
                            <td  >ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td  >Delhi</td>
                            <td  >London</td>
                            <td  >Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td    >#1234</td>
                            <td    >
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td    >Dummy Name</td>
                            <td    >ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td    >Delhi</td>
                            <td    >London</td>
                            <td    >Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td   >#1234</td>
                            <td   >
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td   >Dummy Name</td>
                            <td   >ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td   >Delhi</td>
                            <td   >London</td>
                            <td   >Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className=" ">#1234</td>
                            <td className=" ">
                              12 May 2024 <br />
                              09:12 pm
                            </td>
                            <td className=" ">Dummy Name</td>
                            <td className=" ">ramesh@gmail.com</td>
                            <td>+91 1232423443</td>
                            <td className=" ">Delhi</td>
                            <td className=" ">London</td>
                            <td className=" ">Ravi Sharma</td>
                            <td>
                              <a href="#" className="d-flex justify-content-center align-items-center py-2">
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Summary">
                                  <img src="/img/viewSummary.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </a>
                            </td>
                            <td>
                              <Link
                                to="/Kyc_dashboard/customer-registration-form"
                                className="d-flex justify-content-center align-items-center py-2"
                              >
                                <div type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Client Onboarding Details">
                                  <img src="/img/Vector.png" alt="view" className='pointer w-fit border-radius-none' />
                                </div>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="main_list_next_page">
                        <ul>
                          <li>
                            {" "}
                            <a href="#">
                              <img src="/img/right.png" alt="right" />
                            </a>{" "}
                          </li>
                          <li className="main_list_next_list active">
                            <a href="#">1</a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">2</a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">3</a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">4</a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">5</a>
                          </li>
                          <li className="main_list_next_list img_icon_sec">
                            <a href="#">
                              <img src="/img/Menu-icon.png" alt="manu" />
                            </a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">10</a>
                          </li>
                          <li className="main_list_next_list">
                            <a href="#">11</a>
                          </li>
                          <li>
                            <a href="#">
                              <img src="/img/left.png" alt="left" />
                            </a>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-process-tab">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {otherHeader.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td
                              colSpan="12"
                              style={{
                                height: "300px",
                                width: "100%",
                                textAlign: "center"
                              }}
                            >
                              No data available
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-pending" role="tabpanel" aria-labelledby="pills-send-pending">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {otherHeader.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td
                              colSpan="12"
                              style={{
                                height: "300px",
                                width: "100%",
                                textAlign: "center"
                              }}
                            >
                              No data available
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-send" role="tabpanel" aria-labelledby="pills-send-send">
                  <div className="scroll_listing_detail">
                    <div className="users-table ">
                      <table className="posts-table">
                        <thead>
                          <tr className="users-table-info">
                            {otherHeader.map((header) => (
                              <th key={header}>{capitalizeText(header)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td
                              colSpan="12"
                              style={{
                                height: "300px",
                                width: "100%",
                                textAlign: "center"
                              }}
                            >
                              No data available
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default KycApprovalRequest