import React,{useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Shipment = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <form action="#">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-nav-det">
                            <h4>Shipment Details</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>MAWB No. </label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>MAWB Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>HAWB No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>HAWB Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>PO NO.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>GRN No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>Airline</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>DO No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section ">
                            <label>LR No.</label>
                            <input
                                type="text"
                                className="form-control color_place"
                                placeholder="Enter here"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input_box_section por_main_detail">
                            <label>LR No. Date</label>
                            <div className="custom-datepicker">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <span>
                                    <img src="/img/Calendar.png" alt="Calendar" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Shipment