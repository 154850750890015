import './Responsive.css';
import './App.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Sales_CRM/Auth/Login/Login';
import AdminLogin from './Admin/Auth/Login/Login';
import ForgetEmail from './Admin/Auth/Login/forgetEmail';
import ForgetOtp from './Admin/Auth/Login/forgetOtp';
import ForgetChange from './Admin/Auth/Login/forgetChange';
import AdminDashboard from './Admin/Dashboard/Dashboard';
import EmployeeDashboard from './EmployeeUser/Dashboard/Dashboard';
import PricingEmployeeDashboard from './PricingEmployee/Dashboard/Dashboard';
import Dashboard from './Sales_CRM/Dashboard/Dashboard';
import ClientDashboard from './Kyc_Onboarding/Dashboard/ClientDashboard';
import LandingPage from './LandingPage/LandingPage';
import Email from './Sales_CRM/EmailTemplate/Email';
import ClientLogin from './Kyc_Onboarding/ClientLogin';
import CustomerDashboard from './Customer_Onboarding/Dashboard/CustomerDashboard';
import KycLogin from './Kyc_Onboarding/KycLogin';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { getSidebarPermission } from './Redux/action/NavbarAction';
import Beatloader from './Common/Loaders/Beatloader';
import Unauthorized from './Common/Unauthorized/Unauthorized';
import Email2 from './Admin/EmailTemplate/Email2';


function App() {
  const dispatch = useDispatch();
  let tokenCookie = Cookies.get("token");
  const [token, setToken] = useState(tokenCookie);
  const [accessedModules, setAccessedModules] = useState([]);
  const [accessedPermission, setAccessedPermission] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [roleInfo, setRoleInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const { loadingSidebarPermissionApi, sidebarPermissionData } = useSelector(state => state?.NavbarReducer);

  const checkToken = () => {
    const cookieToken = Cookies.get('token');
    if (cookieToken !== token) {
      setToken(cookieToken);  // Update the state if token changes
    }
  };

  // Listen for token changes in the app
  useEffect(() => {
    const interval = setInterval(() => {
      checkToken();
    }, 500);
    return () => clearInterval(interval);
  }, [token]);


  useEffect(() => {
    if (token) {
      dispatch(getSidebarPermission(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (sidebarPermissionData) {
      if (sidebarPermissionData?.modules) {
        let module_arr = [];
        sidebarPermissionData?.modules?.map(mod => {
          if (mod.assign_module_user) {
            module_arr.push(mod.short_name)
          }
        });
        setAccessedModules(module_arr);
      }
      if (sidebarPermissionData?.permissions) {
        let permission_arr = [];
        sidebarPermissionData?.permissions?.map(perm => {
          if (perm?.assign_permission_user) {
            permission_arr.push(perm.short_name);
          }
        });
        setAccessedPermission(permission_arr);
      }

      if (sidebarPermissionData?.userInfo) {
        setUserInfo(sidebarPermissionData?.userInfo);
      }
      if (sidebarPermissionData?.role) {
        setRoleInfo(sidebarPermissionData?.role?.name);
      }
    };

    if (loadingSidebarPermissionApi) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingSidebarPermissionApi, sidebarPermissionData])

  if (token && loading) {
    return <Beatloader />
  }

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          {/* <Route exact path='/' element={localStorage.getItem("recruiter_token") ? <RecPostAJob /> : <Home />}></Route> */}
          <Route exact path='dashboard/*' element={<Dashboard />}></Route>
          <Route exact path='Kyc_dashboard/*' element={<ClientDashboard />}></Route>
          <Route exact path='Customer-dashboard/*' element={<CustomerDashboard />}></Route>
          <Route exact path='landing-page' element={<LandingPage />}></Route>
          <Route exact path='/' element={<Login />}></Route>
          <Route exact path='admin-login' element={<AdminLogin />}></Route>
          <Route exact path='admin-login-forget-email' element={<ForgetEmail />}></Route>
          <Route exact path='admin-login-forget-otp' element={<ForgetOtp />}></Route>
          <Route exact path='admin-login-forget-change' element={<ForgetChange />}></Route>
          <Route exact path='email' element={<Email />}></Route>
          <Route exact path='email-2' element={<Email2 />}></Route>
          <Route exact path='client-login' element={<ClientLogin />}></Route>
          <Route exact path='kyc-login' element={<KycLogin />}></Route>
          {/* <Route exact path='admin-dashboard/*' element={<AdminDashboard accessedModules={accessedModules} accessedPermission={accessedPermission} />}></Route> */}
          <Route exact path='admin-dashboard/*' element={
            token ? accessedModules.length && accessedPermission.length ? (
              <AdminDashboard accessedModules={accessedModules} accessedPermission={accessedPermission} userInfo={userInfo} roleInfo={roleInfo} />
            ) : (
              <Beatloader />
            ) : <AdminDashboard />
          } />
          <Route exact path='employee-dashboard/*' element={<EmployeeDashboard />}></Route>
          <Route exact path='pricing-employee-dashboard/*' element={<PricingEmployeeDashboard />}></Route>
          <Route exact path='/unauthorized-access' element={<Unauthorized />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
